import ReactJsonThemed from '@/common/data/PaletteModeReactJson';
import { Alert, Box, Paper } from '@mui/material';

interface TransactionJSONDataProps {
  data?: object;
  helperText?: string;
}

export const TransactionJSONData = ({ data, helperText }: TransactionJSONDataProps) => {
  const childPropsOneLevelDown = Object.keys(data ?? {}); // By default, collapse all child props below one level for performance.
  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      {helperText && (
        <Alert severity="info" sx={{ mb: 2 }}>
          {helperText}
        </Alert>
      )}

      <Paper sx={{ border: 0, boxShadow: 'none', height: '100%' }}>
        <ReactJsonThemed
          shouldCollapse={field =>
            !!field.name && childPropsOneLevelDown.indexOf(field.name) !== -1
          }
          src={data ?? {}}
        />
      </Paper>
    </Box>
  );
};

export default TransactionJSONData;
