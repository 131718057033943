import { Alert, AlertTitle } from '@mui/material';

export const ItemAssignmentStrategyAlert = () => {
  return (
    <Alert severity="info">
      <AlertTitle>Item Assignment Strategy</AlertTitle>
      When buying item(s) from a <strong>collection listing</strong>, you can either:
      <ul>
        <li>
          <strong>Assign a random item</strong> from the listing
        </li>
        <li>
          <strong>Automatically assign</strong> the next available item
        </li>
      </ul>
    </Alert>
  );
};

export default ItemAssignmentStrategyAlert;
