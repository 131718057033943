import { PropsWithChildren } from 'react';
import { ProviderConfig, LDProvider } from 'launchdarkly-react-client-sdk';
import { featureFlagsClientId } from './config';

export const FeatureFlagsProvider = ({ children }: PropsWithChildren) => {
  const config: ProviderConfig = {
    clientSideID: featureFlagsClientId,
    context: {
      kind: 'user',
      key: 'platform-ui-user',
    },
    options: {
      bootstrap: 'localStorage',
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    deferInitialization: false,
  };

  return <LDProvider {...config}>{children}</LDProvider>;
};

export default FeatureFlagsProvider;
