import ClientPaginatedDataGrid from '@/common/data/grid/ClientPaginated';
import { BaseRow } from '@/common/data/grid/constants';
import { AppRoute, MAIN_ROUTES } from '@/routes';
import { ROUTE_NAME } from '@/utils/constants';
import { useAllEmailTemplateTypes } from '@/utils/hooks/emailTemplate';
import { GridColDef } from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { find } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';

interface TemplateGridRow extends BaseRow {
  id: string;
  name: string;
  campaignId: string;
}

const columns: Array<GridColDef<TemplateGridRow>> = [
  {
    field: 'name',
    headerName: 'Template',
    width: 250,
    sortable: false,
    filterable: true,
  },
];


// Initial state specific to this grid.
const initialState: GridInitialStateCommunity = {
  columns: {
    columnVisibilityModel: {
      id: false,
    },
  },
};

const TemplateGrid = () => {
  // Get readonly snaps from the state.
  const { campaignId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rows, setRows] = useState<Array<TemplateGridRow>>([]);
  const { data: emailTemplateTypes } = useAllEmailTemplateTypes();

  // Populate rows when any API data changes.
  useEffect(() => {
    if (campaignId) {
        const newRows = emailTemplateTypes.map((r) => {
            return { id: r.toString(), campaignId: campaignId, name: r.replaceAll('_', ' ') };
        });
        newRows.sort((a, b) => a.name.localeCompare(b.name));
        setRows(newRows);
        setIsLoading(false);
    }
  }, [campaignId, emailTemplateTypes])

  const getRowDoubleClickUrl = useCallback((row: TemplateGridRow) => {
    const templateDetailsRoute = find(
      MAIN_ROUTES,
      route => route.name === ROUTE_NAME.EMAIL_CAMPAIGN_TEMPLATE
    ) as AppRoute;
    return generatePath(templateDetailsRoute.path as string, { campaignId: row.campaignId, templateType: row.id });
  }, []);

  return (
    <ClientPaginatedDataGrid
      columns={columns}
      rows={rows}
      initialState={initialState}
      rowsLoading={isLoading}
      getRowDoubleClickUrl={getRowDoubleClickUrl}
    />
  );
};

export default TemplateGrid;
