import { EditUserInput, InviteUserInput } from '@/api-client';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { adminApi, publicApi } from '../api';
import {
  API_SORT_DIRECTION_ASC,
  API_SORT_DIRECTION_DESC,
  INVITES_QUERY_KEY,
  USERS_QUERY_KEY,
  USER_QUERY_KEY,
} from '../constants';
import { getApiErrorMessage } from '../helpers';
import { auth0ClientId } from '@/config'; 

export const useUser = (userId: string) => {
  return useQuery({
    queryKey: [USER_QUERY_KEY, userId],
    queryFn: () => adminApi.user.usersUserIdGet(userId).then(res => res.data),
    enabled: Boolean(userId),
  });
};

export const useUsers = (
  pageSize?: number,
  cursor?: string,
  sort?: {
    fieldName?: string;
    direction?: typeof API_SORT_DIRECTION_ASC | typeof API_SORT_DIRECTION_DESC;
  },
  organizationId?: string
) => {
  return useQuery({
    queryKey: [USERS_QUERY_KEY, sort, pageSize, cursor, organizationId],
    queryFn: () =>
      adminApi.user
        .usersGet(sort?.direction, cursor, pageSize, organizationId)
        .then(res => res.data),
  });
};

export const useInviteUser = () => {
  const queryClient = useQueryClient();

  // if we ever want to break these hooks out into a library, we don't want this to be a dependency
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (body: InviteUserInput) => adminApi.user.usersInvitePost(body),
    onSuccess: (_, variables) => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey:[USER_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [INVITES_QUERY_KEY] });
      enqueueSnackbar(`An invite has been sent to ${variables.email}`, { variant: 'success' });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error creating user invite', { variant: 'error' });
    },
  });

  return mutation;
};

export const useEditUser = () => {
  const queryClient = useQueryClient();

  // if we ever want to break these hooks out into a library, we don't want this to be a dependency
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: ({userId, body} : {userId: string, body: EditUserInput}) => adminApi.user.usersUserIdPatch(userId, body),
    onSuccess: (_, variables) => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey:[USER_QUERY_KEY, variables.userId] });
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] });
      enqueueSnackbar(`User has been updated`, { variant: 'success' });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error updating user', { variant: 'error' });
    },
  });

  return mutation;
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  // if we ever want to break these hooks out into a library, we don't want this to be a dependency
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (userId: string) => adminApi.user.usersUserIdDelete(userId),
    onSuccess: (_, variables) => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey:[USER_QUERY_KEY, variables] });
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] });
      enqueueSnackbar(`User has been deleted`, { variant: 'success' });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error deleting user', { variant: 'error' });
    },
  });

  return mutation;
};

export const useResendEmail = () => {
  const queryClient = useQueryClient();

  // if we ever want to break these hooks out into a library, we don't want this to be a dependency
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (auth0_id: string) => publicApi.auth0.auth0VerificationEmailPost({ auth0_id: auth0_id, client_id: auth0ClientId }),
    onSuccess: () => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] });
      enqueueSnackbar(`The verify email request has been resent`, { variant: 'success' });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error resending verify email request', { variant: 'error' });
    },
  });

  return { ...mutation };
};
