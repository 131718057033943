import { CollectionOutputRevealStrategyEnum } from '@/api-client';
import { Alert, AlertTitle } from '@mui/material';
import { useMemo } from 'react';

interface RevealStrategyAlertProps {
  strategy?: CollectionOutputRevealStrategyEnum;
}

export const RevealStrategyAlert = ({ strategy }: RevealStrategyAlertProps) => {
  const alertText = useMemo(() => {
    switch (strategy) {
      case CollectionOutputRevealStrategyEnum.Instant:
        return 'The platform always returns the actual artwork and metadata for the items.';
      case CollectionOutputRevealStrategyEnum.Delayed:
        return 'The platform will return preview artwork and metadata for the items until a user triggers a collection wide reveal.';
      default:
        return 'The platform always returns the actual artwork and metadata for the items.';
    }
  }, [strategy]);

  if (!alertText) return null;

  return (
    <Alert severity="info">
      <AlertTitle>{strategy}</AlertTitle>
      {alertText}
    </Alert>
  );
};

export default RevealStrategyAlert;
