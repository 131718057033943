import { proxy } from 'valtio';
import { TestModeState } from '@/utils/types';

const testModeCacheKey =  'phosphor.dev.test-mode-state';
const initialState: TestModeState = {
  active: false,
};

export const testModeState = proxy<TestModeState>(initialState);

// ACTIONS
export const getTestMode = () => {
  const storedState = localStorage.getItem(testModeCacheKey);
  return storedState ? JSON.parse(storedState) : initialState.active;
};

export const setTestMode = (active: boolean) => {
  testModeState.active = active;
  localStorage.setItem(testModeCacheKey, JSON.stringify(active));
};

// init testModeState using session state or default state
const initTestModeState = getTestMode();
testModeState.active = initTestModeState;
