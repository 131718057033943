import { EntityPageVisit } from '@/types/pagevisit';
import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { ENTITY_PAGE_VISIT_TYPE } from '../constants';
import { getEntityPageVisitLocalStorageUserKey, uuidv4 } from '../helpers';
import { useSnapshot } from 'valtio';
import { authState } from '../states/authState';

const safeParseFromLocalStorage = (userId: string, roleId: string) => {
  const localStorageKey = getEntityPageVisitLocalStorageUserKey(userId, roleId);
  const unparsedEntityPageVisits = localStorage.getItem(localStorageKey);
  let parsedEntityPageVisits = [];
  try {
    parsedEntityPageVisits = unparsedEntityPageVisits ? JSON.parse(unparsedEntityPageVisits) : [];
  } catch (error) {
    console.warn('Failed to parse page visits, resetting and continuing with empty array.');
  }
  return parsedEntityPageVisits;
};

let sharedHookKey = uuidv4();

export const useAllEntityPageVisits = () => {
  const { platformUser } = useSnapshot(authState);
  const [entityPageVisits, setEntityPageVisits] = useState<Array<EntityPageVisit>>([]);

  const registerPageVisit = (
    context: string,
    path: string,
    type: ENTITY_PAGE_VISIT_TYPE,
    platformId: string
  ) => {
    if (!platformUser?.id || !context || path === '/' || !platformId) return;

    // Get the current page visits from local storage.
    const localStorageKey = getEntityPageVisitLocalStorageUserKey(
      platformUser?.id,
      platformUser?.role_id
    );
    const unparsedEntityPageVisits = localStorage.getItem(localStorageKey);
    const entityPageVisits: Array<EntityPageVisit> = unparsedEntityPageVisits
      ? JSON.parse(unparsedEntityPageVisits)
      : [];

    const lastEntityPageVisit = entityPageVisits?.length
      ? find(
          entityPageVisits,
          pageVisit => pageVisit.type === type && pageVisit.platformId === platformId
        )
      : null;

    const lastVisitedOn = new Date().toISOString();

    // Skip if we've already registered a page visit for this entity within the last 5 seconds.
    if (lastEntityPageVisit) {
      lastEntityPageVisit.visitedOn = lastVisitedOn;
      localStorage.setItem(localStorageKey, JSON.stringify(entityPageVisits));
      setEntityPageVisits(entityPageVisits);
    } else {
      const newEntityPageVisits = [
        ...entityPageVisits,
        {
          type: type,
          path: path,
          context: context,
          visitedOn: lastVisitedOn,
          platformId: platformId,
        },
      ];
      localStorage.setItem(
        getEntityPageVisitLocalStorageUserKey(platformUser?.id, platformUser.role_id),
        JSON.stringify(newEntityPageVisits)
      );
      setEntityPageVisits(newEntityPageVisits);
    }
  };

  const clearEntityPageVisits = () => {
    if (!platformUser?.id) return;
    localStorage.removeItem(
      getEntityPageVisitLocalStorageUserKey(platformUser?.id, platformUser?.role_id)
    );
    setEntityPageVisits([]);
    sharedHookKey = uuidv4();
  };

  useEffect(() => {
    if (!platformUser?.id) return;
    setEntityPageVisits(safeParseFromLocalStorage(platformUser?.id, platformUser?.role_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformUser?.id, sharedHookKey]);

  return {
    entityPageVisits,
    registerPageVisit,
    clearEntityPageVisits,
  };
};

export default useAllEntityPageVisits;
