import { Box, Chip, ChipProps, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  ItemContent,
  ITEM_CONTENT_IMAGE_PLACEHOLDER,
  ITEM_CONTENT_VIDEO_AUDIO_PLACEHOLDER,
} from './Index';
import { HTTP_URL_REGEX } from '@/utils/constants';
import PhotoIcon from '@mui/icons-material/PhotoOutlined';
import AnimationIcon from '@mui/icons-material/AnimationOutlined';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

interface ItemContentTabsProps {
  imageUrl?: string;
  animationUrl?: string;
}

interface ItemContentTabOptionProps {
  label: string;
  icon: ReactJSXElement;
  active: boolean;
  chipProps?: ChipProps;
}

const ItemContentTabOption = ({ label, icon, chipProps, active }: ItemContentTabOptionProps) => {
  return (
    <Chip
      label={label}
      variant={active ? 'filled' : 'outlined'}
      color={active ? 'primary' : 'secondary'}
      icon={icon}
      {...(chipProps ?? {})}
      sx={{
        borderRadius: 0,
        width: '100%',
        '&:hover': { cursor: 'pointer' },
      }}
    />
  );
};

export const ItemContentTabs = ({ imageUrl, animationUrl }: ItemContentTabsProps) => {
  const [tabIndex, setTabIndex] = useState('0');
  const theme = useTheme();

  const isValidAnimationUrl = useMemo(() => {
    return HTTP_URL_REGEX.test(animationUrl ?? '');
  }, [animationUrl]);

  useEffect(() => {
    setTabIndex(isValidAnimationUrl ? '1' : '0'); // Animation URL takes precedence.
  }, [isValidAnimationUrl]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',

        '.MuiTabPanel-root': {
          p: 0,
          width: '100%',
          height: 'calc(100% - 56px)',
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '30px',
          mb: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ItemContentTabOption
          chipProps={{ onClick: () => setTabIndex('0') }}
          active={tabIndex === '0'}
          label="Image"
          icon={<PhotoIcon />}
        />

        <ItemContentTabOption
          chipProps={{ onClick: () => setTabIndex('1') }}
          active={tabIndex === '1'}
          label="Animation/Audio"
          icon={<AnimationIcon />}
        />
      </Box>

      <Box
        sx={{
          width: '100%',
          height: 'calc(100% - 35px)',
          border: `1px solid ${theme.palette.divider}}`,
        }}
      >
        {tabIndex === '0' && (
          <ItemContent contentUrl={imageUrl} placeholderIcon={ITEM_CONTENT_IMAGE_PLACEHOLDER} />
        )}

        {tabIndex === '1' && (
          <ItemContent
            contentUrl={isValidAnimationUrl ? animationUrl : undefined}
            placeholderIcon={ITEM_CONTENT_VIDEO_AUDIO_PLACEHOLDER}
          />
        )}
      </Box>
    </Box>
  );
};

export default ItemContentTabs;
