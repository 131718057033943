import { useCallback } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export interface AuthContext {
  onLogin: () => void;
}

const AuthPage = () => {
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';
  const navigate = useNavigate();

  const onLogin = useCallback(() => {
    navigate(from);
  }, [from, navigate]);

  const authContext: AuthContext = { onLogin };

  return <Outlet context={authContext} />;
};

export default AuthPage;
