import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';

interface PrettyDateProps {
  date: string;
}

/**
 * The most basic way to display a date in the common read-only format.
 */
export const PrettyDate = ({ date }: PrettyDateProps) => {
  if (date === '') return (<Typography variant="body2">Never</Typography>)

  return (
    <Typography variant="body2">
      {DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
    </Typography>
  );
};

export default PrettyDate;
