import { useState } from 'react';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
interface BasicInfoDisplayProps {
  title: string;
  children?: React.ReactNode | string;
  sx?: SxProps;
  variant?: 'boolean' | 'inline';
}

interface SecretInfoDisplayProps {
  title: string;
  children?: React.ReactNode | string;
  sx?: SxProps;
  variant?: 'boolean' | 'inline';
}

const CommonTitle = ({ title }: { title: string }) => {
  return (
    <Typography variant="caption" sx={{ display: 'block' }}>
      {title}
    </Typography>
  );
};

const BooleanFieldDisplay = ({ title, children, sx = {} }: BasicInfoDisplayProps) => {
  const booleanValue = Boolean(children as boolean);

  return (
    <Box
      sx={{
        color: booleanValue ? 'colors.success' : 'colors.warning',
        ...sx,
      }}
    >
      <CommonTitle title={title} />
      {booleanValue ? 'Yes' : 'No'}
    </Box>
  );
};

const InlineDisplay = ({ title, children, sx = {} }: BasicInfoDisplayProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        ...sx,
      }}
    >
      <Typography variant="caption" sx={{ display: 'block', marginRight: 1 }}>
        {title}
      </Typography>

      {children || <></>}
    </Box>
  );
};

const DefaultInfoDisplay = ({ title, children, sx = {} }: BasicInfoDisplayProps) => {
  return (
    <Box sx={{ ...sx }}>
      <CommonTitle title={title} />

      {children || <></>}
    </Box>
  );
};

/**
 * The most basic way to display read-only information.
 */
export const BasicInfoDisplay = ({ variant, ...props }: BasicInfoDisplayProps) => {
  if (variant === 'boolean') {
    return <BooleanFieldDisplay {...props} />;
  } else if (variant === 'inline') {
    return <InlineDisplay {...props} />;
  } else {
    return <DefaultInfoDisplay {...props} />;
  }
};

export const SecretInfoDisplay = ({ title, children } : SecretInfoDisplayProps ) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => { setIsVisible(!isVisible); };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <BasicInfoDisplay title={title}>
      <IconButton onClick={toggleVisibility} aria-label="toggle visibility" sx={{ mr: .5 }}>
        {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
      </IconButton>        
        {isVisible ? children : '*******'}
      </BasicInfoDisplay>
    </div>
  );
}

export default BasicInfoDisplay;
