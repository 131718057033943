import PermissionModalButton from '@/common/button/PermissionModal';
import DisconnectStripeForm from './setup/DisconnectForm';

export const DisconnectStripeAccountButton = () => {
  return (
    <PermissionModalButton
      modalTitle="Disconnect stripe account"
      buttonLabel="Disconnect"
      modalDescription="Are you sure you want to disconnect your stripe account?"
      requiredPermissions={['payment_integrations:delete']}
    >
      {({ closeModal }) => {
        const close = () => {
          closeModal();
        };
        return <DisconnectStripeForm onCancel={close} onSuccess={close} />;
      }}
    </PermissionModalButton>
  );
};

export default DisconnectStripeAccountButton;
