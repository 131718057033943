import PageContentTabs, {
  ContentTab,
  OVERVIEW_TAB_LABEL,
  OVERVIEW_TAB_VALUE,
} from '@/common/page/ContentTabs';
import Page, { entityPageVisitState } from '@/common/page/Index';
import { MAIN_ROUTES } from '@/routes';
import { ENTITY_PAGE_VISIT_TYPE, ROUTE_NAME } from '@/utils/constants';
import { useCollection } from '@/utils/hooks/collection';
import { useItem, useItemTitle } from '@/utils/hooks/item';
import { useListing } from '@/utils/hooks/listing';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { generatePath } from 'react-router-dom';
import PurchaseIntentGrid from '../purchase-intent/Grid';
import ListingDetailsOverview from './DetailsOverview';

const ListingDetailsPage = () => {
  const {
    listingId: listingIdFromUrl,
    collectionId: collectionIdFromUrl,
    itemId: itemIdFromUrl,
  } = useParams();
  const { data: listing, isError: listingError } = useListing(listingIdFromUrl ?? '');
  const { data: item } = useItem(listing?.item_id ?? '');
  const itemTitle = useItemTitle(item?.attributes, item?.token_id);
  const { data: collection } = useCollection(listing?.collection_id ?? item?.collection_id ?? '');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const tabs = useMemo(() => {
    const result: Array<ContentTab> = [
      {
        label: OVERVIEW_TAB_LABEL,
        value: OVERVIEW_TAB_VALUE,
        gridContent: false,
        content: listing?.id ? <ListingDetailsOverview listingId={listing?.id} /> : <></>,
      },
      {
        label: 'Purchase Intents',
        value: 'purchase-intents',
        gridContent: true,
        content: <PurchaseIntentGrid listingId={listing?.id} />,
      },
    ];
    return result;
  }, [listing?.id]);

  useEffect(() => {
    if (!listing?.id || entityPageVisitState?.data?.platformId === listing?.id) return;

    entityPageVisitState.data = {
      type: ENTITY_PAGE_VISIT_TYPE.LISTING,
      context: !item?.id
        ? `Listing for collection ${collection?.name ?? ''}`
        : `Listing for item ${itemTitle}`,
      platformId: listing?.id,
    };
  }, [listing?.id, collection?.name, item?.id, itemTitle]);

  useEffect(() => {
    if (listingError) {
      const overviewRoute = MAIN_ROUTES.find(route => route.name === ROUTE_NAME.LISTINGS_OVERVIEW);
      if (!overviewRoute) return;
      enqueueSnackbar('Listing not found, returning to listing overview.', {
        variant: 'info',
      });
      navigate(generatePath(overviewRoute.path));
    }
  }, [listingError, navigate, enqueueSnackbar]);

  const backToRoutePath = useMemo(() => {
    const overviewRoute = MAIN_ROUTES.find(route => route.name === ROUTE_NAME.LISTINGS_OVERVIEW);
    if (!overviewRoute) return undefined; // fallback route should always exist.

    let routePath = generatePath(overviewRoute.path);

    if (itemIdFromUrl && collectionIdFromUrl) {
      const itemRoute = MAIN_ROUTES.find(route => route.name === ROUTE_NAME.ITEM_DETAILS);
      if (itemRoute)
        routePath = `${generatePath(itemRoute.path, {
          collectionId: collectionIdFromUrl,
          itemId: itemIdFromUrl,
        })}?tab=listings`;
    } else if (collectionIdFromUrl) {
      const collectionRoute = MAIN_ROUTES.find(
        route => route.name === ROUTE_NAME.COLLECTION_DETAILS
      );
      if (collectionRoute)
        routePath = `${generatePath(collectionRoute.path, {
          collectionId: collectionIdFromUrl,
        })}?tab=listings`;
    }

    return routePath;
  }, [collectionIdFromUrl, itemIdFromUrl]);

  return (
    <Page
      testIdPrefix="listing-details"
      helmet="Listing Details"
      title="Listing Details"
      backTo={{
        routePath: backToRoutePath ?? '',
        buttonLabel: `Back To All${
          itemIdFromUrl && collectionIdFromUrl ? ' Item' : collectionIdFromUrl ? ' Collection' : ''
        } Listings`,
      }}
    >
      <PageContentTabs tabs={tabs} />
    </Page>
  );
};

export default ListingDetailsPage;
