import { EditOrganizationPayoutConfigurationInput } from '@/api-client';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { adminApi, axiosAuthedApiInstance } from '../api';
import { ORG_QUERY_KEY, ORG_PAYOUT_CONFIG_KEY } from '../constants';
import { getApiErrorMessage } from '../helpers';
import { betaBaseApiUrl } from '@/config';


export const useOrganization = (organizationId?: string) => {
  const getOrg = useQuery({
    queryKey: [ORG_QUERY_KEY, organizationId],
    queryFn: () =>
      adminApi.org.organizationsOrganizationIdGet(organizationId as string).then(res => res.data),
    enabled: Boolean(organizationId),
  });

  return getOrg;
};

export const useOrganizationPayoutConfig = (organizationId?: string) => {
  const getOrg = useQuery({
    queryKey: [ORG_PAYOUT_CONFIG_KEY, organizationId],
    queryFn: () =>
      adminApi.org
        .organizationsOrganizationIdPayoutConfigurationGet(organizationId as string)
        .then(res => res.data),
    enabled: Boolean(organizationId),
  });

  return getOrg;
};

export const useEditOrganizationPayoutConfig = (organizationId: string) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (body: EditOrganizationPayoutConfigurationInput) =>
      adminApi.org
        .organizationsOrganizationIdPayoutConfigurationPatch(organizationId as string, body)
        .then(res => res.data),
    onSuccess: () => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey: [ORG_PAYOUT_CONFIG_KEY] });
      enqueueSnackbar('Payout config updated', { variant: 'success' });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error setting payout config', { variant: 'error' });
    },
  });

  return mutation;
};

interface OrganizationProfileOutput {
  public_name?: string;
  description?: string;
  media?: string;
  socials?: string;
  slug?: string;
  website?: string;
}  

export interface EditOrganizationProfileInput {
  public_name?: string;
  description?: string;
  media?: string;
  socials?: string;
  slug?: string;
  website?: string;
}

export const useOrganizationProfile = () => {
  return useQuery({
    queryKey: [ORG_QUERY_KEY],
    queryFn: () =>
      axiosAuthedApiInstance
        .get('beta/organizations/profile', { baseURL: betaBaseApiUrl })
        .then(res => res.data as OrganizationProfileOutput),
  });
};

export const useEditOrganizationProfile = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

 const mutation = useMutation({
    mutationFn: (body: EditOrganizationProfileInput) =>
      axiosAuthedApiInstance
        .patch('beta/organizations/profile', body, { baseURL: betaBaseApiUrl })
        .then(res => res.data as OrganizationProfileOutput),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ORG_QUERY_KEY] });
        enqueueSnackbar('Organization Profile updated', { variant: 'success' });
      },
      onError: (error: any) => {
        const errorMessage = getApiErrorMessage(error);
        enqueueSnackbar(errorMessage || 'Error updating organization profile', { variant: 'error' });
      },
  });

  return mutation;
}