import { GridColDef } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { useCollections } from '@/utils/hooks/collection';
import find from 'lodash/find';
import { BlockExplorerFilter, BlockExplorerFilterType } from '@/common/data/BlockExplorerFilter';
import ServerPaginatedDataGrid, {
  DEFAULT_PAGINATED_GRID_PAGE,
  PaginatedGridPage,
} from '@/common/data/grid/ServerPaginated';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { proxy, useSnapshot } from 'valtio';
import { CollectionsPaginatedOutputResultsInner } from '@/api-client';
import { useAllPublicNetworks } from '@/utils/hooks/publicNetwork';
import { PublicNetworksPaginatedOutputResultsInner } from '@/api-client-public';
import { API_SORT_DIRECTION_ASC, ROUTE_NAME } from '@/utils/constants';
import NetworkName from '@/common/data/NetworkName';
import { useSortAndFilterGrid } from '@/utils/hooks/useSortAndFilterGrid';
import PlatformEntityIdentifier from '@/common/data/PlatformEntityIdentifier';
import TransactionDetailsOverview, {
  TRANSACTION_OVERVIEW_DETAILS_POPOVER_SIZE,
} from '@/transactions/DetailsOverview';
import { generatePath } from 'react-router';
import { AppRoute, MAIN_ROUTES } from '@/routes';
import { BaseRow } from '@/common/data/grid/constants';

// Row specific to this grid.
interface CollectionGridRow extends BaseRow {
  name: string;
  networkId?: number | null;
  networkName?: string | null;
  networkTestnet?: boolean;
  tokenType?: string | null;
  contractAddress?: string | null;
  transactionId?: string | null;
  revealStrategy?: string | null;
  editableMetadata?: string | null;
}

// Columns specific to this grid.
const columns: Array<GridColDef<CollectionGridRow>> = [
  {
    field: 'name',
    headerName: 'Collection Name',
    width: 250,
    sortable: true,
    filterable: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 150,
    renderCell: (params: { row: CollectionGridRow }) => (
      <PlatformEntityIdentifier
        tooltipContext="this collection ID"
        entityId={params.row.id as string}
      />
    ),
    filterable: true,
  },
  {
    field: 'networkId',
    headerName: 'Network ID',
    width: 125,
    filterable: true,
  },
  {
    field: 'networkName',
    headerName: 'Contract Network',
    width: 215,
    renderCell: (params: { row: CollectionGridRow }) =>
      params.row.networkName ? (
        <NetworkName name={params.row.networkName} testnet={params.row.networkTestnet} />
      ) : (
        <></>
      ),
  },
  {
    field: 'tokenType',
    headerName: 'Token Type',
    width: 115,
  },
  {
    field: 'transactionId',
    headerName: 'Deployment Tx ID',
    width: 145,
    renderCell: (params: { row: CollectionGridRow }) =>
      params.row.transactionId ? (
        <PlatformEntityIdentifier
          tooltipContext="this collection's deployment transaction ID"
          entityId={params.row.transactionId}
          popoverPreviewContent={
            <TransactionDetailsOverview transactionId={params.row.transactionId} />
          }
          popoverPreviewSx={{ ...TRANSACTION_OVERVIEW_DETAILS_POPOVER_SIZE }}
        />
      ) : (
        <></>
      ),
    filterable: true,
  },
  {
    field: 'contractAddress',
    headerName: 'Contract Address',
    width: 145,
    renderCell: (params: { row: CollectionGridRow }) =>
      params.row.contractAddress ? (
        <BlockExplorerFilter
          filterType={BlockExplorerFilterType.ADDRESS}
          filterValue={params.row.contractAddress}
          networkId={Number(params.row.networkId)}
        />
      ) : (
        <>Not Deployed</>
      ),
    filterable: true,
  },
  {
    field: 'revealStrategy',
    headerName: 'Reveal Strategy',
    width: 115,
    sortable: false,
  },
  {
    field: 'editableMetadata',
    headerName: 'Editable Metadata',
    width: 115,
    sortable: false,
  },
];

// Initial state specific to this grid.
const initialState: GridInitialStateCommunity = {
  columns: {
    columnVisibilityModel: {
      id: false,
      networkId: false,
      networkTestnet: false,
      transactionId: false,
    },
  },
};

// Will map API data (in this case a mix of multiple sources) to rows.
const mapToRow = (
  collectionOutput: CollectionsPaginatedOutputResultsInner,
  networkOutput?: PublicNetworksPaginatedOutputResultsInner | null
): CollectionGridRow => {
  return {
    id: collectionOutput.id,
    name: collectionOutput.name,
    networkId: collectionOutput?.deployment?.network_id,
    networkName: networkOutput?.name,
    networkTestnet: networkOutput?.testnet,
    tokenType: collectionOutput?.deployment?.token_type,
    contractAddress: collectionOutput?.deployment?.address,
    transactionId: collectionOutput?.deployment?.transaction_id,
    original: { collection: collectionOutput, network: networkOutput },
    revealStrategy: collectionOutput?.reveal_strategy,
    editableMetadata: collectionOutput?.editable_metadata === true ? 'Enabled' : 'Disabled',
  };
};

const pageState: PaginatedGridPage<CollectionGridRow> = proxy({
  ...DEFAULT_PAGINATED_GRID_PAGE,
  sortBy: {
    field: 'name',
    direction: API_SORT_DIRECTION_ASC,
  },
});

const CollectionsGrid = () => {
  // Get readonly snaps from the state.
  const pageSnap = useSnapshot(pageState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [collectionIds, setCollectionids] = useState<Array<string>>([]);

  // Construct sort and filter args based on the snap of the active page.
  const { sortBy, filterBy } = useSortAndFilterGrid(pageState);

  // Get all API data for current page.
  const {
    data: collectionsResponse,
    isPending: collectionsLoading,
    isFetched: collectionsFetched,
  } = useCollections(pageSnap.pageSize, pageSnap.cursor, sortBy, filterBy);

  const { data: networks, isPending: networksLoading } = useAllPublicNetworks();

  // Populate rows when any API data changes.
  useEffect(() => {
    pageState.rows =
      collectionsResponse?.results.map(collection => {
        const network = collection?.deployment?.network_id
          ? find(networks, network => {
              return network.id === collection?.deployment?.network_id;
            })
          : null;
        return mapToRow(collection, network);
      }) ?? [];
    pageState.nextCursor = collectionsResponse?.cursor ?? DEFAULT_PAGINATED_GRID_PAGE.cursor;
  }, [collectionsResponse?.results, networks, collectionsResponse?.cursor]);

  // Derive collection IDs when collection data changes.
  // These are needed to bulk query the contracts.
  useEffect(() => {
    setCollectionids(collectionsResponse?.results.map(collection => collection.id) ?? []);
  }, [collectionsResponse?.results]);

  // We're done loading if ALL API queries for the grid rows have completed.
  useEffect(() => {
    setIsLoading(collectionsLoading || networksLoading);
  }, [
    collectionsLoading,
    networksLoading,
    collectionsFetched,
    collectionIds,
    collectionsResponse?.total_results,
  ]);

  const getRowDoubleClickUrl = useCallback((row: CollectionGridRow) => {
    const collectionDetailsRoute = find(
      MAIN_ROUTES,
      route => route.name === ROUTE_NAME.COLLECTION_DETAILS
    ) as AppRoute;
    return generatePath(collectionDetailsRoute.path as string, { collectionId: row.id });
  }, []);

  return (
    <ServerPaginatedDataGrid
      columns={columns}
      initialState={initialState}
      // eslint-disable-next-line valtio/state-snapshot-rule
      activePageProxy={pageState}
      pageLoading={isLoading}
      totalRowCount={collectionsResponse?.total_results ?? 0}
      getRowDoubleClickUrl={getRowDoubleClickUrl}
    />
  );
};

export default CollectionsGrid;
