import { GridColDef } from '@mui/x-data-grid';
import { useCallback, useEffect } from 'react';
import ServerPaginatedDataGrid, {
  DEFAULT_PAGINATED_GRID_PAGE,
  PaginatedGridPage,
} from '@/common/data/grid/ServerPaginated';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { proxy, useSnapshot } from 'valtio';
import {
  GetOrgInvitationsPaginatedOutputResultsInner,
  RolesPaginatedOutputResultsInner,
} from '@/api-client';
import { useAllRoles } from '@/utils/hooks/role';
import { API_SORT_DIRECTION_ASC, ROUTE_NAME } from '@/utils/constants';
import { useSortAndFilterGrid } from '@/utils/hooks/useSortAndFilterGrid';
import PlatformEntityIdentifier from '@/common/data/PlatformEntityIdentifier';
import { BaseRow } from '@/common/data/grid/constants';
import { useInvites } from '@/utils/hooks/invites';
import PrettyDate from '@/common/data/PrettyDate';
import { AppRoute, MAIN_ROUTES } from '@/routes';
import { generatePath } from 'react-router-dom';
import { find } from 'lodash';

// Row specific to this grid.
interface UserGridRow extends BaseRow {
  email: string;
  roleId: string;
  token: string;
  roleName?: string;
  expires: string;
}

// Columns specific to this grid.
const columns: Array<GridColDef<UserGridRow>> = [
  {
    field: 'id',
    headerName: 'ID',
    width: 150,
    filterable: false,
    sortable: false,
    renderCell: (params: { row: UserGridRow }) => (
      <PlatformEntityIdentifier
        tooltipContext="this invite ID"
        entityId={params.row.id as string}
      />
    ),
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 2,
    sortable: true,
  },
  {
    field: 'roleName',
    headerName: 'Role',
    width: 125,
    filterable: false,
    sortable: true,
  },
  {
    field: 'expires',
    headerName: 'Expires',
    width: 250,
    filterable: false,
    sortable: true,
    renderCell: (params: { row: UserGridRow }) => {
      return params.row.expires ? <PrettyDate date={params.row.expires} /> : <>Never</>;
    },
  },
];

// Initial state specific to this grid.
const initialState: GridInitialStateCommunity = {
  sorting: {
    sortModel: [{ field: 'email', sort: 'asc' }],
  },
  columns: {
    columnVisibilityModel: {
      id: false,
    },
  },
};

const mapToRow = (
  invite: GetOrgInvitationsPaginatedOutputResultsInner,
  role?: RolesPaginatedOutputResultsInner
): UserGridRow => {
  return {
    id: invite.id,
    email: invite.email ?? '',
    expires: invite.expiration ?? '',
    roleId: invite.role_id,
    roleName: role?.name as any,
    token: invite.token,
    original: { invite, role },
  };
};

const pageState: PaginatedGridPage<UserGridRow> = proxy({
  ...DEFAULT_PAGINATED_GRID_PAGE,
  sortBy: initialState.sorting?.sortModel
    ? {
        field: initialState.sorting?.sortModel[0].field,
        direction:
          initialState.sorting?.sortModel[0].sort === 'asc'
            ? API_SORT_DIRECTION_ASC
            : API_SORT_DIRECTION_ASC,
      }
    : undefined,
});

const InvitesGrid = () => {
  // Get readonly snaps from the state.
  const pageSnap = useSnapshot(pageState);

  // Construct sort and filter args based on the snap of the active page.
  const { sortBy } = useSortAndFilterGrid(pageState);

  // Get all API data for current render.
  const { data: usersResponse, isPending: usersLoading } = useInvites(
    pageSnap.pageSize,
    pageSnap.cursor,
    sortBy
  );
  const { data: roles, isPending: rolesLoading } = useAllRoles();

  // Populate rows when any API data changes.
  useEffect(() => {
    pageState.rows =
      usersResponse?.results
        .filter(invite => invite.email)
        .map(invite => {
          const role = roles.find((r: RolesPaginatedOutputResultsInner) => r.id === invite.role_id);
          return mapToRow(invite, role);
        }) ?? [];
    pageState.nextCursor = usersResponse?.cursor ?? DEFAULT_PAGINATED_GRID_PAGE.cursor;
  }, [usersResponse?.results, usersResponse?.cursor, roles]);

  const getRowDoubleClickUrl = useCallback((row: UserGridRow) => {
    const detailsRoute = find(
      MAIN_ROUTES,
      route => route.name === ROUTE_NAME.USER_INVITED_DETAILS
    ) as AppRoute;
    return generatePath(detailsRoute.path as string, { token: row.token });
  }, []);

  return (
    <ServerPaginatedDataGrid
      columns={columns}
      initialState={initialState}
      // eslint-disable-next-line valtio/state-snapshot-rule
      activePageProxy={pageState}
      pageLoading={usersLoading || rolesLoading}
      totalRowCount={pageState.rows.length ?? 0}
      getRowDoubleClickUrl={getRowDoubleClickUrl}
    />
  );
};

export default InvitesGrid;
