import {
  useContractRead,
  UseContractReadConfig,
  useContractWrite,
  UseContractWriteConfig,
  usePrepareContractWrite,
  UsePrepareContractWriteConfig,
  useContractEvent,
  UseContractEventConfig,
  useNetwork,
  useChainId,
  Address,
} from 'wagmi';
import { ReadContractResult, WriteContractMode, PrepareWriteContractResult } from 'wagmi/actions';

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC20
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc20ABI = [
  {
    type: 'event',
    inputs: [
      { name: 'owner', type: 'address', indexed: true },
      { name: 'spender', type: 'address', indexed: true },
      { name: 'value', type: 'uint256', indexed: false },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    inputs: [
      { name: 'from', type: 'address', indexed: true },
      { name: 'to', type: 'address', indexed: true },
      { name: 'value', type: 'uint256', indexed: false },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', type: 'address' },
      { name: 'spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'amount', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'decimals',
    outputs: [{ type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'recipient', type: 'address' },
      { name: 'amount', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'sender', type: 'address' },
      { name: 'recipient', type: 'address' },
      { name: 'amount', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'addedValue', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'subtractedValue', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ type: 'bool' }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PlatformDeposit
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export const platformDepositABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: 'paymentTokenAddress_', internalType: 'address', type: 'address' },
      { name: 'payoutAddress_', internalType: 'address', type: 'address' },
      { name: 'maxDeposit_', internalType: 'uint256', type: 'uint256' },
    ],
  },
  { type: 'error', inputs: [], name: 'CannotBeZeroAddress' },
  { type: 'error', inputs: [], name: 'CannotExceedMaxDeposit' },
  { type: 'error', inputs: [], name: 'InsufficientBalance' },
  { type: 'error', inputs: [], name: 'ValueCannotBeZero' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'clientId', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'depositor', internalType: 'address', type: 'address', indexed: false },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Deposit',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferStarted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'previousOwner', internalType: 'address', type: 'address', indexed: true },
      { name: 'newOwner', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'clientId', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'purchaseId', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'PaymentTransfer',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'previousAdminRole', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'newAdminRole', internalType: 'bytes32', type: 'bytes32', indexed: true },
    ],
    name: 'RoleAdminChanged',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleGranted',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'account', internalType: 'address', type: 'address', indexed: true },
      { name: 'sender', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'RoleRevoked',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'clientId', internalType: 'bytes32', type: 'bytes32', indexed: true },
      { name: 'depositor', internalType: 'address', type: 'address', indexed: false },
      { name: 'amount', internalType: 'uint256', type: 'uint256', indexed: false },
    ],
    name: 'Withdraw',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'PAYOUT_EXECUTOR',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'acceptOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'accounts', internalType: 'address[]', type: 'address[]' }],
    name: 'addPayoutRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'clientId', internalType: 'bytes32', type: 'bytes32' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'clientId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'deposit',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'role', internalType: 'bytes32', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'maxDeposit',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'paymentTokenAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'payoutAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'pendingOwner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'clientId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'purchaseId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'purchasePayout',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'accounts', internalType: 'address[]', type: 'address[]' }],
    name: 'removePayoutRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'role', internalType: 'bytes32', type: 'bytes32' },
      { name: 'account', internalType: 'address', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'payoutAddress_', internalType: 'address', type: 'address' }],
    name: 'setPayoutAddress',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'clientId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'amount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'withdraw',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'clientId', internalType: 'bytes32', type: 'bytes32' },
      { name: 'depositor', internalType: 'address', type: 'address' },
    ],
    name: 'withdrawable',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
] as const;

/**
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export const platformDepositAddress = {
  137: '0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1',
} as const;

/**
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export const platformDepositConfig = {
  address: platformDepositAddress,
  abi: platformDepositABI,
} as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__.
 */
export function useErc20Read<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi'
  > = {} as any
) {
  return useContractRead({ abi: erc20ABI, ...config } as UseContractReadConfig<
    typeof erc20ABI,
    TFunctionName,
    TSelectData
  >);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"allowance"`.
 */
export function useErc20Allowance<
  TFunctionName extends 'allowance',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'allowance',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"balanceOf"`.
 */
export function useErc20BalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"decimals"`.
 */
export function useErc20Decimals<
  TFunctionName extends 'decimals',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'decimals',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"name"`.
 */
export function useErc20Name<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"symbol"`.
 */
export function useErc20Symbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"totalSupply"`.
 */
export function useErc20TotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof erc20ABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>,
    'abi' | 'functionName'
  > = {} as any
) {
  return useContractRead({
    abi: erc20ABI,
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof erc20ABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__.
 */
export function useErc20Write<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, string>['request']['abi'],
        TFunctionName,
        TMode
      >
    : UseContractWriteConfig<typeof erc20ABI, TFunctionName, TMode> & {
        abi?: never;
      } = {} as any
) {
  return useContractWrite<typeof erc20ABI, TFunctionName, TMode>({
    abi: erc20ABI,
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"approve"`.
 */
export function useErc20Approve<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'approve'>['request']['abi'],
        'approve',
        TMode
      > & { functionName?: 'approve' }
    : UseContractWriteConfig<typeof erc20ABI, 'approve', TMode> & {
        abi?: never;
        functionName?: 'approve';
      } = {} as any
) {
  return useContractWrite<typeof erc20ABI, 'approve', TMode>({
    abi: erc20ABI,
    functionName: 'approve',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function useErc20Transfer<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'transfer'>['request']['abi'],
        'transfer',
        TMode
      > & { functionName?: 'transfer' }
    : UseContractWriteConfig<typeof erc20ABI, 'transfer', TMode> & {
        abi?: never;
        functionName?: 'transfer';
      } = {} as any
) {
  return useContractWrite<typeof erc20ABI, 'transfer', TMode>({
    abi: erc20ABI,
    functionName: 'transfer',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function useErc20TransferFrom<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'transferFrom'>['request']['abi'],
        'transferFrom',
        TMode
      > & { functionName?: 'transferFrom' }
    : UseContractWriteConfig<typeof erc20ABI, 'transferFrom', TMode> & {
        abi?: never;
        functionName?: 'transferFrom';
      } = {} as any
) {
  return useContractWrite<typeof erc20ABI, 'transferFrom', TMode>({
    abi: erc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function useErc20IncreaseAllowance<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'increaseAllowance'>['request']['abi'],
        'increaseAllowance',
        TMode
      > & { functionName?: 'increaseAllowance' }
    : UseContractWriteConfig<typeof erc20ABI, 'increaseAllowance', TMode> & {
        abi?: never;
        functionName?: 'increaseAllowance';
      } = {} as any
) {
  return useContractWrite<typeof erc20ABI, 'increaseAllowance', TMode>({
    abi: erc20ABI,
    functionName: 'increaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function useErc20DecreaseAllowance<TMode extends WriteContractMode = undefined>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof erc20ABI, 'decreaseAllowance'>['request']['abi'],
        'decreaseAllowance',
        TMode
      > & { functionName?: 'decreaseAllowance' }
    : UseContractWriteConfig<typeof erc20ABI, 'decreaseAllowance', TMode> & {
        abi?: never;
        functionName?: 'decreaseAllowance';
      } = {} as any
) {
  return useContractWrite<typeof erc20ABI, 'decreaseAllowance', TMode>({
    abi: erc20ABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__.
 */
export function usePrepareErc20Write<TFunctionName extends string>(
  config: Omit<UsePrepareContractWriteConfig<typeof erc20ABI, TFunctionName>, 'abi'> = {} as any
) {
  return usePrepareContractWrite({ abi: erc20ABI, ...config } as UsePrepareContractWriteConfig<
    typeof erc20ABI,
    TFunctionName
  >);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"approve"`.
 */
export function usePrepareErc20Approve(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20ABI, 'approve'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'approve'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transfer"`.
 */
export function usePrepareErc20Transfer(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20ABI, 'transfer'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'transfer',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'transfer'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"transferFrom"`.
 */
export function usePrepareErc20TransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20ABI, 'transferFrom'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'transferFrom'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"increaseAllowance"`.
 */
export function usePrepareErc20IncreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20ABI, 'increaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'increaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'increaseAllowance'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link erc20ABI}__ and `functionName` set to `"decreaseAllowance"`.
 */
export function usePrepareErc20DecreaseAllowance(
  config: Omit<
    UsePrepareContractWriteConfig<typeof erc20ABI, 'decreaseAllowance'>,
    'abi' | 'functionName'
  > = {} as any
) {
  return usePrepareContractWrite({
    abi: erc20ABI,
    functionName: 'decreaseAllowance',
    ...config,
  } as UsePrepareContractWriteConfig<typeof erc20ABI, 'decreaseAllowance'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link erc20ABI}__.
 */
export function useErc20Event<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof erc20ABI, TEventName>, 'abi'> = {} as any
) {
  return useContractEvent({ abi: erc20ABI, ...config } as UseContractEventConfig<
    typeof erc20ABI,
    TEventName
  >);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link erc20ABI}__ and `eventName` set to `"Approval"`.
 */
export function useErc20ApprovalEvent(
  config: Omit<UseContractEventConfig<typeof erc20ABI, 'Approval'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({
    abi: erc20ABI,
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof erc20ABI, 'Approval'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link erc20ABI}__ and `eventName` set to `"Transfer"`.
 */
export function useErc20TransferEvent(
  config: Omit<UseContractEventConfig<typeof erc20ABI, 'Transfer'>, 'abi' | 'eventName'> = {} as any
) {
  return useContractEvent({
    abi: erc20ABI,
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof erc20ABI, 'Transfer'>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link platformDepositABI}__.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof platformDepositABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractRead({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    ...config,
  } as UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"DEFAULT_ADMIN_ROLE"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositDefaultAdminRole<
  TFunctionName extends 'DEFAULT_ADMIN_ROLE',
  TSelectData = ReadContractResult<typeof platformDepositABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractRead({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'DEFAULT_ADMIN_ROLE',
    ...config,
  } as UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"PAYOUT_EXECUTOR"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositPayoutExecutor<
  TFunctionName extends 'PAYOUT_EXECUTOR',
  TSelectData = ReadContractResult<typeof platformDepositABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractRead({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'PAYOUT_EXECUTOR',
    ...config,
  } as UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"balanceOf"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof platformDepositABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractRead({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"getRoleAdmin"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositGetRoleAdmin<
  TFunctionName extends 'getRoleAdmin',
  TSelectData = ReadContractResult<typeof platformDepositABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractRead({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'getRoleAdmin',
    ...config,
  } as UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"hasRole"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositHasRole<
  TFunctionName extends 'hasRole',
  TSelectData = ReadContractResult<typeof platformDepositABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractRead({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'hasRole',
    ...config,
  } as UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"maxDeposit"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositMaxDeposit<
  TFunctionName extends 'maxDeposit',
  TSelectData = ReadContractResult<typeof platformDepositABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractRead({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'maxDeposit',
    ...config,
  } as UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"owner"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof platformDepositABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractRead({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"paymentTokenAddress"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositPaymentTokenAddress<
  TFunctionName extends 'paymentTokenAddress',
  TSelectData = ReadContractResult<typeof platformDepositABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractRead({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'paymentTokenAddress',
    ...config,
  } as UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"payoutAddress"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositPayoutAddress<
  TFunctionName extends 'payoutAddress',
  TSelectData = ReadContractResult<typeof platformDepositABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractRead({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'payoutAddress',
    ...config,
  } as UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"pendingOwner"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositPendingOwner<
  TFunctionName extends 'pendingOwner',
  TSelectData = ReadContractResult<typeof platformDepositABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractRead({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'pendingOwner',
    ...config,
  } as UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"supportsInterface"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof platformDepositABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractRead({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"withdrawable"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositWithdrawable<
  TFunctionName extends 'withdrawable',
  TSelectData = ReadContractResult<typeof platformDepositABI, TFunctionName>
>(
  config: Omit<
    UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractRead({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'withdrawable',
    ...config,
  } as UseContractReadConfig<typeof platformDepositABI, TFunctionName, TSelectData>);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link platformDepositABI}__.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof platformDepositAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof platformDepositABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof platformDepositABI, TFunctionName, TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
      } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractWrite<typeof platformDepositABI, TFunctionName, TMode>({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"acceptOwnership"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositAcceptOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof platformDepositAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof platformDepositABI, 'acceptOwnership'>['request']['abi'],
        'acceptOwnership',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'acceptOwnership' }
    : UseContractWriteConfig<typeof platformDepositABI, 'acceptOwnership', TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: 'acceptOwnership';
      } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractWrite<typeof platformDepositABI, 'acceptOwnership', TMode>({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'acceptOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"addPayoutRole"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositAddPayoutRole<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof platformDepositAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof platformDepositABI, 'addPayoutRole'>['request']['abi'],
        'addPayoutRole',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'addPayoutRole' }
    : UseContractWriteConfig<typeof platformDepositABI, 'addPayoutRole', TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: 'addPayoutRole';
      } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractWrite<typeof platformDepositABI, 'addPayoutRole', TMode>({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'addPayoutRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"deposit"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositDeposit<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof platformDepositAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof platformDepositABI, 'deposit'>['request']['abi'],
        'deposit',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'deposit' }
    : UseContractWriteConfig<typeof platformDepositABI, 'deposit', TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: 'deposit';
      } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractWrite<typeof platformDepositABI, 'deposit', TMode>({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'deposit',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"grantRole"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositGrantRole<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof platformDepositAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof platformDepositABI, 'grantRole'>['request']['abi'],
        'grantRole',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'grantRole' }
    : UseContractWriteConfig<typeof platformDepositABI, 'grantRole', TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: 'grantRole';
      } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractWrite<typeof platformDepositABI, 'grantRole', TMode>({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'grantRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"purchasePayout"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositPurchasePayout<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof platformDepositAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof platformDepositABI, 'purchasePayout'>['request']['abi'],
        'purchasePayout',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'purchasePayout' }
    : UseContractWriteConfig<typeof platformDepositABI, 'purchasePayout', TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: 'purchasePayout';
      } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractWrite<typeof platformDepositABI, 'purchasePayout', TMode>({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'purchasePayout',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"removePayoutRole"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositRemovePayoutRole<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof platformDepositAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof platformDepositABI, 'removePayoutRole'>['request']['abi'],
        'removePayoutRole',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'removePayoutRole' }
    : UseContractWriteConfig<typeof platformDepositABI, 'removePayoutRole', TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: 'removePayoutRole';
      } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractWrite<typeof platformDepositABI, 'removePayoutRole', TMode>({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'removePayoutRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"renounceOwnership"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositRenounceOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof platformDepositAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof platformDepositABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'renounceOwnership' }
    : UseContractWriteConfig<typeof platformDepositABI, 'renounceOwnership', TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: 'renounceOwnership';
      } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractWrite<typeof platformDepositABI, 'renounceOwnership', TMode>({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'renounceOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"renounceRole"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositRenounceRole<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof platformDepositAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof platformDepositABI, 'renounceRole'>['request']['abi'],
        'renounceRole',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'renounceRole' }
    : UseContractWriteConfig<typeof platformDepositABI, 'renounceRole', TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: 'renounceRole';
      } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractWrite<typeof platformDepositABI, 'renounceRole', TMode>({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'renounceRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"revokeRole"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositRevokeRole<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof platformDepositAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof platformDepositABI, 'revokeRole'>['request']['abi'],
        'revokeRole',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'revokeRole' }
    : UseContractWriteConfig<typeof platformDepositABI, 'revokeRole', TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: 'revokeRole';
      } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractWrite<typeof platformDepositABI, 'revokeRole', TMode>({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'revokeRole',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"setPayoutAddress"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositSetPayoutAddress<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof platformDepositAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof platformDepositABI, 'setPayoutAddress'>['request']['abi'],
        'setPayoutAddress',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setPayoutAddress' }
    : UseContractWriteConfig<typeof platformDepositABI, 'setPayoutAddress', TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: 'setPayoutAddress';
      } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractWrite<typeof platformDepositABI, 'setPayoutAddress', TMode>({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'setPayoutAddress',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"transferOwnership"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositTransferOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof platformDepositAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof platformDepositABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'transferOwnership' }
    : UseContractWriteConfig<typeof platformDepositABI, 'transferOwnership', TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: 'transferOwnership';
      } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractWrite<typeof platformDepositABI, 'transferOwnership', TMode>({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'transferOwnership',
    ...config,
  } as any);
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"withdraw"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositWithdraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof platformDepositAddress
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof platformDepositABI, 'withdraw'>['request']['abi'],
        'withdraw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'withdraw' }
    : UseContractWriteConfig<typeof platformDepositABI, 'withdraw', TMode> & {
        abi?: never;
        address?: never;
        chainId?: TChainId;
        functionName?: 'withdraw';
      } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractWrite<typeof platformDepositABI, 'withdraw', TMode>({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'withdraw',
    ...config,
  } as any);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link platformDepositABI}__.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePreparePlatformDepositWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof platformDepositABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return usePrepareContractWrite({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    ...config,
  } as UsePrepareContractWriteConfig<typeof platformDepositABI, TFunctionName>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"acceptOwnership"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePreparePlatformDepositAcceptOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof platformDepositABI, 'acceptOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return usePrepareContractWrite({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'acceptOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof platformDepositABI, 'acceptOwnership'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"addPayoutRole"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePreparePlatformDepositAddPayoutRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof platformDepositABI, 'addPayoutRole'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return usePrepareContractWrite({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'addPayoutRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof platformDepositABI, 'addPayoutRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"deposit"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePreparePlatformDepositDeposit(
  config: Omit<
    UsePrepareContractWriteConfig<typeof platformDepositABI, 'deposit'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return usePrepareContractWrite({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'deposit',
    ...config,
  } as UsePrepareContractWriteConfig<typeof platformDepositABI, 'deposit'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"grantRole"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePreparePlatformDepositGrantRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof platformDepositABI, 'grantRole'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return usePrepareContractWrite({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'grantRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof platformDepositABI, 'grantRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"purchasePayout"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePreparePlatformDepositPurchasePayout(
  config: Omit<
    UsePrepareContractWriteConfig<typeof platformDepositABI, 'purchasePayout'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return usePrepareContractWrite({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'purchasePayout',
    ...config,
  } as UsePrepareContractWriteConfig<typeof platformDepositABI, 'purchasePayout'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"removePayoutRole"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePreparePlatformDepositRemovePayoutRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof platformDepositABI, 'removePayoutRole'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return usePrepareContractWrite({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'removePayoutRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof platformDepositABI, 'removePayoutRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"renounceOwnership"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePreparePlatformDepositRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof platformDepositABI, 'renounceOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return usePrepareContractWrite({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof platformDepositABI, 'renounceOwnership'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"renounceRole"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePreparePlatformDepositRenounceRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof platformDepositABI, 'renounceRole'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return usePrepareContractWrite({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'renounceRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof platformDepositABI, 'renounceRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"revokeRole"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePreparePlatformDepositRevokeRole(
  config: Omit<
    UsePrepareContractWriteConfig<typeof platformDepositABI, 'revokeRole'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return usePrepareContractWrite({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'revokeRole',
    ...config,
  } as UsePrepareContractWriteConfig<typeof platformDepositABI, 'revokeRole'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"setPayoutAddress"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePreparePlatformDepositSetPayoutAddress(
  config: Omit<
    UsePrepareContractWriteConfig<typeof platformDepositABI, 'setPayoutAddress'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return usePrepareContractWrite({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'setPayoutAddress',
    ...config,
  } as UsePrepareContractWriteConfig<typeof platformDepositABI, 'setPayoutAddress'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"transferOwnership"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePreparePlatformDepositTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof platformDepositABI, 'transferOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return usePrepareContractWrite({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof platformDepositABI, 'transferOwnership'>);
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link platformDepositABI}__ and `functionName` set to `"withdraw"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePreparePlatformDepositWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof platformDepositABI, 'withdraw'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return usePrepareContractWrite({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    functionName: 'withdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof platformDepositABI, 'withdraw'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link platformDepositABI}__.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositEvent<TEventName extends string>(
  config: Omit<UseContractEventConfig<typeof platformDepositABI, TEventName>, 'abi' | 'address'> & {
    chainId?: keyof typeof platformDepositAddress;
  } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractEvent({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    ...config,
  } as UseContractEventConfig<typeof platformDepositABI, TEventName>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link platformDepositABI}__ and `eventName` set to `"Deposit"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositDepositEvent(
  config: Omit<
    UseContractEventConfig<typeof platformDepositABI, 'Deposit'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractEvent({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    eventName: 'Deposit',
    ...config,
  } as UseContractEventConfig<typeof platformDepositABI, 'Deposit'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link platformDepositABI}__ and `eventName` set to `"OwnershipTransferStarted"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositOwnershipTransferStartedEvent(
  config: Omit<
    UseContractEventConfig<typeof platformDepositABI, 'OwnershipTransferStarted'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractEvent({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    eventName: 'OwnershipTransferStarted',
    ...config,
  } as UseContractEventConfig<typeof platformDepositABI, 'OwnershipTransferStarted'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link platformDepositABI}__ and `eventName` set to `"OwnershipTransferred"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositOwnershipTransferredEvent(
  config: Omit<
    UseContractEventConfig<typeof platformDepositABI, 'OwnershipTransferred'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractEvent({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    eventName: 'OwnershipTransferred',
    ...config,
  } as UseContractEventConfig<typeof platformDepositABI, 'OwnershipTransferred'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link platformDepositABI}__ and `eventName` set to `"PaymentTransfer"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositPaymentTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof platformDepositABI, 'PaymentTransfer'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractEvent({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    eventName: 'PaymentTransfer',
    ...config,
  } as UseContractEventConfig<typeof platformDepositABI, 'PaymentTransfer'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link platformDepositABI}__ and `eventName` set to `"RoleAdminChanged"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositRoleAdminChangedEvent(
  config: Omit<
    UseContractEventConfig<typeof platformDepositABI, 'RoleAdminChanged'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractEvent({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    eventName: 'RoleAdminChanged',
    ...config,
  } as UseContractEventConfig<typeof platformDepositABI, 'RoleAdminChanged'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link platformDepositABI}__ and `eventName` set to `"RoleGranted"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositRoleGrantedEvent(
  config: Omit<
    UseContractEventConfig<typeof platformDepositABI, 'RoleGranted'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractEvent({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    eventName: 'RoleGranted',
    ...config,
  } as UseContractEventConfig<typeof platformDepositABI, 'RoleGranted'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link platformDepositABI}__ and `eventName` set to `"RoleRevoked"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositRoleRevokedEvent(
  config: Omit<
    UseContractEventConfig<typeof platformDepositABI, 'RoleRevoked'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractEvent({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    eventName: 'RoleRevoked',
    ...config,
  } as UseContractEventConfig<typeof platformDepositABI, 'RoleRevoked'>);
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link platformDepositABI}__ and `eventName` set to `"Withdraw"`.
 *
 * - [__View Contract on Polygon Polygon Scan__](https://polygonscan.com/address/0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1)
 * - [__View Contract on Polygon Mumbai Polygon Scan__](https://mumbai.polygonscan.com/address/0x0ef99394257373CB1203630954643B665c005B0c)
 */
export function usePlatformDepositWithdrawEvent(
  config: Omit<
    UseContractEventConfig<typeof platformDepositABI, 'Withdraw'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof platformDepositAddress } = {} as any
) {
  const { chain } = useNetwork();
  const defaultChainId = useChainId();
  const chainId = config.chainId ?? chain?.id ?? defaultChainId;
  return useContractEvent({
    abi: platformDepositABI,
    address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
    eventName: 'Withdraw',
    ...config,
  } as UseContractEventConfig<typeof platformDepositABI, 'Withdraw'>);
}
