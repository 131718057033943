import StripePaymentIntegration from './stripe/PaymentIntegration';
import PageContentTabs, { ContentTab } from '@/common/page/ContentTabs';
import { useMemo } from 'react';
import Page from '@/common/page/Index';
import PayoutWalletDetailsOverview from '@/wallets/payout-wallet/DetailsOverview';
import { authState } from '@/utils/states/authState';
import { useSnapshot } from 'valtio';
import PermissionModalButton from '@/common/button/PermissionModal';
import CreatePayoutWalletForm from '@/wallets/payout-wallet/CreateForm';

const CreatePayoutWalletAction = ({ organizationId }: { organizationId: string }) => {
  return (
    <PermissionModalButton
      modalTitle="Set payout wallet"
      buttonLabel="Set payout wallet"
      modalDescription="This wallet will receive all payouts from primary sales"
      variant="configure"
      modalPaperSxProps={{ width: '450px' }}
      buttonProps={{ size: 'small' }}
      requiredPermissions={['payment_integrations:update']}
    >
      {({ closeModal }) => (
        <CreatePayoutWalletForm
          onSuccess={closeModal}
          onCancel={closeModal}
          organizationId={organizationId}
        />
      )}
    </PermissionModalButton>
  );
};

const PaymentProviderIntegrationOptions = () => {
  const { platformUser } = useSnapshot(authState);

  const tabs = useMemo(() => {
    const tabs: Array<ContentTab> = [
      {
        label: 'Fiat Payout',
        value: 'fiat',
        content: <StripePaymentIntegration />,
      },
      {
        label: 'Crypto Payout',
        value: 'crypto',
        content: (
          <PayoutWalletDetailsOverview organizationId={platformUser?.organization_id as string} />
        ),
        action: (
          <CreatePayoutWalletAction organizationId={platformUser?.organization_id as string} />
        ),
      },
    ];
    return tabs;
  }, [platformUser?.organization_id]);

  return (
    <Page
      testIdPrefix="nodocs-payments"
      sx={{ flexDirection: 'column', margin: 0, padding: 0, border: 'none', height: 'auto' }}
    >
      <PageContentTabs tabs={tabs} tabName={'sub-tab'} />
    </Page>
  );
};

export default PaymentProviderIntegrationOptions;
