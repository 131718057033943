import { useCollection } from '@/utils/hooks/collection';
import { useAllPublicNetworks } from '@/utils/hooks/publicNetwork';
import Box from '@mui/material/Box';
import find from 'lodash/find';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import NetworkName from '@/common/data/NetworkName';
import { PublicNetworksPaginatedOutputResultsInner } from '@/api-client-public';
import { CollectionOutputRevealStrategyEnum } from '@/api-client';
import ClipboardContent from '@/common/data/ClipboardContent';
import { BlockExplorerFilter, BlockExplorerFilterType } from '@/common/data/BlockExplorerFilter';
import { shortenUuid4 } from '@/utils/helpers';
import PlatformEntityIdentifier from '@/common/data/PlatformEntityIdentifier';
import TransactionDetailsOverview, {
  TRANSACTION_OVERVIEW_DETAILS_POPOVER_SIZE,
} from '@/transactions/DetailsOverview';
import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import { useTransaction } from '@/utils/hooks/transaction';
import PopoverButton from '@/common/button/Popover';
import TokenAssignmentStrategyAlert from './TokenAssignmentStrategyAlert';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import OverviewContainer from '@/common/container/OverviewContainer';
import EditableMetadataAlert from './EditableMetadataAlert';
import RevealStrategyAlert from './reveal-strategy/Alert';
import BaseUriAlert from './BaseUriAlert';

export interface CollectionDetailsOverviewProps {
  explicitCollectionId?: string;
}

export const COLLECTION_DETAILS_OVERVIEW_POPOVER_SIZE = { width: '1200px', height: '315px' };

const COLLECTION_OVERVIEW_ROW_DIVISIONS = 4;
const COLLECTION_OVERVIEW_COLUMN_DIVISIONS = 4;

export const CollectionDetailsOverview = ({
  explicitCollectionId,
}: CollectionDetailsOverviewProps) => {
  const { collectionId } = useParams();
  const collectionIdToUse = explicitCollectionId ?? collectionId ?? '';
  const [network, setNetwork] = useState<
    PublicNetworksPaginatedOutputResultsInner | null | undefined
  >(null);

  const {
    data: collection,
    isPending: collectionLoading,
    isFetched: collectionFetched,
  } = useCollection(collectionIdToUse);
  const { data: networks, isPending: networksLoading } = useAllPublicNetworks();
  const { data: deployTx } = useTransaction(collection?.deployment?.transaction_id ?? '');

  const royaltyPercentage = useMemo(() => {
    if (collection?.royalty_info?.amount_bps) {
      return `${collection?.royalty_info?.amount_bps / 100}%`;
    } else {
      return 'N/A';
    }
  }, [collection?.royalty_info]);

  const revealHiddenState = useMemo(() => {
    if (collection?.reveal_strategy !== CollectionOutputRevealStrategyEnum.Instant) {
      return collection?.reveal_hidden ? 'Yes' : 'No';
    }
    return 'N/A';
  }, [collection?.reveal_strategy, collection?.reveal_hidden]);

  useEffect(() => {
    const network_id = collection?.deployment?.network_id ?? deployTx?.network_id;
    const contractNetwork = network_id
      ? find(networks, network => network.id === network_id)
      : null;
    setNetwork(contractNetwork);
  }, [networks, collection?.deployment?.network_id, deployTx?.network_id]);

  if (!collectionIdToUse) {
    return <></>;
  }

  return (
    <OverviewContainer
      isLoading={collectionLoading || networksLoading}
      gridColumnDivisions={COLLECTION_OVERVIEW_COLUMN_DIVISIONS}
      gridRowDivisions={COLLECTION_OVERVIEW_ROW_DIVISIONS}
      notFound={!collection && collectionFetched}
      fullHeight
    >
      <BasicInfoDisplay title="Title">{collection?.name}</BasicInfoDisplay>

      <BasicInfoDisplay title="Collection ID">
        <ClipboardContent tooltipContext="collection ID" clipboardValue={collection?.id}>
          {shortenUuid4(collection?.id)}
        </ClipboardContent>
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Token ID Assignment">
        <Box sx={{ display: 'flex' }}>
          {collection?.deployment?.token_id_assignment_strategy && (
            <PopoverButton
              buttonIcon={<InfoIcon />}
              popoverContainerSx={{ p: 0 }}
              popoverContent={
                <TokenAssignmentStrategyAlert
                  strategy={collection?.deployment?.token_id_assignment_strategy}
                />
              }
            />
          )}

          {collection?.deployment?.token_id_assignment_strategy}
        </Box>
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Token Type">{collection?.deployment?.token_type}</BasicInfoDisplay>

      <BasicInfoDisplay title="Contract Network">
        <NetworkName name={network?.name ?? 'Not Deployed'} testnet={network?.testnet} />
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Contract Address">
        {collection?.deployment?.address ? (
          <BlockExplorerFilter
            filterValue={collection?.deployment?.address ?? ''}
            filterType={BlockExplorerFilterType.ADDRESS}
            networkId={Number(collection?.deployment?.network_id)}
          />
        ) : (
          'Not Deployed'
        )}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Reveal Strategy">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PopoverButton
            buttonIcon={<InfoIcon />}
            popoverContainerSx={{ p: 0 }}
            popoverContent={<RevealStrategyAlert strategy={collection?.reveal_strategy} />}
          />
          {collection?.reveal_strategy}
        </Box>
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Item Metadata Hidden">{revealHiddenState}</BasicInfoDisplay>

      <BasicInfoDisplay title="Editable Metadata">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PopoverButton
            buttonIcon={<InfoIcon />}
            popoverContainerSx={{ p: 0 }}
            popoverContent={
              <EditableMetadataAlert editableMetadata={collection?.editable_metadata} />
            }
          />
          {collection?.editable_metadata ? 'Enabled' : 'Disabled'}
        </Box>
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Allow Manual Contract Execution" variant="boolean">
        {collection?.functions_enabled}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Royalty %">{royaltyPercentage}</BasicInfoDisplay>

      <BasicInfoDisplay title="Royalty Receiver Address">
        {collection?.royalty_info?.receiver ? (
          <BlockExplorerFilter
            filterValue={collection?.royalty_info?.receiver ?? ''}
            filterType={BlockExplorerFilterType.ADDRESS}
            networkId={Number(collection?.deployment?.network_id)}
          />
        ) : (
          'N/A'
        )}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Deployment Tx ID">
        {collection?.deployment?.transaction_id && (
          <PlatformEntityIdentifier
            entityId={collection?.deployment?.transaction_id}
            popoverPreviewContent={
              <TransactionDetailsOverview transactionId={collection?.deployment?.transaction_id} />
            }
            popoverPreviewSx={{ ...TRANSACTION_OVERVIEW_DETAILS_POPOVER_SIZE }}
          />
        )}
      </BasicInfoDisplay>
      <BasicInfoDisplay title="Base URI">
        {collection?.base_uri && (
          <PlatformEntityIdentifier
            buttonIcon={<InfoIcon />}
            entityId={collection?.base_uri}
            charLengthOnEitherSide={10}
            popoverPreviewContent={<BaseUriAlert baseUri={collection?.base_uri} />}
            popoverPreviewSx={{ border: 'none', padding: '0' }}
          />
        )}
      </BasicInfoDisplay>
    </OverviewContainer>
  );
};

export default CollectionDetailsOverview;
