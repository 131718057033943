import { PropsWithChildren, useMemo } from 'react';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

interface PageContentPaperProps extends PropsWithChildren {
  headerIcon?: React.ReactNode;
  headerText?: string;
  headerSubtitle?: string;
  headerAction?: JSX.Element | string;
  headerActionClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void; // Only makes sense for string headerAction
  paperWell?: boolean;
  sx?: SxProps;
}

/**
 * A basic wrapper that can be used at the root level of a Page.
 * @param headerIcon Icon to be displayed in the header
 * @param headerText Text to be displayed in the header
 * @param headerSubtitle Title of the subheader.
 * @param headerAction Action to be displayed in the header. Outlined to the far right.
 * @param headerActionClick Click handler for the headerAction. Only makes sense for @string headerAction.
 * @param paperWell Whether to display a well around the children.
 * @param sx Additional styles to be applied to the Paper.
 */
export const PageContentPaper = ({
  headerIcon,
  headerText,
  headerSubtitle,
  headerAction,
  headerActionClick,
  children,
  paperWell,
  sx,
}: PageContentPaperProps) => {
  const childrenHeight = useMemo(() => {
    let heightSubtractPessimistic = 85;

    if (!headerAction && !headerText) {
      heightSubtractPessimistic -= 40;
    }
    if (!headerSubtitle) {
      heightSubtractPessimistic -= 40;
    }

    return `calc(100% - ${heightSubtractPessimistic}px)`;
  }, [headerSubtitle, headerAction, headerText]);

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        rowGap: '16px',
        border: paperWell ? 'auto' : 0,
        p: paperWell ? 3 : 0,
        ...sx,
      }}
    >
      {(headerAction || headerText) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: headerText ? 'space-between' : 'flex-end',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {headerIcon && (
              <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>{headerIcon}</Box>
            )}

            <Typography variant="h6">{headerText}</Typography>
          </Box>

          {headerAction ? (
            typeof headerAction === 'string' ? (
              <Button
                onClick={e => {
                  if (headerActionClick) headerActionClick(e);
                }}
                variant="contained"
                color="primary"
                sx={{ height: 36 }}
              >
                {headerAction}
              </Button>
            ) : (
              headerAction
            )
          ) : null}
        </Box>
      )}

      {headerSubtitle && (
        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
          {headerSubtitle}
        </Typography>
      )}

      <Box
        sx={{
          height: childrenHeight,
        }}
      >
        {children}
      </Box>
    </Paper>
  );
};

export default PageContentPaper;
