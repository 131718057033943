import {
  EmailClaim,
  EmailClaimsPaginatedResultResultsInner,
  EmailClaimsPaginatedResult,
  CreateEmailClaimInput,
} from '@/api-client';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { adminApi } from '../api';
import {
  API_SORT_DIRECTION_ASC,
  API_SORT_DIRECTION_DESC,
  EMAIL_CLAIMS_QUERY_KEY,
} from '../constants';
import { getApiErrorMessage } from '../helpers';

export const useEmailClaim = (emailClaimId?: string) => {
  return useQuery({
    queryKey: [EMAIL_CLAIMS_QUERY_KEY, emailClaimId],
    queryFn: () =>
      adminApi.emailClaim.emailClaimsClaimIdGet(emailClaimId as string).then(res => res.data),
    enabled: Boolean(emailClaimId),
  });
};

export const useEmailClaims = (
  pageSize?: number,
  cursor?: string,
  sort?: {
    fieldName?: string;
    direction?: typeof API_SORT_DIRECTION_ASC | typeof API_SORT_DIRECTION_DESC;
  },
  filter?: {
    collectionId?: string;
    itemId?: string;
    tokenId?: string;
    email?: string;
    claimed?: boolean;
    active?: boolean;
    id?: string;
  }
) => {
  const [output, setOutput] = useState<any>({} as any);
  const getEmailClaims = useQuery({
    queryKey: [
      EMAIL_CLAIMS_QUERY_KEY,
      pageSize,
      cursor,
      sort?.fieldName,
      sort?.direction,
      filter?.collectionId,
      filter?.itemId,
      filter?.tokenId,
      filter?.email,
      filter?.active,
      filter?.claimed,
    ],
    queryFn: () =>
      adminApi.emailClaim
        .emailClaimsGet(
          sort?.direction ?? API_SORT_DIRECTION_ASC,
          cursor,
          pageSize,
          filter?.collectionId ? [filter?.collectionId] : undefined,
          filter?.itemId ? [filter?.itemId] : undefined,
          filter?.tokenId ? [filter?.tokenId] : undefined,
          filter?.email,
          filter?.claimed,
          filter?.active,
          sort?.fieldName?.toUpperCase() as any
        )
        .then(res => res.data),
    enabled: !filter?.id, // Don't fire if we're filtering by id.
  });

  const getEmailClaim: UseQueryResult<EmailClaim, unknown> = useQuery({
    queryKey: [EMAIL_CLAIMS_QUERY_KEY, filter?.id],
    queryFn: () =>
      adminApi.emailClaim.emailClaimsClaimIdGet(filter?.id as string).then(res => res.data),
    enabled: Boolean(filter?.id),
  });

  useEffect(() => {
    if (filter?.id) {
      setOutput({
        ...getEmailClaim,
        data: {
          cursor: null,
          results: getEmailClaim.data
            ? [getEmailClaim.data as EmailClaimsPaginatedResultResultsInner]
            : [],
          total_results: getEmailClaim.data ? (getEmailClaim.data.id ? 1 : 0) : 0,
          has_more: false,
        },
      });
    } else {
      setOutput(getEmailClaims);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getEmailClaim.data,
    getEmailClaim.isPending,
    getEmailClaim.isError,
    getEmailClaims.data,
    getEmailClaims.isPending,
    getEmailClaims.isError,
    filter?.id,
  ]);

  return output as UseQueryResult<EmailClaimsPaginatedResult, unknown>;
};

export const useCreateItemClaim = (includeSnackbarFeedback = true) => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (body: CreateEmailClaimInput) => adminApi.emailClaim.emailClaimsPost(body),
    onSuccess: data => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey: [EMAIL_CLAIMS_QUERY_KEY] });

      // Show feedback if necessary.
      if (data?.data && includeSnackbarFeedback) {
        enqueueSnackbar(`An email has been sent to start the claim process.`, {
          variant: 'success',
        });
      }
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error) || 'Error send email claim';
      if (includeSnackbarFeedback) {
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    },
  });

  return mutation;
};
