import PageContentPaper from '@/common/page/ContentPaper';
import Page from '@/common/page/Index';
import TransactionsGrid from './Grid';

const TransactionsOverviewPage = () => {
  return (
    <Page testIdPrefix="transactions" helmet="Transactions" title="Transactions">
      <PageContentPaper>
        <TransactionsGrid />
      </PageContentPaper>
    </Page>
  );
};

export default TransactionsOverviewPage;
