import { Typography } from '@mui/material';
import React from 'react';

interface DisconnectWalletProps {
  onDisconnect: () => void;
}

const DisconnectWallet: React.FC<DisconnectWalletProps> = ({ onDisconnect }) => {
  return (
    <Typography
      variant="caption"
      sx={{ color: 'blue', cursor: 'pointer' }}
      onClick={() => onDisconnect()}
    >
      Disconnect wallet
    </Typography>
  );
};

export default DisconnectWallet;
