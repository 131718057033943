import { useState } from 'react';
import PermissionGuard from '@/common/PermissionGuard';
import { Button, ButtonProps, SxProps } from '@mui/material';
import SimpleModal from '@/common/SimpleModal';
import { useSnackbar } from 'notistack';

interface PermissionModalButtonChildrenState {
  open: boolean;
  openModal: () => void;
  closeModal: () => void;
}

interface PermissionModalButtonProps {
  children(modalState: PermissionModalButtonChildrenState): void;
  modalTitle: string;
  modalDescription?: string;
  modalPaperSxProps?: SxProps;
  buttonLabel: string;
  buttonProps?: ButtonProps;
  variant?: 'create' | 'delete' | 'configure';
  requiredPermissions?: string[];
  defaultOpenState?: boolean;
  notImplemented?: boolean;
}

/**
 * Renders a single button that opens a modal, restricted by permissions.
 * @param variant The variant of the button. Defaults to 'create'. Affects button color.
 * @param modalTitle The title of the modal.
 * @param description The description of the modal.
 * @param modalPaperSxProps Props to pass to the Paper component of the modal.
 * @param buttonLabel The label of the button that can open the modal.
 * @param requiredPermissions The permissions required to render the button.
 * @param buttonProps Props to pass to the button.
 * @param defaultOpenState Open state of modal.
 * @param notImplemented Whether or not the feature(s) for this button is/are not implemented.
 */
export const PermissionModalButton = ({
  children,
  variant,
  modalTitle,
  modalDescription,
  buttonLabel,
  requiredPermissions,
  modalPaperSxProps,
  defaultOpenState,
  buttonProps = {},
  notImplemented,
}: PermissionModalButtonProps) => {
  const [modalOpen, setModalOpen] = useState(!!defaultOpenState);
  const { enqueueSnackbar } = useSnackbar();
  const openModal = () => {
    if (!notImplemented) setModalOpen(true);
    else {
      enqueueSnackbar('Not implemented (yet)', { variant: 'info' });
    }
  };
  const closeModal = () => setModalOpen(false);

  return (
    <PermissionGuard requiredPermissions={requiredPermissions || []}>
      <Button
        color={variant === 'delete' ? 'error' : 'primary'}
        variant="contained"
        onClick={openModal}
        {...(buttonProps ?? {})}
      >
        {buttonLabel}
      </Button>

      <SimpleModal
        open={modalOpen}
        title={modalTitle}
        description={modalDescription}
        paperSxProps={modalPaperSxProps}
      >
        {children({ openModal, closeModal, open: modalOpen })}
      </SimpleModal>
    </PermissionGuard>
  );
};

export default PermissionModalButton;
