import { useMemo } from 'react';
import Page from '@/common/page/Index';
import PageContentTabs, { ContentTab } from '@/common/page/ContentTabs';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import BalanceOverview from './BalanceOverview';
import PageContentPaper from '@/common/page/ContentPaper';
import { Alert, Box, Typography } from '@mui/material';
import { useSnapshot } from 'valtio';
import { authState } from '@/utils/states/authState';
import ChargesGrid from '@/billing/ChargesGrid';
import { testModeState } from '@/utils/states/testModeState';

const TestModeBillingPage = () => {
  return (
    <PageContentPaper>
      <Box display="flex">
        <Typography variant="body1">
          <Box sx={{ fontWeight: 700 }}>🚀 Testnet Transactions: Sponsored & Stress-Free! 🚀</Box>
          <Box>
            Hello, amazing creators! Big news: We&apos;re now covering your testnet transactions on
            Phosphor Developer. That&apos;s right, no more hunting for testnet tokens!
          </Box>

          <Box sx={{ fontWeight: 700, mt: 2 }}>🌈 Focus on Creation, Not Tokens 🌈</Box>
          <Box>
            Build, experiment, and iterate without worrying about testnet funding. It&apos;s all on
            us, so you can keep your creative flow uninterrupted.
          </Box>

          <Box sx={{ fontWeight: 700, mt: 2 }}>🎉 Why? Because You Deserve It!🎉</Box>
          <Box>
            We&apos;re here to support your innovation journey by removing the little obstacles,
            like testnet tokens. This way, you can concentrate on what truly matters - bringing your
            ideas to life. Get ready to build the future, hassle-free. Let&apos;s create something
            incredible together!
          </Box>

          <Box sx={{ fontWeight: 700, mt: 2 }}>Happy coding!🛠️</Box>
        </Typography>
      </Box>
    </PageContentPaper>
  );
};

export const BillingOverview = () => {
  const { platformOrg } = useSnapshot(authState);
  const { active: testMode } = useSnapshot(testModeState);

  const tabs = useMemo(() => {
    const result: Array<ContentTab> = [
      {
        label: 'Balance',
        value: 'balance',
        gridContent: false,
        content: <BalanceOverview />,
      },
      {
        label: 'Charges',
        value: 'charges',
        gridContent: false,
        content: <ChargesGrid />,
      },
    ];
    return result;
  }, []);

  const billingEnabled = platformOrg?.billing_enabled;

  return (
    <Page testIdPrefix="billing-page" helmet="Billing & Balances" title="Billing & Balances">
      {billingEnabled ? (
        testMode ? (
          <TestModeBillingPage />
        ) : (
          <PageContentTabs tabs={tabs} />
        )
      ) : (
        <PageContentPaper>
          <Box display="flex">
            <Alert severity="info" sx={{ height: 'fit-content' }}>
              Billing is not enabled for your organization.
            </Alert>
          </Box>
        </PageContentPaper>
      )}
    </Page>
  );
};

export default BillingOverview;
