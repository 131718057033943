import { useAllRoles } from '@/utils/hooks/role';
import { useInviteUser } from '@/utils/hooks/user';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  email: yup.string().email().required('Please enter a valid email'),
  role: yup.string().required('Please enter a valid role'),
});

const CreateUserForm = ({ onSuccess, onCancel }: any) => {
  const navigate = useNavigate();
  const { data: roles, isPending: rolesLoading } = useAllRoles();
  const { mutate: inviteUser, isPending: invitingUser } = useInviteUser();

  const formik = useFormik({
    initialValues: {
      email: '',
      role: '',
    },
    validationSchema,
    onSubmit: (values, { resetForm }) =>
      inviteUser(
        { email: values.email, role_id: values.role, application_type: 'DEV_UI' },
        {
          ...(onSuccess ? { onSuccess } : {}),
          onSettled: () => {
            resetForm({ values });
            navigate('/users?tab=invited');
          },
        }
      ),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        type="email"
        name="email"
        label="Email"
        disabled={formik.isSubmitting}
        sx={{ width: '100%', mb: 2 }}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        placeholder="Fill in the email of the user"
        required
      />

      <FormControl fullWidth>
        <InputLabel id="role-select-label">Role*</InputLabel>
        <Select
          labelId="role-select-label"
          id="role-select"
          name="role"
          value={formik.values.role}
          label="Role"
          onChange={formik.handleChange}
          disabled={formik.isSubmitting}
          required
        >
          {roles &&
            (roles ?? []).map(role => (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          mt: 2,
        }}
      >
        <Button variant="outlined" type="button" onClick={onCancel} sx={{ flexGrow: 1 }}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          disabled={rolesLoading || formik.isSubmitting || !formik.dirty}
          loading={formik.isSubmitting || invitingUser}
          variant="contained"
          color="primary"
          sx={{ flexGrow: 1 }}
        >
          Send Invite
        </LoadingButton>
      </Box>
    </form>
  );
};

export default CreateUserForm;
