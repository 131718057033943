import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import { Button, SxProps, Theme, useTheme } from '@mui/material';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

interface PopoverButtonProps extends React.PropsWithChildren {
  buttonIcon?: ReactJSXElement;
  popoverContent?: ReactJSXElement;
  popoverContainerSx?: SxProps<Theme>;
}

/**
 * Renders a button that opens a popover.
 * @param buttonIcon The icon of the button.
 * @param popoverContent The content of the popover.
 * @param popoverContainerSx Props to pass to the container of the popover. Use this to limit the size of the popover content.
 * @param children Optional content to render inside the button. If not specified, the button will be an icon button.
 */
export const PopoverButton = ({
  buttonIcon = <VisibilityIcon />,
  popoverContent,
  popoverContainerSx,
  children,
}: PopoverButtonProps) => {
  const theme = useTheme();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handlePopoverClick = (event: any) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  return (
    <Box>
      {children ? (
        <Button onClick={handlePopoverClick} variant="text">
          {children}
        </Button>
      ) : (
        <IconButton
          onClick={handlePopoverClick}
          sx={{
            color: theme.palette.mode === 'dark' ? 'white' : 'primary.main',
            p: 0,
            mr: '4px',
          }}
        >
          {buttonIcon}
        </IconButton>
      )}
      <Popover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        elevation={2}
        sx={{
          '.MuiPopover-paper': {
            p: 2,
            ...(popoverContainerSx as any),
          },
        }}
      >
        <Box sx={{ height: '100%', width: '100%' }}>{popoverContent}</Box>
      </Popover>
    </Box>
  );
};

export default PopoverButton;
