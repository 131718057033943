import * as yup from 'yup';
import OverviewContainer from '@/common/container/OverviewContainer';
import { useEditOrganizationPayoutConfig, useOrganizationPayoutConfig } from '@/utils/hooks/organization';
import { EditOrganizationPayoutConfigurationInput } from '@/api-client';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import { Box, Button, FormControl, TextField } from '@mui/material';
import PermissionGuard from '@/common/PermissionGuard';
import { LoadingButton } from '@mui/lab';
import { isValidAddress } from '@/utils/helpers';

const validationSchema = yup.object().shape({
  wallet: yup.string().required('Please enter a wallet address').test('is-valid-ethereum-address', 'Please enter a valid Ethereum address', value => {
    return isValidAddress(value);
  }),
});

const PayoutWalletDetailsOverview = ({ organizationId }: { organizationId: string }) => {
  const {
    data: payoutConfig,
    isPending: payoutConfigLoading,
    isFetched: payoutConfigFetched,
  } = useOrganizationPayoutConfig(organizationId);
  const { mutate: editPayout } = useEditOrganizationPayoutConfig(organizationId);

  const [initialWallet, setInitialWallet] = useState('');
  const [disableSave, setDisableSave] = useState(true);

  const formik = useFormik({
    initialValues: { wallet: '' },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (!isValidAddress(values.wallet)) return resetForm({ values });

      editPayout({ payout_address: values.wallet}  as EditOrganizationPayoutConfigurationInput, {
        onSettled: () => {
          resetForm({ values });
          setDisableSave(true);
        },  
      });
    },
  });

  useMemo(() => {
    if (payoutConfig?.payout_address) {
      formik.setFieldValue('wallet', payoutConfig?.payout_address);
      setInitialWallet(payoutConfig?.payout_address)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutConfig]);


  return (
    <OverviewContainer
      isLoading={payoutConfigLoading}
      gridColumnDivisions={1}
      gridRowDivisions={2}
      notFound={!payoutConfig && !payoutConfigLoading && payoutConfigFetched}
    >
      <form onSubmit={formik.handleSubmit}>
        <FormControl fullWidth>
          <TextField
            type="text"
            name="wallet"
            value={formik.values.wallet}
            label="Wallet Address"
            disabled={formik.isSubmitting || disableSave}
            sx={{ width: '60%', mt: 2, mb: 2 }}
            onChange={formik.handleChange}
            error={formik.touched.wallet && Boolean(formik.errors.wallet)}
            helperText={
              formik.touched.wallet
                ? (formik.errors.wallet as string)
                : 'Please enter a wallet address'
            }
            placeholder="0x..."
            autoComplete="off"
            required
          />
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'left',
              alignItems: 'center',
              gap: 2,
              mt: 2,
            }}
          >
            <PermissionGuard requiredPermissions={['organizations:update']}>
              <Button
                variant="outlined"
                type="button"
                onClick={() => {
                  if (disableSave === false) formik.setFieldValue('wallet', initialWallet);
                  setDisableSave(disableSave === false);
                }}
                sx={{ minWidth: '50px' }}
              >
                Reset
              </Button>
            </PermissionGuard>

            <PermissionGuard requiredPermissions={['organizations:update']}>
              <LoadingButton
                type="submit"
                disabled={disableSave && 
                  (  
                    (initialWallet && formik.values.wallet == initialWallet) || formik.isSubmitting || !formik.dirty
                  )
                }
                loading={formik.isSubmitting}
                variant="contained"
                color="primary"
                sx={{ minWidth: '50px' }}
              >
                Save
              </LoadingButton>
            </PermissionGuard>
          </Box>
        </FormControl>
      </form>

    </OverviewContainer>
  );
};

export default PayoutWalletDetailsOverview;
