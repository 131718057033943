import { Alert, AlertTitle } from '@mui/material';

export const ListingPolicyTypeAlert = () => {
  return (
    <Alert severity="info">
      <AlertTitle>Policy Type</AlertTitle>
      When buying item(s) from a <strong>collection listing</strong>, the platform can enforce a
      specific policy:
      <ul>
        <li>
          <strong>First Come First Serve (FCFS)</strong>
        </li>
        <li>
          <strong>Allow List</strong> - Only email addresses or ethereum addresses that are on the
          allow list can purchase items.
        </li>
      </ul>
    </Alert>
  );
};

export default ListingPolicyTypeAlert;
