import { GridColDef } from '@mui/x-data-grid';
import React, { useCallback, useEffect } from 'react';
import ServerPaginatedDataGrid, {
  DEFAULT_PAGINATED_GRID_PAGE,
  PaginatedGridPage,
} from '@/common/data/grid/ServerPaginated';
import { ItemOutput, ItemOutputTokenStatusEnum } from '@/api-client';
import PlatformEntityIdentifier from '@/common/data/PlatformEntityIdentifier';
import Checkbox from '@mui/material/Checkbox';
import PopoverButton from '@/common/button/Popover';
import Box from '@mui/material/Box';
import ItemAttributeGridFilter, { ItemAttributeGridFilterItem } from './GridAttributeFilter';
import { customPalette } from '@/theme/custom';
import { MAIN_ROUTES, AppRoute } from '@/routes';
import { META_ATTRIBUTE, ROUTE_NAME } from '@/utils/constants';
import { extractUncommonAttributes, getMaxSupplyCoerced } from '@/utils/helpers';
import { useCollectionStats } from '@/utils/hooks/collection';
import { useCollectionItems } from '@/utils/hooks/item';
import { useSortAndFilterGrid } from '@/utils/hooks/useSortAndFilterGrid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { find } from 'lodash';
import { generatePath } from 'react-router-dom';
import { proxy, useSnapshot, subscribe } from 'valtio';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ItemSupply from './Supply';
import Status from '@/common/data/Status';
import { BaseRow } from '@/common/data/grid/constants';
import { ItemContent } from './content/Index';
import ItemContentTabs from './content/Tabs';

// Row specific to this grid.
interface ItemGridRow extends BaseRow {
  image: string;
  animationImage: string;
  locked: boolean;
  mintStatus: string;
  tokenId?: string;
  title: string;
  maxSupply?: string | null;
  customAttributes?: { [key: string]: string };
  customAttributeCount: number;
  metaAttributes?: { [key: string]: string };
  metaAttributeCount: number;
}

// Columns specific to this grid.
const columns: Array<GridColDef<ItemGridRow>> = [
  {
    field: 'tokenId',
    headerName: 'Token ID',
    width: 80,
    sortable: true,
    filterable: true,
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 200,
    sortable: true,
    filterable: false,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 150,
    sortable: false,
    filterable: true,
    renderCell: (params: { row: ItemGridRow }) => (
      <PlatformEntityIdentifier tooltipContext="this item ID" entityId={params.row.id as string} />
    ),
  },
  {
    field: 'image',
    headerName: 'Image',
    sortable: false,
    filterable: false,
    width: 90,
    renderCell: (params: { row: ItemGridRow }) => (
      <Box sx={{ display: 'flex', columnGap: '8px', alignItems: 'center' }}>
        {(params.row.image || params.row.animationImage) && (
          <PopoverButton
            popoverContent={
              <ItemContentTabs
                imageUrl={params.row.image}
                animationUrl={params.row.animationImage}
              />
            }
            popoverContainerSx={{ width: '350px', height: '350px' }}
          />
        )}

        {(params.row.image ||
          (params.row.animationImage && params.row.animationImage.indexOf('.gif') > 0)) && (
          <Box
            sx={{
              height: '35px',
              width: '35px',
            }}
          >
            <ItemContent
              contentUrl={
                params.row.animationImage && params.row.animationImage.indexOf('.gif') > 0
                  ? params.row.animationImage
                  : params.row.image
              }
            />
          </Box>
        )}
      </Box>
    ),
  },
  {
    field: 'locked',
    headerName: 'Locked',
    sortable: false,
    filterable: false,
    width: 72,
    renderCell: (params: { row: ItemGridRow }) => (
      <Checkbox onChange={() => params.row.locked} checked={params.row.locked} />
    ),
  },
  {
    field: 'mintStatus',
    headerName: 'Mint Status',
    sortable: false,
    filterable: false,
    width: 110,
    renderCell: (params: { row: ItemGridRow }) => <Status status={params.row.mintStatus} />,
  },
  {
    field: 'customAttributes',
    headerName: 'Custom Attributes',
    sortable: false,
    filterable: false,
    width: 130,
    renderCell: (params: { row: ItemGridRow }) =>
      params.row.customAttributes && params.row.customAttributeCount ? (
        <Box sx={{ width: '100%', display: 'flex' }}>{params.row.customAttributeCount}</Box>
      ) : (
        <></>
      ),
  },
  {
    field: 'metaAttributes',
    headerName: 'Meta Attributes',
    sortable: false,
    filterable: false,
    width: 115,
    renderCell: (params: { row: ItemGridRow }) =>
      params.row.metaAttributes && params.row.metaAttributeCount ? (
        <Box sx={{ width: '100%', display: 'flex' }}>{params.row.metaAttributeCount}</Box>
      ) : (
        <></>
      ),
  },
  {
    field: 'maxSupply',
    headerName: 'Max Supply',
    sortable: false,
    filterable: false,
    width: 120,
    renderCell: (params: { row: ItemGridRow }) =>
      params.row.maxSupply ? (
        <Box sx={{ width: '100%', display: 'flex' }}>
          <PopoverButton
            buttonIcon={<InfoIcon />}
            popoverContent={<ItemSupply itemId={params.row.id as string} />}
          />
          <Box sx={{ flexGrow: 1 }}>{params.row.maxSupply}</Box>
        </Box>
      ) : (
        <></>
      ),
  },
];

// Initial state specific to this grid.
const initialState: GridInitialStateCommunity = {
  sorting: {
    sortModel: [{ field: 'name', sort: 'asc' }],
  },
  columns: {
    columnVisibilityModel: {
      id: false,
      customAttributes: false,
      metaAttributes: false,
    },
  },
};

// Will map API data (in this case a mix of multiple sources) to rows.
const mapToRow = (item: ItemOutput): ItemGridRow => {
  const attributes = (item.attributes as any) ?? {};
  const { custom, meta } = extractUncommonAttributes(item?.attributes ?? {});

  return {
    id: item.id,
    image: item.media?.image?.full ?? '',
    animationImage: meta[META_ATTRIBUTE.META_ANIMATION_URL] ?? '',
    locked: item.locked,
    tokenId: item.token_id ?? undefined,
    mintStatus: item?.token_status ?? ItemOutputTokenStatusEnum.NotMinted,
    title: (attributes['title'] ?? '') as unknown as string,
    customAttributes: custom,
    customAttributeCount: custom ? Object.keys(custom).length : 0,
    metaAttributes: meta,
    metaAttributeCount: meta ? Object.keys(meta).length : 0,
    maxSupply: getMaxSupplyCoerced(item?.max_supply ?? ''),
    original: item,
  };
};

const pageState: PaginatedGridPage<ItemGridRow> = proxy({
  ...DEFAULT_PAGINATED_GRID_PAGE,
});

export interface ItemsGridProps {
  collectionId: string;
  rowSelectionChange?: (selected: ItemOutput[]) => void;
  showAttributeFilter?: boolean;
}

const ItemsGrid = ({ collectionId, rowSelectionChange, showAttributeFilter }: ItemsGridProps) => {
  // Get readonly snaps from the state.
  const pageSnap = useSnapshot(pageState);

  // Construct sort and filter args based on the snap of the active page.
  const { sortBy, filterBy } = useSortAndFilterGrid(pageState);

  // Get all API data for current render.
  const { data: itemsResponse, isPending } = useCollectionItems(
    collectionId,
    pageSnap.pageSize,
    pageSnap.cursor,
    sortBy,
    filterBy
  );

  // Get collection stats for the custom filter.
  const collectionStats = useCollectionStats(collectionId);

  // Populate rows when any API data changes.
  useEffect(() => {
    pageState.rows =
      itemsResponse?.results.map(item => {
        return mapToRow(item);
      }) ?? [];
    pageState.nextCursor = itemsResponse?.cursor ?? DEFAULT_PAGINATED_GRID_PAGE.cursor;
  }, [itemsResponse?.results, itemsResponse?.cursor]);

  useEffect(() => {
    if (pageState.selected === null || pageState.selected === undefined || !rowSelectionChange)
      return;

    subscribe(pageState, () => {
      if (rowSelectionChange) {
        rowSelectionChange(pageState.selected.map(row => row.original as ItemOutput));
      }
    });
  }, [rowSelectionChange]);

  const handleAttributeFilterChange = useCallback((checked: ItemAttributeGridFilterItem[]) => {
    pageState.filterBy = [
      ...(pageState.filterBy ?? []),
      {
        field: 'attributes',
        operator: 'contains',
        value: checked.reduce(
          (reduced, current) => ({
            ...reduced,
            [current.category]: current.attribute === '' ? undefined : current.attribute,
          }),
          {}
        ),
      },
    ];
  }, []);

  const getRowDoubleClickUrl = useCallback(
    (row: ItemGridRow) => {
      const collectionDetailsRoute = find(
        MAIN_ROUTES,
        route => route.name === ROUTE_NAME.ITEM_DETAILS
      ) as AppRoute;
      return generatePath(collectionDetailsRoute.path as string, {
        collectionId: collectionId,
        itemId: row.id,
      });
    },
    [collectionId]
  );

  return (
    <>
      <Box sx={{ height: '100%', display: 'flex' }}>
        {showAttributeFilter && (
          <Box
            sx={{
              width: '250px',
              mr: 2,
              maxHeight: '58vh',
              pr: 2,
              borderRight: `1px solid ${customPalette.colors.lightGrey}`,
            }}
          >
            <ItemAttributeGridFilter
              collectionStats={collectionStats?.data}
              handleFilter={handleAttributeFilterChange}
            />
          </Box>
        )}

        <Box
          sx={{
            transition: showAttributeFilter ? 'width 0.8s' : '',
            width: showAttributeFilter ? 'calc(100% - 250px)' : '100%',
            height: '100%',
          }}
        >
          <ServerPaginatedDataGrid
            columns={columns}
            initialState={initialState}
            // eslint-disable-next-line valtio/state-snapshot-rule
            activePageProxy={pageState}
            pageLoading={isPending}
            totalRowCount={itemsResponse?.total_results ?? 0}
            getRowDoubleClickUrl={getRowDoubleClickUrl}
          />
        </Box>
      </Box>
    </>
  );
};

export default ItemsGrid;
