import SimpleDeleteForm from '@/common/data/SimpleDeleteForm';
import { useRetryStripeCommand } from '@/utils/hooks/stripePaymentIntegration';

const RetryStripeSetupForm = ({ onSuccess, onCancel }: any) => {
  const { mutate } = useRetryStripeCommand();

  return (
    <SimpleDeleteForm
      onCancel={onCancel}
      onSuccess={onSuccess}
      mutate={mutate}
      id={null}
      deleteConfirmationMessage="If setup is still incomplete after retrying, manually disconnect then try to connect again."
    />
  );
};

export default RetryStripeSetupForm;
