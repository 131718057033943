import MuiDrawer from '@mui/material/Drawer';
import styled from '@emotion/styled';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CSSObject, Theme } from '@mui/material/styles';
import { useSnapshot } from 'valtio';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate, matchRoutes } from 'react-router-dom';
import PermissionGuard from '@/common/PermissionGuard';
import { AppRoute, MAIN_ROUTES } from '@/routes';
import { useEffect, useState } from 'react';
import { proxy } from 'valtio';
import { authState } from '@/utils/states/authState';
import { testModeState } from '@/utils/states/testModeState';

interface SideBarState {
  open: boolean;
}

const initialState: SideBarState = {
  open: true,
};

export const sideBarState = proxy<SideBarState>(initialState);

export function toggleSideBar() {
  sideBarState.open = !sideBarState.open;
}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }: any) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Sidebar = () => {
  const [activeItemName, setActiveItemName] = useState<string>('');
  const { open } = useSnapshot(sideBarState);
  const navigate = useNavigate();
  const { platformPermissions } = useSnapshot(authState);
  const { active: testMode } = useSnapshot(testModeState);
  const location = useLocation();

  useEffect(() => {
    const routeMatches = matchRoutes(MAIN_ROUTES, location);
    const routeMatch: AppRoute | null = routeMatches?.length
      ? (routeMatches[0]?.route as AppRoute | null)
      : null;
    if (!routeMatch) return;

    const routeInSidebarMatch = MAIN_ROUTES.find(
      route =>
        route.path &&
        routeMatch.path &&
        ((routeMatch.path === '/' && route.path === '/') ||
          (route.path !== '/' && routeMatch.path.includes(route.path)))
    );
    if (!routeInSidebarMatch) return;

    if (routeInSidebarMatch.name !== activeItemName) {
      setActiveItemName(routeInSidebarMatch.name);
    }
  }, [location, activeItemName]);

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        position: 'relative',
        '& .MuiDrawer-paper': {
          position: 'inherit',
          padding: '0',
          borderLeft: 0,
        },
        background: 'background.default',
        height: '100%',
        zIndex: 0, // 1 below the appbar zindex, otherwise appbar dropshadow will not be visible over this component.
      }}
    >
      <List sx={{ color: 'text.primary', pt: 0 }}>
        {platformPermissions &&
          MAIN_ROUTES.filter(route => route.includeInSidebar).map(
            ({ name, Icon, path, requiredPermissions, hasTestMode }) => {
              let className = activeItemName === name ? 'active' : '';
              className += hasTestMode && testMode ? ' test-mode-active' : '';

              return (
                <PermissionGuard requiredPermissions={requiredPermissions} key={name}>
                  <ListItem
                    key={name}
                    disablePadding
                    className={className}
                    sx={{
                      display: 'block',
                      borderBottomWidth: '1px',
                      borderBottomStyle: 'solid',
                      borderBottomColor: 'colors.lightGrey',
                    }}
                    onClick={() => setActiveItemName(name)}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                      onClick={() => navigate(path)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 2 : 'auto',
                          justifyContent: 'center',
                          '.MuiSvgIcon-root': {
                            fontSize: '1.85rem',
                          },
                        }}
                      >
                        <Icon />
                      </ListItemIcon>
                      <ListItemText
                        primary={name}
                        sx={{
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </PermissionGuard>
              );
            }
          )}
      </List>

      <List
        sx={{
          width: '100%',
          p: 0,
          position: 'absolute',
          bottom: 0,
          color: 'text.primary',
        }}
      >
        <ListItemText sx={{ p: 1, textAlign: 'center', minHeight: '40px' }}>
          <Typography variant="caption" sx={{ color: 'primary.dark' }}>
            {open ? `Powered by © ${new Date().getFullYear()} ConsenSys` : ''}
          </Typography>
        </ListItemText>
      </List>
    </Drawer>
  );
};

export default Sidebar;
