import ClipboardContent from '@/common/data/ClipboardContent';
import { Alert, Typography, Box, Button } from '@mui/material';

export const DisplayApiKey = ({ apiKey, onClose }: { apiKey: string; onClose: any }) => {
  return (
    <>
      <Alert severity="info" sx={{ mb: 2 }}>
        <Typography variant="body1">This is your API key. Please keep it secure.</Typography>
      </Alert>

      <ClipboardContent tooltipContext="" clipboardValue={apiKey}>
        <Box
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            flexShrink: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'primary.lightest',
            borderRadius: '3px',
            paddingY: '24px',
            paddingX: '8px',
            width: '100%',
            textAlign: 'center',
            fontSize: '18px',
          }}
        >
          {apiKey}
        </Box>
      </ClipboardContent>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          mt: 2,
        }}
      >
        <Button variant="outlined" type="button" onClick={onClose} sx={{ flexGrow: 1 }}>
          Close
        </Button>
      </Box>
    </>
  );
};

export default DisplayApiKey;
