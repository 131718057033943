import { WebhooksPaginatedOutputResultsInner } from '@/api-client';
import PermissionModalButton from '@/common/button/PermissionModal';
import PlatformEntityIdentifier from '@/common/data/PlatformEntityIdentifier';
import ServerPaginatedDataGrid, {
  DEFAULT_PAGINATED_GRID_PAGE,
  PaginatedGridPage,
} from '@/common/data/grid/ServerPaginated';
import { BaseRow } from '@/common/data/grid/constants';
import Box from '@mui/material/Box';
import { GridColDef } from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import orderBy from 'lodash/orderBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { proxy, useSnapshot } from 'valtio';
import DeleteWebhookForm from './DeleteForm';
import OverviewContainer from '@/common/container/OverviewContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSendTestWebhook, useWebhooks } from '@/utils/hooks/webhook';
import { Button } from '@mui/material';
import EnableChangeWebhookForm from './EnableChangeForm';
import CreateUpdateWebHookForm from './CreateForm';

// Row specific to this grid.
interface WebHooksGridRow extends BaseRow {
  name: string;
  enabled: boolean | false;
  secret: string | null;
  url: string | null;
}

// Columns specific to this grid.
const columns: Array<GridColDef<WebHooksGridRow>> = [
  {
    field: 'id',
    headerName: 'ID',
    width: 150,
    renderCell: (params: { row: WebHooksGridRow }) => (
      <PlatformEntityIdentifier
        tooltipContext="this Webhook ID"
        entityId={params.row.id as string}
      />
    ),
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
  },
  {
    field: 'enabled',
    headerName: 'Status',
    width: 250,
    renderCell: (params: { row: WebHooksGridRow }) => {
      return params.row.enabled ? <>ACTIVE</> : <>INACTIVE</>;
    },
  },
];

// Initial state specific to this grid.
const initialState: GridInitialStateCommunity = {
  columns: {
    columnVisibilityModel: {
      id: false,
    },
  },
};

// Will map API data (in this case a mix of multiple sources) to rows.
const mapToRow = (row: WebhooksPaginatedOutputResultsInner): WebHooksGridRow => {
  return {
    id: row.id,
    enabled: row.enabled,
    name: row.name,
    secret: row.secret,
    url: row.url,
  };
};

const pageState: PaginatedGridPage<WebHooksGridRow> = proxy({
  ...DEFAULT_PAGINATED_GRID_PAGE,
});

const WebHooksGrid = () => {
  // Get readonly snaps from the state.
  const pageSnap = useSnapshot(pageState);
  const navigate = useNavigate();
  const location = useLocation();
  const [webHookStatus, setWebHookStatus] = useState<boolean>(false);
  const { mutate: sendTestWebhook } = useSendTestWebhook();

  const { state: urlState } = location;

  const webHook = useMemo(() => {
    setWebHookStatus(urlState ? (urlState['webHookStatus'] as boolean) : false);

    return {
      id: urlState ? (urlState['webHookId'] as string) : null,
      name: urlState ? (urlState['webHookName'] as string) : null,
      url: urlState ? (urlState['webHookUrl'] as string) : null,
      secret: urlState ? (urlState['webHookSecret'] as string) : null,
      status: webHookStatus,
    };
  }, [urlState, webHookStatus]);

  const { data: webHooks, isLoading: isWebHooksLoading } = useWebhooks(
    pageSnap.pageSize,
    pageSnap.cursor
  );

  // Populate rows when any API data changes.
  useEffect(() => {
    pageState.rows =
      orderBy(webHooks?.results, 'name').map(id => {
        return mapToRow(id);
      }) ?? [];
    pageState.nextCursor = webHooks?.cursor ?? DEFAULT_PAGINATED_GRID_PAGE.cursor;
  }, [location.state, webHooks?.results, webHooks?.cursor]);

  const doubleClickNavigationFunction = useCallback(
    (row: WebHooksGridRow) => {
      return navigate('/settings?tab=webhooks', {
        state: {
          cleared: null,
          webHookId: row.id,
          webHookName: row.name,
          webHookUrl: row.url,
          webHookSecret: row.secret,
          webHookStatus: row.enabled,
        },
      });
    },
    [navigate]
  );

  if (webHook?.id) {
    return (
      <Box>
        <OverviewContainer
          isLoading={isWebHooksLoading}
          gridColumnDivisions={2}
          columnGap={1}
          gridRowDivisions={1}
          notFound={!webHooks?.total_results}
          fullHeight={false}
        >
          <CreateUpdateWebHookForm onSuccess={() => {}} onCancel={() => {}} webHook={webHook} />
        </OverviewContainer>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'left',
            alignItems: 'center',
            gap: 2,
            mt: 2,
          }}
        >
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              navigate('/settings?tab=webhooks', {
                replace: true,
                state: {
                  webHookId: null,
                  webHookName: null,
                  webHookStatus: null,
                  webHookLastUsed: null,
                },
              });
            }}
            sx={{ minWidth: '50px' }}
          >
            Cancel
          </Button>

          <PermissionModalButton
            modalTitle="Delete Webhook"
            modalDescription={'Delete Webhook'}
            variant="delete"
            buttonLabel="Delete"
            requiredPermissions={['webhooks:delete']}
            buttonProps={{ variant: 'outlined' }}
          >
            {({ closeModal }) => {
              const close = () => closeModal();
              const success = () => {
                closeModal();
                navigate('/settings?tab=webhooks', {
                  replace: true,
                  state: {
                    webHookId: null,
                    webHookName: null,
                    webHookStatus: null,
                    webHookLastUsed: null,
                  },
                });
              };
              return (
                <DeleteWebhookForm webHookId={webHook.id} onSuccess={success} onCancel={close} />
              );
            }}
          </PermissionModalButton>

          <PermissionModalButton
            modalTitle={webHookStatus ? 'Disable Webhook' : 'Enable Webhook'}
            modalDescription={webHookStatus ? 'Disable Webhook' : 'Enable Webhook'}
            variant="delete"
            buttonLabel={webHookStatus ? 'Disable' : 'Enable'}
            requiredPermissions={['webhooks:update']}
            buttonProps={{ variant: 'outlined' }}
          >
            {({ closeModal }) => {
              const close = () => closeModal();
              const success = () => {
                urlState['webHookStatus'] = !webHookStatus;
                setWebHookStatus(!webHookStatus);
                close();
              };
              return (
                <EnableChangeWebhookForm
                  webHookId={webHook.id}
                  enabled={!webHookStatus}
                  onSuccess={success}
                  onCancel={close}
                />
              );
            }}
          </PermissionModalButton>

          <Button
            variant="contained"
            type="button"
            onClick={() =>
              sendTestWebhook({
                url: webHook.url as string,
                secret: 'bad-' + (webHook.secret as string),
              })
            }
            sx={{ minWidth: '50px' }}
          >
            Send Test
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ height: 'calc(100% - 65px)', width: '100%' }}>
      <ServerPaginatedDataGrid
        columns={columns}
        initialState={initialState}
        // eslint-disable-next-line valtio/state-snapshot-rule
        activePageProxy={pageState}
        pageLoading={isWebHooksLoading}
        totalRowCount={webHooks?.total_results ?? 0}
        getRowDoubleClickNavigationFunction={doubleClickNavigationFunction}
      />
    </Box>
  );
};

export default WebHooksGrid;
