import Box from '@mui/material/Box';
import { styled, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const EMPTY_OVERLAY_DEFAULT_TEXT = 'No data';

// note: we could extract these colors to the palette property and adjust them
// for light/dark mode in the theme. It may be useful if we use these colors elsewhere.
const StyledNoDataOverlay = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  zIndex: 6,
  background: 'colors.offWhite.grey',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? 'lightgrey' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? 'black' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

interface NoDataOverlayProps {
  text?: string;
}

/**
 * The base component for indicating that there is no data in a section or page.
 * @param text The text to display in the overlay.
 */
const NoDataOverlay = ({ text = EMPTY_OVERLAY_DEFAULT_TEXT }: NoDataOverlayProps) => {
  return (
    <StyledNoDataOverlay>
      <Box
        sx={{
          background: 'white',
          borderRadius: '125px',

          zIndex: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          svg: { marginTop: -1 },
        }}
      >
        <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
          </g>
        </svg>
      </Box>
      {text && (
        <Typography
          sx={{
            fontSize: '1.2vw',
            zIndex: 5,
            mt: 2,
            color: 'text.secondary',
            textTransform: 'uppercase',
            textAlign: 'center',
          }}
        >
          {text}
        </Typography>
      )}
    </StyledNoDataOverlay>
  );
};

export default NoDataOverlay;
