import Page from '@/common/page/Index';
import CollectionsGrid from './Grid';
import PageContentPaper from '@/common/page/ContentPaper';

const CollectionsOverviewPage = () => {
  return (
    <Page testIdPrefix="collections" helmet="Collections" title="Collections">
      <PageContentPaper>
        <CollectionsGrid />
      </PageContentPaper>
    </Page>
  );
};

export default CollectionsOverviewPage;
