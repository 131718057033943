import BlockExplorerFilter, { BlockExplorerFilterType } from '@/common/data/BlockExplorerFilter';
import { Alert, Box, Divider, Typography } from '@mui/material';
import { useTransaction } from '@/utils/hooks/transaction';
import PlatformEntityIdentifier from '@/common/data/PlatformEntityIdentifier';
import CollectionDetailsOverview, {
  COLLECTION_DETAILS_OVERVIEW_POPOVER_SIZE,
} from '@/collections/DetailsOverview';
import ItemDetailsOverview, { ITEM_DETAILS_OVERVIEW_POPOVER_SIZE } from '@/items/DetailsOverview';
import PrettyDate from '@/common/data/PrettyDate';
import { useAllPublicNetworks } from '@/utils/hooks/publicNetwork';
import { useMemo } from 'react';
import find from 'lodash/find';
import NetworkName from '@/common/data/NetworkName';
import { formatEther } from 'ethers';
import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import OverviewContainer from '@/common/container/OverviewContainer';
import Status from '@/common/data/Status';
import { TransactionOutputOnChainStatusEnum, TransactionOutputStateEnum } from '@/api-client';
import TransactionCancellationAlert from './TransactionCancellationAlert';
import { centsToFormattedDollars } from '@/utils/helpers';

interface TransactionDetailsOverviewProps {
  transactionId?: string;
}

export const TRANSACTION_OVERVIEW_DETAILS_POPOVER_SIZE = { width: '750px', height: '475px' };

const TRANSACTION_OVERVIEW_DETAILS_COLUMN_DIVISIONS = 5;
const TRANSACTION_OVERVIEW_DETAILS_ROW_DIVISIONS = 12;

const StatusHeader = ({ label, status }: { label: string; status: string }) => {
  return (
    <Box sx={{ gridColumn: '1/6', display: 'flex', alignItems: 'baseline' }}>
      <Typography variant="h6" sx={{ textDecoration: 'underline', mr: 2 }}>
        {label} status:
      </Typography>
      <Status isChip status={status} />
    </Box>
  );
};

export const TransactionDetailsOverview = ({ transactionId }: TransactionDetailsOverviewProps) => {
  const {
    data: tx,
    isPending: txIsLoading,
    isFetched: txFetched,
  } = useTransaction(transactionId ?? '');
  const { data: networks, isPending: networksIsLoading } = useAllPublicNetworks();

  const txNetwork = useMemo(() => {
    return find(networks, network => network.id === tx?.network_id);
  }, [networks, tx?.network_id]);

  const failureChipColor = useMemo(() => {
    if (!tx?.failure_count) return 'info';
    else {
      if (tx?.state === TransactionOutputStateEnum.Completed) return 'warning';
      else return 'error';
    }
  }, [tx?.state, tx?.failure_count]);

  const effectiveGasPrice = useMemo(() => {
    return tx?.effective_gas_price ? formatEther(tx?.effective_gas_price) : 'N/A';
  }, [tx?.effective_gas_price]);

  const txFee = useMemo(() => {
    return tx?.tx_fee ? formatEther(tx?.tx_fee) : 'N/A';
  }, [tx?.tx_fee]);

  const isActivelyFailing = useMemo(() => {
    return Boolean(
      tx?.failure_count &&
        tx?.failure_count > 0 &&
        tx?.state !== TransactionOutputStateEnum.Completed &&
        tx?.on_chain_status === TransactionOutputOnChainStatusEnum.Success
    );
  }, [tx?.failure_count, tx?.state, tx?.on_chain_status]);

  const coercedRowDivisions = useMemo(() => {
    return isActivelyFailing
      ? TRANSACTION_OVERVIEW_DETAILS_ROW_DIVISIONS + 1 // Account for the alert
      : TRANSACTION_OVERVIEW_DETAILS_ROW_DIVISIONS;
  }, [isActivelyFailing]);

  return (
    <OverviewContainer
      isLoading={txIsLoading || networksIsLoading}
      notFound={!tx && txFetched}
      gridColumnDivisions={TRANSACTION_OVERVIEW_DETAILS_COLUMN_DIVISIONS}
      gridRowDivisions={coercedRowDivisions}
      rowGap={2}
    >
      {isActivelyFailing && (
        <Alert
          severity="error"
          sx={{
            gridColumn: '1 / 6',
            maxHeight: '80px',
            alignSelf: 'self-start',
            justifySelf: 'center',
          }}
        >
          There are issues with this transaction. Please check the transaction details for more
          info.
        </Alert>
      )}

      <StatusHeader label="On chain" status={tx?.on_chain_status ?? 'N/A'} />
      <BasicInfoDisplay title="Tx hash" sx={{ gridColumn: '1/2' }}>
        {tx?.tx_hash && (
          <BlockExplorerFilter
            filterValue={tx?.tx_hash ?? ''}
            filterType={BlockExplorerFilterType.TX_HASH}
            networkId={Number(tx?.network_id)}
            charLengthOnEitherSide={6}
          />
        )}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Network" sx={{ gridColumn: '3/4' }}>
        <NetworkName name={txNetwork?.name ?? ''} testnet={txNetwork?.testnet} />
      </BasicInfoDisplay>

      <BasicInfoDisplay title="On Chain State Timestamp" sx={{ gridColumn: '1/2' }}>
        {tx?.on_chain_timestamp && <PrettyDate date={tx?.on_chain_timestamp} />}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Block" sx={{ gridColumn: '3/4' }}>
        {tx?.mined_block_number}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Signer" sx={{ gridColumn: '1/2' }}>
        {tx?.signer && (
          <BlockExplorerFilter
            filterValue={tx?.signer ?? ''}
            filterType={BlockExplorerFilterType.ADDRESS}
            networkId={Number(tx?.network_id)}
          />
        )}
      </BasicInfoDisplay>
      <BasicInfoDisplay title="Signer Nonce" sx={{ gridColumn: '3/4' }}>
        {tx?.nonce}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Transaction Fee" sx={{ gridColumn: '1/2' }}>
        {txFee}
      </BasicInfoDisplay>
      <BasicInfoDisplay title="Gas Used" sx={{ gridColumn: '3/4' }}>
        {tx?.gas_used}
      </BasicInfoDisplay>
      <BasicInfoDisplay title="Effective Gas Price" sx={{ gridColumn: '5/6' }}>
        {effectiveGasPrice}
      </BasicInfoDisplay>

      <Divider sx={{ gridColumn: `span ${TRANSACTION_OVERVIEW_DETAILS_COLUMN_DIVISIONS}` }} />

      <StatusHeader label="Phosphor API" status={tx?.state?.toString() ?? '0'} />
      {tx?.error_message && (
        <Box sx={{ gridColumn: '1/6', display: 'flex', alignItems: 'baseline' }}>
          <TransactionCancellationAlert errorMessage={tx.error_message} />
        </Box>
      )}

      <BasicInfoDisplay title="Created At" sx={{ gridColumn: '1/2' }}>
        {tx?.created_at && <PrettyDate date={tx?.created_at} />}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Type" sx={{ gridColumn: '3/4' }}>
        {tx?.tx_type?.toString() ?? 'UNKNOWN'}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Failures" sx={{ gridColumn: '5/6', color: failureChipColor }}>
        {tx?.failure_count?.toString() ?? '0'}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="ID" sx={{ gridColumn: '1/2' }}>
        <PlatformEntityIdentifier entityId={tx?.id} />
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Collection ID" sx={{ gridColumn: '3/4' }}>
        {tx?.collection_id && (
          <PlatformEntityIdentifier
            entityId={tx?.collection_id}
            popoverPreviewContent={
              <CollectionDetailsOverview explicitCollectionId={tx?.collection_id} />
            }
            tooltipContext="this tx's collection ID"
            popoverPreviewSx={{ ...COLLECTION_DETAILS_OVERVIEW_POPOVER_SIZE }}
          />
        )}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Item ID" sx={{ gridColumn: '5/6' }}>
        {tx?.item_id && tx?.collection_id && (
          <PlatformEntityIdentifier
            entityId={tx?.item_id}
            popoverPreviewContent={<ItemDetailsOverview explicitItemId={tx?.item_id} />}
            popoverPreviewSx={{ ...ITEM_DETAILS_OVERVIEW_POPOVER_SIZE }}
            tooltipContext="this tx's item ID"
          />
        )}
      </BasicInfoDisplay>

      <Divider sx={{ gridColumn: `span ${TRANSACTION_OVERVIEW_DETAILS_COLUMN_DIVISIONS}` }} />

      <StatusHeader label="Billing" status={tx?.billing_status?.toString() ?? 'N/A'} />
      <BasicInfoDisplay title="Total" sx={{ gridColumn: '1/2' }}>
        {tx?.billing_details?.total
          ? `${centsToFormattedDollars(tx?.billing_details?.total, tx?.billing_details?.currency)}`
          : ''}
      </BasicInfoDisplay>
      <BasicInfoDisplay title="Gas Estimate" sx={{ gridColumn: '3/4' }}>
        {tx?.billing_details?.gas_estimate}
      </BasicInfoDisplay>
      <BasicInfoDisplay title="Service Fee" sx={{ gridColumn: '5/6' }}>
        {tx?.billing_details?.service_fee}
      </BasicInfoDisplay>
    </OverviewContainer>
  );
};

export default TransactionDetailsOverview;
