import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export interface SimpleSubmitFormProps {
  id: string | null;
  onSuccess?: () => void;
  onCancel: () => void;
  // react-query mutation mutate function
  mutate: any;
  confirmationMessage?: string;
  hideConfirmationMessage?: boolean;
  buttonTitle?: string;
}

const DEFAULT_ALERT_MESSAGE =
  'Are you sure you want to continue this action?';

/**
 * The most basic way to submit an entity by ID.
 * Renders two buttons, one to cancel and one to trigger the mutation function.
 * @param id The id of the platform entity to pass to the mutation.
 * @param onSuccess Callback to run on successful deletion.
 * @param onCancel Callback to run on cancel.
 * @param mutate The react-query mutate function that will execute the platform call.
 * @param confirmationMessage A custom message to display as a reminder to the user that they are about to execute a call.
 * @param hideConfirmationMessage Whether to hide the confirmation message.
 * @param buttonTitle Whether to hide the delete confirmation message.
 */
export const SimpleSubmitForm = ({
  id,
  onSuccess,
  onCancel,
  mutate,
  confirmationMessage,
  hideConfirmationMessage = false,
  buttonTitle = 'Confirm',
}: SimpleSubmitFormProps) => {
  return (
    <Box>
      {!hideConfirmationMessage && (
        <Alert severity="warning">{confirmationMessage || DEFAULT_ALERT_MESSAGE}</Alert>
      )}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          mt: 2,
        }}
      >
        <Button variant="outlined" type="button" onClick={onCancel} sx={{ flexGrow: 1 }}>
          Cancel
        </Button>

        <Button
          variant="contained"
          color="error" // DELETE button is error color
          sx={{ flexGrow: 1 }}
          onClick={() => {
            mutate(id, {
              onSuccess: () => {
                onSuccess?.();
              },
            });
          }}
        >
          {buttonTitle}
        </Button>
      </Box>
    </Box>
  );
};

export default SimpleSubmitForm;
