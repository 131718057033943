import { GridColDef } from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { useEffect, useState } from 'react';
import { uuidv4 } from '@/utils/helpers';
import { InputListingContextPolicy } from '@/api-client';
import BlockExplorerFilter, { BlockExplorerFilterType } from '@/common/data/BlockExplorerFilter';
import ClipboardContent from '@/common/data/ClipboardContent';
import { LISTING_BUYER_TYPE } from '@/utils/constants';
import ClientPaginatedDataGrid from '@/common/data/grid/ClientPaginated';

export enum ListingPolicyAllowListGridColumnField {
  ID = 'id',
  TYPE = 'type',
  NETWORK_ID = 'networkId',
  ALLOWED_VALUE = 'allowedValue',
}

type ListingPolicyAllowListGridRow = { [key in ListingPolicyAllowListGridColumnField]: any };

// Columns specific to this grid.
const columns: Array<GridColDef<ListingPolicyAllowListGridRow>> = [
  {
    field: ListingPolicyAllowListGridColumnField.ID,
    headerName: 'ID',
    width: 115,
    filterable: false,
    sortable: false,
  },
  {
    field: ListingPolicyAllowListGridColumnField.TYPE,
    headerName: 'Recipient Type',
    width: 150,
    filterable: true,
    sortable: true,
  },
  {
    field: ListingPolicyAllowListGridColumnField.ALLOWED_VALUE,
    headerName: 'Recipient',
    flex: 1,
    filterable: true,
    sortable: true,
    renderCell: (params: { row: ListingPolicyAllowListGridRow }) =>
      params.row.type === LISTING_BUYER_TYPE.ETH_ADDRESS ? (
        <BlockExplorerFilter
          filterType={BlockExplorerFilterType.ADDRESS}
          filterValue={params.row.allowedValue}
          networkId={Number(params.row.networkId)}
        />
      ) : (
        <ClipboardContent
          tooltipContext="allowed recipient"
          clipboardValue={params.row.allowedValue}
        >
          {params.row.allowedValue}
        </ClipboardContent>
      ),
  },
];

// Initial state specific to this grid.
const initialState: GridInitialStateCommunity = {
  sorting: {
    sortModel: [{ field: ListingPolicyAllowListGridColumnField.ALLOWED_VALUE, sort: 'asc' }],
  },
  columns: {
    columnVisibilityModel: {
      [ListingPolicyAllowListGridColumnField.ID]: false,
    },
  },
};

const mapToRow = (
  type: LISTING_BUYER_TYPE,
  value: string,
  networkId: number
): ListingPolicyAllowListGridRow => {
  return {
    id: uuidv4(),
    type,
    networkId,
    allowedValue: value,
  };
};

interface ListingPolicyAllowListGridProps {
  listingPolicy: InputListingContextPolicy;
  networkId: number;
}

const ListingPolicyAllowListGrid = ({
  listingPolicy,
  networkId,
}: ListingPolicyAllowListGridProps) => {
  // These are the rows that will be displayed in the grid.
  const [rows, setRows] = useState<ListingPolicyAllowListGridRow[]>([]);

  // Map the page visits to rows for this grid.
  useEffect(() => {
    const mappedRows: ListingPolicyAllowListGridRow[] = [];
    if (listingPolicy.email_addresses) {
      listingPolicy.email_addresses.forEach(emailAddress => {
        mappedRows.push(mapToRow(LISTING_BUYER_TYPE.EMAIL, emailAddress, networkId));
      });
    }
    if (listingPolicy.eth_addresses) {
      listingPolicy.eth_addresses.forEach(ethAddress => {
        mappedRows.push(mapToRow(LISTING_BUYER_TYPE.ETH_ADDRESS, ethAddress, networkId));
      });
    }
    setRows(mappedRows);
  }, [listingPolicy, networkId]);

  return (
    <ClientPaginatedDataGrid<ListingPolicyAllowListGridRow>
      columns={columns}
      initialState={initialState}
      rows={rows}
      rowsLoading={false}
    />
  );
};

export default ListingPolicyAllowListGrid;
