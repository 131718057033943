import Box from '@mui/material/Box';

export interface NetworkNameProps {
  name: string;
  testnet?: boolean;
}

/**
 * The base component for rendering a (read-only) representation of network.
 * @param name The name of the network.
 * @param testnet Whether or not the network is a testnet.
 */
export const NetworkName = ({ name, testnet }: NetworkNameProps) => {

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
     {testnet !== undefined && (
      name
    )}
    </Box>
  );
};

export default NetworkName;
