import { Alert, AlertTitle, Typography } from '@mui/material';

export const PaymentSessionDurationAlert = () => {
  return (
    <Alert severity="info">
      <AlertTitle>Payment Session Duration</AlertTitle>

      <Typography variant="inherit" paragraph>
        This indicates how much time a buyer has to complete the payment process. The platform will
        keep track of expired purchase intents and cancel them.
      </Typography>

      <Typography variant="inherit">
        Caveats for <strong>mint vouchers</strong>:
      </Typography>

      <ul>
        <li>
          This duration applies to the time window of buying the item and claiming it via the smart
          contract.
        </li>
        <li>
          The smart contracts will <strong>reject</strong> expired vouchers.
        </li>
      </ul>
    </Alert>
  );
};

export default PaymentSessionDurationAlert;
