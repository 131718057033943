import { GridColDef, DataGrid, GridRowId } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import NoDataOverlay from '@/common/data/NoDataOverlay';
import { useNavigate } from 'react-router';
import { COMMON_GRID_PAGINATION_LIMITS, MUI_DATAGRID_FILTER_PANEL_OVERRIDE } from './constants';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';

export interface ClientPaginatedDataGridProps<TRow extends object> {
  rowsLoading: boolean;
  columns: Array<GridColDef<TRow>>;
  initialState?: GridInitialStateCommunity;
  getRowDoubleClickUrl?: (row: TRow) => string;
  enableSelection?: boolean;
  rows: Array<TRow>;
  onSelectionChange?: (selectedRowIds: GridRowId[]) => void;
}

/**
 * The default component for rendering a paginated data grid with client side data.
 * @param rowsLoading Whether the rows are loading.
 * @param columns The columns to render.
 * @param initialState The initial state of the grid.
 * @param getRowDoubleClickUrl A function that returns the URL to navigate to when a row is double clicked.
 * @param enableSelection Whether to enable selection.
 * @param rows ALL rows to render.
 */
export const ClientPaginatedDataGrid = <TRow extends object>({
  rowsLoading,
  columns,
  initialState,
  getRowDoubleClickUrl,
  enableSelection,
  onSelectionChange,
  rows,
}: ClientPaginatedDataGridProps<TRow>) => {
  const navigate = useNavigate();
  const [coercedColumns, setCoercedGridColumns] = useState<Array<GridColDef<TRow>>>([]);

  // Keep track of the filterable and sortable columns.
  useEffect(() => {
    const coerced = columns.map(col => {
      // Only 1 filterable, and only allow exact match for filterable.
      let coercedColumn = {
        ...col,
        sortable:
          col.sortable === undefined || !Object.prototype.hasOwnProperty.call(col, 'sortable')
            ? false
            : col.sortable,
        filterable:
          col.filterable === undefined || !Object.prototype.hasOwnProperty.call(col, 'filterable')
            ? false
            : col.filterable,
      };

      if (coercedColumn.filterable) {
        coercedColumn = {
          ...coercedColumn,
        };
      }

      return coercedColumn;
    });

    if (getRowDoubleClickUrl) {
      // if there is a double click shortcut, add an action column as well
      coerced.push({
        field: 'actions',
        headerName: '',
        headerAlign: 'right',
        align: 'right',
        flex: 1,
        minWidth: 50,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => (
          <Tooltip title="Click to navigate to the details page">
            <Button
              variant="text"
              color="primary"
              aria-label="view details"
              onClick={() => navigate(getRowDoubleClickUrl(row as TRow))}
            >
              View Details
            </Button>
          </Tooltip>
        ),
      });
    }

    setCoercedGridColumns(coerced);
  }, [columns, getRowDoubleClickUrl, navigate]);

  return (
    <DataGrid
      disableRowSelectionOnClick
      checkboxSelection={enableSelection}
      slots={{
        toolbar: undefined,
        noRowsOverlay: NoDataOverlay,
      }}
      slotProps={{
        ...MUI_DATAGRID_FILTER_PANEL_OVERRIDE,
      }}
      initialState={initialState}
      rows={rowsLoading ? [] : rows}
      columns={coercedColumns}
      pageSizeOptions={COMMON_GRID_PAGINATION_LIMITS}
      getRowHeight={() => 48}
      keepNonExistentRowsSelected
      pagination
      sx={{
        height: '100%',
        minHeight: '250px',
        '.MuiDataGrid-row': {
          cursor: getRowDoubleClickUrl ? 'pointer' : 'inherit',
        },
      }}
      onRowSelectionModelChange={onSelectionChange}
      loading={rowsLoading}
      rowHeight={64}
      onRowDoubleClick={params => {
        if (getRowDoubleClickUrl) {
          const url = getRowDoubleClickUrl(params.row);
          navigate(url);
        }
      }}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'MuiGrid-row-even' : 'MuiGrid-row-odd'
      }
    />
  );
};

export default ClientPaginatedDataGrid;
