import { InputListingContext, ListingOutputStatusEnum } from '@/api-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { adminApi } from '../api';
import { API_SORT_DIRECTION_ASC, API_SORT_DIRECTION_DESC, LISTINGS_QUERY_KEY } from '../constants';
import { getApiErrorMessage } from '../helpers';

export const useListing = (listingId: string) => {
  return useQuery({
    queryKey: [LISTINGS_QUERY_KEY, listingId],
    queryFn: () => adminApi.listing.listingsListingIdGet(listingId).then(res => res.data),
    enabled: Boolean(listingId),
  });
};

interface ListingFilter {
  collectionId?: string;
  itemId?: string;
  status?: string;
}

export const useListings = (
  pageSize?: number,
  cursor?: string,
  sort?: {
    fieldName?: string;
    direction?: typeof API_SORT_DIRECTION_ASC | typeof API_SORT_DIRECTION_DESC;
  },
  filter?: ListingFilter
) => {
  const result = useQuery({
    queryKey: [LISTINGS_QUERY_KEY, pageSize, cursor, sort, filter],
    queryFn: () =>
      adminApi.listing
        .listingsGet(
          sort?.direction,
          cursor,
          pageSize,
          filter?.itemId,
          filter?.collectionId,
          filter?.status as ListingOutputStatusEnum
        )
        .then(response => response.data),
  });

  return result;
};

export const useCollectionListings = (
  collectionId: string,
  pageSize?: number,
  cursor?: string,
  sort?: {
    fieldName?: string;
    direction?: typeof API_SORT_DIRECTION_ASC | typeof API_SORT_DIRECTION_DESC;
  }
) => {
  return useListings(pageSize, cursor, sort, { collectionId });
};

export const useItemListings = (
  itemId: string,
  pageSize?: number,
  cursor?: string,
  sort?: {
    fieldName?: string;
    direction?: typeof API_SORT_DIRECTION_ASC | typeof API_SORT_DIRECTION_DESC;
  }
) => {
  return useListings(pageSize, cursor, sort, { itemId });
};

export const useCreateListing = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const createListing = useMutation({
    mutationFn: (body: InputListingContext) => adminApi.listing.listingsPost(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [LISTINGS_QUERY_KEY] });
      enqueueSnackbar('Listing created', {
        variant: 'success',
      });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error creating listing', { variant: 'error' });
    },
  });

  return createListing;
};
