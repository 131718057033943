export const ITEMS_TAB_LABEL = 'Items';
export const ITEMS_TAB_VALUE = 'items';
export const LISTINGS_TAB_LABEL = 'Listings';
export const LISTINGS_TAB_VALUE = 'listings';
export const EMAIL_CLAIMS_TAB_LABEL = 'Email Claims';
export const EMAIL_CLAIMS_TAB_VALUE = 'email-claims';
export const TRANSACTIONS_TAB_LABEL = 'Transactions';
export const TRANSACTIONS_TAB_VALUE = 'transactions';
export const DELAYED_REVEAL_TAB_LABEL = 'Delayed Reveal';
export const DELAYED_REVEAL_TAB_VALUE = 'delayed-reveal';
