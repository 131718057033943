import PageContentTabs, {
  ContentTab,
  OVERVIEW_TAB_LABEL,
  OVERVIEW_TAB_VALUE,
} from '@/common/page/ContentTabs';
import Page, { entityPageVisitState } from '@/common/page/Index';
import { MAIN_ROUTES } from '@/routes';
import { ENTITY_PAGE_VISIT_TYPE, ROUTE_NAME } from '@/utils/constants';
import { getTransactionTitle } from '@/utils/helpers';
import { useTransaction } from '@/utils/hooks/transaction';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { generatePath, useLocation } from 'react-router-dom';
import TransactionDetailsOverview from './DetailsOverview';
import TransactionJSONData from './JSONData';

const TransactionDetailsPage = () => {
  const {
    transactionId: transactionIdFromUrl,
    collectionId: collectionIdFromUrl,
    itemId: itemIdFromUrl,
  } = useParams();
  const location = useLocation();
  const [routeLabel, setRouteLabel] = useState('All Transactions');
  const { data: transaction } = useTransaction(transactionIdFromUrl ?? '');

  const tabs = useMemo(() => {
    const result: Array<ContentTab> = [
      {
        label: OVERVIEW_TAB_LABEL,
        value: OVERVIEW_TAB_VALUE,
        gridContent: false,
        content: <TransactionDetailsOverview transactionId={transactionIdFromUrl} />,
      },
      {
        label: 'Data',
        value: 'data',
        gridContent: false,
        content: <TransactionJSONData data={transaction?.data} />,
      },
      {
        label: 'Billing',
        value: 'billing',
        gridContent: false,
        content: <TransactionJSONData data={transaction?.billing_details || undefined} />,
      },
    ];
    return result;
  }, [transactionIdFromUrl, transaction?.data, transaction?.billing_details]);

  useEffect(() => {
    if (!transaction || entityPageVisitState?.data?.platformId === transaction.id) return;
    entityPageVisitState.data = {
      type: ENTITY_PAGE_VISIT_TYPE.TRANSACTION,
      context: getTransactionTitle(transaction.tx_type, transaction.created_at),
      platformId: transaction.id,
    };
  }, [transaction]);

  const backToRoutePath = useMemo(() => {
    const overviewRoute = MAIN_ROUTES.find(
      route => route.name === ROUTE_NAME.TRANSACTIONS_OVERVIEW
    );
    if (!overviewRoute) return undefined; // fallback route should always exist.

    let routePath = generatePath(overviewRoute.path);

    if (location.pathname.startsWith('/billing/transactions/')) {
      const billingPath = MAIN_ROUTES.find(route => route.name === ROUTE_NAME.BILLING_OVERVIEW);
      if (billingPath) {
        routePath = generatePath(billingPath.path + '?tab=charges');
        setRouteLabel('Billing Charges');
      }
    } else if (itemIdFromUrl && collectionIdFromUrl) {
      const itemRoute = MAIN_ROUTES.find(route => route.name === ROUTE_NAME.ITEM_DETAILS);
      if (itemRoute) {
        routePath = `${generatePath(itemRoute.path, {
          collectionId: collectionIdFromUrl,
          itemId: itemIdFromUrl,
        })}?tab=transactions`;
        setRouteLabel('Collection Items');
      }
    } else if (collectionIdFromUrl) {
      const collectionRoute = MAIN_ROUTES.find(
        route => route.name === ROUTE_NAME.COLLECTION_DETAILS
      );
      if (collectionRoute) {
        routePath = `${generatePath(collectionRoute.path, {
          collectionId: collectionIdFromUrl,
        })}?tab=transactions`;
        setRouteLabel('Collections');
      }
    }

    return routePath;
  }, [collectionIdFromUrl, itemIdFromUrl, location.pathname]);

  return (
    <Page
      testIdPrefix="transaction-details"
      helmet="Transaction Details"
      title="Transaction Details"
      backTo={{
        routePath: backToRoutePath ?? '',
        buttonLabel: `Back to ${routeLabel}`,
      }}
    >
      <PageContentTabs tabs={tabs} />
    </Page>
  );
};

export default TransactionDetailsPage;
