import CollectionDetailsOverview, {
  COLLECTION_DETAILS_OVERVIEW_POPOVER_SIZE,
} from '@/collections/DetailsOverview';
import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import BlockExplorerFilter, { BlockExplorerFilterType } from '@/common/data/BlockExplorerFilter';
import ClipboardContent from '@/common/data/ClipboardContent';
import OverviewContainer from '@/common/container/OverviewContainer';
import PlatformEntityIdentifier from '@/common/data/PlatformEntityIdentifier';
import PrettyDate from '@/common/data/PrettyDate';
import ItemDetailsOverview, { ITEM_DETAILS_OVERVIEW_POPOVER_SIZE } from '@/items/DetailsOverview';
import { shortenUuid4 } from '@/utils/helpers';
import { useCollection } from '@/utils/hooks/collection';
import { useEmailClaim } from '@/utils/hooks/emailClaim';
import { useItem, useItemTitle } from '@/utils/hooks/item';
import { Box, Divider, Typography } from '@mui/material';

export interface EmailClaimDetailsOverviewProps {
  emailClaimId: string;
}

const EMAIL_CLAIM_OVERVIEW_ROW_DIVISONS = 11;
const EMAIL_CLAIM_OVERVIEW_COLUMN_DIVISONS = 6;

export const EmailClaimDetailsOverview = ({ emailClaimId }: EmailClaimDetailsOverviewProps) => {
  const {
    data: emailClaim,
    isPending: emailClaimLoading,
    isFetched: emailClaimFetched,
  } = useEmailClaim(emailClaimId ?? '');
  const { data: item, isPending: itemLoading } = useItem(emailClaim?.item_id ?? '');
  const itemTitle = useItemTitle(item?.attributes ?? {}, item?.token_id);
  const { data: collection, isPending: collectionLoading } = useCollection(
    item?.collection_id ?? ''
  );

  if (!emailClaimId) {
    return <></>;
  }

  return (
    <OverviewContainer
      isLoading={emailClaimLoading || itemLoading || collectionLoading}
      notFound={!emailClaim && emailClaimFetched}
      gridColumnDivisions={EMAIL_CLAIM_OVERVIEW_COLUMN_DIVISONS}
      gridRowDivisions={EMAIL_CLAIM_OVERVIEW_ROW_DIVISONS}
    >
      <BasicInfoDisplay title="ID">
        <ClipboardContent tooltipContext="email claim ID" clipboardValue={emailClaim?.id}>
          {shortenUuid4(emailClaim?.id)}
        </ClipboardContent>
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Active" variant="boolean">
        {emailClaim?.active}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Claimed" variant="boolean">
        {emailClaim?.claimed}
      </BasicInfoDisplay>

      <BasicInfoDisplay
        title="Claimed By"
        sx={{ gridColumnStart: `span ${EMAIL_CLAIM_OVERVIEW_COLUMN_DIVISONS - 3}` }}
      >
        {emailClaim?.email}
      </BasicInfoDisplay>

      <Divider sx={{ gridColumn: `span ${EMAIL_CLAIM_OVERVIEW_COLUMN_DIVISONS}` }} />

      <Box sx={{ gridColumnStart: `span ${EMAIL_CLAIM_OVERVIEW_COLUMN_DIVISONS}` }}>
        <Typography variant="h6" sx={{ textDecoration: 'underline' }}>
          Dates
        </Typography>
      </Box>

      <BasicInfoDisplay title="Created On">
        {<PrettyDate date={emailClaim?.created_at ?? ''} />}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Expires On">
        {<PrettyDate date={emailClaim?.expiration ?? ''} />}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Claimed On">
        {<PrettyDate date={emailClaim?.claimed_at ?? ''} />}
      </BasicInfoDisplay>

      <BasicInfoDisplay
        title="PIN Code Last Sent"
        sx={{ gridColumnStart: `span ${EMAIL_CLAIM_OVERVIEW_COLUMN_DIVISONS - 3}` }}
      >
        {<PrettyDate date={emailClaim?.last_pin_code_email_sent_at ?? ''} />}
      </BasicInfoDisplay>

      <Divider sx={{ gridColumn: `span ${EMAIL_CLAIM_OVERVIEW_COLUMN_DIVISONS}` }} />

      <Box sx={{ gridColumnStart: `span ${EMAIL_CLAIM_OVERVIEW_COLUMN_DIVISONS}` }}>
        <Typography variant="h6" sx={{ textDecoration: 'underline' }}>
          Scope
        </Typography>
      </Box>

      <BasicInfoDisplay title="Quantity">{emailClaim?.quantity}</BasicInfoDisplay>

      <BasicInfoDisplay title="Item">{itemTitle}</BasicInfoDisplay>

      <BasicInfoDisplay
        title="Collection"
        sx={{ gridColumnStart: `span ${EMAIL_CLAIM_OVERVIEW_COLUMN_DIVISONS - 2}` }}
      >
        {collection?.name}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Item ID">
        {emailClaim?.item_id && item?.collection_id && (
          <PlatformEntityIdentifier
            entityId={emailClaim?.item_id}
            popoverPreviewContent={<ItemDetailsOverview explicitItemId={emailClaim?.item_id} />}
            popoverPreviewSx={{ ...ITEM_DETAILS_OVERVIEW_POPOVER_SIZE }}
            tooltipContext="this email claim's item ID"
          />
        )}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Collection ID">
        {emailClaim?.collection_id && (
          <PlatformEntityIdentifier
            entityId={emailClaim?.collection_id}
            popoverPreviewContent={
              <CollectionDetailsOverview explicitCollectionId={emailClaim?.collection_id} />
            }
            tooltipContext="this email claim's collection ID"
            popoverPreviewSx={{ ...COLLECTION_DETAILS_OVERVIEW_POPOVER_SIZE }}
          />
        )}
      </BasicInfoDisplay>

      <BasicInfoDisplay
        title="Voucher ID"
        sx={{ gridColumnStart: `span ${EMAIL_CLAIM_OVERVIEW_COLUMN_DIVISONS - 2}` }}
      >
        {emailClaim?.voucher_id ? (
          <ClipboardContent tooltipContext="voucher ID" clipboardValue={emailClaim?.voucher_id}>
            {shortenUuid4(emailClaim?.voucher_id ?? '')}
          </ClipboardContent>
        ) : (
          'N/A'
        )}
      </BasicInfoDisplay>

      <Divider sx={{ gridColumn: `span ${EMAIL_CLAIM_OVERVIEW_COLUMN_DIVISONS}` }} />

      <Box sx={{ gridColumnStart: `span ${EMAIL_CLAIM_OVERVIEW_COLUMN_DIVISONS}` }}>
        <Typography variant="h6" sx={{ textDecoration: 'underline' }}>
          Transaction Details
        </Typography>
      </Box>

      <BasicInfoDisplay title="Tx Hash">
        {emailClaim?.tx_hash && collection?.deployment?.network_id ? (
          <BlockExplorerFilter
            filterValue={emailClaim?.tx_hash ?? ''}
            filterType={BlockExplorerFilterType.TX_HASH}
            networkId={Number(collection?.deployment?.network_id)}
            charLengthOnEitherSide={6}
          />
        ) : (
          'N/A'
        )}
      </BasicInfoDisplay>

      <BasicInfoDisplay
        title="Tx Payer"
        sx={{ gridColumnStart: `span ${EMAIL_CLAIM_OVERVIEW_COLUMN_DIVISONS - 2}` }}
      >
        {emailClaim?.tx_payer}
      </BasicInfoDisplay>
    </OverviewContainer>
  );
};

export default EmailClaimDetailsOverview;
