import {
  CreateItemInput,
  CreateItemsInBulkBody,
  ItemOutput,
  ItemsPaginatedOutput,
  LockItemInput,
  MintRequestInput,
  TransferRequestInput,
  UpdateItemInput,
} from '@/api-client';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { adminApi, HOT_REFETCH_INTERVAL_MILLISECONDS } from '../api';
import {
  API_SORT_DIRECTION_ASC,
  API_SORT_DIRECTION_DESC,
  COMMON_ITEM_ATTRIBUTES,
  ITEMS_QUERY_KEY,
  ITEM_TYPES_QUERY_KEY,
  META_ATTRIBUTES,
} from '../constants';
import {
  extractUncommonAttributes,
  getApiErrorMessage,
  getItemTitle,
  mapItemAttributeKeysToFilterEndpointArray,
} from '../helpers';
import { isValidAttributeValue } from '../validation';
import { useCollection } from './collection';

export const useItem = (itemId?: string) => {
  return useQuery({
    queryKey: [ITEMS_QUERY_KEY, itemId],
    queryFn: () => adminApi.item.itemsItemIdGet(itemId as string).then(res => res.data),
    enabled: Boolean(itemId),
    refetchInterval: HOT_REFETCH_INTERVAL_MILLISECONDS,
  });
};

export const useItemStatus = (itemId: string) => {
  return useQuery({
    queryKey: [ITEMS_QUERY_KEY, itemId, 'status'],
    queryFn: () => adminApi.item.itemsItemIdStatusGet(itemId).then(res => res.data),
    enabled: Boolean(itemId),
  });
};

export const useItemTitle = (itemAttributes?: object, tokenId?: string | null) => {
  const title = useMemo(() => {
    if (!itemAttributes) return '';
    return getItemTitle(itemAttributes, tokenId);
  }, [itemAttributes, tokenId]);

  return title;
};

export enum ITEM_ATTRIBUTE_SCHEMA_INPUT_MODE {
  EDIT = 'edit',
  READ = 'read',
}

export interface UseItemAttributeSchemaProps {
  itemTypeId?: string | null;
  attributes?: { [key: string]: any } | null;
  mode: ITEM_ATTRIBUTE_SCHEMA_INPUT_MODE;
}

/**
 * Returns a UI schema and a data schema for the given item type.
 * Based on the input mode, the schema will be adjusted to show/filter attributes that do not comply with the schema.
 */
export const useItemAttributeSchema = ({
  itemTypeId,
  attributes,
  mode,
}: UseItemAttributeSchemaProps) => {
  const getItemType = useQuery({
    queryKey: [ITEM_TYPES_QUERY_KEY, itemTypeId],
    queryFn: () =>
      adminApi.itemType.itemTypesItemTypeIdGet(itemTypeId as string).then(res => res.data),
    enabled: Boolean(itemTypeId),
  });

  const dataSchema = useMemo(() => {
    const candidate: { [key: string]: object } = getItemType.data?.json_schema || ({} as any);

    // By default the implementation of our UI component differs from how JSON schemas work.
    // If this key is not present then additional properties are allowed by default, only limited on type.
    if (!('additionalProperties' in candidate)) {
      candidate['additionalProperties'] = { type: 'string' };
    }

    // Remove the non-custom attributes from the schema.
    // These will have to be handled by their own components.
    if (candidate['properties'] && Object.keys(candidate['properties']).length) {
      [...META_ATTRIBUTES, ...COMMON_ITEM_ATTRIBUTES].forEach(metaAttribute => {
        if (metaAttribute in candidate['properties'])
          delete (candidate['properties'] as { [key: string]: object })[metaAttribute];
      });
    }

    // If we are in read mode, we need to filter the schema based on the existing attributes.
    // We're not interested in the entire schema, but only in the attributes that are present.
    if (attributes) {
      // Coerce properties of the schema if necessary.
      candidate['properties'] = candidate['properties'] || {};

      // Get the uncommon attributes from the item.
      const { custom } = extractUncommonAttributes(attributes);

      // Remove attributes from the data schema that don't have a value in the custom attributes.
      if (mode === ITEM_ATTRIBUTE_SCHEMA_INPUT_MODE.READ) {
        const schemaAttributeKeys = Object.keys(candidate['properties']);
        const schemaAttributeKeysWithoutValues = schemaAttributeKeys.filter(
          (schemaAttributeKey: string) => {
            return !isValidAttributeValue(custom[schemaAttributeKey]);
          }
        );
        schemaAttributeKeysWithoutValues.forEach(key => {
          if ((candidate['properties'] as any)[key]) {
            delete (candidate['properties'] as { [key: string]: object })[key];
          }
        });
      }

      // Enrich data schema with additional custom attributes (of fallback type string) if they are missing.
      const customAttributeKeys = Object.keys(custom);
      if (customAttributeKeys.length > 0) {
        customAttributeKeys.forEach(key => {
          if (!(candidate['properties'] as any)[key]) {
            (candidate['properties'] as { [key: string]: object })[key] = {
              type: 'string',
              title: key,
            };
          }
        });
      }
    }

    return candidate;
  }, [getItemType.data, attributes, mode]);

  const uiSchema = useMemo(() => {
    const uiSchema: any = {
      'ui:submitButtonOptions': {
        norender: true,
      },
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(dataSchema.properties || {}).forEach(([key, property]: [string, any]) => {
      if (property.format === 'uri') {
        // uiSchema[key] = {
        //   'ui:widget': PlatformFileWidget,
        // };
      }

      if (property.format === 'data-url') {
        // uiSchema[key] = {
        //   'ui:widget': DataUrlFileWidget,
        // };
      }
    });
    return uiSchema;
  }, [dataSchema]);

  return {
    getItemType,
    uiSchema,
    dataSchema,
  };
};

export const useEditItem = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: ({ itemId, body }: { itemId: string; body: UpdateItemInput }) =>
      adminApi.item.itemsItemIdPatch(itemId, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ITEMS_QUERY_KEY] });
      enqueueSnackbar('Successfully updated item.', {
        variant: 'success',
      });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error updating item', { variant: 'error' });
    },
  });

  return mutation;
};

export const useBulkCreateItems = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (body: CreateItemsInBulkBody) => adminApi.item.itemsBulkPost(body),
    onSuccess: data => {
      // invalidate item queries
      queryClient.invalidateQueries({ queryKey: [ITEMS_QUERY_KEY] });

      const bulkOutputs = data?.data?.item_ids;
      enqueueSnackbar(
        `The item${bulkOutputs?.length > 1 ? 's have' : ' has'} been successfully created.`,
        { variant: 'success' }
      );
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error) || 'Error locking item';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  return mutation;
};

export const useLockItem = (includeSnackbarFeedback = true) => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (body: LockItemInput) => adminApi.item.itemsLockPost(body),
    onSuccess: data => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey: [ITEMS_QUERY_KEY] });

      // Show feedback if necessary.
      if (data?.data && includeSnackbarFeedback) {
        const { lock_outputs: lockOutputs } = data.data;
        const tokenId = lockOutputs?.length === 1 ? lockOutputs[0]?.token_id : null;
        enqueueSnackbar(
          `The item${lockOutputs?.length > 1 ? 's have' : ' has'} been successfully locked ${
            tokenId ? ' with token ID ' + tokenId : ''
          }.`,
          { variant: 'success' }
        );
      }
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error) || 'Error locking item';
      if (includeSnackbarFeedback) {
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    },
  });

  return mutation;
};

export const useMintItem = (includeSnackbarFeedback = true) => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (body: MintRequestInput) => adminApi.item.mintRequestsPost(body),
    onSuccess: data => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey: [ITEMS_QUERY_KEY] });

      // Show feedback if necessary.
      if (data?.data && includeSnackbarFeedback) {
        const { mint_requests: mintRequests } = data.data;
        enqueueSnackbar(
          `The item${
            mintRequests?.length > 1 ? 's have' : ' has'
          } been successfully queued for minting.`,
          { variant: 'success' }
        );
      }
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error) || 'Error minting item(s)';
      if (includeSnackbarFeedback) {
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    },
  });

  return mutation;
};

export const useTransferItem = (includeSnackbarFeedback = true) => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (body: TransferRequestInput) => adminApi.item.transferRequestsPost(body),
    onSuccess: data => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey: [ITEMS_QUERY_KEY] });

      // Show feedback if necessary.
      if (data?.data && includeSnackbarFeedback) {
        const { transfer_requests: transfer_requests } = data.data;
        enqueueSnackbar(
          `The item${
            transfer_requests?.length > 1 ? 's have' : ' has'
          } been successfully queued for transfer.`,
          { variant: 'success' }
        );
      }
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error) || 'Error transferring item(s)';
      if (includeSnackbarFeedback) {
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    },
  });

  return mutation;
};

export const useCreateItem = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (body: CreateItemInput) => adminApi.item.itemsPost(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ITEMS_QUERY_KEY] });
      enqueueSnackbar('Successfully created item.', {
        variant: 'success',
      });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error creating item', { variant: 'error' });
    },
  });

  return mutation;
};

export interface DeleteItemInput {
  itemId: string;
  itemTitle?: string; // Needed for feedback
}

export const useDeleteItem = (includeSnackbarFeedback = true, invalidateQueries = true) => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (input: DeleteItemInput) => adminApi.item.itemsItemIdDelete(input.itemId),
    onSuccess: (_, input: DeleteItemInput) => {
      if (invalidateQueries) {
        queryClient.invalidateQueries({ queryKey: [ITEMS_QUERY_KEY] });
      }

      // Show feedback if necessary.
      if (includeSnackbarFeedback) {
        enqueueSnackbar(
          input.itemTitle
            ? `The item (${input.itemTitle}) been successfully deleted.`
            : 'The item has been successfully deleted.',
          { variant: 'success' }
        );
      }
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error) || 'Error deleting item';
      if (includeSnackbarFeedback) {
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    },
  });

  return mutation;
};

export const useCollectionItems = (
  collectionId: string,
  pageSize?: number,
  cursor?: string,
  sort?: {
    fieldName?: string;
    direction?: typeof API_SORT_DIRECTION_ASC | typeof API_SORT_DIRECTION_DESC;
  },
  filter?: {
    id?: string;
    tokenId?: string;
    attributes?: { [key: string]: string };
    locked?: boolean;
  }
) => {
  const [output, setOutput] = useState<any>({} as any);

  const getCollectionItems = useQuery({
    queryKey: [ITEMS_QUERY_KEY, collectionId, sort, pageSize, cursor, filter],
    queryFn: () =>
      adminApi.item
        .itemsGet(
          collectionId,
          sort?.direction,
          cursor,
          pageSize,
          filter?.tokenId ? [filter.tokenId] : [],
          mapItemAttributeKeysToFilterEndpointArray(filter?.attributes ?? {}),
          filter?.locked
        )
        .then(res => res.data),
    refetchInterval: HOT_REFETCH_INTERVAL_MILLISECONDS,
  });

  const getItem: UseQueryResult<ItemOutput, unknown> = useQuery({
    queryKey: [ITEMS_QUERY_KEY, filter?.id],
    queryFn: () => adminApi.item.itemsItemIdGet(filter?.id as string).then(res => res.data),
    enabled: Boolean(filter?.id),
    refetchInterval: HOT_REFETCH_INTERVAL_MILLISECONDS,
  });

  useEffect(() => {
    if (filter?.id) {
      setOutput({
        ...getItem,
        data: {
          cursor: null,
          results: getItem.data ? [getItem.data as ItemOutput] : [],
          total_results: getItem.data ? (getItem.data.id ? 1 : 0) : 0,
          has_more: false,
        },
      });
    } else {
      setOutput(getCollectionItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getItem.data,
    getItem.isPending,
    getItem.isError,
    getCollectionItems.data,
    getCollectionItems.isPending,
    getCollectionItems.isError,
    filter?.id,
  ]);

  return output as UseQueryResult<ItemsPaginatedOutput, unknown>;
};

interface IsMetadataMutableProps {
  collectionId?: string | undefined;
  itemId?: string | undefined;
}

export const useIsMetadataMutable = ({ collectionId, itemId }: IsMetadataMutableProps): boolean => {
  const { data: collection, isPending: collectionLoading } = useCollection(collectionId ?? '');
  const { data: item, isPending: itemLoading } = useItem(itemId ?? '');

  // Only mutable when: (AND)
  // - We're not retrieving state. (unknown state === block state changes)
  // - Editable Metadata applies or Item is not locked.
  const metadataMutable = useMemo(() => {
    return (
      Boolean(collectionId) &&
      !collectionLoading &&
      (!itemId || (!itemLoading && (collection?.editable_metadata || !item?.locked)))
    );
  }, [
    collectionId,
    itemId,
    collection?.editable_metadata,
    item?.locked,
    collectionLoading,
    itemLoading,
  ]);

  return metadataMutable;
};
