import {
  PaginatedPublicPlatformStripeAccountsOutput,
  PaginatedPublicPlatformStripeAccountsOutputResultsInner,
  StripePaymentsSetupInput,
} from '@/api-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { adminApi, DEFAULT_API_PAGINATION_LIMIT } from '../api';
import {
  STRIPE_COMMAND,
  STRIPE_INTEGRATION_QUERY_KEY,
  STRIPE_PAYMENT_ACCOUNTS_QUERY_KEY,
} from '../constants';
import { getApiErrorMessage } from '../helpers';
import useAllPages from './useAllPages';
import { useSnapshot } from 'valtio';
import { authState } from '../states/authState';

export const useAllStripePaymentAccountsForOrg = (organizationId?: string) => {
  const result = useAllPages<
    PaginatedPublicPlatformStripeAccountsOutputResultsInner,
    PaginatedPublicPlatformStripeAccountsOutput
  >(
    [STRIPE_PAYMENT_ACCOUNTS_QUERY_KEY, organizationId],
    (cursor: string | undefined) =>
      adminApi.paymentIntegration.organizationsOrganizationIdPaymentIntegrationsStripeGet(
        organizationId as string,
        undefined,
        cursor,
        DEFAULT_API_PAGINATION_LIMIT,
        undefined
      ),
    {
      staleTime: 1000 * 60 * 60 * 4, // 4 hours
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      enabled: Boolean(organizationId),
    }
  );
  return result;
};

export const useAllStripePaymentAccounts = () => {
  const { platformOrg } = useSnapshot(authState);
  return useAllStripePaymentAccountsForOrg(platformOrg?.id);
};

export const useStripeIntegration = () => {
  return useQuery({
    queryKey: [STRIPE_INTEGRATION_QUERY_KEY],
    queryFn: () => adminApi.paymentIntegration.paymentIntegrationsStripeGet().then(res => res.data),
  });
};

export const useStripePaymentIntegrationCreate = () => {
  const queryClient = useQueryClient();

  // if we ever want to break these hooks out into a library, we don't want this to be a dependency
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (body: StripePaymentsSetupInput) =>
      adminApi.paymentIntegration.paymentIntegrationsStripePost({
        ...body,
        return_url: body.return_url || window.location.href,
      }),
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: [STRIPE_INTEGRATION_QUERY_KEY] });
      enqueueSnackbar('Navigating to Stripe to complete the setup', { variant: 'success' });
      // navigate to response something
      const { url } = data;
      window.location.href = url;
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error connecting Stripe', { variant: 'error' });
    },
  });

  return mutation;
};

export const useDisconnectStripe = () => {
  const queryClient = useQueryClient();

  // if we ever want to break these hooks out into a library, we don't want this to be a dependency
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: () => adminApi.paymentIntegration.paymentIntegrationsStripeDelete(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [STRIPE_INTEGRATION_QUERY_KEY] });

      enqueueSnackbar('Successfully disconnected stripe', { variant: 'success' });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error disconnecting stripe', { variant: 'error' });
    },
  });

  return mutation;
};

export const useRetryStripeCommand = () => {
  const queryClient = useQueryClient();

  // if we ever want to break these hooks out into a library, we don't want this to be a dependency
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: () =>
      adminApi.paymentIntegration.paymentIntegrationsStripeCommandPost({
        command: STRIPE_COMMAND.RETRY,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [STRIPE_INTEGRATION_QUERY_KEY] });

      enqueueSnackbar('Attempting to connect to Stripe...', { variant: 'info' });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error retrying stripe', { variant: 'error' });
    },
  });

  return mutation;
};
