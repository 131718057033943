import SimpleDeleteForm from '@/common/data/SimpleDeleteForm';
import { useDeleteApiKey } from '@/utils/hooks/apiKey';

const DeleteApiKeyForm = ({ apiKeyId, onSuccess, onCancel }: any) => {
  const { mutate } = useDeleteApiKey();

  return (
    <SimpleDeleteForm
      onCancel={onCancel}
      onSuccess={onSuccess}
      mutate={mutate}
      id={apiKeyId}
      deleteConfirmationMessage="If you press delete, the api key will no longer be valid. Any application using this key will stop working. Are you sure you want to delete this?"
    />
  );
};

export default DeleteApiKeyForm;
