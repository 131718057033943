import { ApiKeyCreatedOutput } from '@/api-client';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import CreateApiKeyForm from './CreateForm';
import DisplayApiKey from './Display';

const CreateOrDisplayApiKey = ({ onCancel, open }: any) => {
  const [newApiKey, setNewApiKey] = useState<string>('');

  useEffect(() => {
    // if open changes, reset the newApiKey
    if (open) {
      setNewApiKey('');
    }
  }, [open]);

  return !newApiKey ? (
    <CreateApiKeyForm
      onSuccess={(data: AxiosResponse<ApiKeyCreatedOutput>) => {
        const {
          data: { key },
        } = data;
        setNewApiKey(key ?? '');
      }}
      onCancel={onCancel}
    />
  ) : (
    <DisplayApiKey apiKey={newApiKey} onClose={onCancel} />
  );
};

export default CreateOrDisplayApiKey;
