import { EmailIntegrationsPaginatedOutput, MarketingCampaignOutput } from '@/api-client';
import { BaseRow } from '@/common/data/grid/constants';
import ServerPaginatedDataGrid, {
  DEFAULT_PAGINATED_GRID_PAGE,
  PaginatedGridPage,
} from '@/common/data/grid/ServerPaginated';
import PlatformEntityIdentifier from '@/common/data/PlatformEntityIdentifier';
import { AppRoute, MAIN_ROUTES } from '@/routes';
import { API_SORT_DIRECTION_ASC, ROUTE_NAME } from '@/utils/constants';
import { useCampaigns } from '@/utils/hooks/marketingCampaigns';
import { useOrganizationProfile } from '@/utils/hooks/organization';
import { useSortAndFilterGrid } from '@/utils/hooks/useSortAndFilterGrid';
import { authState } from '@/utils/states/authState';
import { GridColDef } from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { find } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { proxy, useSnapshot } from 'valtio';

interface CampaignGridRow extends BaseRow {
  name: string;
  emailFrom?: string | null;
}

const columns: Array<GridColDef<CampaignGridRow>> = [
  {
    field: 'name',
    headerName: 'Campaign Name',
    width: 250,
    sortable: true,
    filterable: true,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 150,
    renderCell: (params: { row: CampaignGridRow }) => (
      <PlatformEntityIdentifier
        tooltipContext="this campaign ID"
        entityId={params.row.id as string}
      />
    ),
    filterable: true,
  },
  {
    field: 'emailFrom',
    headerName: 'Email From',
    width: 300,
  },
];

const mapToRow = (
  campaign: MarketingCampaignOutput,
  defaultEmailFrom?: string
): CampaignGridRow => {
  return {
    id: campaign.id,
    name: campaign.name,
    emailFrom: campaign.email_from || defaultEmailFrom,
  };
};

// Initial state specific to this grid.
const initialState: GridInitialStateCommunity = {
  columns: {
    columnVisibilityModel: {
      id: false,
    },
  },
};

const pageState: PaginatedGridPage<CampaignGridRow> = proxy({
  ...DEFAULT_PAGINATED_GRID_PAGE,
  sortBy: {
    field: 'name',
    direction: API_SORT_DIRECTION_ASC,
  },
});

interface CampaignGripProps {
  emailIntegration?: EmailIntegrationsPaginatedOutput;
  emailIntegrationLoading: boolean;
}

const CampaignsGrid = ({ emailIntegration, emailIntegrationLoading }: CampaignGripProps) => {
  // Get readonly snaps from the state.
  const pageSnap = useSnapshot(pageState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataContextHintKey, setDataContextHintKey] = useState<string>('email-campaigns');
  const [totalRowCount, setTotalRowCount] = useState(0);

  // Extract default email_from from org name
  const { platformOrg } = useSnapshot(authState);
  const { data: orgProfile } = useOrganizationProfile();

  const defaultEmailFrom = useMemo(() => {
    return orgProfile?.public_name ?? `${platformOrg?.id} at Phosphor`;
  }, [platformOrg, orgProfile]);

  // Construct sort and filter args based on the snap of the active page.
  const { sortBy, filterBy } = useSortAndFilterGrid(pageState);

  // Get all API data for current page.
  const { data: campaignsResponse, isPending: campaignsLoading } = useCampaigns(
    pageSnap.pageSize,
    pageSnap.cursor,
    sortBy,
    filterBy
  );

  // Populate rows when any API data changes.
  useEffect(() => {
    if (!campaignsLoading) {
      pageState.rows =
        campaignsResponse?.results.map(campaign => mapToRow(campaign, defaultEmailFrom)) ?? [];
      pageState.nextCursor = campaignsResponse?.cursor ?? DEFAULT_PAGINATED_GRID_PAGE.cursor;
    }

    // override showing grid, until email provider added
    if (emailIntegration?.total_results === 0) {
      pageState.rows = [];
      setTotalRowCount(0);
      setDataContextHintKey('email-campaigns-no-provider');
    }

    // hide campaign grid, until provider added
    setIsLoading(campaignsLoading || emailIntegrationLoading);
  }, [
    campaignsResponse,
    campaignsLoading,
    campaignsResponse?.cursor,
    defaultEmailFrom,
    emailIntegration?.total_results,
    emailIntegration,
    emailIntegrationLoading,
  ]);

  const getRowDoubleClickUrl = useCallback((row: CampaignGridRow) => {
    const campaignDetailsRoute = find(
      MAIN_ROUTES,
      route => route.name === ROUTE_NAME.EMAIL_CAMPAIGN_DETAILS
    ) as AppRoute;
    return generatePath(campaignDetailsRoute.path as string, { campaignId: row.id });
  }, []);

  return (
    <ServerPaginatedDataGrid
      columns={columns}
      initialState={initialState}
      // eslint-disable-next-line valtio/state-snapshot-rule
      activePageProxy={pageState}
      pageLoading={isLoading}
      totalRowCount={totalRowCount}
      getRowDoubleClickUrl={getRowDoubleClickUrl}
      noDataContextHintKey={dataContextHintKey}
    />
  );
};

export default CampaignsGrid;
