import { DateTime } from 'luxon';
import Page from '@/common/page/Index';
import { useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { find } from 'lodash';
import { ROUTE_NAME } from '@/utils/constants';
import { AppRoute, MAIN_ROUTES } from '@/routes';
import PageContentPaper from '@/common/page/ContentPaper';
import { useAllRoles } from '@/utils/hooks/role';
import { Box, Button } from '@mui/material';
import PermissionModalButton from '@/common/button/PermissionModal';
import { useDeleteInvite, useInvite, useResendInvite } from '@/utils/hooks/invites';
import OverviewContainer from '@/common/container/OverviewContainer';
import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import SimpleSubmitForm from '@/common/data/SimpleSubmitForm';

const ResendInviteForm = ({ inviteId, onSuccess, onCancel }: any) => {
  const { mutate } = useResendInvite();

  return (
    <SimpleSubmitForm
      onCancel={onCancel}
      onSuccess={onSuccess}
      mutate={mutate}
      id={inviteId}
      confirmationMessage="Resend invitation notification to user."
      buttonTitle="Resend Invitation"
    />
  );
};

const DeleteInviteForm = ({ inviteId, onSuccess, onCancel }: any) => {
  const { mutate } = useDeleteInvite();

  return (
    <SimpleSubmitForm
      onCancel={onCancel}
      onSuccess={onSuccess}
      mutate={mutate}
      id={inviteId}
      confirmationMessage="Are you sure you want to delete this invitation? This action is permanent."
      buttonTitle="Delete"
    />
  );
};

const UserInvitedDetailsPage = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const { data: roles, isPending: rolesLoading } = useAllRoles();
  const { data: user, isPending: userPending } = useInvite(token as string);

  const mainPath = useMemo(() => {
    const route = find(MAIN_ROUTES, route => route.name === ROUTE_NAME.USERS) as AppRoute;
    return generatePath((route.path as string) + '?tab=invited');
  }, []);

  const roleName = useMemo(() => {
    const role = find(roles, r => r.id === user?.role_id);
    return role ? role.name : '';
  }, [roles, user]);

  const cancelToRoute = () => {
    navigate(mainPath);
  };

  return (
    <Page
      testIdPrefix="user-details"
      title={user?.email as string}
      backTo={{
        routePath: mainPath ?? '',
        buttonLabel: 'Back To Active Users',
      }}
    >
      <PageContentPaper>
        <OverviewContainer
          isLoading={rolesLoading || userPending}
          gridColumnDivisions={3}
          columnGap={1}
          gridRowDivisions={1}
          notFound={!user}
          fullHeight={false}
        >
          <BasicInfoDisplay title="Email">{user?.email}</BasicInfoDisplay>
          <BasicInfoDisplay title="Role">{roleName}</BasicInfoDisplay>
          <BasicInfoDisplay title="Expiration">
            {user?.expiration
              ? DateTime.fromISO(user?.expiration as string).toLocaleString(
                  DateTime.DATETIME_SHORT_WITH_SECONDS
                )
              : 'Never'}
          </BasicInfoDisplay>
        </OverviewContainer>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'left',
            alignItems: 'center',
            gap: 2,
            mt: 2,
          }}
        >
          <Button
            variant="outlined"
            type="button"
            onClick={cancelToRoute}
            sx={{ minWidth: '50px' }}
          >
            Cancel
          </Button>

          <PermissionModalButton
            modalTitle="Delete Invitation"
            buttonLabel="Delete"
            variant="delete"
            buttonProps={{ size: 'small', variant: 'outlined' }}
            requiredPermissions={['users:delete']}
            modalDescription={user?.email as string}
          >
            {({ closeModal }) => {
              const close = () => {
                closeModal();
                cancelToRoute();
              };

              return (
                <DeleteInviteForm inviteId={user?.id} onSuccess={close} onCancel={closeModal} />
              );
            }}
          </PermissionModalButton>

          <PermissionModalButton
            modalTitle="Resend Invitation"
            buttonLabel="Resend Invitation"
            variant="create"
            buttonProps={{ size: 'small', variant: 'outlined' }}
            requiredPermissions={['users:update']}
            modalDescription={user?.email as string}
          >
            {({ closeModal }) => {
              const close = () => {
                closeModal();
              };

              return (
                <ResendInviteForm inviteId={user?.id} onSuccess={close} onCancel={closeModal} />
              );
            }}
          </PermissionModalButton>
        </Box>
      </PageContentPaper>
    </Page>
  );
};

export default UserInvitedDetailsPage;
