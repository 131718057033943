import { useMemo } from 'react';
import PageContentPaper from '@/common/page/ContentPaper';
import { useFormik } from 'formik';
import { Box, Skeleton, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import PermissionGuard from '@/common/PermissionGuard';
import { authState } from '@/utils/states/authState';
import { useSnapshot } from 'valtio';
import {
  EditOrganizationProfileInput,
  useEditOrganizationProfile,
  useOrganizationProfile,
} from '@/utils/hooks/organization';

const validationSchema = yup.object().shape({
  name: yup.string().required('Please enter a organization name'),
});

const UpdateOrgForm = () => {
  const { platformOrg } = useSnapshot(authState);
  const { data: orgProfile } = useOrganizationProfile();
  const { mutate: editOrgProfile, isPending: orgPending } = useEditOrganizationProfile();

  const currentOrgName = useMemo(() => {
    return orgProfile?.public_name ?? '';
  }, [orgProfile]);

  const formik = useFormik({
    initialValues: {
      name: currentOrgName,
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      editOrgProfile({ public_name: values.name } as EditOrganizationProfileInput, {
        onSettled: () => {
          resetForm({ values });
        },
      });
    },
  });

  if (orgPending || !platformOrg) {
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'grid',
          rowGap: '16px',
          gridTemplateRows: '1fr',
        }}
      >
        <Skeleton key="pending-user" variant="rectangular" />
      </Box>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        type="text"
        name="id"
        label="Organization ID"
        disabled={true}
        sx={{ width: '60%', mb: 2 }}
        value={platformOrg.id as string}
        required
      />
      <TextField
        type="text"
        name="name"
        label="Name"
        value={formik.values.name}
        onChange={formik.handleChange}
        disabled={formik.isSubmitting}
        sx={{ width: '60%', mb: 2 }}
        error={formik.touched.name && Boolean(formik.errors.name)}
        required
      />

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'left',
          alignItems: 'center',
          gap: 2,
          mt: 2,
        }}
      >
        <PermissionGuard requiredPermissions={['organizations:update']}>
          <LoadingButton
            type="submit"
            disabled={
              formik.values.name == platformOrg.name || formik.isSubmitting || !formik.dirty
            }
            loading={formik.isSubmitting}
            variant="contained"
            color="primary"
            sx={{ minWidth: '50px' }}
          >
            Save
          </LoadingButton>
        </PermissionGuard>
      </Box>
    </form>
  );
};

const OrganizationProfilePage = () => {
  return (
    <Box sx={{ flexDirection: 'column', mt: 1 }}>
      <PageContentPaper>
        <UpdateOrgForm />
      </PageContentPaper>
    </Box>
  );
};

export default OrganizationProfilePage;
