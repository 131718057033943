import Box from '@mui/material/Box';
import { useParams } from 'react-router';
import { useItem } from '@/utils/hooks/item';
import ItemDetailsOverviewSummary from './DetailsOverviewSummary';
import ItemContentTabs from './content/Tabs';
import { useMemo } from 'react';
import { extractUncommonAttributes } from '@/utils/helpers';
import { META_ATTRIBUTE } from '@/utils/constants';
import OverviewContainer from '@/common/container/OverviewContainer';
import OverviewAttributes from './OverviewAttributes';

export interface ItemDetailsOverviewProps {
  explicitItemId?: string;
}

export const ITEM_DETAILS_OVERVIEW_POPOVER_SIZE = { width: '1000px', height: '650px' };

export const ITEM_OVERVIEW_COLUMN_DIVISIONS = ['350px', '1fr'];

export const ItemDetailsOverview = ({ explicitItemId }: ItemDetailsOverviewProps) => {
  const { itemId } = useParams();

  const itemIdToUse = explicitItemId ?? itemId ?? '';
  const { data: item, isPending: itemLoading, isFetched: itemFetched } = useItem(itemIdToUse);

  const uncommonAttributes = useMemo(() => {
    return extractUncommonAttributes(item?.attributes ?? {});
  }, [item?.attributes]);

  const metaAnimationUrl = useMemo(() => {
    return uncommonAttributes?.meta[META_ATTRIBUTE.META_ANIMATION_URL] ?? '';
  }, [uncommonAttributes]);

  if (!itemIdToUse) {
    return <></>;
  }
  return (
    <>
      <OverviewContainer
        isLoading={itemLoading}
        gridColumnDivisions={ITEM_OVERVIEW_COLUMN_DIVISIONS}
        gridRowDivisions={1}
        notFound={itemFetched && !item}
        isPaper={false}
        fullHeight={false}
      >
        <Box sx={{ height: '75%', maxHeight: '350px' }}>
          <ItemContentTabs
            imageUrl={item?.media?.image?.full ?? ''}
            animationUrl={metaAnimationUrl}
          />
        </Box>

        <ItemDetailsOverviewSummary itemId={itemIdToUse} />
      </OverviewContainer>
      <OverviewAttributes attributes={item?.attributes} isLoading={itemLoading} />
    </>
  );
};

export default ItemDetailsOverview;
