import { DEFAULT_API_PAGINATION_LIMIT } from '@/utils/api';
import { GridRowId } from '@mui/x-data-grid';

export const MUI_DATAGRID_FILTER_PANEL_OVERRIDE = {
  filterPanel: {
    filterFormProps: {
      // Customize inputs by passing props
      linkOperatorInputProps: {
        variant: 'outlined',
      },
      columnInputProps: {
        variant: 'outlined',
        sx: { mt: 'auto', mr: 1 },
      },
      operatorInputProps: {
        variant: 'outlined',
        sx: { mt: 'auto', mr: 1 },
      },
      valueInputProps: {
        InputComponentProps: {
          variant: 'outlined',
          sx: { mr: 1 },
        },
      },
      deleteIconProps: {
        sx: {
          '& .MuiSvgIcon-root': { color: '#d32f2f' },
        },
      },
    },
  },
};

export interface BaseRow {
  id: GridRowId;
  original?: any;
}

export const COMMON_GRID_PAGINATION_LIMITS = [5, 15, 45, DEFAULT_API_PAGINATION_LIMIT];
export const DEFAULT_GRID_PAGINATION_LIMIT = DEFAULT_API_PAGINATION_LIMIT;
