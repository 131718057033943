import SimpleSubmitForm from './SimpleSubmitForm';

export interface SimpleDeleteFormProps {
  id: string | null;
  onSuccess?: () => void;
  onCancel: () => void;
  // react-query mutation mutate function
  mutate: any;
  deleteConfirmationMessage?: string;
  hideDeleteConfirmationMessage?: boolean;
}

const DEFAULT_ALERT_MESSAGE =
  'If you press delete, the entry will be gone forever. Are you sure you want to delete this?';

/**
 * The most basic way to delete an entity by ID.
 * Renders two buttons, one to cancel and one to trigger the delete mutation function.
 * @param id The id of the platform entity to pass to the mutation.
 * @param onSuccess Callback to run on successful deletion.
 * @param onCancel Callback to run on cancel.
 * @param mutate The react-query mutate function that will delete the platform entity.
 * @param deleteConfirmationMessage A custom message to display as a reminder to the user that they are about to delete a platform entity.
 * @param hideDeleteConfirmationMessage Whether to hide the delete confirmation message.
 */
export const SimpleDeleteForm = ({
  id,
  onSuccess,
  onCancel,
  mutate,
  deleteConfirmationMessage,
  hideDeleteConfirmationMessage = false,
}: SimpleDeleteFormProps) => {

  return (
    <SimpleSubmitForm
      onCancel={onCancel}
      onSuccess={onSuccess}
      mutate={mutate}
      id={id}
      confirmationMessage={deleteConfirmationMessage || DEFAULT_ALERT_MESSAGE}
      hideConfirmationMessage={hideDeleteConfirmationMessage}
      buttonTitle='Delete'
    />
  );

};

export default SimpleDeleteForm;
