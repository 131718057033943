import { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Alert, Stack, Typography } from '@mui/material';
import ModalActionButtons from './ModalActionButtons';

interface CheckoutFormProps {
  amount: number;
  onCancel: () => void;
  onPaymentComplete: () => void;
}

export default function CheckoutForm({ amount, onCancel, onPaymentComplete }: CheckoutFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const stripeLoaded = stripe && elements;

  const handleSubmit = async () => {
    if (!stripeLoaded) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    try {
      const resp = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });

      // error
      if (resp?.error) {
        if (resp?.error?.type === 'card_error' || resp?.error?.type === 'validation_error') {
          setErrorMessage(resp?.error?.message ?? '');
        } else {
          setErrorMessage('An unexpected error occurred.');
        }
      }

      // success and complete
      if (resp?.paymentIntent?.status === 'succeeded') {
        // todo: paymentIntent.last_charge contains the charge, we can potentially fetch that for the card info.. for now don't worry about it
        onPaymentComplete();
      }

      // in progress?
      console.log('payment intent not completed yet');
    } catch (error: any) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error?.type === 'card_error' || error?.type === 'validation_error') {
        setErrorMessage(error?.message ?? '');
      } else {
        setErrorMessage('An unexpected error occurred.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack spacing="42px">
      <Typography variant="subtitle2" id="parent-modal-description" sx={{ marginBottom: 3 }}>
        Please enter the details for the <b>${amount}</b> payment.
      </Typography>

      <PaymentElement
        id="payment-element"
        options={{
          layout: 'tabs',
        }}
      />

      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

      <ModalActionButtons
        onCancel={onCancel}
        onNext={handleSubmit}
        nextDisabled={!stripeLoaded}
        nextLabel="Submit Payment"
        isLoading={isLoading}
      />
    </Stack>
  );
}
