import { Box, useTheme } from '@mui/material';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';

/**
 * A wrapper around react-json-view that adjust for the active color mode of the theme.
 * @param props The props to pass to react-json-view.
 */
export const ReactJsonThemed = (props: ReactJsonViewProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        '.variable-value span, svg': {
          color: theme.palette.primary.main,
          fontWeight: 'bold',
          fill: theme.palette.primary.main,
        },
      }}
    >
      <ReactJson
        {...props}
        theme={'bright:inverted'}
        style={{
          height: '100%',
          width: '100%',
        }}
      />
    </Box>
  );
};

export default ReactJsonThemed;
