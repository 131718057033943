import { WebhookInput, TestWebhookInput, EditWebhookInput } from '@/api-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { adminApi } from '../api';
import { WEBHOOKS_QUERY_KEY } from '../constants';
import { useSnackbar } from 'notistack';
import { getApiErrorMessage } from '../helpers';

export const useWebhooks = (pageSize?: number, cursor?: string) => {
  return useQuery({
    queryKey: [WEBHOOKS_QUERY_KEY, pageSize, cursor],
    queryFn: () => adminApi.webhook.webhooksGet(undefined, cursor, pageSize).then(res => res.data),
  });
};

export const useCreateWebhook = () => {
  const queryClient = useQueryClient();

  // if we ever want to break these hooks out into a library, we don't want this to be a dependency
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (body: WebhookInput) => adminApi.webhook.webhooksPost(body),
    onSuccess: (r) => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey: [WEBHOOKS_QUERY_KEY] });
      enqueueSnackbar(`Created "${r.data.name}" webhook`, { variant: 'success' });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error creating webhook', { variant: 'error' });
    },
  });

  return mutation;
};

export const useEditWebhook = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: ({id, body}: {id: string, body: EditWebhookInput}) => adminApi.webhook.webhooksWebhookIdPatch(id, body),
    onSuccess: () => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey: [WEBHOOKS_QUERY_KEY] });
      enqueueSnackbar(`Webhook updated`, { variant: 'success' });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || `Error updating webhook`, { variant: 'error' });
    },
  });

  return mutation;
};
export const useEnabledWebhook = (enable: boolean) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const state = enable ? 'en' : 'dis';

  const mutation = useMutation({
    mutationFn: (id: string) => adminApi.webhook.webhooksWebhookIdPatch(id, {enabled: enable}),
    onSuccess: () => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey: [WEBHOOKS_QUERY_KEY] });
      enqueueSnackbar(`Webhook ${state}abled`, { variant: 'success' });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || `Error ${state}abling webhook`, { variant: 'error' });
    },
  });

  return mutation;
};

export const useDeleteWebhook = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (id: string) => adminApi.webhook.webhooksWebhookIdDelete(id),
    onSuccess: () => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey: [WEBHOOKS_QUERY_KEY] });
      enqueueSnackbar('Webhook deleted', { variant: 'success' });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error deleting webhook', { variant: 'error' });
    },
  });

  return mutation;
};

export const useSendTestWebhook = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (body: TestWebhookInput) => adminApi.webhook.webhooksTestPost(body),
    onSuccess: (r) => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey: [WEBHOOKS_QUERY_KEY] });
      if (r?.data?.success) {
        enqueueSnackbar('Webhook sent', { variant: 'success' });
      } else {
        enqueueSnackbar(`Webhook failed with ${r?.data?.response ?? 'no status'}`, { variant: 'error' });
      }
          },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error sending webhook', { variant: 'error' });
    },
  });

  return mutation;
};