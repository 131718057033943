export const baseApiUrl = window.env.VITE_BASE_API_URL;
export const publicApiUrl = window.env.VITE_PUBLIC_API_URL;
export const auth0Domain = window.env.VITE_AUTH0_DOMAIN;
export const auth0ClientId = window.env.VITE_AUTH0_CLIENT_ID;
export const auth0Audience = window.env.VITE_AUTH0_AUDIENCE;
export const featureFlagsClientId = window.env.VITE_FEATURE_FLAGS_CLIENT_ID;
export const sentryDsn = window.env.VITE_SENTRY_DSN;
export const sentryRelease = window.env.SENTRY_RELEASE;
export const userVerifyUrl = window.env.VITE_USER_VERIFY_URL;
export const infuraPublicApiKey = window.env.VITE_PUBLIC_INFURA_KEY;
export const wcProjectId = window.env.VITE_WC_PROJECT_ID;
export const stripeKey = window.env.VITE_STRIPE_KEY;
export const googleAnalyticsId = window.env.VITE_GOOGLE_ANALYTICS_ID;

// payments env should be live or test
export const paymentsEnv = window.env.VITE_PAYMENTS_ENV;
export const paymentsNetworkId = paymentsEnv === 'live' ? 137 : 137;  // TODO: add back 80002 when amoy working on platform

export function stripTrailingV1() {
  const matches = baseApiUrl.match(/(.+)\/v[13].*/);
  if (matches?.length != 2) {
    throw Error('Invalid base url');
  }
  return matches[1];
}

export const betaBaseApiUrl = stripTrailingV1();
