import { Alert, Box, Paper, Typography } from '@mui/material';
import { useMemo } from 'react';
import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import {
  InputListingContextPolicy,
  InputListingContextPolicyItemAssignmentStrategyEnum,
} from '@/api-client';
import { useListing } from '@/utils/hooks/listing';
import NoDataOverlay from '@/common/data/NoDataOverlay';
import ListingPolicyAllowListGrid from './AllowListGrid';
import { useItem } from '@/utils/hooks/item';
import { useCollection } from '@/utils/hooks/collection';
import PopoverButton from '@/common/button/Popover';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { LISTING_POLICY_TYPE } from '@/utils/constants';
import { LISTING_OVERVIEW_COLUMN_DIVISIONS } from '../DetailsOverview';
import OverviewContainer from '@/common/container/OverviewContainer';
import ItemAssignmentStrategyAlert from './alert/ItemAssignmentStrategy';
import EmailClaimDurationAlert from './alert/EmailClaimDuration';
import TxPayerAlert from './alert/TxPayer';
import ListingPolicyTypeAlert from './alert/PolicyType';
import PaymentSessionDurationAlert from './alert/PaymentSessionDuration';

interface ListingPolicyDetailsOverviewProps {
  listingId?: string;
  allowListHeight?: string;
}

const LISTING_POLICY_TYPE_INFO_MAP = {
  [LISTING_POLICY_TYPE.FCFS]: 'First come, first serve.',
  [LISTING_POLICY_TYPE.ALLOW_LIST]: 'Purchases can only be made if the buyer is in the allow list.',
};

export const LISTING_POLICY_OVERVIEW_POPOVER_SIZE = { height: '625px', width: '750px' };

const LISTING_POLICY_OVERVIEW_GRID_HEIGHT = 350;
const LISTING_POLICY_OVERVIEW_ROW_DIVISIONS = 5;

export const ListingPolicyDetailsOverview = ({
  listingId,
  allowListHeight,
}: ListingPolicyDetailsOverviewProps) => {
  const {
    data: listing,
    isPending: listingLoading,
    isFetched: listingFetched,
  } = useListing(listingId ?? '');
  const { data: item, isPending: itemLoading } = useItem(listing?.item_id ?? '');
  const { data: collection, isPending: collectionLoading } = useCollection(
    listing?.collection_id ?? item?.collection_id ?? ''
  );

  const isLoading = (listing?.item_id && itemLoading) || listingLoading || collectionLoading;

  const scope = useMemo(() => {
    return listing?.policy as InputListingContextPolicy;
  }, [listing?.policy]);

  const networkId = useMemo(() => {
    return collection?.deployment?.network_id ?? 0;
  }, [collection?.deployment?.network_id]);

  const coercedItemAssignmentStrategy = useMemo(() => {
    return (
      scope?.item_assignment_strategy ??
      InputListingContextPolicyItemAssignmentStrategyEnum.Automatic
    );
  }, [scope?.item_assignment_strategy]);

  const hasAllowLists = useMemo(() => {
    return (scope?.eth_addresses?.length ?? 0) > 0 || (scope?.email_addresses?.length ?? 0) > 0;
  }, [scope?.eth_addresses, scope?.email_addresses]);

  const policyTypeAlertInfo = useMemo(() => {
    return scope?.type && scope?.type in LISTING_POLICY_TYPE_INFO_MAP
      ? LISTING_POLICY_TYPE_INFO_MAP[scope?.type as LISTING_POLICY_TYPE]
      : null;
  }, [scope?.type]);

  return (
    <OverviewContainer
      isLoading={isLoading}
      gridColumnDivisions={LISTING_OVERVIEW_COLUMN_DIVISIONS}
      gridRowDivisions={LISTING_POLICY_OVERVIEW_ROW_DIVISIONS}
      notFound={listingFetched && !listing}
      isPaper={false}
    >
      <Box sx={{ gridColumnStart: `span ${LISTING_OVERVIEW_COLUMN_DIVISIONS}` }}>
        <Typography variant="h6">Policy</Typography>
      </Box>

      <BasicInfoDisplay title="Policy Type">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {policyTypeAlertInfo && (
            <PopoverButton
              buttonIcon={<InfoIcon />}
              popoverContent={<ListingPolicyTypeAlert />}
              popoverContainerSx={{ p: 0 }}
            />
          )}

          {scope?.type ?? 'N/A'}
        </Box>
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Maximum Buyable Per User">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {scope?.max_per_user && (
            <PopoverButton
              buttonIcon={<InfoIcon />}
              popoverContent={
                <Alert severity="info">
                  This is the maximum number of items that can be purchased by a single user.
                </Alert>
              }
              popoverContainerSx={{ p: 0 }}
            />
          )}

          <Box sx={{ ml: scope?.max_per_user ? 1 : 0 }}>{scope?.max_per_user ?? 'N/A'}</Box>
        </Box>
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Maximum Buyable Per Tx">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {listing?.max_quantity_per_tx && (
            <PopoverButton
              buttonIcon={<InfoIcon />}
              popoverContent={
                <Alert severity="info">
                  The maximum number of items that can be purchased in a single transaction.
                </Alert>
              }
              popoverContainerSx={{ p: 0 }}
            />
          )}

          {listing?.max_quantity_per_tx}
        </Box>
      </BasicInfoDisplay>

      {scope?.payment_session_duration?.timeout_seconds && (
        <BasicInfoDisplay title="Payment Session Duration (Seconds)">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {scope?.payment_session_duration?.timeout_seconds && (
              <PopoverButton
                buttonIcon={<InfoIcon />}
                popoverContent={<PaymentSessionDurationAlert />}
                popoverContainerSx={{ p: 0 }}
              />
            )}

            <Box sx={{ ml: scope?.payment_session_duration?.timeout_seconds ? 1 : 0 }}>
              {`${scope?.payment_session_duration?.timeout_seconds} second(s)`}
            </Box>
          </Box>
        </BasicInfoDisplay>
      )}

      <BasicInfoDisplay title="Email Claim Duration (Seconds)">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {scope?.email_claim_duration && (
            <PopoverButton
              buttonIcon={<InfoIcon />}
              popoverContent={<EmailClaimDurationAlert />}
              popoverContainerSx={{ p: 0 }}
            />
          )}

          <Box sx={{ ml: scope?.email_claim_duration ? 1 : 0 }}>
            {scope?.email_claim_duration
              ? `${scope?.email_claim_duration ?? 'N/A'} second(s)`
              : 'N/A'}
          </Box>
        </Box>
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Item Assignment Strategy">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PopoverButton
            buttonIcon={<InfoIcon />}
            popoverContent={<ItemAssignmentStrategyAlert />}
            popoverContainerSx={{ p: 0 }}
          />

          <Box>{coercedItemAssignmentStrategy}</Box>
        </Box>
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Tx Payer">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PopoverButton
            buttonIcon={<InfoIcon />}
            popoverContent={<TxPayerAlert />}
            popoverContainerSx={{ p: 0 }}
          />

          {scope?.tx_payer}
        </Box>
      </BasicInfoDisplay>

      <Typography
        variant="h6"
        sx={{
          gridColumnStart: `span ${LISTING_OVERVIEW_COLUMN_DIVISIONS}`,
          textDecoration: 'underline',
        }}
      >
        Policy Allow List
      </Typography>

      <Box
        sx={{
          gridColumnStart: `span ${LISTING_OVERVIEW_COLUMN_DIVISIONS}`,
          height: allowListHeight ?? `${LISTING_POLICY_OVERVIEW_GRID_HEIGHT}px`,
        }}
      >
        {hasAllowLists ? (
          <ListingPolicyAllowListGrid listingPolicy={scope} networkId={networkId} />
        ) : (
          <Paper
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              p: 2,
            }}
          >
            <NoDataOverlay text="Not Applicable" />
          </Paper>
        )}
      </Box>
    </OverviewContainer>
  );
};

export default ListingPolicyDetailsOverview;
