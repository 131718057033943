import {
  GridColDef,
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridSortModel,
  GridFilterModel,
  getGridNumericOperators,
  getGridStringOperators,
  getGridBooleanOperators,
  getGridDateOperators,
  GridFilterOperator,
  GridFilterItem,
  GridRowId,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { subscribe, useSnapshot } from 'valtio';
import { API_SORT_DIRECTION_ASC, API_SORT_DIRECTION_DESC } from '@/utils/constants';
import { useNavigate } from 'react-router';
import { Button, Tooltip } from '@mui/material';
import {
  BaseRow,
  COMMON_GRID_PAGINATION_LIMITS,
MUI_DATAGRID_FILTER_PANEL_OVERRIDE,
  DEFAULT_GRID_PAGINATION_LIMIT,
} from './constants';
import NoDataContextHint from '../NoDataContextHint';

export interface PaginatedGridPage<TRow extends BaseRow> {
  pageNumber: number;
  pageSize: number;
  rows: Array<TRow>;
  sortBy?: {
    field: string;
    direction: typeof API_SORT_DIRECTION_ASC | typeof API_SORT_DIRECTION_DESC;
  };
  filterBy?: GridFilterItem[];
  cursor?: string;
  nextCursor?: string;
  selected: TRow[];
}

export interface PaginatedDataGridProps<TRow extends BaseRow> {
  totalRowCount: number;
  /**
   * Should be a Valtio Proxy object!
   */
  activePageProxy: PaginatedGridPage<TRow>;
  pageLoading: boolean;
  columns: Array<GridColDef<TRow>>;
  initialState?: GridInitialStateCommunity;
  Toolbar?: React.JSXElementConstructor<any>;
  toolbarProps?: object;
  getRowDoubleClickUrl?: (row: TRow) => string;
  getRowDoubleClickNavigationFunction?: (row: TRow) => void;
  enableSelection?: boolean;
  noDataContextHintKey?: string;
}

/**
 * The default page state for the server grid.
 */
export const DEFAULT_PAGINATED_GRID_PAGE = {
  pageNumber: 1,
  pageSize: DEFAULT_GRID_PAGINATION_LIMIT,
  rows: [],
  sortBy: undefined,
  filterBy: [],
  cursor: '',
  nextCursor: '',
  selected: [],
};

/**
 * Server grid has a custom toolbar, for optional filtering.
 */
export const DefaultDataGridToolbar = ({ canFilter }: { canFilter: boolean }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton variant="outlined" sx={{ mr: 1 }} />
      {canFilter && (
        <GridToolbarFilterButton
          componentsProps={{ button: { variant: 'outlined' } }}
          sx={{ mr: 1 }}
        />
      )}
      <GridToolbarExport variant="outlined" />
    </GridToolbarContainer>
  );
};

/**
 * Server grid is limited on exact filtering only.
 */
const getExactFilterOperator = (column: GridColDef): Array<GridFilterOperator> => {
  if (column.type === 'number') {
    return getGridNumericOperators().filter(op => op.value === '=');
  } else if (column.type === 'boolean') {
    return getGridBooleanOperators().filter(op => op.value === 'is');
  } else if (column.type === 'date' || column.type === 'datetime') {
    return getGridDateOperators(column.type === 'datetime').filter(op => op.value === 'is');
  } else {
    return getGridStringOperators().filter(op => op.value === 'equals');
  }
};

/**
 * The default component for rendering a paginated data grid with server side pagination that is compatible with our endpoints.
 * @param totalRowCount The total number of rows that is returned in the paginated response.
 * @param activePageProxy The active page proxy (Valtio) object that is used to store the current page state.
 * @param pageLoading Whether the page is loading.
 * @param columns The columns to render.
 * @param initialState The initial state of the grid. (columns visible & default sorting)
 * @param Toolbar The toolbar to render. (optional)
 * @param toolbarProps The props to pass to the toolbar. (optional)
 * @param getRowDoubleClickUrl The function to get the URL to navigate to when a row is double clicked. (optional)
 * @param getRowDoubleClickNavigationFunction A navigate function with options for the double click. (optional)
 * @param enableSelection Whether to enable row selection. (optional) The selected rows can be accessed via the `selected` property of the `activePageProxy`.
 * @param noDataContextHintKey Hint key to use when no data found. Optionally overrides the default route hint. 
 */
export const ServerPaginatedDataGrid = <TRow extends BaseRow>({
  totalRowCount,
  activePageProxy,
  pageLoading,
  columns,
  initialState,
  Toolbar,
  toolbarProps,
  getRowDoubleClickUrl,
  getRowDoubleClickNavigationFunction,
  enableSelection,
  noDataContextHintKey,
}: PaginatedDataGridProps<TRow>) => {
  const navigate = useNavigate();
  const [canFilter, setCanFilter] = useState<boolean>(false);
  const [rows, setRows] = useState<Array<TRow>>([]);
  const [pageCursorCache] = useState<Map<number, string>>(new Map<number, string>());
  const [coercedGridColumns, setCoercedGridColumns] = useState<Array<GridColDef<TRow>>>([]);
  const { pageNumber, pageSize, cursor, nextCursor } = useSnapshot(activePageProxy);
  const [paginationModel, setPaginationModel] = useState({
    pageSize,
    page: pageNumber,
  });

  pageCursorCache.set(pageNumber, cursor ?? '');
  pageCursorCache.set(pageNumber + 1, nextCursor ?? '');

  useEffect(() => {
    activePageProxy.pageNumber = paginationModel?.page;
    activePageProxy.pageSize = paginationModel?.pageSize;
    activePageProxy.cursor = pageCursorCache.get(paginationModel?.page) ?? '';
  }, [activePageProxy, pageCursorCache, paginationModel?.page, paginationModel?.pageSize]);

  useEffect(() => {
    subscribe(activePageProxy, () => {
      setRows(activePageProxy.rows);
    });
  }, [activePageProxy]);

  // Keep track of the filterable and sortable columns.
  useEffect(() => {
    const coerced = columns.map(col => {
      // Only 1 filterable, and only allow exact match for filterable.
      let coercedColumn = {
        ...col,
        sortable:
          col.sortable === undefined || !Object.prototype.hasOwnProperty.call(col, 'sortable')
            ? false
            : col.sortable,
        filterable:
          col.filterable === undefined || !Object.prototype.hasOwnProperty.call(col, 'filterable')
            ? false
            : col.filterable,
      };

      if (coercedColumn.filterable) {
        coercedColumn = {
          ...coercedColumn,
          filterOperators: getExactFilterOperator(col),
        };
        setCanFilter(old => (old ? old : coercedColumn.filterable));
      }

      return coercedColumn;
    });

    if (getRowDoubleClickUrl) {
      // if there is a double click shortcut, add an action column as well
      coerced.push({
        field: 'actions',
        headerName: '',
        headerAlign: 'right',
        align: 'right',
        flex: 1,
        minWidth: 50,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => (
          <Tooltip title="Click to navigate to the details page">
            <Button
              variant="text"
              color="primary"
              aria-label="view details"
              onClick={() => navigate(getRowDoubleClickUrl(row as TRow))}
            >
              View Details
            </Button>
          </Tooltip>
        ),
      });
    }

    if (getRowDoubleClickNavigationFunction) {
      // if there is a double click shortcut with NavigateOptions, add an action column as well
      coerced.push({
        field: 'actions',
        headerName: '',
        headerAlign: 'right',
        align: 'right',
        flex: 1,
        minWidth: 50,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => (
          <Tooltip title="Click to navigate to the details page">
            <Button
              variant="text"
              color="primary"
              aria-label="view details"
              onClick={() => getRowDoubleClickNavigationFunction(row as TRow)}
            >
              View Details
            </Button>
          </Tooltip>
        ),
      });
    }
    setCoercedGridColumns(coerced);
  }, [columns, getRowDoubleClickNavigationFunction, getRowDoubleClickUrl, navigate]);

  const handleSortModelChange = (sortByColumns: GridSortModel) => {
    if (!sortByColumns?.length && activePageProxy.sortBy) {
      return;
    }

    // We only support sorting on 1 field at a time via the server.
    activePageProxy.sortBy = sortByColumns?.length
      ? {
          field: sortByColumns[0].field,
          direction: sortByColumns[0].sort?.toUpperCase() as
            | typeof API_SORT_DIRECTION_ASC
            | typeof API_SORT_DIRECTION_DESC,
        }
      : undefined;
  };

  const onFilterChange = (filterBy: GridFilterModel) => {
    activePageProxy.filterBy = filterBy?.items;
  };

  const onSelectionChange = (selectedRowIds: GridRowId[]) => {
    // Determine what we selected in other pages, and what we selected in this page.
    const currentPageRowIds = rows.map(row => row.id);
    const pageSelection = selectedRowIds.length
      ? rows.filter(row => selectedRowIds.indexOf(row.id) !== -1)
      : [];
    const otherSelections = activePageProxy.selected.filter(
      row => currentPageRowIds.indexOf(row.id) === -1
    );

    activePageProxy.selected = [...otherSelections, ...pageSelection];
  };

  const NoDataContextHintOverride = () => <NoDataContextHint overrideKey={noDataContextHintKey} />;

  return (
    <DataGrid
      disableRowSelectionOnClick
      checkboxSelection={enableSelection}
      slots={{
        toolbar: Toolbar ?? undefined,
        noRowsOverlay: NoDataContextHintOverride,
      }}
      slotProps={{
        toolbar: { canFilter, ...(toolbarProps ?? {}) },
        ...MUI_DATAGRID_FILTER_PANEL_OVERRIDE,
      }}
      initialState={initialState}
      rows={pageLoading ? [] : rows} // For some reason this can't be the snap value
      columns={coercedGridColumns}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      filterMode="server"
      onFilterModelChange={onFilterChange}
      pageSizeOptions={COMMON_GRID_PAGINATION_LIMITS}
      rowCount={totalRowCount}
      pagination
      getRowHeight={() => 48}
      keepNonExistentRowsSelected
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      onRowSelectionModelChange={onSelectionChange}
      sx={{
        height: '100%',
        minHeight: '250px',
        '.MuiDataGrid-row': {
          cursor: getRowDoubleClickUrl ? 'pointer' : 'inherit',
        },
      }}
      loading={pageLoading}
      rowHeight={64}
      onRowDoubleClick={params => {
        if (getRowDoubleClickUrl) {
          const url = getRowDoubleClickUrl(params.row);
          navigate(url);
        }
      }}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'MuiGrid-row-even' : 'MuiGrid-row-odd'
      }
    />
  );
};

export default ServerPaginatedDataGrid;
