import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { ReactComponent as DevLogoSvg } from '@/assets/phosphor-developer-logo.svg';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LogoText = styled<any>(({ fillColor, ...rest }: any) => <DevLogoSvg {...rest} />)`
  > path {
    fill: ${props => props.fillColor ?? 'white'};
  }
`;

export interface AppLogoProps {
  fillColor?: string;
  textWidth?: string;
}

/**
 * The default organization logo read-only component.
 */
export const AppLogo = ({ fillColor, textWidth }: AppLogoProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        display: 'flex',
        columnGap: '8px',
        alignItems: 'center',
      }}
    >
      <LogoText fillColor={fillColor ?? 'white'} width={textWidth ?? '294px'} height="100%" />
    </Box>
  );
};

export default AppLogo;
