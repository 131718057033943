import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import useSearchParamsState from '@/utils/hooks/useSearchParamsState';
import PageContentPaper from '@/common/page/ContentPaper';
import { SxProps } from '@mui/material/styles';
import { hasPermissions } from '@/utils/helpers';
import { authState } from '@/utils/states/authState';
import { useSnapshot } from 'valtio';

export const OVERVIEW_TAB_VALUE = 'overview';
export const OVERVIEW_TAB_LABEL = 'Overview';

interface ContentTabListLabelProps {
  label: string;
}

export interface ContentTab extends ContentTabListLabelProps {
  value: string;
  content: JSX.Element;
  gridContent?: boolean;
  description?: string;
  action?: string | JSX.Element;
  actionClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void; // Only makes sense for string action
  requiredPermissions?: string[];
}

interface ContentTabsProps {
  tabs: Array<ContentTab>;
  defaultTab?: string;
  contentPaperSx?: SxProps;
  tabName?: string;
}

const ContentTabListLabel = ({
  label,
}: ContentTabListLabelProps & { activeTab: boolean }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography variant="body2" sx={{ ml: 0, mr: 0 }}>
        {label}
      </Typography>
    </Box>
  );
};

/**
 * A basic wrapper that can be used at the root level of a page that restrict access by permissions. 
 * @param tabs The tabs to display
 * @param defaultTab The default tab to display
 * @param contentPaperSx The sx props to apply to the PageContentPaper
 * @param tabName The name to lookup tab state in useSearchParamState. (Optional)
 */
export const PageContentTabs = ({ tabs, defaultTab, contentPaperSx, tabName='tab' }: ContentTabsProps) => {
  // use the url query param to store the active tab state
  const { platformPermissions } = useSnapshot(authState);
  const [activeTab, setActiveTab] = useSearchParamsState(tabName, defaultTab || tabs[0].value);

  const handleChange = (event: React.SyntheticEvent, newTab: string) => {
    setActiveTab(newTab);
  };  

  return (
    <TabContext value={activeTab}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginBottom: 2,
        }}
      >
        <TabList onChange={handleChange}>
          {tabs.map(tab => (
              (
                !platformPermissions || !tab.requiredPermissions ||
                (tab.requiredPermissions &&
                  tab.requiredPermissions.length > 0 &&
                  hasPermissions(tab.requiredPermissions, platformPermissions as string[]))
              ) && 
              <Tab
                key={tab.value}
                label={
                  <ContentTabListLabel
                    label={tab.label}
                    activeTab={activeTab === tab.value}
                  />
                }
                value={tab.value}
              />
            )
          )}
        </TabList>
      </Box>

      {tabs.map(tab => (
        <TabPanel
          value={tab.value}
          sx={{
            height: 'calc(100% - 75px)',
            width: '100%',
            padding: 0,
            border: 0,
            boxShadow: 'none',
          }}
          key={tab.value}
        >
          {(
              !platformPermissions || !tab.requiredPermissions ||
              (tab.requiredPermissions &&
                tab.requiredPermissions.length > 0 &&
                hasPermissions(tab.requiredPermissions, platformPermissions as string[]))
            ) && (
            <PageContentPaper
              headerAction={tab.action}
              headerActionClick={tab.actionClick}
              headerSubtitle={tab.description}
              sx={{ ...(contentPaperSx || {}) }}
            >
              {tab.content}
            </PageContentPaper>
          )}
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default PageContentTabs;
