import { useCreateWebhook, useEditWebhook } from '@/utils/hooks/webhook';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';

interface CreateUpdateWebHookFormProps {
  onSuccess: any;
  onCancel: any;
  webHook?: any;
}

const validationSchema = yup.object().shape({
  name: yup.string().trim().required('Fill in the name of the webhook'),
  url: yup.string().trim().required('Fill in the URL of the webhook'),
  secret: yup
    .string()
    .trim()
    .required('Fill in the secret key of the webhook')
    .min(10, 'Secret must be at least 10 characters long'),
});

const CreateUpdateWebHookForm = ({
  onSuccess,
  onCancel,
  webHook,
}: CreateUpdateWebHookFormProps) => {
  const { mutate: createWebhook, isPending: creatingWebhook } = useCreateWebhook();
  const { mutate: editWebhook, isPending: editingWebhook } = useEditWebhook();

  const formik = useFormik({
    initialValues: {
      name: webHook?.name as string,
      url: webHook?.url as string,
      secret: webHook?.secret as string,
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (webHook?.id) {
        editWebhook(
          {
            id: webHook?.id,
            body: {
              name: values.name.trim(),
              url: values.url.trim(),
              secret: values.secret.trim(),
            },
          },
          { ...(onSuccess ? { onSuccess } : {}), onSettled: () => resetForm({ values: values }) }
        );
      } else {
        createWebhook(
          {
            name: values.name.trim(),
            url: values.url.trim(),
            secret: values.secret.trim(),
            enabled: true,
          },
          { ...(onSuccess ? { onSuccess } : {}), onSettled: () => onCancel() }
        );
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="subtitle1" id="parent-modal-description" sx={{ marginBottom: 3 }}>
        Provide the information below to create you new webhook.
      </Typography>

      <TextField
        type="text"
        name="name"
        label="Name"
        value={formik.values.name}
        disabled={formik.isSubmitting}
        sx={{ width: '100%', mb: 2 }}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        placeholder="Fill in the name of the webhook"
        required
      />

      <TextField
        type="url"
        name="url"
        label="URL"
        value={formik.values.url}
        disabled={formik.isSubmitting}
        sx={{ width: '100%', mb: 2 }}
        onChange={formik.handleChange}
        error={formik.touched.url && Boolean(formik.errors.url)}
        placeholder="Fill in the URL of the webhook"
        required
      />

      <TextField
        type="text"
        name="secret"
        label="Secret"
        value={formik.values.secret}
        disabled={formik.isSubmitting}
        sx={{ width: '100%', mb: 2 }}
        onChange={formik.handleChange}
        error={formik.touched.secret && Boolean(formik.errors.secret)}
        placeholder="Fill in the secret key of the webhook"
        required
      />

      {webHook?.id && (
        <TextField
          type="text"
          name="Status"
          label="Secret"
          value={webHook?.status ? 'ACTIVE' : 'INACTIVE'}
          disabled={true}
          sx={{ width: '100%', mb: 2 }}
        />
      )}

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          mt: 2,
        }}
      >
        {!webHook?.id && (
          <Button variant="outlined" type="button" onClick={onCancel} sx={{ flexGrow: 1 }}>
            Cancel
          </Button>
        )}

        <LoadingButton
          type="submit"
          disabled={!formik.dirty || formik.isSubmitting}
          loading={formik.isSubmitting || creatingWebhook || editingWebhook}
          variant="contained"
          color="primary"
          sx={{ flexGrow: 1 }}
        >
          {webHook?.id ? 'Save' : 'Confirm'}
        </LoadingButton>
      </Box>
    </form>
  );
};

export default CreateUpdateWebHookForm;
