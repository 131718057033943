import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Page from '@/common/page/Index';
import { SxProps } from '@mui/material';

/**
 * The common page for 404 errors.
 */
export const NotFoundPage = ({
  message = 'You found a broken link',
  sx = {},
}: {
  message?: string;
  sx?: SxProps;
}) => {
  return (
    <Page testIdPrefix="404" helmet="Not Found!" sx={{ width: '100vw', height: '100vh', ...sx }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1" gutterBottom textAlign="center">
          Oops!
        </Typography>

        <Typography variant="h4" gutterBottom textAlign="center">
          {message}
        </Typography>

        <Typography variant="h5" textAlign="center">
          <Link to="/">Go back to the home page</Link>
        </Typography>
      </Box>
    </Page>
  );
};

export default NotFoundPage;
