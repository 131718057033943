import SimpleSubmitForm from '@/common/data/SimpleSubmitForm';
import { useEnabledWebhook } from '@/utils/hooks/webhook';

const EnableChangeWebhookForm = ({ webHookId, buttonTitle, enabled, onSuccess, onCancel }: any) => {
  const { mutate: enabledMutate } = useEnabledWebhook(enabled);

  return (
    <SimpleSubmitForm
      onCancel={onCancel}
      onSuccess={onSuccess}
      mutate={enabledMutate}
      id={webHookId}
      hideConfirmationMessage={enabled}
      confirmationMessage="If you press confirm, the webhook will no longer be used. Any application using this webhook will stop receiving events. Are you sure you want to disable this?"
      buttonTitle={buttonTitle}
    />
  );
};

export default EnableChangeWebhookForm;
