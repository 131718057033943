import PageContentPaper from '@/common/page/ContentPaper';
import Page from '@/common/page/Index';
import { generatePath, useParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { authState } from '@/utils/states/authState';
import { useOrganizationProfile } from '@/utils/hooks/organization';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { Alert, AlertTitle, Box, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { EMAIL_TEMPLATES_TYPE, ROUTE_NAME } from '@/utils/constants';
import { MAIN_ROUTES } from '@/routes';
import {
  useAllEmailTemplateVariables,
  useCreateEmailTemplate,
  useDeleteEmailTemplate,
  useEmailTemplate,
  useSendTestEmailTemplate,
  useUpdateEmailTemplate,
} from '@/utils/hooks/emailTemplate';
import { useCampaign } from '@/utils/hooks/marketingCampaigns';
import { EmailTemplateType } from '@/utils/types';
import { customPalette } from '@/theme/custom';
import {
  CreateOrganizationEmailTemplateInput,
  EditOrganizationEmailTemplateInput,
} from '@/api-client';

/**
 * Wrapper around the API output with some extra UI state.
 */
export interface OrganizationEmailTemplateVariable {
  template_type: string;
  name: string;
  default_value: string;
  value: string;
}

const initialValues = {
  email_from: '',
  html: '',
  subject: '',
};

const validationSchema = yup.object().shape({
  email_from: yup.string().optional(),
  html: yup.string().required('Please enter valid html page'),
  subject: yup.string().max(128).required('Please enter a valid subject'),
});

const TemplateDetailsPage = () => {
  const { campaignId, templateType } = useParams();

  // Extract default email_from from org name
  const [isFormReady, setIsFormReady] = useState<boolean>(false);
  const [templateReloaded, setTemplateReloaded] = useState<boolean>(false);
  const { data: templateVariables, isPending: variablesLoading } = useAllEmailTemplateVariables(
    templateType as EmailTemplateType
  );

  // Extract default email_from from org name
  const { platformOrg } = useSnapshot(authState);
  const { data: orgProfile } = useOrganizationProfile();

  const defaultEmailFrom = useMemo(() => {
    return orgProfile?.public_name ?? `${platformOrg?.id} at Phosphor`;
  }, [platformOrg, orgProfile]);

  const routePath = useMemo(() => {
    const route = MAIN_ROUTES.find(route => route.name === ROUTE_NAME.EMAIL_CAMPAIGN_DETAILS);
    if (!route) return '';
    return generatePath(route.path, { campaignId: campaignId });
  }, [campaignId]);

  const coercedTemplateVariables = useMemo(() => {
    return variablesLoading
      ? []
      : templateVariables?.map(variable => {
          const mapped: OrganizationEmailTemplateVariable = {
            ...variable,
            default_value: variable.default_value || '',
            value: '',
          };
          return mapped;
        }) ?? [];
  }, [templateVariables, variablesLoading]);

  const { data: campaign, isFetched: campaignFetched } = useCampaign(campaignId);

  // returns template for campaign (either custom or platform default)
  const {
    data: template,
    isFetched: templateFetched,
    refetch: templateFetch,
    isRefetching: templateIsRefetching,
  } = useEmailTemplate(templateType as EmailTemplateType, campaignId);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (!campaignId || !template || !('html' in values) || !('subject' in values)) return;

      if (template.origin_type === EMAIL_TEMPLATES_TYPE.OrganizationCustom) {
        const editBody: EditOrganizationEmailTemplateInput = {
          html: values.html,
          subject: values.subject,
          email_from: values.email_from,
          campaign_id: campaignId,
        };
        updateTemplate({ templateType: templateType as EmailTemplateType, template: editBody });
        setSubmitting(false);
      } else {
        const createBody: CreateOrganizationEmailTemplateInput = {
          html: values.html,
          subject: values.subject,
          email_from: values.email_from,
          campaign_id: campaignId,
        };
        createTemplate({
          templateType: templateType as EmailTemplateType,
          template: createBody,
        });
        setSubmitting(false);
      }
    },
  });

  const initFormikValues = useCallback(() => {
    if (!template?.subject || !template?.html) return;
    formik.setValues({
      email_from: template?.email_from ?? (campaign?.email_from as string) ?? defaultEmailFrom,
      subject: template.subject as string,
      html: template.html as string,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template, campaign?.email_from, defaultEmailFrom]);

  const { mutate: deleteTemplate, isPending: deletingTemplate } = useDeleteEmailTemplate(); // reset causes refetch to use platform template
  const { mutate: createTemplate, isPending: creatingTemplate } = useCreateEmailTemplate();
  const { mutate: updateTemplate, isPending: updatingTemplate } = useUpdateEmailTemplate();
  const { mutate: sendTestEmail, isPending: isTestMailBeingSent } = useSendTestEmailTemplate();

  const onSendEmailClick = useCallback(() => {
    if (!templateType) return;

    sendTestEmail({
      templateType: templateType as EmailTemplateType,
      testMailBody: {
        html: formik.values.html as string,
        subject: formik.values.subject as string,
        variables: coercedTemplateVariables as any,
        campaign_id: campaignId,
      },
    });
  }, [campaignId, coercedTemplateVariables, formik.values, sendTestEmail, templateType]);

  const onResetTemplateClick = useCallback(() => {
    if (!template) return;

    // Reset the html field to the original template.
    if (template.origin_type === EMAIL_TEMPLATES_TYPE.OrganizationCustom) {
      const canContinue = confirm(
        'Are you sure you want to completely reset this template? You will lose all of your changes.'
      );
      if (canContinue) {
        deleteTemplate({
          templateType: templateType as EmailTemplateType,
          body: { campaign_id: campaignId },
        });
        templateFetch();
        setTemplateReloaded(true);
      }
    } else {
      initFormikValues();
    }
  }, [template, deleteTemplate, templateType, campaignId, templateFetch, initFormikValues]);

  useEffect(() => {
    if (campaignFetched && templateFetched)
      initialValues.email_from =
        template?.email_from ?? (campaign?.email_from as string) ?? defaultEmailFrom;
    if (
      (!isFormReady && campaignFetched && templateFetched) ||
      (templateReloaded && !templateIsRefetching)
    )
      initFormikValues();
    if (!isFormReady && campaignFetched && templateFetched) setIsFormReady(true);
  }, [
    campaign,
    campaignFetched,
    defaultEmailFrom,
    initFormikValues,
    isFormReady,
    template?.email_from,
    templateFetched,
    templateIsRefetching,
    templateReloaded,
  ]);

  return (
    <Page
      testIdPrefix="email-campaigns-template-details"
      helmet="Email Template Details"
      title={`${templateType?.replaceAll('_', ' ')} Template`}
      backTo={{
        routePath: routePath ?? '',
        buttonLabel: 'Back To Campaign Details',
      }}
    >
      <PageContentPaper>
        {templateFetched && !template && (
          <Box sx={{ gridColumn: '1/6', display: 'flex', alignItems: 'baseline' }}>
            <Alert severity="error" sx={{ width: 1 }}>
              <AlertTitle>Email Template not Found</AlertTitle>
              {'Invalid Template Requested'}
            </Alert>
          </Box>
        )}
        {isFormReady && (
          <form onSubmit={formik.handleSubmit}>
            <TextField
              autoFocus
              required
              type="text"
              name="email_from"
              value={formik.values.email_from}
              label="From Name"
              disabled={formik.isSubmitting}
              sx={{ width: '70%', mb: 2 }}
              onChange={formik.handleChange}
              error={formik.touched.email_from && Boolean(formik.errors.email_from)}
              helperText={
                formik.touched.email_from
                  ? (formik.errors.email_from as string)
                  : 'Current "From" name to use for this specific template.'
              }
              autoComplete="off"
            />
            <TextField
              type="text"
              name="subject"
              value={formik.values.subject}
              label="Subject"
              disabled={formik.isSubmitting}
              sx={{ width: '70%', mb: 2 }}
              onChange={formik.handleChange}
              error={formik.touched.subject && Boolean(formik.errors.subject)}
              helperText={
                formik.touched.subject
                  ? (formik.errors.subject as string)
                  : 'Specify the email subject'
              }
              autoComplete="off"
              required
            />
            <TextField
              multiline
              rows={4}
              name="html"
              value={formik.values.html}
              label="HTML"
              disabled={formik.isSubmitting}
              sx={{
                width: '70%',
                mb: 2,
                color: customPalette.colors.offWhite.grey,
                '& .MuiInputBase-root': {
                  backgroundColor: customPalette.colors.cnftDarkGrey,
                  color: customPalette.colors.offWhite.grey,
                  mt: 2,
                },
                '& textarea': {
                  minHeight: '10vh',
                  overflow: 'auto',
                  scrollbarColor: `${customPalette.colors.offWhite.grey} ${customPalette.colors.cnftDarkGrey}`,
                },
                '& .MuiFormHelperText-root': {
                  backgroundColor: customPalette.colors.white,
                  color: customPalette.colors.black,
                },
              }}
              onChange={formik.handleChange}
              error={formik.touched.html && Boolean(formik.errors.html)}
              helperText={
                formik.touched.html
                  ? (formik.errors.html as string)
                  : 'Displayed in the current HTML code for this template. Feel free to edit and customize it for suit your needs. \
              Please note, any changes made here here apply to this template only.'
              }
              autoComplete="off"
              required
            />
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'left',
                alignItems: 'center',
                gap: 2,
                mt: 2,
              }}
            >
              <LoadingButton
                variant="outlined"
                type="button"
                loading={templateReloaded && templateIsRefetching}
                disabled={
                  deletingTemplate ||
                  updatingTemplate ||
                  creatingTemplate ||
                  (templateReloaded && templateIsRefetching)
                }
                onClick={onResetTemplateClick}
                sx={{ minWidth: '100px' }}
              >
                Reset
              </LoadingButton>

              <LoadingButton
                loading={isTestMailBeingSent}
                disabled={
                  isTestMailBeingSent ||
                  deletingTemplate ||
                  updatingTemplate ||
                  creatingTemplate ||
                  (templateReloaded && templateIsRefetching)
                }
                variant="outlined"
                type="button"
                onClick={onSendEmailClick}
                sx={{ minWidth: '100px' }}
              >
                Send Test
              </LoadingButton>

              <LoadingButton
                type="submit"
                loading={deletingTemplate || updatingTemplate || creatingTemplate}
                disabled={
                  deletingTemplate ||
                  updatingTemplate ||
                  creatingTemplate ||
                  !formik.values.html ||
                  !!formik.errors.email_from ||
                  !!formik.errors.subject ||
                  !!formik.errors.html ||
                  !formik.dirty
                }
                variant="contained"
                color="primary"
                sx={{ minWidth: '100px' }}
              >
                Save
              </LoadingButton>
            </Box>
          </form>
        )}
      </PageContentPaper>
    </Page>
  );
};

export default TemplateDetailsPage;
