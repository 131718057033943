import Box from '@mui/material/Box';
import { useListing } from '@/utils/hooks/listing';
import ListingPolicyDetailsOverview from './policy/DetailsOverview';
import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import PrettyDate from '@/common/data/PrettyDate';
import OverviewContainer from '@/common/container/OverviewContainer';
import Status from '@/common/data/Status';
import { useMemo } from 'react';
import { getCoercedListingPrice, shortenUuid4 } from '@/utils/helpers';
import ClipboardContent from '@/common/data/ClipboardContent';

export interface ListingDetailsOverviewProps {
  listingId?: string;
}

export const LISTING_OVERVIEW_ROW_DIVISONS = 4;
export const LISTING_OVERVIEW_COLUMN_DIVISIONS = 4;
export const LISTING_OVERVIEW_ROW_GAP = 2;

export const ListingDetailsOverview = ({ listingId }: ListingDetailsOverviewProps) => {
  const {
    data: listing,
    isPending: listingLoading,
    isFetched: listingFetched,
  } = useListing(listingId ?? '');

  const coercedListingPrice = useMemo(() => {
    if (listing?.price && listing?.currency) {
      return getCoercedListingPrice(listing.price, listing.currency);
    } else if (listing?.price) {
      return listing?.price;
    }

    return 'N/A';
  }, [listing?.price, listing?.currency]);

  return (
    <OverviewContainer
      isLoading={listingLoading}
      gridColumnDivisions={LISTING_OVERVIEW_COLUMN_DIVISIONS}
      gridRowDivisions={LISTING_OVERVIEW_ROW_DIVISONS}
      notFound={listingFetched && !listing}
    >
      <BasicInfoDisplay title="Status">
        <Status status={listing?.status ?? 'N/A'} />
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Start time">
        {listing?.start_time ? <PrettyDate date={listing?.start_time} /> : <>N/A</>}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="End time">
        {listing?.end_time ? <PrettyDate date={listing?.end_time} /> : <>N/A</>}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Listed">{listing?.quantity_listed ?? 'N/A'}</BasicInfoDisplay>

      <BasicInfoDisplay title="Remaining">
        {String(listing?.quantity_remaining) ?? 'N/A'}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Price">{coercedListingPrice}</BasicInfoDisplay>

      <BasicInfoDisplay title="Currency">{listing?.currency ?? 'N/A'}</BasicInfoDisplay>

      <BasicInfoDisplay title="Payment">
        {listing?.payment_providers?.length ? listing?.payment_providers.join(', ') : 'N/A'}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Collection ID">
        {listing?.collection_id ? (
          <ClipboardContent tooltipContext="collection ID" clipboardValue={listing?.collection_id}>
            {shortenUuid4(listing?.collection_id)}
          </ClipboardContent>
        ) : (
          'N/A'
        )}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Item ID">
        {listing?.item_id ? (
          <ClipboardContent tooltipContext="item ID" clipboardValue={listing?.item_id}>
            {shortenUuid4(listing?.item_id)}
          </ClipboardContent>
        ) : (
          'N/A'
        )}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Campaign ID">
        {listing?.campaign_id ? (
          <ClipboardContent tooltipContext="campaign ID" clipboardValue={listing?.campaign_id}>
            {shortenUuid4(listing?.campaign_id)}
          </ClipboardContent>
        ) : (
          'N/A'
        )}
      </BasicInfoDisplay>

      <Box
        sx={{
          gridColumnStart: `span ${LISTING_OVERVIEW_COLUMN_DIVISIONS}`,
          height: { xs: '350px', lg: '100%' },
        }}
      >
        <ListingPolicyDetailsOverview listingId={listingId} allowListHeight="100%" />
      </Box>
    </OverviewContainer>
  );
};

export default ListingDetailsOverview;
