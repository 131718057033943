import { StripePaymentsSetupInput } from '@/api-client';
import LoadingBox from '@/common/container/LoadingBox';
import { objectKeysToSnakeCase } from '@/utils/helpers';
import {
  useAllStripePaymentAccounts,
  useStripePaymentIntegrationCreate,
} from '@/utils/hooks/stripePaymentIntegration';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useFormik } from 'formik';
import * as yup from 'yup';


export enum SetupStripeFormFieldType {
  platformStripeAccountId = 'platformStripeAccountId',
  connectType = 'connectType',
}

type SetupStripeFormState = { [key in SetupStripeFormFieldType]: any };

const validationSchema = yup.object().shape<SetupStripeFormState>({
  platformStripeAccountId: yup.string().required('Please select a platform stripe account'),
  connectType: yup.string().required('Please select a connect type'),
});

interface SetupStripeFormProps {
  onSuccess?(): void;
  onCancel(): void;
}

const stripeConnectType = 'STANDARD'; // all other types deprecated


const SetupStripeForm = ({ onSuccess, onCancel }: SetupStripeFormProps) => {
  const { mutate, isPending: creatingStripePaymentIntegration } =
    useStripePaymentIntegrationCreate();

  const formik = useFormik({
    initialValues: {
      platformStripeAccountId: '',
      connectType: stripeConnectType,
    },
    validationSchema,
    onSubmit: (values: SetupStripeFormState, { resetForm }) => {
      mutate(objectKeysToSnakeCase<StripePaymentsSetupInput>(values), {
        ...(onSuccess ? { onSuccess } : {}),
        onSettled: () => resetForm({ values }),
      });
    },
  });

  const { data: stripePaymentAccounts, isPending: isLoadingStripePaymentAccounts } =
    useAllStripePaymentAccounts();

  return (
    <form onSubmit={formik.handleSubmit}>
      <LoadingBox isLoading={formik.isSubmitting} minimumTime={1000}>
        <FormControl fullWidth>
          <InputLabel id="platform-stripe-account-select-label">Stripe account</InputLabel>
          <Select
            labelId="platform-stripe-account-select-label"
            id="platform-stripe-account-select"
            value={formik.values.platformStripeAccountId}
            onChange={formik.handleChange}
            label="Stripe account"
            name="platformStripeAccountId"
            required
          >
            {stripePaymentAccounts &&
              stripePaymentAccounts.map((account: any) => (
                <MenuItem value={account.id} key={account.id}>
                  {account.name} - {account.mode}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </LoadingBox>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          mt: 2,
        }}
      >
        <Button variant="outlined" type="button" onClick={onCancel} sx={{ flexGrow: 1 }}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          disabled={isLoadingStripePaymentAccounts || formik.isSubmitting || !formik.dirty}
          loading={creatingStripePaymentIntegration || formik.isSubmitting}
          variant="contained"
          color="primary"
          sx={{ flexGrow: 1 }}
        >
          Connect
        </LoadingButton>
      </Box>
    </form>
  );
};

export default SetupStripeForm;
