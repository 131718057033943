import { hasPermissions } from '@/utils/helpers';
import { authState } from '@/utils/states/authState';
import { useSnapshot } from 'valtio';

interface PermissionGuardProps {
  requiredPermissions: string[];
  children: React.ReactNode;
}

/**
 * The lowest level component for restricting access to a component by permissions.
 * @param requiredPermissions The permissions required to render the children.
 * @param children The children to render if the permission restrictions are met.
 */
export const PermissionGuard = ({ requiredPermissions, children }: PermissionGuardProps) => {
  const { platformPermissions } = useSnapshot(authState);

  if (
    !platformPermissions ||
    (requiredPermissions &&
      requiredPermissions.length > 0 &&
      !hasPermissions(requiredPermissions, platformPermissions as string[]))
  ) {
    return <></>;
  }

  return children;
};

export default PermissionGuard;
