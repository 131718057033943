import { Alert, AlertTitle } from '@mui/material';

interface TransactionCancellationProps {
  errorMessage?: string;
}

export const TransactionCancellationAlert = ({ errorMessage }: TransactionCancellationProps) => {
  return (
    <Alert severity="error" sx={{ width: 1 }}>
      <AlertTitle>Cancellation reason</AlertTitle>
      {errorMessage}
    </Alert>
  );
};

export default TransactionCancellationAlert;
