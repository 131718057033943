import Box from '@mui/material/Box';
import { useMemo } from 'react';
import ClipboardContent from './ClipboardContent';
import { SxProps, Theme } from '@mui/material';
import PopoverButton from '@/common/button/Popover';
import { ROUTE_NAME, SHORT_UUID_DEFAULT_CHAR_LENGTH_ON_EITHER_SIDE } from '@/utils/constants';
import { shortenUuid4 } from '@/utils/helpers';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

interface PlatformIdentifierCellProps {
  entityId?: string;
  full?: boolean;
  charLengthOnEitherSide?: number;
  tooltipContext?: string;
  routeName?: ROUTE_NAME;
  routeParams?: { [key: string]: any };
  routeQueryFilter?: string;
  popoverPreviewContent?: JSX.Element;
  popoverPreviewSx?: SxProps<Theme>;
  buttonIcon?: ReactJSXElement;
}

/**
 * Base component for rendering a platform entity identifiers. (UUID)
 * It is compatible with popover content (e.g. DetailsOverview for the entity) if supplied.
 * @param entityId The entity identifier (UUID) to display.
 * @param full Whether or not to show the full identifier.
 * @param charLengthOnEitherSide The number of characters to show on either side of the ellipsis.
 * @param tooltipContext The context of the value being displayed. For example, "this identifier".
 * @param popoverPreviewContent The content to display in the popover preview.
 * @param popoverPreviewSx The sx prop to pass to the popover preview.
 * @param buttonIcon The icon of the button.
 */
export const PlatformEntityIdentifier = ({
  entityId,
  full = false,
  charLengthOnEitherSide = SHORT_UUID_DEFAULT_CHAR_LENGTH_ON_EITHER_SIDE,
  tooltipContext = 'this identifier',
  popoverPreviewContent,
  popoverPreviewSx,
  buttonIcon,
}: PlatformIdentifierCellProps) => {
  const displayedId = useMemo(() => {
    if (full || !entityId) return entityId;
    return shortenUuid4(entityId, charLengthOnEitherSide);
  }, [entityId, full, charLengthOnEitherSide]);

  return (
    <Box sx={{ display: 'flex' }}>
      {popoverPreviewContent && (
        <PopoverButton
          buttonIcon={buttonIcon}
          popoverContent={popoverPreviewContent}
          popoverContainerSx={popoverPreviewSx}
        />
      )}
      <ClipboardContent tooltipContext={tooltipContext} clipboardValue={entityId}>
        <Box sx={{ '&:hover': { fontWeight: 'bold' } }}>{displayedId}</Box>
      </ClipboardContent>
    </Box>
  );
};

export default PlatformEntityIdentifier;
