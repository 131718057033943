import SimpleDeleteForm from '@/common/data/SimpleDeleteForm';
import { useDeleteWebhook } from '@/utils/hooks/webhook';

const DeleteWebhookForm = ({ webHookId, onSuccess, onCancel }: any) => {
  const { mutate } = useDeleteWebhook();

  return (
    <SimpleDeleteForm
      onCancel={onCancel}
      onSuccess={onSuccess}
      mutate={mutate}
      id={webHookId}
      deleteConfirmationMessage="If you press delete, the webhook will be permanently deleted. Are you sure you want to delete this?"
    />
  );
};

export default DeleteWebhookForm;
