import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { customPalette } from '@/theme/custom';
import { useMemo } from 'react';
import { extractUncommonAttributes } from '@/utils/helpers';

interface OverviewAttributesProps {
  attributes?: object;
  isLoading: boolean;
}

/**
 * Contains a summary of the item.
 */
export const OverviewAttributes = ({ attributes, isLoading }: OverviewAttributesProps) => {
  const { custom: customAttributes, meta: metaAttributes } = extractUncommonAttributes(
    attributes ?? {}
  );

  const filteredCustom = Object.fromEntries(
    Object.entries(customAttributes).filter(([, value]) => value)
  );
  const filteredMeta = Object.fromEntries(
    Object.entries(metaAttributes).filter(([, value]) => value !== null)
  );

  const allAttributes = useMemo(() => {
    return { ...filteredCustom, ...filteredMeta };
  }, [filteredCustom, filteredMeta]);

  const attributeCount = useMemo(() => {
    if (!allAttributes) return 0;
    return Object.entries(allAttributes).length;
  }, [allAttributes]);

  if (isLoading) {
    return (
      <Alert
        severity="info"
        icon={false}
        sx={{ '.MuiAlert-message': { display: 'flex', columnGap: 1, border: 0 } }}
      >
        <CircularProgress size={20} sx={{ color: 'primary.main', border: 'none !important' }} />
        <Typography variant="inherit">Loading</Typography>
      </Alert>
    );
  }

  return (
    <Box sx={{ p: 0, m: 0 }}>
      <Typography variant="h5" sx={{ fontWeight: 400 }}>
        {attributeCount} Attributes
      </Typography>
      <Box
        sx={{
          display: { xs: 'flex', lg: 'grid' },
          flexDirection: 'column',
          width: '100%',
          height: 'auto',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridTemplateRows: '1fr',
          rowGap: 3,
          columnGap: 4,
          p: 0,
          borderTop: `1px solid ${customPalette.colors.lightGrey}`,
        }}
      >
        {allAttributes &&
          Object.entries(allAttributes).map(
            ([key, value], index) =>
              value && (
                <BasicInfoDisplay
                  key={index}
                  title={key}
                  sx={{ display: 'grid', width: '100%', rowGap: 0 }}
                >
                  {value}
                </BasicInfoDisplay>
              )
          )}
      </Box>
    </Box>
  );
};

export default OverviewAttributes;
