import PageContentPaper from '@/common/page/ContentPaper';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AMAZON_SES_SUPPORTED_REGIONS,
  EMAIL_PROVIDER_MAP,
  MAILGUN_SUPPORTED_REGIONS,
  ROUTE_NAME,
} from '@/utils/constants';
import { AppRoute, MAIN_ROUTES } from '@/routes';
import { generatePath, useNavigate } from 'react-router-dom';
import { find } from 'lodash';
import {
  useCreateEmailProvider,
  useDeleteEmailProvider,
  useEmailIntegration,
  useSendTestEmailProvider,
  useUpdateEmailProvider,
} from '@/utils/hooks/emailIntegration';
import { useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  ListItemIcon,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import ClickToNav from '@/common/data/ClickToNav';
import CustomSMTPIcon from '@mui/icons-material/MailOutline';
import { ReactComponent as MailgunIcon } from '/src/images/mailgun-avatar.svg';
import { ReactComponent as AWSIcon } from '/src/images/aws-avatar.svg';
import { EmailIntegrationInput } from '@/api-client';
import { authState } from '@/utils/states/authState';
import { useSnapshot } from 'valtio';

const initialValues = {
  id: '',
  provider: '',
  domain: '',
  email_from: '',
  mg_api_key: '',
  mg_region: '',
  az_region: '',
  az_api_key: '',
  username: '',
  password: '',
  secret: '',
  host: '',
  port: 587,
};

const amazonSESConfigInput = [
  'provider',
  'az_api_key',
  'secret',
  'email_from',
  'az_region',
  'port',
];

const customSMTPConfigInput = ['provider', 'username', 'email_from', 'host', 'password', 'port'];

const mailgunConfigInput = ['provider', 'domain', 'mg_api_key', 'email_from', 'mg_region'];

const formToConfigMapping: Record<string, string> = {
  mg_api_key: 'api_key',
  mg_region: 'region',
  az_region: 'region',
  az_api_key: 'smtp_username',
  secret: 'smtp_password',
};

const CreateCampaignForm = ({ onCancel }: any) => {
  const [hasProvider, setHasProvider] = useState(false);
  const [isFormReady, setIsFormReady] = useState(false);
  const { platformUser } = useSnapshot(authState);
  const [configInputFields, setConfigInputFields] = useState<string[]>(Object.keys(initialValues));
  const { data: emailIntegration, isPending: emailIntegrationLoading } = useEmailIntegration();

  const { mutate: createProvider } = useCreateEmailProvider();
  const { mutate: deleteProvider } = useDeleteEmailProvider();
  const { mutate: updateProvider } = useUpdateEmailProvider();
  const { mutate: sendTestEmailProvider } = useSendTestEmailProvider();

  const formik = useFormik({
    initialValues: initialValues,
    validate: values => {
      const fieldsToCheck =
        values.provider === 'AMAZON_SES'
          ? amazonSESConfigInput
          : values.provider === 'CUSTOM_SMTP'
            ? customSMTPConfigInput
            : mailgunConfigInput;
      setConfigInputFields(fieldsToCheck);

      const missingFields = fieldsToCheck.reduce(
        (acc, field) => {
          const value = values[field as keyof typeof values];
          if (!value || value === '') acc[field] = 'Field is required';
          return acc;
        },
        {} as Record<string, string>
      );

      return missingFields;
    },
    onSubmit: (values, { setSubmitting }) => {
      const config = configInputFields.reduce(
        (acc, field) => {
          if (field === 'provider') return acc;
          if (values[field as keyof typeof values] !== '') {
            const fieldMapped = field in formToConfigMapping ? formToConfigMapping[field] : field;
            acc[fieldMapped] = values[field as keyof typeof values] as string;
          }
          return acc;
        },
        {} as Record<string, string>
      );

      if (hasProvider) {
        updateProvider({
          id: values.id,
          body: { provider: values?.provider, config: config } as EmailIntegrationInput,
        });
        setSubmitting(false);
      } else {
        createProvider({ provider: values?.provider, config: config } as EmailIntegrationInput);
        setSubmitting(false);
      }
    },
  });

  const onSendEmailClick = useCallback(() => {
    if (!hasProvider || !platformUser?.email) return;
    sendTestEmailProvider({ to: platformUser.email });
  }, [hasProvider, platformUser, sendTestEmailProvider]);

  const onDeleteProviderClick = useCallback(() => {
    if (formik.values.id === '') return;

    // Reset the html field to the original template.
    const canContinue = confirm(
      'Are you sure you want to completely delete this provider? Your email delivery will be disabled.'
    );
    if (!canContinue) return;
    deleteProvider(formik.values.id);
  }, [deleteProvider, formik.values.id]);

  useEffect(() => {
    if (!emailIntegrationLoading && emailIntegration) {
      // triggered when delete occurs
      if (hasProvider && !emailIntegration.results[0]?.provider) {
        setHasProvider(false);
        formik.setValues(initialValues);
        setIsFormReady(true);
        return;
      }
      // no change needed
      if (!emailIntegration.results[0]?.provider) {
        setIsFormReady(true);
        return;
      }

      const provider = emailIntegration.results[0].provider;
      const newResults: any = emailIntegration.results.map(result => {
        const newConfig = Object.entries(initialValues).reduce(
          (acc, [key, value]) => {
            // if the key exists in configToFormMapping, replace it
            const valueKey: string = key in formToConfigMapping ? formToConfigMapping[key] : key;

            if (valueKey in result.config) {
              // region maps to 2 keys (mg_ and az_), so check which gets empty value
              if (valueKey === 'region') {
                if (
                  (provider === 'MAILGUN' && key.startsWith('az_')) ||
                  (provider === 'AMAZON_SES' && key.startsWith('mg_'))
                ) {
                  acc[key] = value as string; // auto set empty value
                  return acc;
                }
              }

              acc[key] = (result.config as Record<string, string>)[valueKey];
            } else {
              acc[valueKey] = value as string; // auto set empty value
            }
            return acc;
          },
          {} as Record<string, string>
        );

        return { ...result, config: newConfig };
      });

      formik.setValues({
        ...newResults[0].config,
        provider: newResults[0].provider,
        id: newResults[0].id,
      });
      setHasProvider(true);
      setIsFormReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailIntegration, emailIntegrationLoading, hasProvider]);

  if (!isFormReady) return <></>;

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl fullWidth>
        <Typography variant="body2">Provider*</Typography>
        <Select
          id="provider"
          name="provider"
          value={formik.values.provider}
          disabled={formik.isSubmitting}
          sx={{ width: '60%', mb: 2 }}
          onChange={formik.handleChange}
          error={formik.touched.provider && Boolean(formik.errors.provider)}
        >
          {Object.entries(EMAIL_PROVIDER_MAP).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              <ListItemIcon sx={{ verticalAlign: 'middle' }}>
                {key === 'Amazon SES' && (
                  <AWSIcon style={{ width: 32, height: 32, marginRight: 12 }} />
                )}
                {key === 'Mailgun' && (
                  <MailgunIcon style={{ width: 32, height: 32, marginRight: 12 }} />
                )}
                {key === 'SMTP Provider' && (
                  <CustomSMTPIcon style={{ width: 32, height: 32, marginRight: 12 }} />
                )}
              </ListItemIcon>
              {key}
            </MenuItem>
          ))}
        </Select>
        {formik.values.provider === 'AMAZON_SES' && (
          <Box sx={{ width: '60%', gap: 2, mt: 2, mb: 4 }}>
            <Typography variant="h6">Amazon SES Settings</Typography>
            <Typography variant="body2">
              Your credentials will always be encrypted in our database. Sign up for a{' '}
              <ClickToNav
                key="az-help"
                hideIcon
                sx={{
                  display: 'flex-inline',
                  textDecoration: 'underline',
                  fontSize: '14px',
                  width: 'auto',
                }}
                url={'http://aws.amazon.com/'}
                mimicButton={false}
              >
                Amazon
              </ClickToNav>{' '}
              account and{' '}
              <ClickToNav
                key="mg-help"
                hideIcon
                sx={{
                  display: 'flex-inline',
                  textDecoration: 'underline',
                  fontSize: '14px',
                  width: 'auto',
                }}
                url={'http://aws.amazon.com/'}
                mimicButton={false}
              >
                configure Amazon SES
              </ClickToNav>{' '}
              by validating your domain and requesting production access
            </Typography>
            <Box sx={{ mt: 2 }}>
              Please Save your settings first, then use the SEND TEST EMAIL button to test the
              connection.
            </Box>
            <Box>
              Need help? Check{' '}
              <ClickToNav
                key="az-docs"
                hideIcon
                sx={{
                  display: 'flex-inline',
                  textDecoration: 'underline',
                  fontSize: '14px',
                  width: 'auto',
                }}
                url={
                  'https://docs.phosphor.xyz/platform-features/digital-asset-distribution/email-claims/use-your-own-email-provider'
                }
                mimicButton={false}
              >
                our instructions
              </ClickToNav>
              .
            </Box>
          </Box>
        )}
        {formik.values.provider === 'CUSTOM_SMTP' && (
          <Box sx={{ width: '60%', gap: 2, mt: 2, mb: 4 }}>
            <Typography variant="h6">SMTP Provider Settings</Typography>
            <Typography variant="body2">
              Your SMTP Credentials will always be encrypted in our database. A few important
              expectations about your server:
            </Typography>
            <ul>
              <li>
                <Typography variant="body2">It must support LOGIN authentication</Typography>
              </li>
              <li>
                <Typography variant="body2">It must support TLS 1.0 or higher</Typography>
              </li>
            </ul>
            <Box sx={{ mt: 2 }}>
              Please Save your settings first, then use the SEND TEST EMAIL button to test the
              connection.
            </Box>
            <Box>
              Need help? Check{' '}
              <ClickToNav
                key="mail-docs"
                hideIcon
                sx={{
                  display: 'flex-inline',
                  textDecoration: 'underline',
                  fontSize: '14px',
                  width: 'auto',
                }}
                url={
                  'https://docs.phosphor.xyz/platform-features/digital-asset-distribution/email-claims/use-your-own-email-provider'
                }
                mimicButton={false}
              >
                our instructions
              </ClickToNav>
              .
            </Box>
          </Box>
        )}
        {formik.values.provider === 'MAILGUN' && (
          <Box sx={{ width: '60%', gap: 2, mt: 2, mb: 4 }}>
            <Typography variant="h6">Mailgun Settings</Typography>
            <Typography variant="body2">
              Your{' '}
              <ClickToNav
                key="mg-help"
                hideIcon
                sx={{
                  display: 'flex-inline',
                  textDecoration: 'underline',
                  fontSize: '14px',
                  width: 'auto',
                }}
                url={'https://app.mailgun.com/app/account/security/api_keys'}
                mimicButton={false}
              >
                Mailgun API Key
              </ClickToNav>{' '}
              will always be encrypted in our database. Sign up for a{' '}
              <ClickToNav
                key="mg-signup"
                hideIcon
                sx={{
                  display: 'flex-inline',
                  textDecoration: 'underline',
                  fontSize: '14px',
                  width: 'auto',
                }}
                url={'https://app.mailgun.com/'}
                mimicButton={false}
              >
                Mailgun
              </ClickToNav>{' '}
              account.
            </Typography>
            <Box sx={{ mt: 2 }}>
              Please Save your settings first, then use the SEND TEST EMAIL button to test the
              connection.
            </Box>
            <Box>
              Need help? Check{' '}
              <ClickToNav
                key="mg-docs"
                hideIcon
                sx={{
                  display: 'flex-inline',
                  textDecoration: 'underline',
                  fontSize: '14px',
                  width: 'auto',
                }}
                url={
                  'https://docs.phosphor.xyz/platform-features/digital-asset-distribution/email-claims/use-your-own-email-provider'
                }
                mimicButton={false}
              >
                our instructions
              </ClickToNav>
              .
            </Box>
          </Box>
        )}
      </FormControl>
      {formik.values.provider !== '' && (
        <TextField
          type="text"
          name="email_from"
          value={formik.values.email_from}
          label="From"
          disabled={formik.isSubmitting}
          sx={{ width: '60%', mb: 2 }}
          onChange={formik.handleChange}
          error={formik.touched.email_from && Boolean(formik.errors.email_from)}
          helperText={
            formik.touched.email_from
              ? (formik.errors.email_from as string)
              : 'Default from address for all emails'
          }
          placeholder="Your Name <your.name@your_company.com>"
          autoComplete="off"
          required
        />
      )}

      {formik.values.provider === 'MAILGUN' && (
        <>
          <TextField
            type="text"
            name="mg_api_key"
            value={formik.values.mg_api_key}
            label="API Key"
            disabled={formik.isSubmitting}
            sx={{ width: '60%', mb: 2 }}
            onChange={formik.handleChange}
            error={formik.touched.mg_api_key && Boolean(formik.errors.mg_api_key)}
            helperText={
              formik.touched.mg_api_key
                ? (formik.errors.mg_api_key as string)
                : 'Your Mailgun API Key'
            }
            autoComplete="off"
            required
          />
          <TextField
            type="text"
            name="domain"
            value={formik.values.domain}
            label="Domain"
            disabled={formik.isSubmitting}
            sx={{ width: '60%', mb: 2 }}
            onChange={formik.handleChange}
            error={formik.touched.domain && Boolean(formik.errors.domain)}
            helperText={
              formik.touched.domain
                ? (formik.errors.domain as string)
                : 'Your Domain registered with Mailgun'
            }
            autoComplete="off"
            required
          />
          <FormControl component="fieldset" fullWidth>
            <Typography variant="body2">Region*</Typography>
            <RadioGroup
              row
              name="mg_region"
              value={formik.values.mg_region}
              defaultValue="US"
              onChange={formik.handleChange}
            >
              {MAILGUN_SUPPORTED_REGIONS.map(region => (
                <FormControlLabel
                  key={region}
                  value={region}
                  control={<Radio size="small" />}
                  label={`Mailgun ${region}`}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </>
      )}

      {formik.values.provider === 'AMAZON_SES' && (
        <>
          <TextField
            type="text"
            name="az_api_key"
            value={formik.values.az_api_key}
            label="API Key"
            disabled={formik.isSubmitting}
            sx={{ width: '60%', mb: 2 }}
            onChange={formik.handleChange}
            error={formik.touched.az_api_key && Boolean(formik.errors.az_api_key)}
            helperText={
              formik.touched.az_api_key
                ? (formik.errors.az_api_key as string)
                : 'Your Amazon SES API Key'
            }
            autoComplete="off"
            required
          />
          <TextField
            type="password"
            name="secret"
            value={formik.values.secret}
            label="Secret Access Key"
            disabled={formik.isSubmitting}
            sx={{ width: '60%', mb: 2 }}
            onChange={formik.handleChange}
            error={formik.touched.secret && Boolean(formik.errors.secret)}
            helperText={
              formik.touched.secret ? (formik.errors.secret as string) : 'Your Amazon Secret Key'
            }
            autoComplete="off"
            required
          />
          <FormControl component="fieldset" fullWidth>
            <Typography variant="body2">Region*</Typography>
            <Select
              name="az_region"
              value={formik.values.az_region}
              disabled={formik.isSubmitting}
              sx={{ width: '60%', mb: 2 }}
              onChange={formik.handleChange}
              error={formik.touched.az_region && Boolean(formik.errors.az_region)}
            >
              {AMAZON_SES_SUPPORTED_REGIONS.map(region => (
                <MenuItem key={region} value={region}>
                  {region}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}

      {formik.values.provider === 'CUSTOM_SMTP' && (
        <>
          <TextField
            type="text"
            name="host"
            value={formik.values.host}
            label="Host"
            disabled={formik.isSubmitting}
            sx={{ width: '60%', mb: 2 }}
            onChange={formik.handleChange}
            error={formik.touched.host && Boolean(formik.errors.host)}
            helperText={
              formik.touched.host
                ? (formik.errors.host as string)
                : 'Hostname or IP address of your SMTP server'
            }
            autoComplete="off"
            required
          />
          <TextField
            type="text"
            name="port"
            value={formik.values.port}
            label="Port"
            disabled={formik.isSubmitting}
            sx={{ width: '60%', mb: 2 }}
            onChange={formik.handleChange}
            error={formik.touched.port && Boolean(formik.errors.port)}
            autoComplete="off"
            helperText={
              formik.touched.password
                ? (formik.errors.password as string)
                : 'Port used by your SMTP server. Common ports include 25, 465, and 587. Please avoid using port 25 if you can, since many providers have limitations on this port'
            }
            required
          />
          <TextField
            type="text"
            name="username"
            value={formik.values.username}
            label="Username"
            disabled={formik.isSubmitting}
            sx={{ width: '60%', mb: 2 }}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            autoComplete="off"
            required
          />
          <TextField
            type="password"
            name="password"
            value={formik.values.password}
            label="Password"
            disabled={formik.isSubmitting}
            sx={{ width: '60%', mb: 2 }}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            autoComplete="off"
            required
          />
        </>
      )}

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'left',
          alignItems: 'center',
          gap: 2,
          mt: 2,
        }}
      >
        <Button
          variant="outlined"
          type="button"
          onClick={() => onCancel()}
          sx={{ minWidth: '50px' }}
        >
          Cancel
        </Button>

        <Button
          variant="outlined"
          type="button"
          onClick={onSendEmailClick}
          sx={{ minWidth: '50px' }}
        >
          Send Test Email
        </Button>

        <Button
          variant="outlined"
          type="button"
          onClick={onDeleteProviderClick}
          sx={{ minWidth: '50px' }}
        >
          Delete
        </Button>

        <LoadingButton
          type="submit"
          loading={formik.isSubmitting}
          disabled={
            formik.isSubmitting ||
            formik.values.provider === '' ||
            !formik.dirty ||
            Object.keys(formik.errors).length !== 0
          }
          variant="contained"
          color="primary"
          sx={{ minWidth: '50px' }}
        >
          Save
        </LoadingButton>
      </Box>
    </form>
  );
};

const MarketingCampaignsOverviewPage = () => {
  const navigate = useNavigate();

  const mainPath = useMemo(() => {
    const route = find(MAIN_ROUTES, route => route.name === ROUTE_NAME.SETTINGS) as AppRoute;
    return generatePath(route.path as string);
  }, []);

  const cancelToRoute = () => {
    navigate(mainPath);
  };

  return (
    <Box sx={{ flexDirection: 'column', mt: 1 }}>
      <PageContentPaper headerSubtitle="Select Email Provider">
        <CreateCampaignForm onCancel={cancelToRoute} />
      </PageContentPaper>
    </Box>
  );
};

export default MarketingCampaignsOverviewPage;
