import Box from '@mui/material/Box';
import ItemContentTabs from '@/items/content/Tabs';
import { useMemo } from 'react';
import { extractUncommonAttributes, filterCollectionPreviewAttributes } from '@/utils/helpers';
import { META_ATTRIBUTE } from '@/utils/constants';
import OverviewContainer from '@/common/container/OverviewContainer';
import { useCollection } from '@/utils/hooks/collection';
import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import { Alert, AlertTitle } from '@mui/material';
import { CollectionOutputRevealStrategyEnum } from '@/api-client';
import { get } from 'lodash';
import OverviewAttributes from '@/items/OverviewAttributes';

export interface RevealStrategyOverviewProps {
  collectionId?: string;
}

export const REVEAL_OVERVIEW_COLUMN_DIVISIONS = ['350px', '565px'];

export const RevealStrategyOverviewPage = ({ collectionId }: RevealStrategyOverviewProps) => {
  const {
    data: collection,
    isPending: collectionLoading,
    isFetched: collectionFetched,
  } = useCollection(collectionId ?? '');

  // collection preview include 'id' and 'media' - remove
  const [collectionAttributes, uncommonAttributes] = useMemo(() => {
    const filtered_attributes = filterCollectionPreviewAttributes(
      collection?.preview_metadata ?? {}
    );
    return [filtered_attributes, extractUncommonAttributes(filtered_attributes)];
  }, [collection?.preview_metadata]);

  const metaAnimationUrl = useMemo(() => {
    return uncommonAttributes?.meta[META_ATTRIBUTE.META_ANIMATION_URL] ?? '';
  }, [uncommonAttributes]);

  if (!collection) {
    return <></>;
  }

  if (collection.reveal_strategy === CollectionOutputRevealStrategyEnum.Instant) {
    return (
      <OverviewContainer
        isLoading={collectionLoading}
        gridColumnDivisions={REVEAL_OVERVIEW_COLUMN_DIVISIONS}
        gridRowDivisions={1}
        notFound={true}
        isPaper={false}
      >
        <BasicInfoDisplay title="" sx={{ gridColumnStart: 'span 2' }}>
          <Alert severity="warning" color="warning">
            <AlertTitle>Collection does not use delayed reveal strategy</AlertTitle>
            Minted items are always displayed showing actual artwork and metadata.
          </Alert>
        </BasicInfoDisplay>
      </OverviewContainer>
    );
  }

  return (
    <>
      {!collection?.reveal_hidden && (
        <BasicInfoDisplay title="" sx={{ gridColumnStart: 'span 2' }}>
          <Alert color="info">
            <AlertTitle>Collection revealed</AlertTitle>
            Minted items are showing actual artwork and metadata.
          </Alert>
        </BasicInfoDisplay>
      )}

          <OverviewContainer
            isLoading={collectionLoading}
            gridColumnDivisions={REVEAL_OVERVIEW_COLUMN_DIVISIONS}
            gridRowDivisions={1}
            notFound={collectionFetched && !collection}
            isPaper={false}
            fullHeight={false}
          >
            <Box sx={{ height: '75%', maxHeight: '350px' }}>
              <ItemContentTabs
                imageUrl={get(collection, 'preview_metadata.media.image.full', '')}
                animationUrl={metaAnimationUrl}
              />
            </Box>

            <OverviewContainer
              isLoading={collectionLoading}
              gridColumnDivisions={2}
              gridRowDivisions={1}
              notFound={collectionFetched && !collection}
              isPaper={false}
            >
              <BasicInfoDisplay title="Title" sx={{ fontSize: '1.5em' }}>
                {get(collection, 'preview_metadata.title')}
              </BasicInfoDisplay>

              <BasicInfoDisplay title="Description" sx={{ fontSize: '1.5em' }}>
                {get(collection, 'preview_metadata.description', 'N/A')}
              </BasicInfoDisplay>
            </OverviewContainer>
          </OverviewContainer>
          <OverviewAttributes attributes={collectionAttributes} isLoading={collectionLoading} />
    </>
  );
};

export default RevealStrategyOverviewPage;
