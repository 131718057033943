import { TransactionOutputStateEnum, TransactionsPaginatedOutputResultsInner } from '@/api-client';
import { PublicNetworksPaginatedOutputResultsInner } from '@/api-client-public';
import CollectionDetailsOverview, {
  COLLECTION_DETAILS_OVERVIEW_POPOVER_SIZE,
} from '@/collections/DetailsOverview';
import PopoverButton from '@/common/button/Popover';
import { BlockExplorerFilter, BlockExplorerFilterType } from '@/common/data/BlockExplorerFilter';
import NetworkName from '@/common/data/NetworkName';
import PlatformEntityIdentifier from '@/common/data/PlatformEntityIdentifier';
import PrettyDate from '@/common/data/PrettyDate';
import Status from '@/common/data/Status';
import ServerPaginatedDataGrid, {
  DEFAULT_PAGINATED_GRID_PAGE,
  PaginatedGridPage,
} from '@/common/data/grid/ServerPaginated';
import { BaseRow } from '@/common/data/grid/constants';
import ItemDetailsOverview, { ITEM_DETAILS_OVERVIEW_POPOVER_SIZE } from '@/items/DetailsOverview';
import { ROUTE_NAME } from '@/utils/constants';
import { successStatus } from '@/utils/helpers';
import { useAllPublicNetworks } from '@/utils/hooks/publicNetwork';
import { useTransactions } from '@/utils/hooks/transaction';
import { useSortAndFilterGrid } from '@/utils/hooks/useSortAndFilterGrid';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { formatEther } from 'ethers';
import find from 'lodash/find';
import { useCallback, useEffect, useState } from 'react';
import { proxy, useSnapshot } from 'valtio';
import TransactionCancellationAlert from './TransactionCancellationAlert';
import { getColOrItemHierarchyRoutePath } from '@/utils/route';

export enum TransactionGridColumnField {
  ID = 'id',
  TYPE = 'txType',
  SIGNER = 'signer',
  SIGNER_NONCE = 'nonce',
  TO = 'to',
  TX_HASH = 'txHash',
  CREATED_AT = 'createdAt',
  STATUS = 'state',
  ON_CHAIN_STATUS = 'onChainStatus',
  COLLECTION_ID = 'collectionId',
  ITEM_ID = 'itemId',
  FAILURE_COUNT = 'failureCount',
  NETWORK_ID = 'networkId',
  NETWORK_NAME = 'networkName',
  NETWORK_TESTNET = 'networkTestnet',
  BLOCK_NUMBER = 'blockNumber',
  TX_FEE = 'txFee',
  GAS_PRICE = 'gasPrice',
  GAS_USED = 'gasUsed',
  ON_CHAIN_TIMESTAMP = 'onChainTimestamp',
  ERROR_MESSAGE = 'errorMessage',
}

type TransactionGridRow = { [key in TransactionGridColumnField]: any } & BaseRow;

// Columns specific to this grid.
const columns: Array<GridColDef<TransactionGridRow>> = [
  {
    field: TransactionGridColumnField.CREATED_AT,
    headerName: 'Created At',
    width: 165,
    sortable: true,
    renderCell: (params: { row: TransactionGridRow }) => {
      return params.row.createdAt ? <PrettyDate date={params.row.createdAt} /> : <></>;
    },
  },
  {
    field: TransactionGridColumnField.ID,
    headerName: 'ID',
    width: 125,
    filterable: true,
    renderCell: (params: { row: TransactionGridRow }) =>
      params.row.id ? (
        <PlatformEntityIdentifier tooltipContext="this transaction ID" entityId={params.row.id} />
      ) : (
        <></>
      ),
  },
  {
    field: TransactionGridColumnField.TYPE,
    headerName: 'Type',
    minWidth: 250,
    filterable: true,
  },
  {
    field: TransactionGridColumnField.STATUS,
    headerName: 'Status',
    width: 125,
    filterable: true,
    renderCell: (params: { row: TransactionGridRow }) =>
      params.row.state == TransactionOutputStateEnum.Cancelled && params.row.errorMessage ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PopoverButton
            buttonIcon={<InfoIcon color="error" />}
            popoverContainerSx={{ p: 0 }}
            popoverContent={<TransactionCancellationAlert errorMessage={params.row.errorMessage} />}
          />
          <Status status={params.row.state} />
        </Box>
      ) : (
        <Status status={params.row.state} />
      ),
  },
  {
    field: TransactionGridColumnField.ON_CHAIN_STATUS,
    headerName: 'OnChain Status',
    filterable: false,
    width: 125,
    renderCell: (params: { row: TransactionGridRow }) => (
      <Status status={params.row.onChainStatus} />
    ),
  },
  {
    field: TransactionGridColumnField.FAILURE_COUNT,
    headerName: 'Failures',
    minWidth: 50,
    renderCell: (params: { row: TransactionGridRow }) =>
      params.row.failureCount ? (
        <Typography
          variant="inherit"
          color={successStatus.indexOf(params.row.state) >= 0 ? 'warning.main' : 'error.main'}
        >
          {params.row.failureCount}
        </Typography>
      ) : (
        <></>
      ),
  },
  {
    field: TransactionGridColumnField.NETWORK_NAME,
    headerName: 'Network',
    width: 215,
    filterable: false,
    renderCell: (params: { row: TransactionGridRow }) =>
      params.row.networkName ? (
        <NetworkName name={params.row.networkName} testnet={params.row.networkTestnet} />
      ) : (
        <></>
      ),
  },
  {
    field: TransactionGridColumnField.NETWORK_ID,
    headerName: 'Network ID',
    minWidth: 100,
    filterable: true,
  },
  {
    field: TransactionGridColumnField.COLLECTION_ID,
    headerName: 'Collection ID',
    width: 150,
    filterable: true,
    renderCell: (params: { row: TransactionGridRow }) =>
      params.row.collectionId ? (
        <PlatformEntityIdentifier
          tooltipContext="this collection ID"
          entityId={params.row.collectionId}
          popoverPreviewContent={
            <CollectionDetailsOverview explicitCollectionId={params.row.collectionId} />
          }
          popoverPreviewSx={{ ...COLLECTION_DETAILS_OVERVIEW_POPOVER_SIZE }}
        />
      ) : (
        <></>
      ),
  },
  {
    field: TransactionGridColumnField.ITEM_ID,
    headerName: 'Item ID',
    width: 150,
    filterable: true,
    renderCell: (params: { row: TransactionGridRow }) =>
      params.row.itemId && params.row.collectionId ? (
        <PlatformEntityIdentifier
          tooltipContext="this item ID"
          entityId={params.row.itemId}
          popoverPreviewContent={<ItemDetailsOverview explicitItemId={params.row.itemId} />}
          popoverPreviewSx={{ ...ITEM_DETAILS_OVERVIEW_POPOVER_SIZE }}
        />
      ) : (
        <></>
      ),
  },
  {
    field: TransactionGridColumnField.BLOCK_NUMBER,
    headerName: 'Block',
    minWidth: 75,
    filterable: false,
  },
  {
    field: TransactionGridColumnField.GAS_PRICE,
    headerName: 'Gas price',
    minWidth: 150,
    filterable: false,
  },
  {
    field: TransactionGridColumnField.TX_FEE,
    headerName: 'Tx fee',
    width: 100,
    filterable: false,
  },
  {
    field: TransactionGridColumnField.GAS_USED,
    headerName: 'Gas used',
    minWidth: 150,
    filterable: false,
  },
  {
    field: TransactionGridColumnField.ON_CHAIN_TIMESTAMP,
    headerName: 'OnChain Timestamp',
    width: 165,
    filterable: false,
    renderCell: (params: { row: TransactionGridRow }) => {
      return params.row.createdAt ? <PrettyDate date={params.row.createdAt} /> : <></>;
    },
  },
  {
    field: TransactionGridColumnField.TX_HASH,
    headerName: 'Tx Hash',
    width: 125,
    filterable: false,
    renderCell: (params: { row: TransactionGridRow }) =>
      params.row.txHash && params.row.networkId ? (
        <BlockExplorerFilter
          filterType={BlockExplorerFilterType.TX_HASH}
          filterValue={params.row.txHash}
          charLengthOnEitherSide={3}
          networkId={Number(params.row.networkId)}
        />
      ) : (
        <></>
      ),
  },
  {
    field: TransactionGridColumnField.SIGNER,
    headerName: 'Signer',
    width: 125,
    filterable: true,
    renderCell: (params: { row: TransactionGridRow }) =>
      params.row.signer ? (
        <BlockExplorerFilter
          filterType={BlockExplorerFilterType.ADDRESS}
          filterValue={params.row.signer}
          charLengthOnEitherSide={3}
          networkId={Number(params.row.networkId)}
        />
      ) : (
        <></>
      ),
  },
  {
    field: TransactionGridColumnField.TO,
    headerName: 'To',
    width: 150,
    renderCell: (params: { row: TransactionGridRow }) =>
      params.row.to ? (
        <BlockExplorerFilter
          filterType={BlockExplorerFilterType.ADDRESS}
          filterValue={params.row.to}
          networkId={Number(params.row.networkId)}
        />
      ) : (
        <></>
      ),
  },
];

export const generateDefaultColumnVisibilityModel = () => {
  return {
    [TransactionGridColumnField.ID]: false,
    [TransactionGridColumnField.NETWORK_ID]: false,
    [TransactionGridColumnField.ON_CHAIN_STATUS]: false,
    [TransactionGridColumnField.BLOCK_NUMBER]: false,
    [TransactionGridColumnField.GAS_USED]: false,
    [TransactionGridColumnField.GAS_PRICE]: false,
    [TransactionGridColumnField.ON_CHAIN_TIMESTAMP]: false,
    [TransactionGridColumnField.TX_HASH]: false,
    [TransactionGridColumnField.TO]: false,
    [TransactionGridColumnField.SIGNER]: false,
  };
};

// Initial state specific to this grid.
const getInitialGridState = (
  customTransactionColumnVisibility?: GridColumnVisibilityModel
): GridInitialStateCommunity => {
  return {
    columns: {
      columnVisibilityModel:
        customTransactionColumnVisibility ?? generateDefaultColumnVisibilityModel(),
    },
  };
};

// Will map API data (in this case a mix of multiple sources) to rows.
const mapToRow = (
  transaction: TransactionsPaginatedOutputResultsInner,
  network?: PublicNetworksPaginatedOutputResultsInner | null
): TransactionGridRow => {
  const txData = (transaction.data as any) ?? {};
  return {
    id: transaction.id,
    failureCount: transaction.failure_count,
    networkId: transaction.network_id,
    networkName: network?.name,
    networkTestnet: network?.testnet,
    nonce: transaction.nonce,
    txType: transaction.tx_type,
    txHash: transaction.tx_hash,
    state: transaction.state,
    onChainStatus: transaction.on_chain_status,
    signer: transaction.signer,
    to: txData['to_address'],
    createdAt: transaction.created_at,
    itemId: txData['item_id'],
    collectionId: transaction?.collection_id,
    blockNumber: transaction.mined_block_number,
    gasPrice: transaction.effective_gas_price ? formatEther(transaction.effective_gas_price) : '',
    txFee: transaction.tx_fee ? formatEther(transaction.tx_fee) : '',
    gasUsed: transaction.gas_used ? formatEther(transaction.gas_used) : '',
    onChainTimestamp: transaction.on_chain_timestamp,
    original: { transaction, network },
    errorMessage: transaction.error_message,
  };
};

const pageState: PaginatedGridPage<TransactionGridRow> = proxy({
  ...DEFAULT_PAGINATED_GRID_PAGE,
  sortBy: { field: TransactionGridColumnField.ON_CHAIN_TIMESTAMP, direction: 'DESC' },
});

export interface TransactionsGridProps {
  collectionId?: string;
  itemId?: string;
  customVisibility?: GridColumnVisibilityModel;
}

const TransactionsGrid = ({ collectionId, itemId, customVisibility }: TransactionsGridProps) => {
  // Get readonly snaps from the state.
  const pageSnap = useSnapshot(pageState);

  const [initialState] = useState<GridInitialStateCommunity>(getInitialGridState(customVisibility));

  // Construct sort and filter args based on the active page.
  const { sortBy, filterBy } = useSortAndFilterGrid(pageState);

  // Get all transactions for the current page.
  const { data: transactionResponse, isPending: transactionsLoading } = useTransactions(
    pageSnap.pageSize,
    pageSnap.cursor,
    sortBy,
    {
      ...filterBy,
      collectionId: collectionId ? collectionId : (filterBy as any)['collectionId'],
      itemId: itemId ? itemId : (filterBy as any)['itemId'],
    }
  );

  // Get all networks.
  const { data: networks, isPending: networksLoading } = useAllPublicNetworks();

  // Populate rows when any API data changes.
  useEffect(() => {
    pageState.rows =
      transactionResponse?.results.map(transaction => {
        const network =
          transaction.network_id && networks
            ? find(networks, network => network.id === transaction.network_id)
            : null;
        return mapToRow(transaction, network);
      }) ?? [];

    pageState.nextCursor = transactionResponse?.cursor ?? DEFAULT_PAGINATED_GRID_PAGE.cursor;
  }, [transactionResponse?.results, transactionResponse?.cursor, networks]);

  const getRowDoubleClickUrl = useCallback(
    (row: TransactionGridRow) => {
      return (
        getColOrItemHierarchyRoutePath({
          id: row.id,
          idPropName: 'transactionId',
          detailsRouteName: ROUTE_NAME.TRANSACTION_DETAILS,
          collectionId,
          collectionDetailsRouteName: ROUTE_NAME.COLLECTION_TRANSACTION_DETAILS,
          itemId,
          itemDetailsRouteName: ROUTE_NAME.ITEM_TRANSACTION_DETAILS,
        }) || ''
      );
    },
    [collectionId, itemId]
  );

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateRows: '1fr',
        rowGap: 2,
      }}
    >
      <ServerPaginatedDataGrid
        columns={columns}
        initialState={initialState}
        // eslint-disable-next-line valtio/state-snapshot-rule
        activePageProxy={pageState}
        pageLoading={transactionsLoading || networksLoading}
        totalRowCount={transactionResponse?.total_results ?? 0}
        getRowDoubleClickUrl={getRowDoubleClickUrl}
      />
    </Box>
  );
};

export default TransactionsGrid;
