import { useLocation } from 'react-router';
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import ClickToNav from './ClickToNav';
import { Box } from '@mui/material';
import { customPalette } from '@/theme/custom';

// add your default no data routes hints below
const HINTS: { [key: string]: JSX.Element } = {
    'email-campaigns-no-provider': (
        <>
            Before you can create and send email templates you will need to specify an email provider. &nbsp;
            <Link to="/settings?tab=email-providers">Click here</Link> to provide the details for your email provider.
            <ClickToNav key='email-provider-list' sx={{ mr: 1, width: 'auto' }} url={'https://docs.phosphor.xyz/platform-features/digital-asset-distribution/email-claims/use-your-own-email-provider'} mimicButton={false}>
            <Typography variant="button">See list of supported email providers</Typography>
          </ClickToNav>
        </>
    ),
    'email-campaigns': (
        <>
            You do not have any existing Email Templates for your organization. &nbsp;
            Click <b>CREATE NEW CAMPAIGN</b> button to create your first campaign.
        </>
    ),
    'settings?tab=api-keys': (
      <>
      This account has no API Keys yet. Click <b>GENERATE API KEY</b> button to create your first API key.
      </>
    ),
    'users?tab=invited': (
      <>
      This account has no active invites.
      Click <b>INVITE NEW USER</b> button to invite a new user.
      </>
    ),
    default: <>No results found</>,
};

interface NoDataContextHintProps {
    overrideKey?: string;
}

/**
 * The base component for indicating that there is no data in a section or page.
 * By default, this will display contextual info based on the route being used.
 * Otherwise, may pass a specific hint key to display.
 * @param key
 */
const NoDataContextHint = ({overrideKey = ''} : NoDataContextHintProps) => {
    const location = useLocation();
    const [isPageReady, setIsPageReady] = useState(false);
    const text = useRef(HINTS['default']);

    useEffect(() => {
        const routeName = location.pathname.replace('/', '');
        const routeSearch = location.search;
        if (routeName+routeSearch in HINTS) {
          text.current = HINTS[routeName+routeSearch];
        } else if (routeName in HINTS ) {
            text.current = HINTS[routeName];
        }
        if (overrideKey && overrideKey in HINTS) text.current = HINTS[overrideKey];
        setIsPageReady(true);
      }, [overrideKey, location]);
    
    if (!isPageReady) return;
    
    return (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'auto',
          zIndex: 6,
          background: customPalette.colors.table.odd,
        }}>
          <Typography
              sx={{
                  color: customPalette.colors.primaryText,
                  fontSize: '14px',
                  zIndex: 5,
                  mt: 2,
                  textAlign: 'center',
              }}
              >
              {text.current}
          </Typography>
        </Box>
    );
};

export default NoDataContextHint;
