import OverviewContainer from '@/common/container/OverviewContainer';
import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import ClipboardContent from '@/common/data/ClipboardContent';
import PrettyDate from '@/common/data/PrettyDate';
import { shortenUuid4 } from '@/utils/helpers';
import { useEmailTemplates } from '@/utils/hooks/emailTemplate';
import {
  useCampaign,
  useDeleteMarketingCampaign,
  useEditMarketingCampaign,
} from '@/utils/hooks/marketingCampaigns';
import { useOrganizationProfile } from '@/utils/hooks/organization';
import { authState } from '@/utils/states/authState';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Chip, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  email_from: yup.string().optional(),
});

const CAMPAIGN_OVERVIEW_ROW_DIVISIONS = 2;
const CAMPAIGN_OVERVIEW_COLUMN_DIVISIONS = 2;
export const CAMPAIGN_OVERVIEW_POPOVER_SIZE = { width: '900px', height: '300px' };

export interface MarketingCampaignDetailsOverviewProps {
  explicitCampaignId?: string;
}

const MarketingCampaignDetailsOverview = ({
  explicitCampaignId,
}: MarketingCampaignDetailsOverviewProps) => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const campaignIdToUse = explicitCampaignId ?? campaignId ?? '';

  const {
    data: campaign,
    isPending: campaignLoading,
    isFetched: campaignFetched,
  } = useCampaign(campaignIdToUse);

  const { data: customizedTemplates } = useEmailTemplates(campaign?.id);
  const customizedTemplateTypes = customizedTemplates?.results.map(
    template => template.template_type
  );

  // Extract default email_from from org name
  const { platformOrg } = useSnapshot(authState);
  const { data: orgProfile } = useOrganizationProfile();

  const defaultEmailFrom = useMemo(() => {
    return campaign?.email_from ?? orgProfile?.public_name ?? `${platformOrg?.id} at Phosphor`;
  }, [campaign?.email_from, orgProfile?.public_name, platformOrg?.id]);

  const { mutate: deleteCampaign, isPending: deletingCampaign } = useDeleteMarketingCampaign();
  const { mutate: updateCampaign } = useEditMarketingCampaign();

  const formik = useFormik({
    initialValues: { email_from: '' },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (!(campaignIdToUse && values.email_from)) return;

      updateCampaign({ campaignId: campaignIdToUse, body: { email_from: values.email_from } });
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (defaultEmailFrom) formik.setFieldValue('email_from', defaultEmailFrom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultEmailFrom]);

  const onDeleteClick = useCallback(() => {
    if (!campaignIdToUse) return;

    const canContinue = confirm(
      'Are you sure you want to completely delete this campaign? You will lose all of your custom changes.'
    );
    if (canContinue) deleteCampaign({ campaignId: campaignIdToUse });
    navigate('/email-campaigns');
  }, [campaignIdToUse, deleteCampaign, navigate]);

  return (
    <Box>
      <OverviewContainer
        isLoading={campaignLoading}
        gridColumnDivisions={CAMPAIGN_OVERVIEW_COLUMN_DIVISIONS}
        gridRowDivisions={CAMPAIGN_OVERVIEW_ROW_DIVISIONS}
        notFound={!campaign && campaignFetched}
        fullHeight={false}
      >
        <BasicInfoDisplay title="Campaign Name">{campaign?.name}</BasicInfoDisplay>
        <BasicInfoDisplay title="Campaign ID">
          <ClipboardContent tooltipContext="campaign ID" clipboardValue={campaign?.id}>
            {shortenUuid4(campaign?.id)}
          </ClipboardContent>
        </BasicInfoDisplay>

        {campaign?.created_at && (
          <BasicInfoDisplay title="Created On">
            <PrettyDate date={campaign?.created_at} />
          </BasicInfoDisplay>
        )}
        <Box sx={{ gridColumn: '1/4', display: 'flex', alignItems: 'baseline' }}>
          <BasicInfoDisplay title="Customized Email Templates">
            {customizedTemplateTypes?.length
              ? customizedTemplateTypes.map((template, index) => (
                  <Chip sx={{ mr: 1 }} label={template} key={index} />
                ))
              : 'None'}
          </BasicInfoDisplay>
        </Box>
      </OverviewContainer>

      <form onSubmit={formik.handleSubmit}>
        <TextField
          type="text"
          name="email_from"
          value={formik.values.email_from}
          label="Email From"
          disabled={formik.isSubmitting}
          sx={{ width: '60%', mb: 2, mt: 2 }}
          onChange={formik.handleChange}
          error={formik.touched.email_from && Boolean(formik.errors.email_from)}
          helperText={
            formik.touched.email_from
              ? (formik.errors.email_from as string)
              : 'Enter a custom email from name or leave it blank for default'
          }
          placeholder={defaultEmailFrom}
          autoComplete="off"
        />
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'left',
            alignItems: 'center',
            gap: 2,
            mt: 2,
          }}
        >
          <Button
            variant="outlined"
            type="button"
            onClick={() => navigate('/email-campaigns')}
            sx={{ minWidth: '50px' }}
          >
            Cancel
          </Button>

          <LoadingButton
            variant="outlined"
            type="button"
            loading={deletingCampaign}
            disabled={deletingCampaign || formik.isSubmitting}
            onClick={onDeleteClick}
            sx={{ minWidth: '100px' }}
          >
            Delete
          </LoadingButton>

          <LoadingButton
            type="submit"
            loading={formik.isSubmitting}
            disabled={
              formik.isSubmitting ||
              deletingCampaign ||
              defaultEmailFrom === formik.values.email_from ||
              formik.values.email_from === '' ||
              !!formik.errors.email_from
            }
            variant="contained"
            color="primary"
            sx={{ minWidth: '50px' }}
          >
            Save
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

export default MarketingCampaignDetailsOverview;
