import Box from '@mui/material/Box';
import AppLogo from '@/common/data/AppLogo';
import { customPalette } from '@/theme/custom';
import { LinearProgress, Typography } from '@mui/material';

/**
 * A page that is displayed when we are loading/initializing the app.
 */
export const LoadingPage = () => {
  return (
    <Box sx={{ width: '100vw', height: '100vh', p: 0, border: 0 }}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          background: customPalette.gradients,
          rowGap: 3,
        }}
      >
        <AppLogo fillColor="black" textWidth="350px"></AppLogo>

        <LinearProgress
          sx={{
            width: '225px',
            background: 'rgba(0,0,0,0.65)',
            '.MuiLinearProgress-bar ': { background: 'white' },
          }}
        />

        <Typography variant="body1" color="black" textAlign="center" sx={{ fontWeight: '200' }}>
          Loading...
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingPage;
