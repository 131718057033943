import SimpleSubmitForm from '@/common/data/SimpleSubmitForm';
import { useDisconnectStripe } from '@/utils/hooks/stripePaymentIntegration';

const DisconnectStripeForm = ({ onSuccess, onCancel }: any) => {
  const { mutate } = useDisconnectStripe();

  return (
    <SimpleSubmitForm
      onCancel={onCancel}
      onSuccess={onSuccess}
      mutate={mutate}
      id={null}
      confirmationMessage="Disconnecting will prevent you from selling in fiat currencies. Are you sure?"
      buttonTitle='Disconnect'
    />
  );
};

export default DisconnectStripeForm;
