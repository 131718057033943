import { useInfiniteQuery } from '@tanstack/react-query';
import { AxiosPromise } from 'axios';
import { useEffect, useState } from 'react';

interface UseAllPagesOutput<TOutput> {
  isPending: boolean;
  data: TOutput[];
}

interface OutputResultsResponse<TOutput> {
  results?: TOutput[];
  has_more?: boolean;
  cursor?: string | null;
}

function useAllPages<TOutput, TOutputResponse extends OutputResultsResponse<TOutput>>(
  queryKey: string | any[],
  cursorGetFn: (cursor: string | undefined) => AxiosPromise<TOutputResponse>,
  queryOptions?: object
): UseAllPagesOutput<TOutput> {
  const coercedoptions = queryOptions ?? {};
  const [allData, setAllData] = useState<TOutput[]>([]);
  const [doneFetchingAll, setDoneFetchingAll] = useState<boolean>(false);
  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isFetching, isPending } =
    useInfiniteQuery({
      queryKey: typeof queryKey === 'string' ? [queryKey] : queryKey,
      initialPageParam: undefined,
      queryFn: ({ pageParam }) => cursorGetFn(pageParam).then(res => res.data),
      getNextPageParam: (lastPage: any) => {
        const castedLastPage = lastPage as TOutputResponse;
        return castedLastPage.has_more ? castedLastPage.cursor : undefined;
      },
      ...coercedoptions,
    });

  useEffect(() => {
    if (hasNextPage && data) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, data]); // Must include data; otherwise it won't fetch recursively for some reason.

  useEffect(() => {
    if (!hasNextPage && data?.pages) {
      setAllData(data?.pages.flatMap(page => page.results ?? []));
      setDoneFetchingAll(true);
    }
  }, [data, hasNextPage]);

  return {
    isPending: !doneFetchingAll || isFetchingNextPage || isFetching || isPending,
    data: allData,
  };
}

export default useAllPages;
