import { uuidv4 } from '@/utils/helpers';
import { Alert, Box, CircularProgress, Paper, Skeleton, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import NoDataOverlay from '@/common/data/NoDataOverlay';

export interface OverviewContainerProps extends PropsWithChildren {
  isLoading: boolean;
  loadingAlertText?: string;
  gridColumnDivisions: string[] | number;
  gridRowDivisions: string[] | number;
  notFound?: boolean;
  rowGap?: number;
  columnGap?: number;
  isPaper?: boolean;
  fullHeight?: boolean;
}

/**
 * The basic layout for a DetailsOverview component.
 * Will show loading indicators, or a "Not Found" message if the data is not found.
 * Based on the row and column divisions, it will coerce to fill the remaining space.
 * @param isLoading Whether or not the data is loading.
 * @param loadingAlertText The alert to show above the loading skeleton if the data is loading. (optional)
 * @param gridColumnDivisions The number of columns to divide the grid into.
 * @param gridRowDivisions The number of rows to divide the grid into.
 * @param notFound Whether or not the data was found.
 * @param rowGap The gap between rows.
 * @param columnGap The gap between columns.
 * @param children The children to render.
 * @param isPaper Whether or not to render the children in a Paper component. Defaults to @true
 * @param fullHeight Whether or not to render the children in a Paper component. Defaults to @true
 */
export const OverviewContainer = ({
  isLoading,
  loadingAlertText,
  gridColumnDivisions,
  gridRowDivisions,
  children,
  notFound,
  rowGap,
  columnGap,
  isPaper = true,
  fullHeight = true,
}: OverviewContainerProps) => {
  if (isLoading) {
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'grid',
          rowGap: '16px',
          gridTemplateRows: loadingAlertText ? 'auto 1fr' : '1fr',
        }}
      >
        {loadingAlertText && (
          <Alert
            severity="info"
            icon={false}
            sx={{ '.MuiAlert-message': { display: 'flex', columnGap: 1 } }}
          >
            <CircularProgress size={20} sx={{ color: 'primary.main' }} />
            <Typography variant="inherit">{loadingAlertText}</Typography>
          </Alert>
        )}

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns:
              typeof gridColumnDivisions === 'number'
                ? `repeat(${gridColumnDivisions}, 1fr)`
                : gridColumnDivisions.join(' '),
            gridTemplateRows:
              typeof gridRowDivisions === 'number'
                ? `repeat(${gridRowDivisions}, 1fr)`
                : gridRowDivisions.join(' '),
            height: '100%',
            width: '100%',
            rowGap: 2,
            columnGap: 2,
            '.MuiSkeleton-root': {
              height: '100%',
              width: '100%',
            },
          }}
        >
          {typeof gridRowDivisions === 'number' &&
            typeof gridColumnDivisions === 'number' &&
            [...Array(gridRowDivisions).keys()].map(() =>
              [...Array(gridColumnDivisions).keys()].map(() => (
                <Skeleton key={uuidv4()} variant="rectangular" />
              ))
            )}

          {typeof gridRowDivisions === 'number' &&
            typeof gridColumnDivisions !== 'number' &&
            [...Array(gridRowDivisions).keys()].map(() =>
              [...Array(gridColumnDivisions.length).keys()].map(() => (
                <Skeleton variant="rectangular" key={uuidv4()} />
              ))
            )}

          {typeof gridRowDivisions !== 'number' &&
            typeof gridColumnDivisions === 'number' &&
            [...Array(gridRowDivisions.length).keys()].map(() =>
              [...Array(gridColumnDivisions).keys()].map(() => (
                <Skeleton variant="rectangular" key={uuidv4()} />
              ))
            )}
        </Box>
      </Box>
    );
  }

  if (notFound === false) {
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: fullHeight ? '100%' : 'auto',
        width: '100%',
        p: 2,
      }}
    >
      <NoDataOverlay text="Not Found" />
    </Box>;
  }

  return (
    <Box
      component={isPaper ? Paper : Box}
      sx={{
        display: { xs: 'flex', lg: 'grid' },
        flexDirection: 'column',
        gridTemplateColumns:
          typeof gridColumnDivisions === 'number'
            ? `repeat(${gridColumnDivisions}, auto)`
            : gridColumnDivisions.join(' '),
        gridTemplateRows:
          typeof gridRowDivisions === 'number'
            ? `repeat(${gridRowDivisions - 1}, auto) 1fr` // Take up the rest of the space
            : gridRowDivisions.join(' '),
        rowGap: rowGap ?? 3,
        columnGap: columnGap ?? 4,
        height: fullHeight ? '100%' : 'auto',
        overflowY: 'auto',
        '.MuiTypography-h6': {
          mb: -2,
        },
        p: 0,
        border: 'none',
        boxShadow: 'none'
      }}
    >
      {children}
    </Box>
  );
};

export default OverviewContainer;
