import { useAuth0 } from '@auth0/auth0-react';
import { auth0ClientId } from '@/config';
import AuthErrorPage from './AuthErrorPage';
import LoadingPage from '@/common/page/Loading';
import { useSearchParams } from 'react-router-dom';

export interface AuthContext {
  onLogin: () => void;
}

const AuthSignupPage = () => {
  const [searchParams] = useSearchParams();

  const { loginWithRedirect, isAuthenticated, error, logout: auth0Logout } = useAuth0();

  // force logout first
  if (isAuthenticated)
    auth0Logout({
      clientId: auth0ClientId,
      logoutParams: {
        returnTo: `${window.location.origin}/auth0/signup`,
      },
    });

  loginWithRedirect({
    authorizationParams: {
      prompt: 'login',
      screen_hint: 'signup',
    },
    ...{ signup_code: searchParams.get('code') ?? '' },
  });

  if (error) return <AuthErrorPage reason={error.message} />;
  else return <LoadingPage />;
};

export default AuthSignupPage;
