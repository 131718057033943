import { Typography, Card, CardContent, CardActions, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { authState } from '@/utils/states/authState';

const UserProfileCard = () => {
  const navigate = useNavigate();
  const { platformUser } = useSnapshot(authState);

  return (
    <Card
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        p: 3,
      }}
    >
      <CardContent sx={{ p: 0, width: '100%', mb: 2 }}>
        <Typography
          variant="h5"
          sx={{ overflowWrap: 'break-word', textAlign: 'center', fontSize: '18px' }}
        >
          {platformUser?.email}
        </Typography>
      </CardContent>

      <CardActions
        sx={{
          display: 'grid',
          width: '100%',
          p: 0,
          '.MuiButton-root': {
            width: '100%',
            ml: 0,
            '&:not(:last-child)': { mb: 1 },
          },
        }}
      >
        <Button
          onClick={() => navigate('/auth0/logout')}
          size="small"
          color="primary"
          variant="contained"
        >
          Logout
        </Button>
      </CardActions>
    </Card>
  );
};

export default UserProfileCard;
