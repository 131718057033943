import { ItemOutputTokenStatusEnum } from '@/api-client';
import PasswordValidator from 'password-validator';
import {
  DEFAULT_CONTRACT_VARIANT,
  DEPRECATED_CONTRACT_VARIANTS,
  HTTP_URL_REGEX,
  META_ATTRIBUTE,
  META_ATTRIBUTES,
  META_ATTRIBUTE_OUTPUT_STYLE,
  META_BACKGROUND_COLOR_REGEX,
  TOKEN_TYPE_721_MAX_SUPPLY,
} from './constants';

export const isValidEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const isValidPassword = (password: any) => {
  // Keep this logic in sync with backend validatioon
  // See modules/api/app/services/admin.py::is_valid_password
  // Also, make sure the message in passwordTest matches with this.
  const passwordPolicy = new PasswordValidator()
    .min(8)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .symbols();

  return passwordPolicy.validate(password) as boolean;
};

export const passwordTest = {
  name: 'valid-password',
  message:
    'Password must be at least 8 characters long and include at least 1 uppercase character, 1 lowercase character, 1 digit and 1 symbol.',
  test: isValidPassword,
};

export const canMintItem = (
  locked?: boolean,
  maxSupply?: string | null,
  tokenStatus?: string | null | ItemOutputTokenStatusEnum
): { canMint: boolean; reason: string } => {
  let errorReason = '';

  if (!locked) {
    errorReason = 'Item is not locked';
  } else if (!maxSupply) {
    errorReason = 'Item has no max supply';
  } else if (
    tokenStatus === ItemOutputTokenStatusEnum.Minted &&
    maxSupply === TOKEN_TYPE_721_MAX_SUPPLY
  ) {
    errorReason = 'Item has already been minted';
  }

  return { canMint: !errorReason, reason: errorReason };
};

export const canTransferItem = (
  locked?: boolean,
  maxSupply?: string | null,
  tokenStatus?: string | null | ItemOutputTokenStatusEnum
): { canTransfer: boolean; reason: string } => {
  let errorReason = '';

  if (!locked) {
    errorReason = 'Item is not locked';
  } else if (
    (tokenStatus !== ItemOutputTokenStatusEnum.Minted && maxSupply === TOKEN_TYPE_721_MAX_SUPPLY) ||
    !maxSupply ||
    maxSupply === '0'
  ) {
    errorReason = 'Item has not been minted yet.';
  }

  return { canTransfer: !errorReason, reason: errorReason };
};

export const canListItem = (
  locked?: boolean,
  tokenStatus?: string | null | ItemOutputTokenStatusEnum,
  listed?: boolean
): { canList: boolean; reason: string } => {
  let errorReason = '';

  if (!locked) {
    errorReason = 'Item is not locked';
  } else if (tokenStatus === ItemOutputTokenStatusEnum.Minted) {
    errorReason = 'Item has already been minted';
  } else if (listed) {
    errorReason = 'Item is already listed';
  }

  return { canList: !errorReason, reason: errorReason };
};

export const canDeleteItem = (
  locked?: boolean,
  tokenStatus?: ItemOutputTokenStatusEnum | null | string
) => {
  let errorReason = '';

  if (locked) {
    errorReason = 'Item is locked';
  } else if (tokenStatus === ItemOutputTokenStatusEnum.Minted) {
    errorReason = 'Item has already been minted';
  }

  return { canDelete: !errorReason, reason: errorReason };
};

export const validateMetaAttributeValue = (metaAttribute: string, value: string) => {
  if (!metaAttribute) return '';

  if (metaAttribute === META_ATTRIBUTE.META_ANIMATION_URL) {
    return !value || !HTTP_URL_REGEX.test(value)
      ? "Please enter a valid URL containing the HTTP or HTTPS protocol for the meta 'animation URL' attribute."
      : '';
  } else if (metaAttribute === META_ATTRIBUTE.META_BACKGROUND_COLOR) {
    return !value || !META_BACKGROUND_COLOR_REGEX.test(value)
      ? "Please enter a valid hex color code without the # for the meta 'background color' attribute."
      : '';
  } else if (metaAttribute === META_ATTRIBUTE.META_EXTERNAL_URL) {
    return !value || !HTTP_URL_REGEX.test(value)
      ? "Please enter a valid URL containing the HTTP or HTTPS protocol for the meta 'external URL' attribute."
      : '';
  } else if (metaAttribute === META_ATTRIBUTE.META_YOUTUBE_URL) {
    return !value || !HTTP_URL_REGEX.test(value)
      ? "Please enter a valid URL containing the HTTP or HTTPS protocol for the meta 'YouTube URL' attribute."
      : '';
  } else if (metaAttribute === META_ATTRIBUTE.META_METADATA_STYLE) {
    return !value ||
      (value !== META_ATTRIBUTE_OUTPUT_STYLE.ENJIN && value !== META_ATTRIBUTE_OUTPUT_STYLE.OPENSEA)
      ? `Please enter a valid value for the meta 'metadata style' attribute. Valid values are ${META_ATTRIBUTE_OUTPUT_STYLE.ENJIN} and ${META_ATTRIBUTE_OUTPUT_STYLE.OPENSEA}.`
      : '';
  }
};

export const isMetaAttribute = (key: string): boolean => {
  return META_ATTRIBUTES.indexOf(key) !== -1;
};

/**
 * Must be a non-empty string with no leading or trailing whitespace.
 */
export const isValidAttributeValue = (value: any) => {
  return (
    value !== null &&
    value !== undefined &&
    value !== '' &&
    (typeof value !== 'string' || value.trim().length > 0)
  );
};

export const isDeprecatedDefaultContractVariant = (variant: DEFAULT_CONTRACT_VARIANT): boolean => {
  return DEPRECATED_CONTRACT_VARIANTS.indexOf(variant) !== -1;
};
