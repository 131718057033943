import { Alert, AlertTitle } from '@mui/material';
import { useMemo } from 'react';

interface EditableMetadataProps {
  editableMetadata?: boolean;
}

export const EditableMetadataAlert = ({ editableMetadata }: EditableMetadataProps) => {
  const [alertState, alertText] = useMemo(() => {
    return editableMetadata
      ? ['Enabled', 'Item attributes can be updated even after items are locked or minted.']
      : ['Disabled', 'Item attributes cannot be changed as soon as the item is locked or minted.'];
  }, [editableMetadata]);

  return (
    <Alert severity="info">
      <AlertTitle>Editable Metadata ({alertState})</AlertTitle>
      {alertText}
    </Alert>
  );
};

export default EditableMetadataAlert;
