import { useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { authState } from '@/utils/states/authState';

import { useAllRoles } from '@/utils/hooks/role';
import { find } from 'lodash';
import OverviewContainer from '@/common/container/OverviewContainer';
import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import ClipboardContent from '@/common/data/ClipboardContent';
import { shortenUuid4 } from '@/utils/helpers';
import { useOrganizationProfile } from '@/utils/hooks/organization';

const UserProfileDetailsPage = () => {
  const { platformUser } = useSnapshot(authState);
  const { data: orgProfile } = useOrganizationProfile();
  const { data: roles, isPending: rolesLoading } = useAllRoles();

  const orgName = useMemo(() => {
    return orgProfile?.public_name ?? `${platformUser?.organization_id} at Phosphor`;
  }, [platformUser, orgProfile]);

  const role = useMemo(() => {
    return roles ? find(roles, role => role.id === platformUser?.role_id) : null;
  }, [platformUser?.role_id, roles]);

  return (
    <OverviewContainer
      isLoading={rolesLoading}
      gridColumnDivisions={5}
      columnGap={1}
      gridRowDivisions={1}
      notFound={!platformUser}
      fullHeight={false}
      isPaper={false}
    >
      <BasicInfoDisplay title="Email">{platformUser?.email}</BasicInfoDisplay>

      <BasicInfoDisplay title="Role Name">{role?.name}</BasicInfoDisplay>

      <BasicInfoDisplay title="User ID">
        <ClipboardContent tooltipContext="collection ID" clipboardValue={platformUser?.id}>
          {shortenUuid4(platformUser?.id)}
        </ClipboardContent>
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Organization Name">{orgName}</BasicInfoDisplay>

      <BasicInfoDisplay title="Organization ID">
        <ClipboardContent
          tooltipContext="organization ID"
          clipboardValue={platformUser?.organization_id}
        >
          {shortenUuid4(platformUser?.organization_id)}
        </ClipboardContent>
      </BasicInfoDisplay>
    </OverviewContainer>
  );
};

export default UserProfileDetailsPage;
