import { useEffect, useMemo, useState } from 'react';
import { useAccount } from 'wagmi';
import { useContractInteraction } from './useContractInteraction';
import { useErc20Allowance, usePrepareErc20Approve } from '@/contracts/generated';
import { decimalStringToUSDC } from '../helpers';
import {
  ContractInteractionHookOptions,
  ContractInteractionHookReturn,
  ContractInteractionPrepareReturn,
} from '@/common/types';

interface UseTokenAllowanceReturn {
  isApproved: boolean;
  status: string;
  isLoading: boolean;
  data?: bigint;
  watchAllowance: boolean;
  setWatchAllowance: (watch: boolean) => void;
}

export function useTokenAllowance(
  amount: number,
  spender: `0x${string}`,
  network: number,
  tokenAddress?: `0x${string}`,
  options: ContractInteractionHookOptions & {
    onAllowanceComplete?: () => void;
  } = {}
): UseTokenAllowanceReturn & ContractInteractionHookReturn & ContractInteractionPrepareReturn {
  const { handlePrepareError, disabled } = options;
  const [prepareError, setPrepareError] = useState<boolean>(false);

  const [watchAllowance, setWatchAllowance] = useState<boolean>(false);
  const { address } = useAccount();

  const { data, isLoading, status } = useErc20Allowance({
    address: tokenAddress,
    args: [address!, spender],
    chainId: network,
    watch: watchAllowance,
    enabled: Boolean(tokenAddress),
    cacheOnBlock: true,
  });

  const isApproved = useMemo(() => {
    if (!tokenAddress) return false;
    if (status !== 'success') return false;

    if (!data) return false;

    try {
      return decimalStringToUSDC(amount.toString()) <= (data as bigint);
    } catch (err) {
      return false;
    }
  }, [amount, status, data, tokenAddress]);

  const { config, isLoading: prepareLoading } = usePrepareErc20Approve({
    address: tokenAddress,
    chainId: network,
    onError: err => {
      if (disabled) {
        return;
      }

      if (handlePrepareError) {
        handlePrepareError(err);
      } else {
        setPrepareError(true);
      }
    },
    onSuccess: () => {
      setPrepareError(false);
    },
    args: [spender, decimalStringToUSDC(amount.toString())],
    enabled: !disabled,
  });

  const { handleSubmit, write, writeAsync } = useContractInteraction(config, {
    ...options,
    onStart: () => {
      setWatchAllowance(true);
      if (options.onStart) options.onStart();
    },
    onError: err => {
      setWatchAllowance(false);
      if (options.onError) options.onError(err);
    },
  });

  // no need to watch if already approved
  useEffect(() => {
    if (isApproved) {
      setWatchAllowance(false);
      options?.onAllowanceComplete?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApproved]);

  return {
    data: data as bigint,
    isApproved,
    status,
    isLoading,
    prepareLoading,
    write,
    writeAsync,
    prepareError,
    watchAllowance,
    setWatchAllowance,
    handleSubmit,
  };
}
