import { Route, Routes, useLocation } from 'react-router-dom';
import AuthPage from './auth/AuthPage';
import NotFoundPage from '@/common/page/NotFound';
import { RequireAuth } from './auth/RequireAuth';
import AuthedRouteLayout from '@/auth/AuthedRouteLayout';
import FilterIcon from '@mui/icons-material/FilterOutlined';
import AuthLandingPage from './auth/AuthLandingPage';
import AuthedRedirectPage from './auth/AuthedRedirectPage';
import CollectionsOverviewPage from './collections/OverviewPage';
import SettingsDetailsPage from './settings/DetailsPage';
import ImportExportIcon from '@mui/icons-material/ImportExportOutlined';
import TransactionsOverviewPage from './transactions/OverviewPage';
import CollectionDetailsPage from './collections/DetailsPage';
import ItemDetailsPage from './items/DetailsPage';
import { ROUTE_NAME } from './utils/constants';
import TransactionDetailsPage from './transactions/DetailsPage';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAltOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import EmailClaimDetailsPage from './email-claim/DetailsPage';
import ListingsOverviewPage from './listings/OverviewPage';
import ListingDetailsPage from './listings/DetailsPage';
import PurchaseIntentDetailsPage from './purchase-intent/DetailsPage';
import RevealStrategyOverviewPage from './collections/reveal-strategy/OverviewPage';
import * as Sentry from '@sentry/react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoneyOutlined';
import InviteUserPage from './auth/InviteUserPage';
import BillingOverview from './billing/BillingOverview';
import AuthErrorPage from './auth/AuthErrorPage';
import { AnimatePresence, motion } from 'framer-motion';
import MarketingCampaignsOverviewPage from './marketing-campaigns/OverviewPage';
import MarketingCampaignDetailsPage from './marketing-campaigns/DetailsPage';
import TemplateDetailsPage from './marketing-campaigns/TemplateDetailsPage';
import UsersPage from './users/UsersPage';
import UserDetailsPage from './users/UserDetailsPage';
import UserInvitedDetailsPage from './users/UserInvitedDetailsPage';
import AuthSignupPage from './auth/AuthSignupPage';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export interface AppRoute {
  name: ROUTE_NAME;
  Icon?: any;
  path: string;
  requiredPermissions: Array<string>;
  element: any;
  includeInSidebar: boolean;
  isIndexRoute?: boolean;
  hasTestMode?: boolean;
}

export const MAIN_ROUTES: Array<AppRoute> = [
  {
    name: ROUTE_NAME.HOME,
    Icon: FilterIcon,
    path: '/',
    requiredPermissions: ['collections:read'],
    element: <CollectionsOverviewPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.COLLECTIONS_OVERVIEW,
    Icon: FilterIcon,
    path: '/collections',
    requiredPermissions: ['collections:read'],
    element: <CollectionsOverviewPage />,
    includeInSidebar: true,
    hasTestMode: true,
  },
  {
    name: ROUTE_NAME.TRANSACTIONS_OVERVIEW,
    Icon: ImportExportIcon,
    path: '/transactions',
    requiredPermissions: ['transactions:read', 'collections:read'],
    element: <TransactionsOverviewPage />,
    includeInSidebar: true,
    hasTestMode: true,
  },
  {
    name: ROUTE_NAME.BILLING_OVERVIEW,
    Icon: AttachMoneyIcon,
    path: '/billing',
    requiredPermissions: [],
    element: <BillingOverview />,
    includeInSidebar: true,
    hasTestMode: true,
  },
  {
    name: ROUTE_NAME.BILLING_TRANSACTION_DETAILS,
    path: '/billing/transactions/:transactionId',
    requiredPermissions: ['transactions:read'],
    element: <TransactionDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.EMAIL_CAMPAIGNS,
    Icon: EmailIcon,
    path: '/email-campaigns',
    requiredPermissions: ['organization_email_templates:read'],
    element: <MarketingCampaignsOverviewPage />,
    includeInSidebar: true,
  },
  {
    name: ROUTE_NAME.SETTINGS,
    Icon: SettingsIcon,
    path: '/settings',
    requiredPermissions: ['api_keys:read'],
    element: <SettingsDetailsPage />,
    includeInSidebar: true,
  },
  {
    name: ROUTE_NAME.USERS,
    Icon: PeopleAltIcon,
    path: '/users',
    requiredPermissions: ['users:read'],
    element: <UsersPage />,
    includeInSidebar: true,
  },
  {
    name: ROUTE_NAME.USER_DETAILS,
    path: '/users/:userId',
    requiredPermissions: ['users:read'],
    element: <UserDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.USER_INVITED_DETAILS,
    path: '/users-invited/:token',
    requiredPermissions: ['users:read'],
    element: <UserInvitedDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.LISTINGS_OVERVIEW,
    path: '/listings',
    requiredPermissions: ['listings:read'],
    element: <ListingsOverviewPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.COLLECTION_DETAILS,
    path: '/collections/:collectionId',
    requiredPermissions: ['collections:read', 'items:read', 'transactions:read'],
    element: <CollectionDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.COLLECTIONS_REVEAL_STRATEGY,
    path: '/collections/:collectionId',
    requiredPermissions: ['collections:read'],
    element: <RevealStrategyOverviewPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.EMAIL_CAMPAIGN_CREATE,
    path: '/email-campaigns/create',
    requiredPermissions: ['organization_email_templates:create'],
    element: <MarketingCampaignsOverviewPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.EMAIL_CAMPAIGN_DETAILS,
    path: '/email-campaigns/:campaignId',
    requiredPermissions: ['organization_email_templates:read'],
    element: <MarketingCampaignDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.EMAIL_CAMPAIGN_TEMPLATE,
    path: '/email-campaigns/:campaignId/:templateType',
    requiredPermissions: ['organization_email_templates:read'],
    element: <TemplateDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.TRANSACTION_DETAILS,
    path: '/transactions/:transactionId',
    requiredPermissions: ['transactions:read'],
    element: <TransactionDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.COLLECTION_TRANSACTION_DETAILS,
    path: '/collections/:collectionId/transactions/:transactionId',
    requiredPermissions: ['transactions:read'],
    element: <TransactionDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.ITEM_TRANSACTION_DETAILS,
    path: '/collections/:collectionId/items/:itemId/transactions/:transactionId',
    requiredPermissions: ['transactions:read'],
    element: <TransactionDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.ITEM_DETAILS,
    path: '/collections/:collectionId/items/:itemId',
    requiredPermissions: ['items:read'],
    element: <ItemDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.EMAIL_CLAIM_DETAILS,
    path: '/email-claims/:emailClaimId',
    requiredPermissions: ['email_claims:read'],
    element: <EmailClaimDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.COLLECTION_EMAIL_CLAIM_DETAILS,
    path: '/collections/:collectionId/email-claims/:emailClaimId',
    requiredPermissions: ['email_claims:read'],
    element: <EmailClaimDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.ITEM_EMAIL_CLAIM_DETAILS,
    path: '/collections/:collectionId/items/:itemId/email-claims/:emailClaimId',
    requiredPermissions: ['email_claims:read'],
    element: <EmailClaimDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.LISTING_DETAILS,
    path: '/listings/:listingId',
    requiredPermissions: ['listings:read'],
    element: <ListingDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.COLLECTION_LISTING_DETAILS,
    path: '/collections/:collectionId/listings/:listingId',
    requiredPermissions: ['listings:read'],
    element: <ListingDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.ITEM_LISTING_DETAILS,
    path: '/collections/:collectionId/items/:itemId/listings/:listingId',
    requiredPermissions: ['listings:read'],
    element: <ListingDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.PURCHASE_INTENT_DETAILS,
    path: '/purchase-intents/:purchaseIntentId',
    requiredPermissions: ['listings:read'],
    element: <PurchaseIntentDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.COLLECTION_PURCHASE_INTENT_DETAILS,
    path: '/collections/:collectionId/purchase-intents/:purchaseIntentId',
    requiredPermissions: ['listings:read'],
    element: <PurchaseIntentDetailsPage />,
    includeInSidebar: false,
  },
  {
    name: ROUTE_NAME.ITEM_PURCHASE_INTENT_DETAILS,
    path: '/collections/:collectionId/items/:itemId/purchase-intents/:purchaseIntentId',
    requiredPermissions: ['listings:read'],
    element: <PurchaseIntentDetailsPage />,
    includeInSidebar: false,
  },
];

// remove first character from route if it is a slash
export const getRelativeRoutePath = (route: string) => {
  return route[0] === '/' ? route.slice(1) : route;
};

const Routing = () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <motion.main
        initial="initial"
        animate="in"
        exit="out"
        variants={{
          initial: {
            opacity: 0,
          },
          in: {
            opacity: 1,
          },
          out: {
            opacity: 0,
          },
        }}
        transition={{
          type: 'tween',
          ease: 'linear',
          duration: 0.75,
        }}
      >
        <SentryRoutes location={location} key={location?.pathname}>
          {/* PROTECTED ROUTES */}
          <Route path="/" element={<AuthedRouteLayout />}>
            {MAIN_ROUTES.map(r =>
              r.isIndexRoute ? (
                <Route
                  index
                  element={
                    <RequireAuth requiredPermissions={r.requiredPermissions}>
                      {r.element}
                    </RequireAuth>
                  }
                  key="index"
                />
              ) : (
                <Route
                  path={getRelativeRoutePath(r.path)}
                  element={
                    <RequireAuth requiredPermissions={r.requiredPermissions}>
                      {r.element}
                    </RequireAuth>
                  }
                  key={r.name}
                />
              )
            )}
          </Route>

          {/* AUTH ROUTES */}
          <Route path="auth0" element={<AuthPage />}>
            <Route path="login" element={<AuthLandingPage />} />
            <Route path="logout" element={<AuthLandingPage forceLogout />} />
            <Route path="redirect" element={<AuthedRedirectPage />} />
            <Route path="invite/:token" element={<InviteUserPage />} />
            <Route path="error" element={<AuthErrorPage />} />
            <Route path="signup" element={<AuthSignupPage />} />
          </Route>

          {/* CATCH ALL */}
          <Route path="*" element={<NotFoundPage />} />
        </SentryRoutes>
      </motion.main>
    </AnimatePresence>
  );
};

export default Routing;
