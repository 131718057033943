import { CssBaseline, createTheme, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect } from 'react';
import Routing from './routes';
import { Auth0Provider } from '@auth0/auth0-react';
import { auth0Audience, auth0ClientId, auth0Domain, googleAnalyticsId } from './config';
import {theme } from './theme';
import { SnackbarProvider } from 'notistack';
import FeatureFlagsProvider from './FeatureFlagsProvider';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import '@rainbow-me/rainbowkit/styles.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const defaultTheme = createTheme(theme);

function App() {

  useEffect(() => {
    if (googleAnalyticsId) {
      ReactGA.initialize(googleAnalyticsId);
    }
  }, []);

  if (!auth0Domain || !auth0ClientId || !auth0Audience) {
    return <div>Invalid configuration, missing auth0Domain, auth0ClientId or auth0Audience</div>;
  }

  return (
    <FeatureFlagsProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={defaultTheme}>
          <SnackbarProvider maxSnack={3}>
            <CssBaseline enableColorScheme />

            <Auth0Provider
              domain={`${auth0Domain}`}
              clientId={`${auth0ClientId}`}
              authorizationParams={{
                redirect_uri: `${window.location.origin}/auth0/redirect`,
                audience: `${auth0Audience}`,
              }}
              useRefreshTokens
              useRefreshTokensFallback
              cacheLocation="localstorage"
            >
              <Routing />
            </Auth0Provider>
          </SnackbarProvider>
        </ThemeProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </FeatureFlagsProvider>
  );
}

export default Sentry.withProfiler(App);
