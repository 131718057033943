import { useLocation } from 'react-router';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import ClickToNav from './ClickToNav';
import { Box } from '@mui/material';
import { ReactComponent as DescriptionOutlinedIcon } from '../../images/in-our-docs.svg';
import { customPalette } from '@/theme/custom';

// add your default route docs hints below
const HINTS: { [key: string]: JSX.Element } = {
    'collections': (
        <>
            <ClickToNav hideIcon key='doc-collections' sx={{ width: 'auto' }} url={'https://docs.phosphor.xyz/platform-features/digital-asset-creation/collections'} mimicButton={false}>
            <Typography sx={{ fontSize: '14px', fontWeight: 400, letterSpacing: '0.4px' }}>Collections</Typography>
          </ClickToNav>
        </>
    ),
    'transactions': (
        <>
            <ClickToNav hideIcon key='doc-transactions' sx={{ width: 'auto' }} url={'https://docs.phosphor.xyz/platform-features/supporting-functionalities/transactions'} mimicButton={false}>
            <Typography sx={{ fontSize: '14px', fontWeight: 400, letterSpacing: '0.4px' }}>Transactions</Typography>
          </ClickToNav>
        </>
     ),
     'billing': (
        <>
            <ClickToNav hideIcon key='doc-billing' sx={{ width: 'contained' }} url={'https://docs.phosphor.xyz/initial-setup/add-funds-to-your-balance'} mimicButton={false}>
            <Typography sx={{ fontSize: '14px', fontWeight: 400, letterSpacing: '0.4px' }}>Billing & Balances</Typography>
          </ClickToNav>
        </>
    ),
    'email-campaigns': (
        <>
            <ClickToNav hideIcon key='doc-email' sx={{ width: 'auto' }} url={'https://docs.phosphor.xyz/platform-features/supporting-functionalities/email-campaigns'} mimicButton={false}>
            <Typography sx={{ fontSize: '14px', fontWeight: 400, letterSpacing: '0.4px' }}>Email Campaigns</Typography>
          </ClickToNav>
        </>
    ),
    'settings': (
        <>
            <ClickToNav hideIcon key='doc-settings' sx={{ mr: 1, width: 'auto' }} url={'https://docs.phosphor.xyz/category/initial-setup'} mimicButton={false}>
            <Typography sx={{ fontSize: '14px', fontWeight: 400, letterSpacing: '0.4px' }}>Settings</Typography>
          </ClickToNav>
        </>
    ),
    'users': (
        <>
            <ClickToNav hideIcon key='dc-users' sx={{ width: 'auto' }} url={'https://docs.phosphor.xyz/initial-setup/users'} mimicButton={false}>
            <Typography sx={{ fontSize: '14px', fontWeight: 400, letterSpacing: '0.4px' }}>Users</Typography>
          </ClickToNav>
        </>
    ),
};


interface DocsContextHintProps {
    overrideKey?: string;
}

/**
 * The base component for displaying contextual documentation section.
 * By default, this will display contextual info based on the route being used.
 * Otherwise, may pass a specific hint key to display.
 * @param key
 */
const DocsContextHint = ({overrideKey = ''} : DocsContextHintProps) => {
    const location = useLocation();
    const [isPageReady, setIsPageReady] = useState(false);
    const [hasHint, setHasHint] = useState(false);
    const text = useRef<JSX.Element>(<></>);

    useEffect(() => {
        const [routeName,] = location.pathname.replace('/', '').split('/', 2);
        if (routeName in HINTS) { text.current = HINTS[routeName]; setHasHint(true); }
        if (overrideKey !== '' && overrideKey in HINTS) { text.current = HINTS[overrideKey]; setHasHint(true); }
 
        // never show docs on auth0 pages
        if ((routeName as string).startsWith('auth0')) setHasHint(false); 
        setIsPageReady(true);
      }, [location.pathname, location.search, overrideKey]);

    if (!isPageReady || !hasHint) return;

    return (
    <Box
        id="in-our-docs"
        sx={{
            zIndex: 5,
            mt: 0,
            pl: 1,
            pr: 1,
            gap: '8px',
            display: 'flex',
            marginLeft: 'auto',
        }}
        >
        <DescriptionOutlinedIcon style={{ width: 30, height: 30 }} />
        <Box>
            <Typography
            sx={{
                zIndex: 5,
                mt: '-4px',
                color: customPalette.colors.cnftDocHint, 
                fontSize: '12px',
                fontWeight: 700,
                lineHeight: '8px',
                letterSpacing: '0.4px',
            }}
            >
            IN OUR DOCS
            </Typography>
            <Box sx={{display: 'inline-block', width: '115px', minWidth: 'max-content' }}>
            {text.current}
            </Box>
        </Box>
    </Box>
    );
};

export default DocsContextHint;
