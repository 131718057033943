import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { AuthContext } from './AuthPage';
import { authState, setAuthAccessToken } from '@/utils/states/authState';
import AuthErrorPage from './AuthErrorPage';
import { useSnapshot } from 'valtio';
import LoadingPage from '@/common/page/Loading';

// Page that is used when redirected back to the app after logging in with Auth0.
const AuthedRedirectPage = () => {
  const { onLogin } = useOutletContext<AuthContext>();

  const { accessToken: authStateAccessToken } = useSnapshot(authState);
  const { error, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [localError, setLocalError] = useState('');

  useEffect(() => {
    async function syncTokenToAuthState() {
      try {
        const accessToken = await getAccessTokenSilently();
        setAuthAccessToken(accessToken);
      } catch (e: any) {
        setLocalError('Could not fetch access token');
      }
    }

    if (isAuthenticated && user) {
      if (!authStateAccessToken) syncTokenToAuthState();
      else {
        onLogin();
      }
    }
  }, [user, isAuthenticated, getAccessTokenSilently, onLogin, authStateAccessToken]);

  if (error?.message || localError) return <AuthErrorPage reason={error?.message || localError} />;
  else return <LoadingPage />;
};

export default AuthedRedirectPage;
