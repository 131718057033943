import { betaBaseApiUrl } from '@/config';
import { axiosAuthedApiInstance } from '@/utils/api';
import { ORG_BALANCE_QUERY_KEY } from '@/utils/constants';
import { useMutation, useQuery } from '@tanstack/react-query';

interface OrganizationBalance {
  crypto: string;
  fiat: string;
  currency: string;
  total: string;
}

export const useOrganizationBalance = () => {
  return useQuery({
    queryKey: [ORG_BALANCE_QUERY_KEY],
    queryFn: () =>
      axiosAuthedApiInstance
        .get('/beta/drops/payments/balance', { baseURL: betaBaseApiUrl })
        .then(res => res.data.balance as OrganizationBalance),
  });
};

export interface CreateOrganizationStripePaymentInput {
  /**
   * Uint256
   * @type {string}
   * @memberof CreateOrganizationStripePaymentInput
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationStripePaymentInput
   */
  currency: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationStripePaymentInput
   */
  organization_id: string;
}

export interface CreateOrganizationStripePaymentOutput {
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationStripePaymentOutput
   */
  client_secret: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationStripePaymentOutput
   */
  stripe_payment_intent_id: string;
}

export const useCreateStripePaymentIntent = () => {
  return useMutation({
    mutationFn: (payload: CreateOrganizationStripePaymentInput) => {
      return axiosAuthedApiInstance
        .post('/beta/payment/stripe', payload, { baseURL: betaBaseApiUrl })
        .then(res => res.data as CreateOrganizationStripePaymentOutput);
    },
  });
};
