import { useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { NETWORK_IDS } from '@/utils/constants';
import { getExplorerUrl } from '@/utils/helpers';
import ClipboardContent from '@/common/data/ClipboardContent';
import { SxProps } from '@mui/material/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined';

export enum BlockExplorerFilterType {
  ADDRESS = 'address',
  TX_HASH = 'txHash',
}

function getTooltipContext(type: BlockExplorerFilterType) {
  switch (type) {
    case BlockExplorerFilterType.ADDRESS:
      return 'this ethereum address';
    case BlockExplorerFilterType.TX_HASH:
      return 'this transaction hash';
  }
}

function getBlockchainExplorerSlugType(type: BlockExplorerFilterType) {
  switch (type) {
    case BlockExplorerFilterType.ADDRESS:
      return 'address';
    case BlockExplorerFilterType.TX_HASH:
      return 'tx';
  }
}

interface BlockExplorerFilterIconProps {
  openExplorer: () => void;
  sx?: SxProps;
}

const BlockExplorerTooltip = ({ children }: { children: React.ReactElement }) => {
  return <Tooltip title="Click to navigate to blockchain explorer">{children}</Tooltip>;
};

const BlockExplorerFilterIcon = ({ openExplorer, sx }: BlockExplorerFilterIconProps) => {
  return (
    <BlockExplorerTooltip>
      <OpenInNewIcon
        color="primary"
        sx={{
          cursor: 'pointer',
          color: 'primary.main',
          '&:hover': {
            color: 'primary.dark',
          },
          ...(sx || {}),
        }}
        onClick={openExplorer}
      />
    </BlockExplorerTooltip>
  );
};

interface BlockExplorerDisplayProps {
  value?: string;
  full?: boolean;
  charLengthOnEitherSide?: number;
  sx?: SxProps;
  tooltipContext: string;
}

/**
 * The default way to render ethereum addresses and transaction hashes.
 * @param value The value of the address or transaction hash.
 * @param full Whether or not to show the full address or transaction hash.
 * @param charLengthOnEitherSide The number of characters to show on either side of the ellipsis.
 * @param sx The sx prop to pass to the Box component.
 * @param tooltipContext The context of the value being displayed. For example, "this ethereum address" or "this transaction hash".
 */
export const BlockExplorerDisplay = ({
  value,
  full,
  charLengthOnEitherSide = 4,
  sx,
  tooltipContext,
}: BlockExplorerDisplayProps) => {
  const displayedAddress = useMemo(() => {
    if (full || !value) return value;
    return `${value.slice(0, 2 + charLengthOnEitherSide)}...${value.slice(
      0 - charLengthOnEitherSide
    )}`;
  }, [value, full, charLengthOnEitherSide]);

  if (!displayedAddress) return <></>;

  return (
    <ClipboardContent tooltipContext={tooltipContext} clipboardValue={value}>
      <Box
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          flexShrink: 1,
          display: 'flex',
          alignItems: 'center',
          ...(sx || {}),
        }}
      >
        {displayedAddress}
      </Box>
    </ClipboardContent>
  );
};

interface BlockExplorerFilterProps {
  filterValue?: string;
  filterType?: BlockExplorerFilterType;
  networkId?: number;
  full?: boolean;
  charLengthOnEitherSide?: number;
  showBlockExplorerIcon?: boolean;
  onlyIcon?: boolean;
  sx?: SxProps;
  children?: React.ReactNode;
  iconPosition?: 'start' | 'end';
  openExplorerOnClick?: boolean;
}

export const BlockExplorerFilter = ({
  filterValue = '',
  filterType = BlockExplorerFilterType.ADDRESS,
  networkId = 1,
  full,
  charLengthOnEitherSide,
  showBlockExplorerIcon = true,
  onlyIcon = false,
  sx = {},
  iconPosition = 'start',
  openExplorerOnClick = false,
  children,
}: BlockExplorerFilterProps) => {
  const openExplorer = () => {
    const url = getExplorerUrl(networkId, filterValue, getBlockchainExplorerSlugType(filterType));
    if (url) {
      window.open(url);
    }
  };

  if (onlyIcon) {
    return (
      <Box sx={{ display: 'flex', ...sx }}>
        {showBlockExplorerIcon && NETWORK_IDS.includes(networkId) && (
          <BlockExplorerFilterIcon openExplorer={openExplorer} sx={{ mr: 1 }} />
        )}
      </Box>
    );
  }

  const tooltipContext = getTooltipContext(filterType);

  return (
    <Box sx={{ display: 'flex', ...sx }}>
      {showBlockExplorerIcon && NETWORK_IDS.includes(networkId) && (
        <BlockExplorerFilterIcon
          openExplorer={openExplorer}
          sx={{
            order: iconPosition === 'end' ? 1 : 0,
            mr: iconPosition === 'start' ? 1 : 0,
            ml: iconPosition === 'end' ? 1 : 0,
          }}
        />
      )}

      {openExplorerOnClick && children ? (
        <BlockExplorerTooltip>
          <Box onClick={openExplorer} sx={{ cursor: 'pointer' }}>
            {children}
          </Box>
        </BlockExplorerTooltip>
      ) : (
        children || (
          <BlockExplorerDisplay
            value={filterValue}
            charLengthOnEitherSide={charLengthOnEitherSide}
            full={full}
            tooltipContext={tooltipContext}
          />
        )
      )}
    </Box>
  );
};

export default BlockExplorerFilter;
