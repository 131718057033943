import PageContentPaper from '@/common/page/ContentPaper';
import Page from '@/common/page/Index';
import CampaignsGrid from './Grid';
import { useEffect, useMemo, useState } from 'react';
import PermissionButton from '@/common/button/PermissionButton';
import { ROUTE_NAME } from '@/utils/constants';
import { AppRoute, MAIN_ROUTES } from '@/routes';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { find } from 'lodash';
import { Grid } from '@mui/material';
import CreateCampaignForm from './Create/Form';
import { useEmailIntegration } from '@/utils/hooks/emailIntegration';

interface CreateCampaignActionProps {
  action: any;
  isDisabled: boolean;
}

const CreateCampaignAction = ({ action, isDisabled }: CreateCampaignActionProps) => {
  return (
    <PermissionButton
      buttonLabel="Create New Campaign"
      variant="create"
      requiredPermissions={['organization_email_templates:create']}
      action={action}
      buttonProps={{ disabled: isDisabled }}
    ></PermissionButton>
  );
};

const MarketingCampaignsOverviewPage = () => {
  const navigate = useNavigate();
  const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
  const { data: emailIntegration, isPending: emailIntegrationLoading } = useEmailIntegration();

  const isCreatingCampaign = !!useLocation().pathname.endsWith('create');

  const mainPath = useMemo(() => {
    const route = find(MAIN_ROUTES, route => route.name === ROUTE_NAME.EMAIL_CAMPAIGNS) as AppRoute;
    return generatePath(route.path as string);
  }, []);

  const createFormPath = useMemo(() => {
    const route = find(
      MAIN_ROUTES,
      route => route.name === ROUTE_NAME.EMAIL_CAMPAIGN_CREATE
    ) as AppRoute;
    return generatePath(route.path as string);
  }, []);

  useEffect(() => {
    if (!emailIntegrationLoading && emailIntegration && emailIntegration?.total_results > 0) {
      setCreateButtonDisabled(false);
    }
  }, [emailIntegration, emailIntegration?.total_results, emailIntegrationLoading]);

  const navigateToRoute = () => {
    navigate(createFormPath);
  };

  const cancelToRoute = () => {
    navigate(mainPath);
  };

  if (isCreatingCampaign) {
    return (
      <Page
        testIdPrefix="email-campaigns-new"
        helmet="New Campaign"
        title="New Campaign"
        backTo={{
          routePath: mainPath ?? '',
          buttonLabel: 'Back To Email Campaign',
        }}
      >
        <PageContentPaper>
          <Grid container>
            <Grid item xs={12} sm={11} md={10}>
              <CreateCampaignForm onCancel={cancelToRoute} />
            </Grid>
          </Grid>
        </PageContentPaper>
      </Page>
    );
  }

  return (
    <Page testIdPrefix="email-campaigns" helmet="Email Campaigns" title="Email Campaigns">
      <PageContentPaper
        headerAction={
          <CreateCampaignAction action={navigateToRoute} isDisabled={createButtonDisabled} />
        }
      >
        <CampaignsGrid
          emailIntegration={emailIntegration}
          emailIntegrationLoading={emailIntegrationLoading}
        />
      </PageContentPaper>
    </Page>
  );
};

export default MarketingCampaignsOverviewPage;
