import Page, { entityPageVisitState } from '@/common/page/Index';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useMemo } from 'react';
import { ENTITY_PAGE_VISIT_TYPE, ROUTE_NAME } from '@/utils/constants';
import { useEmailClaim } from '@/utils/hooks/emailClaim';
import EmailClaimDetailsOverview from './DetailsOverview';
import { useSnackbar } from 'notistack';
import { MAIN_ROUTES } from '@/routes';
import { generatePath } from 'react-router-dom';
import PageContentTabs, {
  ContentTab,
  OVERVIEW_TAB_LABEL,
  OVERVIEW_TAB_VALUE,
} from '@/common/page/ContentTabs';

const EmailClaimDetailsPage = () => {
  const {
    emailClaimId: emailClaimIdFromUrl,
    collectionId: collectionIdFromUrl,
    itemId: itemIdFromUrl,
  } = useParams();
  const { data: emailClaim, isError: emailClaimError } = useEmailClaim(emailClaimIdFromUrl ?? '');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const tabs = useMemo(() => {
    const result: Array<ContentTab> = [
      {
        label: OVERVIEW_TAB_LABEL,
        value: OVERVIEW_TAB_VALUE,
        content: <EmailClaimDetailsOverview emailClaimId={emailClaimIdFromUrl ?? ''} />,
      },
    ];
    return result;
  }, [emailClaimIdFromUrl]);

  const emailClaimTitle = useMemo(() => {
    if (!emailClaim) return '';
    return `${emailClaim?.email} (Quantity: ${emailClaim.quantity}) (${
      emailClaim?.claimed ? 'Claimed' : 'Unclaimed'
    }})`;
  }, [emailClaim]);

  useEffect(() => {
    if (
      !emailClaimIdFromUrl ||
      !emailClaimTitle ||
      entityPageVisitState?.data?.platformId === emailClaimIdFromUrl
    )
      return;
    entityPageVisitState.data = {
      type: ENTITY_PAGE_VISIT_TYPE.EMAIL_CLAIM,
      context: emailClaimTitle,
      platformId: emailClaimIdFromUrl,
    };
  }, [emailClaimIdFromUrl, emailClaimTitle]);

  useEffect(() => {
    if (emailClaimError) {
      const overviewRoute = MAIN_ROUTES.find(
        route => route.name === ROUTE_NAME.COLLECTIONS_OVERVIEW
      );
      if (!overviewRoute) return;
      enqueueSnackbar('Email claim not found, returning to collection overview.', {
        variant: 'info',
      });
      navigate(generatePath(overviewRoute.path));
    }
  }, [emailClaimError, navigate, enqueueSnackbar]);

  const backToRoutePath = useMemo(() => {
    if (!itemIdFromUrl && !collectionIdFromUrl) return undefined;

    let routePath = undefined;

    if (itemIdFromUrl && collectionIdFromUrl) {
      const itemRoute = MAIN_ROUTES.find(route => route.name === ROUTE_NAME.ITEM_DETAILS);
      if (itemRoute)
        routePath = `${generatePath(itemRoute.path, {
          collectionId: collectionIdFromUrl,
          itemId: itemIdFromUrl,
        })}?tab=email-claims`;
    } else if (collectionIdFromUrl) {
      const collectionRoute = MAIN_ROUTES.find(
        route => route.name === ROUTE_NAME.COLLECTION_DETAILS
      );
      if (collectionRoute)
        routePath = `${generatePath(collectionRoute.path, {
          collectionId: collectionIdFromUrl,
        })}?tab=email-claims`;
    }

    return routePath;
  }, [collectionIdFromUrl, itemIdFromUrl]);

  return (
    <Page
      testIdPrefix="email-claim-details"
      helmet="Email Claim Details"
      title="Email Claim Details"
      backTo={
        backToRoutePath
          ? {
              routePath: backToRoutePath ?? '',
              buttonLabel: `Back To All${
                itemIdFromUrl && collectionIdFromUrl
                  ? ' Item'
                  : collectionIdFromUrl
                    ? ' Collection'
                    : ''
              } Email Claims`,
            }
          : undefined
      }
    >
      <PageContentTabs tabs={tabs} />
    </Page>
  );
};

export default EmailClaimDetailsPage;
