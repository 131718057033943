import Page from '@/common/page/Index';
import UsersGrid from './Grid';
import PageContentTabs, { ContentTab } from '@/common/page/ContentTabs';
import CreateUserForm from './CreateForm';
import PermissionModalButton from '@/common/button/PermissionModal';
import { useMemo } from 'react';
import InvitedGrid from '@/invites/InvitesGrid';

const UsersPage = () => {
  const tabs = useMemo(() => {
    const tabs: Array<ContentTab> = [
      {
        label: 'Active',
        value: 'users',
        content: <UsersGrid />,
        action: (
          <PermissionModalButton
            modalTitle="Invite New User"
            buttonLabel="Invite New User"
            modalDescription="Specify the details for the invitation"
            variant="create"
            requiredPermissions={['users:create']}
          >
            {({ closeModal }) => <CreateUserForm onSuccess={closeModal} onCancel={closeModal} />}
          </PermissionModalButton>
        ),
      },
      {
        label: 'Invited',
        value: 'invited',
        content: <InvitedGrid />,
        requiredPermissions: ['users:create'],
        action: (
          <PermissionModalButton
            modalTitle="Invite User"
            buttonLabel="Invite New User"
            modalDescription="Specify the details for the invitation"
            variant="create"
            requiredPermissions={['users:create']}
          >
            {({ closeModal }) => <CreateUserForm onSuccess={closeModal} onCancel={closeModal} />}
          </PermissionModalButton>
        ),
      },
    ];
    return tabs;
  }, []);

  return (
    <Page testIdPrefix="users" helmet="Users" title="Users">
      <PageContentTabs tabs={tabs} />
    </Page>
  );
};

export default UsersPage;
