import { useCreateApiKey } from '@/utils/hooks/apiKey';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  name: yup.string().trim().required('Please enter a name for the API key'),
});

const CreateApiKeyForm = ({ onSuccess, onCancel }: any) => {
  const { mutate, isPending: creatingApiKey } = useCreateApiKey();

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit: (values, { resetForm }) =>
      mutate(
        { name: values.name.trim() },
        { ...(onSuccess ? { onSuccess } : {}), onSettled: () => resetForm({ values }) }
      ),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="subtitle1" id="parent-modal-description" sx={{ marginBottom: 3 }}>
        Give your API key a name so you can easily identify it later.
      </Typography>

      <TextField
        type="name"
        name="name"
        label="Name"
        disabled={formik.isSubmitting}
        sx={{ width: '100%', mb: 2 }}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        placeholder="Fill in the name of the API key"
        required
      />

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          mt: 2,
        }}
      >
        <Button variant="outlined" type="button" onClick={onCancel} sx={{ flexGrow: 1 }}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          disabled={formik.isSubmitting}
          loading={formik.isSubmitting || creatingApiKey}
          variant="contained"
          color="primary"
          sx={{ flexGrow: 1 }}
        >
          Confirm
        </LoadingButton>
      </Box>
    </form>
  );
};

export default CreateApiKeyForm;
