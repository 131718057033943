import SimpleDeleteForm from '@/common/data/SimpleDeleteForm';
import { useDeleteUser } from '@/utils/hooks/user';

const DeleteUserForm = ({ userId, onSuccess, onCancel }: any) => {
  const { mutate } = useDeleteUser();

  return (
    <SimpleDeleteForm
      deleteConfirmationMessage="Are you sure you want to delete this user? This action is permanent."
      onCancel={onCancel}
      onSuccess={onSuccess}
      mutate={mutate}
      id={userId}
    />
  );
};

export default DeleteUserForm;
