import {
  ItemOutputTokenStatusEnum,
  ListingOutputCurrencyEnum,
  ListingOutputStatusEnum,
  PurchaseIntentOutputFulfillmentStatusEnum,
  PurchaseIntentPaginatedOutputResultsInnerStatusEnum,
  TransactionOutputOnChainStatusEnum,
  TransactionOutputStateEnum,
} from '@/api-client';
import { MAIN_ROUTES } from '@/routes';
import {
  BigNumberish,
  ethers,
  parseUnits,
  parseEther,
  getAddress,
  formatUnits as ethersFormatUnits,
} from 'ethers';
import { chunk, flatten, isEmpty, isNil } from 'lodash';
import find from 'lodash/find';
import snakeCase from 'lodash/snakeCase';
import { DateTime } from 'luxon';
import {
  COLLECTION_PREVIEW_EXCLUDE_ATTRIBUTES,
  COMMON_ITEM_ATTRIBUTES,
  IPFS_PROTOCOL,
  ROUTE_NAME,
  SHORT_ADDRESS_DEFAULT_CHAR_LENGTH_ON_EITHER_SIDE,
  SHORT_UUID_DEFAULT_CHAR_LENGTH_ON_EITHER_SIDE,
  UNLIMITED_MAX_SUPPLY,
} from './constants';
import { isMetaAttribute } from './validation';
import { parse } from 'uuid';

export function getApiErrorMessage(error: any): string {
  let errorMessage = error.response?.data?.error?.detail ?? error.message ?? '';
  if (errorMessage && errorMessage.length > 300) {
    errorMessage =
      errorMessage.substring(0, 50) +
      '...' +
      errorMessage.substring(errorMessage.length - 50, errorMessage.length);
  }
  return errorMessage;
}

export function getExplorerUrl(networkId: number, slugValue: string, slugType = 'address'): string {
  let url = '';
  switch (networkId) {
    default:
      url = `https://etherscan.io/${slugType}/${slugValue}`;
      break;
    case 5:
      url = `https://goerli.etherscan.io/${slugType}/${slugValue}`;
      break;
    case 137:
      url = `https://polygonscan.com/${slugType}/${slugValue}`;
      break;
    case 59144:
      url = `https://explorer.linea.build/${slugType}/${slugValue}`;
      break;
    case 59140:
      url = `https://explorer.goerli.linea.build/${slugType}/${slugValue}`;
      break;
    case 11297108109:
      url = `https://www.ondora.xyz/network/palm/accounts/${slugValue}`; // TODO: translate txhash slug
      break;
    case 11155111:
      url = `https://sepolia.etherscan.io/${slugType}/${slugValue}`;
      break;
  }
  return url;
}

export const hasPermission = (requiredPermission: string, permissions: string[]) => {
  return permissions.includes(requiredPermission);
};

export const hasPermissions = (requiredPermissions: string[], permissions: string[]) => {
  return requiredPermissions.every(p => hasPermission(p, permissions));
};

export const singularize = (str: string) => {
  return str.endsWith('s') ? str.slice(0, -1) : str;
};

export const uuidv4 = (): string => {
  let d = new Date().getTime();
  let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const shortenUuid4 = (
  id = '',
  charLengthOnEitherSide = SHORT_UUID_DEFAULT_CHAR_LENGTH_ON_EITHER_SIDE
) => {
  return `${id.slice(0, charLengthOnEitherSide)}...${id.slice(0 - charLengthOnEitherSide)}`;
};

export const shortenAddress = (
  address = '',
  charLengthOnEitherSide = SHORT_ADDRESS_DEFAULT_CHAR_LENGTH_ON_EITHER_SIDE
) => {
  return `0x${address.slice(2, charLengthOnEitherSide + 2)}...${address.slice(
    0 - charLengthOnEitherSide
  )}`;
};

export const inProgressStatus = [
  'Invite sent',
  PurchaseIntentOutputFulfillmentStatusEnum.Pending.toString(),
  PurchaseIntentOutputFulfillmentStatusEnum.Assigned.toString(),
  ItemOutputTokenStatusEnum.NotMinted.toString(),
  ListingOutputStatusEnum.Active.toString(),
  ListingOutputStatusEnum.PendingTx.toString(),
  TransactionOutputStateEnum.Pending.toString(),
  TransactionOutputStateEnum.Queued.toString(),
  TransactionOutputStateEnum.Submitted.toString(),
  PurchaseIntentPaginatedOutputResultsInnerStatusEnum.Pending.toString(),
  PurchaseIntentPaginatedOutputResultsInnerStatusEnum.Unresolved.toString(),
  // BILLING STATUS
  'NEW',
  'INITIATED',
];
export const successStatus = [
  'SUCCESS',
  'Accepted',
  PurchaseIntentOutputFulfillmentStatusEnum.Completed.toString(),
  ItemOutputTokenStatusEnum.Minted.toString(),
  ListingOutputStatusEnum.Complete.toString(),
  TransactionOutputOnChainStatusEnum.Success.toString(),
  TransactionOutputStateEnum.Completed.toString(),
  PurchaseIntentPaginatedOutputResultsInnerStatusEnum.Confirmed.toString(),
  // BILLING STATUS
  'COMPLETED',
  'NO_CHARGE',
];
export const failureStatus = [
  PurchaseIntentOutputFulfillmentStatusEnum.Exception.toString(),
  ListingOutputStatusEnum.Cancelled.toString(),
  TransactionOutputOnChainStatusEnum.Success.toString(),
  TransactionOutputStateEnum.Cancelled.toString(),
  PurchaseIntentPaginatedOutputResultsInnerStatusEnum.Cancelled.toString(),
  // BILLING STATUS
  'FAILED',
  'EXPIRED',
  'CANCELED',
];

export const getNetworkGasSymbol = (networkId: number) => {
  switch (networkId) {
    case 1:
      return 'ETH';
    case 4:
      return 'ETH';
    case 5:
      return 'ETH';
    case 137:
      return 'MATIC';
    case 100:
      return 'xDAI';
    default:
      return 'ETH';
  }
};

export const getEntityPageVisitLocalStorageUserKey = (userId: string, roleId: string) =>
  `entityPageVisits${userId}-${roleId}`;

export const addAlphaToHexColor = (color: string, alpha: number) => {
  if (color.length === 4) {
    color = color.replace(/#([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])/, '#$1$1$2$2$3$3');
  }

  let alphaHex = Math.round((alpha / 100) * 255).toString(16);

  // make alphaHex two digits
  if (alphaHex.length === 1) {
    alphaHex = '0' + alphaHex;
  }

  return color + alphaHex;
};

export const getTransactionTitle = (type: string, dateString: string) => {
  return `${type} (${DateTime.fromISO(dateString).toLocaleString(
    DateTime.DATETIME_SHORT_WITH_SECONDS
  )})`;
};

export const getChecksummedAddress = (address: string) => {
  return getAddress(address);
};

export const isValidAddress = (address: string) => {
  try {
    getAddress(address);
    return true;
  } catch (e) {
    return false;
  }
};

export const getAddressForDisplay = (address: string, charLengthOnEitherSide = 0) => {
  if (!charLengthOnEitherSide || !address) return address;

  return `${address.slice(0, 2 + charLengthOnEitherSide)}...${address.slice(
    0 - charLengthOnEitherSide
  )}`;
};

export const getMaxSupplyCoerced = (maxSupply: string | number): string => {
  if (!maxSupply) return '';
  return Number(maxSupply).toString().includes('e+') ? UNLIMITED_MAX_SUPPLY : maxSupply.toString();
};

export const downloadCsvFromString = (
  data: string,
  filename: string,
  includeUnixTimestamp = false
) => {
  const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  const csvUrl = URL.createObjectURL(csvData);
  const tempLink = document.createElement('a');
  tempLink.href = csvUrl;
  tempLink.setAttribute(
    'download',
    `${filename}${includeUnixTimestamp ? DateTime.now().toFormat('X') : ''}.csv`
  );
  tempLink.click();
};

/**
 * Adopted from https://stackoverflow.com/questions/19721439/download-json-object-as-a-file-from-browser
 */
export const downloadJson = (filename: string, json: object) => {
  const blob = new Blob([JSON.stringify(json)], { type: 'text/json' });
  const link = document.createElement('a');

  link.download = filename;
  link.href = window.URL.createObjectURL(blob);
  link.dataset.downloadurl = ['text/json', link.download, link.href].join(':');

  const evt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  link.dispatchEvent(evt);
  link.remove();
};

// Reads a JSON FileObject & returns it (mutated) with a json-parsed `data` field
export function readJson(file: any): Promise<File & { data: object }> {
  if (typeof file !== 'object' || file.type !== 'application/json') {
    throw new Error('File object type must be application/json');
  }

  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (event: any) => {
      // NOTE: Mutates the file
      try {
        file.data = JSON.parse(event.target.result);
        return resolve(file);
      } catch (e) {
        // catches Invalid JSON
        return reject(`Error parsing json data for file: ${file.name}`);
      }
    };

    reader.onerror = () => {
      return reject(`Error occurred reading file: ${file.name}`);
    };

    reader.readAsText(file);
  });
}

export const formatUnits = (
  value: BigNumberish,
  decimals: number = 0,
  maxDecimalDigits?: number
) => {
  return ethers.FixedNumber.fromString(ethersFormatUnits(value, decimals))
    .round(maxDecimalDigits ?? decimals)
    .toString();
};

export const formatEther = (value: BigNumberish, maxDecimalDigits?: number) => {
  return formatUnits(value, 18, maxDecimalDigits);
};

// TODO: update this to something more meaningful and based on network
//  eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getMinimumGasBalanceThresholds = (networkId?: number): [bigint, bigint][] => {
  return [
    [parseEther('0'), parseEther('0.15')],
    [parseEther('0.15'), parseEther('0.25')],
  ];
};

const isWithinThreshold = (number: BigNumberish, threshold: [bigint, bigint]) => {
  return threshold[0] <= BigInt(number) && threshold[1] > BigInt(number);
};

export const getBalanceStatus = (
  balance: BigNumberish,
  networkId?: number
): 'green' | 'yellow' | 'red' => {
  const [redThredhold, yellowThreshold] = getMinimumGasBalanceThresholds(networkId);

  if (isWithinThreshold(balance, redThredhold)) {
    // less than 10% from minimum
    return 'red';
  } else if (isWithinThreshold(balance, yellowThreshold)) {
    // less than 20% from minimum
    return 'yellow';
  } else {
    return 'green';
  }
};

export const getBalanceDetails = (
  balance: BigNumberish,
  networkId?: number,
  maxDecimalDigits?: number
): {
  status: 'green' | 'yellow' | 'red';
  formattedBalance: string;
  displayBalance: string;
  gasSymbol: string;
} => {
  // note: if network tokens are not 18 decimals this will need to be updated
  const formattedBalance = formatEther(balance);
  const displayBalance = formatEther(balance, maxDecimalDigits || 4);
  const status = getBalanceStatus(balance, networkId);
  const gasSymbol = getNetworkGasSymbol(networkId || 1);

  return {
    status,
    formattedBalance,
    displayBalance,
    gasSymbol,
  };
};

export const mapItemAttributeKeysToFilterEndpointArray = (attributes: {
  [key: string]: string;
}): string[] => {
  return Object.keys(attributes).map(key => `${key}:${attributes[key]}`);
};

export const filterCollectionPreviewAttributes = (attributes?: { [key: string]: any }) => {
  if (!attributes) return {};

  const filtered_keys = attributes
    ? Object.keys(attributes).filter(key => !COLLECTION_PREVIEW_EXCLUDE_ATTRIBUTES.includes(key))
    : [];

  return filtered_keys.reduce(
    (reduced, current) => ({
      ...reduced,
      [current]: attributes[current],
    }),
    {}
  );
};

/**
 * By default, the attributes bag of an item will contain both the common (title, description ...) and custom attributes.
 * We filter those and return the true custom attributes and the meta attributes.
 */
export const extractUncommonAttributes = (mixedItemAttributes: {
  [key: string]: any;
}): { custom: { [key: string]: any }; meta: { [key: string]: any } } => {
  if (!mixedItemAttributes) return { custom: {}, meta: {} };

  const uncommonAttributeKeys = Object.keys(mixedItemAttributes).filter(
    key => !COMMON_ITEM_ATTRIBUTES.includes(key)
  );

  // Get all the attributes that are not common and meta.
  const customAttributeKeys = uncommonAttributeKeys.filter(key => !isMetaAttribute(key));
  const customAttributes = customAttributeKeys.reduce(
    (reduced, current) => ({
      ...reduced,
      [current]: mixedItemAttributes[current],
    }),
    {}
  );

  const metaAttributeKeys = uncommonAttributeKeys.filter((key: string) => isMetaAttribute(key));
  const metaAttributes = metaAttributeKeys.reduce(
    (reduced, current) => ({
      ...reduced,
      [current]: mixedItemAttributes[current],
    }),
    {}
  );

  return { custom: customAttributes, meta: metaAttributes };
};

/**
 * @returns A logical value for grid-template-columns if you want to divide the number of elements equally across x rows.
 */
export const getGridTemplateColumnCount = (
  childElementCount: number,
  maxColumnCount = 10
): number => {
  if (!childElementCount || maxColumnCount === 1 || maxColumnCount > 10 || maxColumnCount < 1)
    return 1;
  let columnCount = 1;
  [...Array(maxColumnCount).keys()].forEach(columnOption => {
    if (columnCount !== 1) return;
    if (childElementCount % columnOption === 0) {
      columnCount = columnOption;
    }
  });
  return columnCount;
};

/**
 * @returns The icon associated with the given route.
 */
export const getRouteIcon = (text: string): any | null => {
  if (!text) return null;

  // First try to find a match on route name.
  const routeNameMatch: ROUTE_NAME | undefined = find(
    Object.values(ROUTE_NAME),
    routeName => routeName.toLowerCase() === text.toLowerCase()
  );
  if (routeNameMatch) {
    return MAIN_ROUTES.find(route => route.name === routeNameMatch)?.Icon;
  }

  // If no match on route name, try to find a match on route path.
  const routeDetailsPathMatch = MAIN_ROUTES.find(route =>
    route.path.toLowerCase().includes(`/${text.toLowerCase()}`)
  );
  if (routeDetailsPathMatch) {
    return routeDetailsPathMatch.Icon;
  }

  const routeGeneralPathMatch = MAIN_ROUTES.find(route =>
    route.path.toLowerCase().includes(`/${text.toLowerCase().replaceAll(' ', '-')}/`)
  );
  if (routeGeneralPathMatch) {
    return routeGeneralPathMatch.Icon;
  }

  // End of the road buddy.
  return null;
};

export const objectKeysToSnakeCase = <T>(
  obj: { [key: string]: any },
  transformNestedObjects = false
): T => {
  if (!obj) return {} as T;

  const newObj: { [key: string]: any } = {};
  Object.keys(obj).forEach(key => {
    const newKey = snakeCase(key);
    const value = obj[key];
    newObj[newKey] = value;
    // recurrsion for nested objects
    if (transformNestedObjects && !isNil(value)) {
      if (typeof value === 'object' && !Array.isArray(value)) {
        newObj[newKey] = objectKeysToSnakeCase(value, transformNestedObjects);
      }
    }
  });
  return newObj as T;
};

export const getItemTitle = (itemAttributes?: object, tokenId?: string | null): string => {
  return ((itemAttributes as any) ?? {})['title'] + (tokenId ? ` (Token #${tokenId})` : '');
};

export const executeChunked = async (promises: Promise<any>[], chunkSize: number) => {
  const batches = chunk(promises, chunkSize);
  const results = [];

  while (batches.length) {
    const batch = batches.shift();
    if (batch && batch.length) {
      const result = await Promise.all(batch);
      results.push(result);
    }
  }
  return flatten(results);
};

export const emptyValueToNull = (value: any) => {
  if (typeof value === 'boolean') {
    return value;
  }

  if (typeof value === 'number') {
    return value;
  }

  if (isEmpty(value)) {
    return null;
  }

  return value;
};

export const getCoercedListingPrice = (
  price: string,
  currency: ListingOutputCurrencyEnum
): string => {
  if (
    [ListingOutputCurrencyEnum.Erc20, ListingOutputCurrencyEnum.Eth].indexOf(currency as any) !== -1
  ) {
    let formattedPrice = null;

    try {
      formattedPrice = formatEther(BigInt(price));
    } catch {
      formattedPrice = price;
    }
    return formattedPrice;
  } else return price;
};

export async function getMimeType(inputString: string): Promise<string | null> {
  // Check if the input is a base64 encoded string
  if (inputString.startsWith('data:')) {
    const [mime] = inputString.split(';base64,');
    return mime;
  }

  // Otherwise, assume it's a URL and fetch the data
  try {
    const response = await fetch(inputString);
    if (!response.ok) {
      console.warn(`Failed to fetch ${inputString} to determine mime type.`);
    }
    const contentType = response.headers.get('Content-Type');
    return contentType || null;
  } catch (error) {
    return null;
  }
}

export const getUriProtocol = (uri?: string): string => {
  return uri ? new window.URL(uri).protocol : '';
};

export const isIpfsUri = (uri?: string): boolean => {
  if (!uri) return true;
  return getUriProtocol(uri) === IPFS_PROTOCOL;
};

export const centsToFormattedDollars = (cents: number, currency: string): string => {
  if (currency === 'USD') {
    return `$${(cents / 100).toFixed(2)}`;
  } else {
    return `${(cents / 100).toFixed(2)} ${currency}`;
  }
};

// add 20%
export function addGasMargin(value: bigint): bigint {
  return (value * (10000n + 2000n)) / 10000n;
}

export function decimalStringToUSDC(fiatAmount: string) {
  return parseUnits(fiatAmount, 6);
}

export function usdcToDecimalString(usdcAmount: BigNumberish) {
  return formatUnits(usdcAmount, 6);
}

export function uuidToBytes32(uuid: string): string {
  return ethers.zeroPadValue(parse(uuid), 32);
}
