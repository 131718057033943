import { useEffect, useState } from 'react';

const useMinimumLoadingTime = (value: boolean, minTime: number | null) => {
  const [loadingValue, setLoadingValue] = useState(value);
  const [loadingStartTime, setLoadingStartTime] = useState<number | null>(null);

  useEffect(() => {
    // If the value hasn't changed, don't do anything
    if (value === loadingValue) {
      return;
    }

    // If the value has changed from false to true (starts loading), set start time
    if (value === true && loadingValue === false) {
      setLoadingValue(value);
      setLoadingStartTime(Date.now());
      return;
    }

    if (!loadingStartTime || !minTime) {
      setLoadingValue(value);
      return;
    }

    // If the value has changed from true to false (stops loading), if the loading time is less than the minimum time, wait until the minimum time has passed
    if (value === false && loadingValue === true) {
      const totalTimeLoading = Date.now() - loadingStartTime;
      const timeLeft = minTime - totalTimeLoading;

      if (timeLeft <= 0) {
        setLoadingValue(value);
        setLoadingStartTime(null);
        return;
      }

      const id = setTimeout(() => {
        setLoadingValue(value);
        setLoadingStartTime(null);
      }, timeLeft);

      return () => clearTimeout(id);
    }
  }, [value, loadingStartTime, minTime, loadingValue]);

  return loadingValue;
};

export default useMinimumLoadingTime;