/* tslint:disable */
/* eslint-disable */
/**
 * Phosphor Public API
 * by <a href=\"https://phosphor.xyz\">Phosphor</a>.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AppApiPublicendpointsCancelPurchaseIntentPath
 */
export interface AppApiPublicendpointsCancelPurchaseIntentPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiPublicendpointsCancelPurchaseIntentPath
     */
    'purchase_intent_id': string;
}
/**
 * 
 * @export
 * @interface AppApiPublicendpointsGetCollectionStatsPublicPath
 */
export interface AppApiPublicendpointsGetCollectionStatsPublicPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiPublicendpointsGetCollectionStatsPublicPath
     */
    'collection_id': string;
}
/**
 * 
 * @export
 * @interface AppApiPublicendpointsGetPublicCapabilityPath
 */
export interface AppApiPublicendpointsGetPublicCapabilityPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiPublicendpointsGetPublicCapabilityPath
     */
    'capability': string;
}
/**
 * 
 * @export
 * @interface AppApiPublicendpointsGetPublicItemHistoryPath
 */
export interface AppApiPublicendpointsGetPublicItemHistoryPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiPublicendpointsGetPublicItemHistoryPath
     */
    'item_id': string;
}
/**
 * 
 * @export
 * @interface AppApiPublicendpointsGetPublicItemOwnersPath
 */
export interface AppApiPublicendpointsGetPublicItemOwnersPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiPublicendpointsGetPublicItemOwnersPath
     */
    'item_id': string;
}
/**
 * 
 * @export
 * @interface AppApiPublicendpointsGetPublicTokenMetadataPath
 */
export interface AppApiPublicendpointsGetPublicTokenMetadataPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiPublicendpointsGetPublicTokenMetadataPath
     */
    'collection_id': string;
    /**
     * Uint256
     * @type {string}
     * @memberof AppApiPublicendpointsGetPublicTokenMetadataPath
     */
    'token_id': string;
}
/**
 * 
 * @export
 * @interface AppApiPublicendpointsPublicGetCollectionByIdPath
 */
export interface AppApiPublicendpointsPublicGetCollectionByIdPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiPublicendpointsPublicGetCollectionByIdPath
     */
    'collection_id': string;
}
/**
 * 
 * @export
 * @interface AppApiPublicendpointsPublicGetCollectionCapabilitiesByIdPath
 */
export interface AppApiPublicendpointsPublicGetCollectionCapabilitiesByIdPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiPublicendpointsPublicGetCollectionCapabilitiesByIdPath
     */
    'collection_id': string;
}
/**
 * 
 * @export
 * @interface AppApiPublicendpointsPublicGetContractMetadataPath
 */
export interface AppApiPublicendpointsPublicGetContractMetadataPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiPublicendpointsPublicGetContractMetadataPath
     */
    'contract_address': string;
    /**
     * Uint256
     * @type {string}
     * @memberof AppApiPublicendpointsPublicGetContractMetadataPath
     */
    'network_id': string;
}
/**
 * 
 * @export
 * @interface AppApiPublicendpointsPublicGetEmailClaimPath
 */
export interface AppApiPublicendpointsPublicGetEmailClaimPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiPublicendpointsPublicGetEmailClaimPath
     */
    'claim_token': string;
}
/**
 * 
 * @export
 * @interface AppApiPublicendpointsPublicGetItemPath
 */
export interface AppApiPublicendpointsPublicGetItemPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiPublicendpointsPublicGetItemPath
     */
    'item_id': string;
}
/**
 * 
 * @export
 * @interface AppApiPublicendpointsPublicGetListingPath
 */
export interface AppApiPublicendpointsPublicGetListingPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiPublicendpointsPublicGetListingPath
     */
    'listing_id': string;
}
/**
 * 
 * @export
 * @interface AppApiPublicendpointsPublicGetOrganizationsPath
 */
export interface AppApiPublicendpointsPublicGetOrganizationsPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiPublicendpointsPublicGetOrganizationsPath
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface AppApiPublicendpointsPublicGetPendingMintVouchersPath
 */
export interface AppApiPublicendpointsPublicGetPendingMintVouchersPath {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof AppApiPublicendpointsPublicGetPendingMintVouchersPath
     */
    'eth_address': string;
}
/**
 * 
 * @export
 * @interface AppApiPublicendpointsPublicRedeemEmailClaimPath
 */
export interface AppApiPublicendpointsPublicRedeemEmailClaimPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiPublicendpointsPublicRedeemEmailClaimPath
     */
    'claim_token': string;
}
/**
 * 
 * @export
 * @interface AppApiPublicendpointsVerifyEmailClaimPath
 */
export interface AppApiPublicendpointsVerifyEmailClaimPath {
    /**
     * 
     * @type {string}
     * @memberof AppApiPublicendpointsVerifyEmailClaimPath
     */
    'claim_token': string;
}
/**
 * 
 * @export
 * @interface AuthTypeForEmailInput
 */
export interface AuthTypeForEmailInput {
    /**
     * Email
     * @type {string}
     * @memberof AuthTypeForEmailInput
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface AuthTypeForEmailOutput
 */
export interface AuthTypeForEmailOutput {
    /**
     * 
     * @type {string}
     * @memberof AuthTypeForEmailOutput
     */
    'auth_type': string;
}
/**
 * 
 * @export
 * @interface CapabilitiesOutput
 */
export interface CapabilitiesOutput {
    /**
     * 
     * @type {Array<string>}
     * @memberof CapabilitiesOutput
     */
    'capabilities': Array<CapabilitiesOutputCapabilitiesEnum>;
}

export const CapabilitiesOutputCapabilitiesEnum = {
    AdminMintMultiple: 'ADMIN_MINT_MULTIPLE',
    AdminMintMultipleNoId: 'ADMIN_MINT_MULTIPLE_NO_ID',
    AdminMintSingle: 'ADMIN_MINT_SINGLE',
    AdminMintSingleUri: 'ADMIN_MINT_SINGLE_URI',
    AuctionV1: 'AUCTION_V1',
    BurnAmount: 'BURN_AMOUNT',
    BurnSingle: 'BURN_SINGLE',
    ContractNameSymbol: 'CONTRACT_NAME_SYMBOL',
    ContractUri: 'CONTRACT_URI',
    CreateForAdminMint: 'CREATE_FOR_ADMIN_MINT',
    CreateForAdminMintWithUri: 'CREATE_FOR_ADMIN_MINT_WITH_URI',
    DeprecatedAdminMint: 'DEPRECATED_ADMIN_MINT',
    DeprecatedManageSupply: 'DEPRECATED_MANAGE_SUPPLY',
    DeprecatedMintToAddressWithTokenUri: 'DEPRECATED_MINT_TO_ADDRESS_WITH_TOKEN_URI',
    DeprecatedDisperse: 'DEPRECATED_DISPERSE',
    Eip165: 'EIP165',
    Erc20: 'ERC20',
    Erc20Metadata: 'ERC20_METADATA',
    Erc721: 'ERC721',
    Erc721Metadata: 'ERC721_METADATA',
    Erc1155: 'ERC1155',
    Erc1155MetadataUri: 'ERC1155_METADATA_URI',
    Erc2981: 'ERC2981',
    ExchangeV1: 'EXCHANGE_V1',
    GrantRevokeMany: 'GRANT_REVOKE_MANY',
    IsMinter: 'IS_MINTER',
    MintMultipleTo: 'MINT_MULTIPLE_TO',
    MintToAddress: 'MINT_TO_ADDRESS',
    MintVoucher: 'MINT_VOUCHER',
    MintVoucherV2: 'MINT_VOUCHER_V2',
    OsRegistryFilter: 'OS_REGISTRY_FILTER',
    OsRegistryFilterToggle: 'OS_REGISTRY_FILTER_TOGGLE',
    OzAccessControl: 'OZ_ACCESS_CONTROL',
    OzOwnable: 'OZ_OWNABLE',
    OzOwnable2Step: 'OZ_OWNABLE_2_STEP',
    ProxyExecute: 'PROXY_EXECUTE',
    RestrictedAuctionV1: 'RESTRICTED_AUCTION_V1',
    SetBaseUri: 'SET_BASE_URI',
    SetBaseUriPermanent: 'SET_BASE_URI_PERMANENT',
    SetContractRoyalty: 'SET_CONTRACT_ROYALTY',
    SetMaxSupply: 'SET_MAX_SUPPLY',
    SetMaxSupplyPerToken: 'SET_MAX_SUPPLY_PER_TOKEN',
    SetTokenRoyalty: 'SET_TOKEN_ROYALTY',
    Soulbound: 'SOULBOUND',
    TokenMintPrices: 'TOKEN_MINT_PRICES',
    WithdrawTokensTo: 'WITHDRAW_TOKENS_TO',
    TokenSupplyView: 'TOKEN_SUPPLY_VIEW'
} as const;

export type CapabilitiesOutputCapabilitiesEnum = typeof CapabilitiesOutputCapabilitiesEnum[keyof typeof CapabilitiesOutputCapabilitiesEnum];

/**
 * 
 * @export
 * @interface CollectionStats
 */
export interface CollectionStats {
    /**
     * 
     * @type {Array<CollectionStatsAttributesInner>}
     * @memberof CollectionStats
     */
    'attributes': Array<CollectionStatsAttributesInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CollectionStats
     */
    'excluded': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CollectionStats
     */
    'tokens_indexed': number;
}
/**
 * 
 * @export
 * @interface CollectionStatsAttributesInner
 */
export interface CollectionStatsAttributesInner {
    /**
     * 
     * @type {number}
     * @memberof CollectionStatsAttributesInner
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof CollectionStatsAttributesInner
     */
    'name': string;
    /**
     * 
     * @type {Array<CollectionStatsAttributesInnerValuesInner>}
     * @memberof CollectionStatsAttributesInner
     */
    'values': Array<CollectionStatsAttributesInnerValuesInner>;
}
/**
 * 
 * @export
 * @interface CollectionStatsAttributesInnerValuesInner
 */
export interface CollectionStatsAttributesInnerValuesInner {
    /**
     * 
     * @type {number}
     * @memberof CollectionStatsAttributesInnerValuesInner
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof CollectionStatsAttributesInnerValuesInner
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreatePurchaseIntentInput
 */
export interface CreatePurchaseIntentInput {
    /**
     * 
     * @type {CreatePurchaseIntentInputBuyer}
     * @memberof CreatePurchaseIntentInput
     */
    'buyer': CreatePurchaseIntentInputBuyer;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseIntentInput
     */
    'listing_id': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseIntentInput
     */
    'provider': CreatePurchaseIntentInputProviderEnum;
    /**
     * 
     * @type {number}
     * @memberof CreatePurchaseIntentInput
     */
    'quantity': number;
}

export const CreatePurchaseIntentInputProviderEnum = {
    Stripe: 'STRIPE',
    MintVoucher: 'MINT_VOUCHER',
    EmailClaim: 'EMAIL_CLAIM',
    Organization: 'ORGANIZATION',
    BetaFreeMint: 'BETA_FREE_MINT'
} as const;

export type CreatePurchaseIntentInputProviderEnum = typeof CreatePurchaseIntentInputProviderEnum[keyof typeof CreatePurchaseIntentInputProviderEnum];

/**
 * 
 * @export
 * @interface CreatePurchaseIntentInputBuyer
 */
export interface CreatePurchaseIntentInputBuyer {
    /**
     * Email
     * @type {string}
     * @memberof CreatePurchaseIntentInputBuyer
     */
    'email'?: string | null;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof CreatePurchaseIntentInputBuyer
     */
    'eth_address'?: string | null;
}
/**
 * 
 * @export
 * @interface CreatePurchaseIntentOutput
 */
export interface CreatePurchaseIntentOutput {
    /**
     * 
     * @type {object}
     * @memberof CreatePurchaseIntentOutput
     */
    'data': object;
    /**
     * UtcDateTime
     * @type {string}
     * @memberof CreatePurchaseIntentOutput
     */
    'expires_at': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePurchaseIntentOutput
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface GetClaimOutput
 */
export interface GetClaimOutput {
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutput
     */
    'campaign_id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetClaimOutput
     */
    'is_active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetClaimOutput
     */
    'is_claimed': boolean;
    /**
     * 
     * @type {GetClaimOutputItem}
     * @memberof GetClaimOutput
     */
    'item': GetClaimOutputItem;
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutput
     */
    'organization_id': string;
    /**
     * 
     * @type {GetClaimOutputPublicItem}
     * @memberof GetClaimOutput
     */
    'public_item': GetClaimOutputPublicItem;
    /**
     * 
     * @type {number}
     * @memberof GetClaimOutput
     */
    'quantity': number;
    /**
     * 
     * @type {GetClaimOutputVoucher}
     * @memberof GetClaimOutput
     */
    'voucher'?: GetClaimOutputVoucher | null;
}
/**
 * 
 * @export
 * @interface GetClaimOutputItem
 */
export interface GetClaimOutputItem {
    /**
     * 
     * @type {object}
     * @memberof GetClaimOutputItem
     */
    'attributes': object;
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutputItem
     */
    'collection_id': string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutputItem
     */
    'id': string;
    /**
     * 
     * @type {GetClaimOutputItemListing}
     * @memberof GetClaimOutputItem
     */
    'listing'?: GetClaimOutputItemListing | null;
    /**
     * 
     * @type {GetClaimOutputItemMedia}
     * @memberof GetClaimOutputItem
     */
    'media': GetClaimOutputItemMedia;
    /**
     * 
     * @type {GetClaimOutputItemTokenContract}
     * @memberof GetClaimOutputItem
     */
    'token_contract': GetClaimOutputItemTokenContract;
    /**
     * Uint256
     * @type {string}
     * @memberof GetClaimOutputItem
     */
    'token_id': string;
}
/**
 * 
 * @export
 * @interface GetClaimOutputItemListing
 */
export interface GetClaimOutputItemListing {
    /**
     * 
     * @type {object}
     * @memberof GetClaimOutputItemListing
     */
    'data': object;
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutputItemListing
     */
    'type': GetClaimOutputItemListingTypeEnum;
}

export const GetClaimOutputItemListingTypeEnum = {
    SetPrice: 'SET_PRICE',
    OnchainEnglishAuction: 'ONCHAIN_ENGLISH_AUCTION',
    FiatSetPrice: 'FIAT_SET_PRICE',
    BuyerMint: 'BUYER_MINT',
    FiatLazyMint: 'FIAT_LAZY_MINT',
    Default: 'DEFAULT'
} as const;

export type GetClaimOutputItemListingTypeEnum = typeof GetClaimOutputItemListingTypeEnum[keyof typeof GetClaimOutputItemListingTypeEnum];

/**
 * 
 * @export
 * @interface GetClaimOutputItemMedia
 */
export interface GetClaimOutputItemMedia {
    /**
     * 
     * @type {GetClaimOutputItemMediaImage}
     * @memberof GetClaimOutputItemMedia
     */
    'image'?: GetClaimOutputItemMediaImage | null;
}
/**
 * 
 * @export
 * @interface GetClaimOutputItemMediaImage
 */
export interface GetClaimOutputItemMediaImage {
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutputItemMediaImage
     */
    'cid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutputItemMediaImage
     */
    'full'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutputItemMediaImage
     */
    'original'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutputItemMediaImage
     */
    'thumb'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutputItemMediaImage
     */
    'tiny'?: string | null;
}
/**
 * 
 * @export
 * @interface GetClaimOutputItemTokenContract
 */
export interface GetClaimOutputItemTokenContract {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof GetClaimOutputItemTokenContract
     */
    'address'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetClaimOutputItemTokenContract
     */
    'capabilities': Array<GetClaimOutputItemTokenContractCapabilitiesEnum>;
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutputItemTokenContract
     */
    'deploy_tx_id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetClaimOutputItemTokenContract
     */
    'network_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutputItemTokenContract
     */
    'symbol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutputItemTokenContract
     */
    'token_type': GetClaimOutputItemTokenContractTokenTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutputItemTokenContract
     */
    'tx_hash'?: string | null;
}

export const GetClaimOutputItemTokenContractCapabilitiesEnum = {
    AdminMintMultiple: 'ADMIN_MINT_MULTIPLE',
    AdminMintMultipleNoId: 'ADMIN_MINT_MULTIPLE_NO_ID',
    AdminMintSingle: 'ADMIN_MINT_SINGLE',
    AdminMintSingleUri: 'ADMIN_MINT_SINGLE_URI',
    AuctionV1: 'AUCTION_V1',
    BurnAmount: 'BURN_AMOUNT',
    BurnSingle: 'BURN_SINGLE',
    ContractNameSymbol: 'CONTRACT_NAME_SYMBOL',
    ContractUri: 'CONTRACT_URI',
    CreateForAdminMint: 'CREATE_FOR_ADMIN_MINT',
    CreateForAdminMintWithUri: 'CREATE_FOR_ADMIN_MINT_WITH_URI',
    DeprecatedAdminMint: 'DEPRECATED_ADMIN_MINT',
    DeprecatedManageSupply: 'DEPRECATED_MANAGE_SUPPLY',
    DeprecatedMintToAddressWithTokenUri: 'DEPRECATED_MINT_TO_ADDRESS_WITH_TOKEN_URI',
    DeprecatedDisperse: 'DEPRECATED_DISPERSE',
    Eip165: 'EIP165',
    Erc20: 'ERC20',
    Erc20Metadata: 'ERC20_METADATA',
    Erc721: 'ERC721',
    Erc721Metadata: 'ERC721_METADATA',
    Erc1155: 'ERC1155',
    Erc1155MetadataUri: 'ERC1155_METADATA_URI',
    Erc2981: 'ERC2981',
    ExchangeV1: 'EXCHANGE_V1',
    GrantRevokeMany: 'GRANT_REVOKE_MANY',
    IsMinter: 'IS_MINTER',
    MintMultipleTo: 'MINT_MULTIPLE_TO',
    MintToAddress: 'MINT_TO_ADDRESS',
    MintVoucher: 'MINT_VOUCHER',
    MintVoucherV2: 'MINT_VOUCHER_V2',
    OsRegistryFilter: 'OS_REGISTRY_FILTER',
    OsRegistryFilterToggle: 'OS_REGISTRY_FILTER_TOGGLE',
    OzAccessControl: 'OZ_ACCESS_CONTROL',
    OzOwnable: 'OZ_OWNABLE',
    OzOwnable2Step: 'OZ_OWNABLE_2_STEP',
    ProxyExecute: 'PROXY_EXECUTE',
    RestrictedAuctionV1: 'RESTRICTED_AUCTION_V1',
    SetBaseUri: 'SET_BASE_URI',
    SetBaseUriPermanent: 'SET_BASE_URI_PERMANENT',
    SetContractRoyalty: 'SET_CONTRACT_ROYALTY',
    SetMaxSupply: 'SET_MAX_SUPPLY',
    SetMaxSupplyPerToken: 'SET_MAX_SUPPLY_PER_TOKEN',
    SetTokenRoyalty: 'SET_TOKEN_ROYALTY',
    Soulbound: 'SOULBOUND',
    TokenMintPrices: 'TOKEN_MINT_PRICES',
    WithdrawTokensTo: 'WITHDRAW_TOKENS_TO',
    TokenSupplyView: 'TOKEN_SUPPLY_VIEW'
} as const;

export type GetClaimOutputItemTokenContractCapabilitiesEnum = typeof GetClaimOutputItemTokenContractCapabilitiesEnum[keyof typeof GetClaimOutputItemTokenContractCapabilitiesEnum];
export const GetClaimOutputItemTokenContractTokenTypeEnum = {
    Erc721: 'ERC721',
    Erc1155: 'ERC1155'
} as const;

export type GetClaimOutputItemTokenContractTokenTypeEnum = typeof GetClaimOutputItemTokenContractTokenTypeEnum[keyof typeof GetClaimOutputItemTokenContractTokenTypeEnum];

/**
 * 
 * @export
 * @interface GetClaimOutputPublicItem
 */
export interface GetClaimOutputPublicItem {
    /**
     * 
     * @type {object}
     * @memberof GetClaimOutputPublicItem
     */
    'attributes': object;
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutputPublicItem
     */
    'collection_id': string;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof GetClaimOutputPublicItem
     */
    'contract_address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutputPublicItem
     */
    'id': string;
    /**
     * 
     * @type {GetClaimOutputItemMedia}
     * @memberof GetClaimOutputPublicItem
     */
    'media': GetClaimOutputItemMedia;
    /**
     * 
     * @type {number}
     * @memberof GetClaimOutputPublicItem
     */
    'network_id': number;
    /**
     * Uint256
     * @type {string}
     * @memberof GetClaimOutputPublicItem
     */
    'token_id': string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutputPublicItem
     */
    'token_type': GetClaimOutputPublicItemTokenTypeEnum;
}

export const GetClaimOutputPublicItemTokenTypeEnum = {
    Erc721: 'ERC721',
    Erc1155: 'ERC1155'
} as const;

export type GetClaimOutputPublicItemTokenTypeEnum = typeof GetClaimOutputPublicItemTokenTypeEnum[keyof typeof GetClaimOutputPublicItemTokenTypeEnum];

/**
 * 
 * @export
 * @interface GetClaimOutputVoucher
 */
export interface GetClaimOutputVoucher {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof GetClaimOutputVoucher
     */
    'contract': string;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof GetClaimOutputVoucher
     */
    'minter': string;
    /**
     * 
     * @type {number}
     * @memberof GetClaimOutputVoucher
     */
    'network_id': number;
    /**
     * HexString
     * @type {string}
     * @memberof GetClaimOutputVoucher
     */
    'signature': string;
    /**
     * 
     * @type {GetClaimOutputVoucherVoucher}
     * @memberof GetClaimOutputVoucher
     */
    'voucher': GetClaimOutputVoucherVoucher;
}
/**
 * 
 * @export
 * @interface GetClaimOutputVoucherVoucher
 */
export interface GetClaimOutputVoucherVoucher {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof GetClaimOutputVoucherVoucher
     */
    'currency': string;
    /**
     * 
     * @type {number}
     * @memberof GetClaimOutputVoucherVoucher
     */
    'expiry': number;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof GetClaimOutputVoucherVoucher
     */
    'initial_recipient': string;
    /**
     * Uint256
     * @type {string}
     * @memberof GetClaimOutputVoucherVoucher
     */
    'initial_recipient_amount': string;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof GetClaimOutputVoucherVoucher
     */
    'net_recipient': string;
    /**
     * 
     * @type {number}
     * @memberof GetClaimOutputVoucherVoucher
     */
    'nonce': number;
    /**
     * Uint256
     * @type {string}
     * @memberof GetClaimOutputVoucherVoucher
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof GetClaimOutputVoucherVoucher
     */
    'quantity': number;
    /**
     * Uint256
     * @type {string}
     * @memberof GetClaimOutputVoucherVoucher
     */
    'token_id': string;
    /**
     * 
     * @type {string}
     * @memberof GetClaimOutputVoucherVoucher
     */
    'token_uri'?: string | null;
}
/**
 * 
 * @export
 * @interface GetContractMetadataOutput
 */
export interface GetContractMetadataOutput {
    /**
     * 
     * @type {string}
     * @memberof GetContractMetadataOutput
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetContractMetadataOutput
     */
    'external_link'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetContractMetadataOutput
     */
    'image'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetContractMetadataOutput
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GetOrganizationByEmailOutputList
 */
export interface GetOrganizationByEmailOutputList {
    /**
     * 
     * @type {Array<object>}
     * @memberof GetOrganizationByEmailOutputList
     */
    'results': Array<object>;
}
/**
 * 
 * @export
 * @interface GetPublicOrgItemsPaginatedInput
 */
export interface GetPublicOrgItemsPaginatedInput {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPublicOrgItemsPaginatedInput
     */
    'attributes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetPublicOrgItemsPaginatedInput
     */
    'collection_ids'?: Array<string> | null;
    /**
     * SafeString
     * @type {string}
     * @memberof GetPublicOrgItemsPaginatedInput
     */
    'cursor'?: string | null;
    /**
     * MediumPaginationLimit
     * @type {number}
     * @memberof GetPublicOrgItemsPaginatedInput
     */
    'limit'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetPublicOrgItemsPaginatedInput
     */
    'network_ids'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof GetPublicOrgItemsPaginatedInput
     */
    'order_direction'?: GetPublicOrgItemsPaginatedInputOrderDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof GetPublicOrgItemsPaginatedInput
     */
    'organization_id'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetPublicOrgItemsPaginatedInput
     */
    'token_ids'?: Array<number> | null;
}

export const GetPublicOrgItemsPaginatedInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetPublicOrgItemsPaginatedInputOrderDirectionEnum = typeof GetPublicOrgItemsPaginatedInputOrderDirectionEnum[keyof typeof GetPublicOrgItemsPaginatedInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetPublicOrgItemsPaginatedOutput
 */
export interface GetPublicOrgItemsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof GetPublicOrgItemsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetPublicOrgItemsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<GetClaimOutputPublicItem>}
     * @memberof GetPublicOrgItemsPaginatedOutput
     */
    'results': Array<GetClaimOutputPublicItem>;
    /**
     * 
     * @type {number}
     * @memberof GetPublicOrgItemsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface GetVouchersPaginatedInput
 */
export interface GetVouchersPaginatedInput {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof GetVouchersPaginatedInput
     */
    'contract_address'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof GetVouchersPaginatedInput
     */
    'cursor'?: string | null;
    /**
     * MediumPaginationLimit
     * @type {number}
     * @memberof GetVouchersPaginatedInput
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetVouchersPaginatedInput
     */
    'network_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetVouchersPaginatedInput
     */
    'order_direction'?: GetVouchersPaginatedInputOrderDirectionEnum;
}

export const GetVouchersPaginatedInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GetVouchersPaginatedInputOrderDirectionEnum = typeof GetVouchersPaginatedInputOrderDirectionEnum[keyof typeof GetVouchersPaginatedInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface GetVouchersPaginatedOutput
 */
export interface GetVouchersPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof GetVouchersPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetVouchersPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<GetVouchersPaginatedOutputResultsInner>}
     * @memberof GetVouchersPaginatedOutput
     */
    'results': Array<GetVouchersPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof GetVouchersPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface GetVouchersPaginatedOutputResultsInner
 */
export interface GetVouchersPaginatedOutputResultsInner {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof GetVouchersPaginatedOutputResultsInner
     */
    'contract': string;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof GetVouchersPaginatedOutputResultsInner
     */
    'minter': string;
    /**
     * 
     * @type {number}
     * @memberof GetVouchersPaginatedOutputResultsInner
     */
    'network_id': number;
    /**
     * HexString
     * @type {string}
     * @memberof GetVouchersPaginatedOutputResultsInner
     */
    'signature': string;
    /**
     * 
     * @type {GetClaimOutputVoucherVoucher}
     * @memberof GetVouchersPaginatedOutputResultsInner
     */
    'voucher': GetClaimOutputVoucherVoucher;
}
/**
 * 
 * @export
 * @interface PublicCollectionOutput
 */
export interface PublicCollectionOutput {
    /**
     * 
     * @type {string}
     * @memberof PublicCollectionOutput
     */
    'base_uri'?: string | null;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof PublicCollectionOutput
     */
    'contract': string;
    /**
     * 
     * @type {string}
     * @memberof PublicCollectionOutput
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicCollectionOutput
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PublicCollectionOutput
     */
    'max_supply'?: number | null;
    /**
     * 
     * @type {PublicCollectionOutputMedia}
     * @memberof PublicCollectionOutput
     */
    'media'?: PublicCollectionOutputMedia | null;
    /**
     * 
     * @type {string}
     * @memberof PublicCollectionOutput
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PublicCollectionOutput
     */
    'network_id': number;
    /**
     * 
     * @type {boolean}
     * @memberof PublicCollectionOutput
     */
    'soulbound': boolean;
}
/**
 * 
 * @export
 * @interface PublicCollectionOutputMedia
 */
export interface PublicCollectionOutputMedia {
    /**
     * 
     * @type {string}
     * @memberof PublicCollectionOutputMedia
     */
    'header_image_url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicCollectionOutputMedia
     */
    'thumbnail_image_url'?: string | null;
}
/**
 * 
 * @export
 * @interface PublicCollectionsPaginatedInput
 */
export interface PublicCollectionsPaginatedInput {
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicCollectionsPaginatedInput
     */
    'collection_id'?: Array<string> | null;
    /**
     * SafeString
     * @type {string}
     * @memberof PublicCollectionsPaginatedInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof PublicCollectionsPaginatedInput
     */
    'limit'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PublicCollectionsPaginatedInput
     */
    'network_id'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof PublicCollectionsPaginatedInput
     */
    'order_direction'?: PublicCollectionsPaginatedInputOrderDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicCollectionsPaginatedInput
     */
    'organization_id': string;
}

export const PublicCollectionsPaginatedInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type PublicCollectionsPaginatedInputOrderDirectionEnum = typeof PublicCollectionsPaginatedInputOrderDirectionEnum[keyof typeof PublicCollectionsPaginatedInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface PublicCollectionsPaginatedOutput
 */
export interface PublicCollectionsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof PublicCollectionsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PublicCollectionsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<PublicCollectionsPaginatedOutputResultsInner>}
     * @memberof PublicCollectionsPaginatedOutput
     */
    'results': Array<PublicCollectionsPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof PublicCollectionsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface PublicCollectionsPaginatedOutputResultsInner
 */
export interface PublicCollectionsPaginatedOutputResultsInner {
    /**
     * 
     * @type {string}
     * @memberof PublicCollectionsPaginatedOutputResultsInner
     */
    'base_uri'?: string | null;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof PublicCollectionsPaginatedOutputResultsInner
     */
    'contract': string;
    /**
     * 
     * @type {string}
     * @memberof PublicCollectionsPaginatedOutputResultsInner
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicCollectionsPaginatedOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof PublicCollectionsPaginatedOutputResultsInner
     */
    'max_supply'?: number | null;
    /**
     * 
     * @type {PublicCollectionOutputMedia}
     * @memberof PublicCollectionsPaginatedOutputResultsInner
     */
    'media'?: PublicCollectionOutputMedia | null;
    /**
     * 
     * @type {string}
     * @memberof PublicCollectionsPaginatedOutputResultsInner
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PublicCollectionsPaginatedOutputResultsInner
     */
    'network_id': number;
    /**
     * 
     * @type {boolean}
     * @memberof PublicCollectionsPaginatedOutputResultsInner
     */
    'soulbound': boolean;
}
/**
 * 
 * @export
 * @interface PublicGetCapabilitiesOutput
 */
export interface PublicGetCapabilitiesOutput {
    /**
     * 
     * @type {Array<object>}
     * @memberof PublicGetCapabilitiesOutput
     */
    'abi': Array<object>;
    /**
     * 
     * @type {string}
     * @memberof PublicGetCapabilitiesOutput
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PublicGetCapabilitiesPaginatedOutput
 */
export interface PublicGetCapabilitiesPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof PublicGetCapabilitiesPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PublicGetCapabilitiesPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<PublicGetCapabilitiesPaginatedOutputResultsInner>}
     * @memberof PublicGetCapabilitiesPaginatedOutput
     */
    'results': Array<PublicGetCapabilitiesPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof PublicGetCapabilitiesPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface PublicGetCapabilitiesPaginatedOutputResultsInner
 */
export interface PublicGetCapabilitiesPaginatedOutputResultsInner {
    /**
     * 
     * @type {Array<object>}
     * @memberof PublicGetCapabilitiesPaginatedOutputResultsInner
     */
    'abi': Array<object>;
    /**
     * 
     * @type {string}
     * @memberof PublicGetCapabilitiesPaginatedOutputResultsInner
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PublicGetContractsPaginatedOutput
 */
export interface PublicGetContractsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof PublicGetContractsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PublicGetContractsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<PublicGetContractsPaginatedOutputResultsInner>}
     * @memberof PublicGetContractsPaginatedOutput
     */
    'results': Array<PublicGetContractsPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof PublicGetContractsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface PublicGetContractsPaginatedOutputResultsInner
 */
export interface PublicGetContractsPaginatedOutputResultsInner {
    /**
     * 
     * @type {string}
     * @memberof PublicGetContractsPaginatedOutputResultsInner
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof PublicGetContractsPaginatedOutputResultsInner
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PublicGetContractsPaginatedOutputResultsInner
     */
    'network_id': number;
}
/**
 * 
 * @export
 * @interface PublicGetItemHistoryPaginatedInput
 */
export interface PublicGetItemHistoryPaginatedInput {
    /**
     * SafeString
     * @type {string}
     * @memberof PublicGetItemHistoryPaginatedInput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicGetItemHistoryPaginatedInput
     */
    'event_type'?: PublicGetItemHistoryPaginatedInputEventTypeEnum;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof PublicGetItemHistoryPaginatedInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof PublicGetItemHistoryPaginatedInput
     */
    'order_direction'?: PublicGetItemHistoryPaginatedInputOrderDirectionEnum;
}

export const PublicGetItemHistoryPaginatedInputEventTypeEnum = {
    Mint: 'MINT',
    Transfer: 'TRANSFER',
    Burn: 'BURN'
} as const;

export type PublicGetItemHistoryPaginatedInputEventTypeEnum = typeof PublicGetItemHistoryPaginatedInputEventTypeEnum[keyof typeof PublicGetItemHistoryPaginatedInputEventTypeEnum];
export const PublicGetItemHistoryPaginatedInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type PublicGetItemHistoryPaginatedInputOrderDirectionEnum = typeof PublicGetItemHistoryPaginatedInputOrderDirectionEnum[keyof typeof PublicGetItemHistoryPaginatedInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface PublicGetItemHistoryPaginatedOutput
 */
export interface PublicGetItemHistoryPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof PublicGetItemHistoryPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PublicGetItemHistoryPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<PublicGetItemHistoryPaginatedOutputResultsInner>}
     * @memberof PublicGetItemHistoryPaginatedOutput
     */
    'results': Array<PublicGetItemHistoryPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof PublicGetItemHistoryPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface PublicGetItemHistoryPaginatedOutputResultsInner
 */
export interface PublicGetItemHistoryPaginatedOutputResultsInner {
    /**
     * 
     * @type {object}
     * @memberof PublicGetItemHistoryPaginatedOutputResultsInner
     */
    'data': object;
    /**
     * 
     * @type {number}
     * @memberof PublicGetItemHistoryPaginatedOutputResultsInner
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof PublicGetItemHistoryPaginatedOutputResultsInner
     */
    'timestamp': string;
    /**
     * 
     * @type {string}
     * @memberof PublicGetItemHistoryPaginatedOutputResultsInner
     */
    'type': PublicGetItemHistoryPaginatedOutputResultsInnerTypeEnum;
}

export const PublicGetItemHistoryPaginatedOutputResultsInnerTypeEnum = {
    Mint: 'MINT',
    Transfer: 'TRANSFER',
    Burn: 'BURN',
    AuctionBid: 'AUCTION_BID',
    Sale: 'SALE'
} as const;

export type PublicGetItemHistoryPaginatedOutputResultsInnerTypeEnum = typeof PublicGetItemHistoryPaginatedOutputResultsInnerTypeEnum[keyof typeof PublicGetItemHistoryPaginatedOutputResultsInnerTypeEnum];

/**
 * 
 * @export
 * @interface PublicGetPaginatedContractsInput
 */
export interface PublicGetPaginatedContractsInput {
    /**
     * SafeString
     * @type {string}
     * @memberof PublicGetPaginatedContractsInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof PublicGetPaginatedContractsInput
     */
    'limit'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PublicGetPaginatedContractsInput
     */
    'network_ids': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PublicGetPaginatedContractsInput
     */
    'order_direction'?: PublicGetPaginatedContractsInputOrderDirectionEnum;
}

export const PublicGetPaginatedContractsInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type PublicGetPaginatedContractsInputOrderDirectionEnum = typeof PublicGetPaginatedContractsInputOrderDirectionEnum[keyof typeof PublicGetPaginatedContractsInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface PublicItem
 */
export interface PublicItem {
    /**
     * 
     * @type {object}
     * @memberof PublicItem
     */
    'attributes': object;
    /**
     * 
     * @type {string}
     * @memberof PublicItem
     */
    'collection_id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicItem
     */
    'id': string;
    /**
     * 
     * @type {GetClaimOutputItemListing}
     * @memberof PublicItem
     */
    'listing'?: GetClaimOutputItemListing | null;
    /**
     * 
     * @type {GetClaimOutputItemMedia}
     * @memberof PublicItem
     */
    'media': GetClaimOutputItemMedia;
    /**
     * 
     * @type {GetClaimOutputItemTokenContract}
     * @memberof PublicItem
     */
    'token_contract': GetClaimOutputItemTokenContract;
    /**
     * Uint256
     * @type {string}
     * @memberof PublicItem
     */
    'token_id': string;
}
/**
 * 
 * @export
 * @interface PublicItemOutput
 */
export interface PublicItemOutput {
    /**
     * 
     * @type {object}
     * @memberof PublicItemOutput
     */
    'attributes': object;
    /**
     * 
     * @type {string}
     * @memberof PublicItemOutput
     */
    'collection_id': string;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof PublicItemOutput
     */
    'contract_address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicItemOutput
     */
    'id': string;
    /**
     * 
     * @type {GetClaimOutputItemMedia}
     * @memberof PublicItemOutput
     */
    'media': GetClaimOutputItemMedia;
    /**
     * 
     * @type {number}
     * @memberof PublicItemOutput
     */
    'network_id': number;
    /**
     * Uint256
     * @type {string}
     * @memberof PublicItemOutput
     */
    'token_id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicItemOutput
     */
    'token_type': PublicItemOutputTokenTypeEnum;
}

export const PublicItemOutputTokenTypeEnum = {
    Erc721: 'ERC721',
    Erc1155: 'ERC1155'
} as const;

export type PublicItemOutputTokenTypeEnum = typeof PublicItemOutputTokenTypeEnum[keyof typeof PublicItemOutputTokenTypeEnum];

/**
 * 
 * @export
 * @interface PublicItemOwnersPaginatedInput
 */
export interface PublicItemOwnersPaginatedInput {
    /**
     * SafeString
     * @type {string}
     * @memberof PublicItemOwnersPaginatedInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof PublicItemOwnersPaginatedInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof PublicItemOwnersPaginatedInput
     */
    'order_direction'?: PublicItemOwnersPaginatedInputOrderDirectionEnum;
}

export const PublicItemOwnersPaginatedInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type PublicItemOwnersPaginatedInputOrderDirectionEnum = typeof PublicItemOwnersPaginatedInputOrderDirectionEnum[keyof typeof PublicItemOwnersPaginatedInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface PublicItemOwnersPaginatedOutput
 */
export interface PublicItemOwnersPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof PublicItemOwnersPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PublicItemOwnersPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<PublicItemOwnersPaginatedOutputResultsInner>}
     * @memberof PublicItemOwnersPaginatedOutput
     */
    'results': Array<PublicItemOwnersPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof PublicItemOwnersPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface PublicItemOwnersPaginatedOutputResultsInner
 */
export interface PublicItemOwnersPaginatedOutputResultsInner {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof PublicItemOwnersPaginatedOutputResultsInner
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof PublicItemOwnersPaginatedOutputResultsInner
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface PublicListingOutput
 */
export interface PublicListingOutput {
    /**
     * 
     * @type {string}
     * @memberof PublicListingOutput
     */
    'campaign_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicListingOutput
     */
    'collection_id'?: string | null;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof PublicListingOutput
     */
    'contract': string;
    /**
     * 
     * @type {string}
     * @memberof PublicListingOutput
     */
    'currency': PublicListingOutputCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicListingOutput
     */
    'end_time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicListingOutput
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicListingOutput
     */
    'item_id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PublicListingOutput
     */
    'max_per_user'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PublicListingOutput
     */
    'max_quantity_per_tx': number;
    /**
     * 
     * @type {number}
     * @memberof PublicListingOutput
     */
    'network_id': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicListingOutput
     */
    'payment_providers': Array<PublicListingOutputPaymentProvidersEnum>;
    /**
     * 
     * @type {string}
     * @memberof PublicListingOutput
     */
    'policy_type'?: PublicListingOutputPolicyTypeEnum;
    /**
     * Uint256
     * @type {string}
     * @memberof PublicListingOutput
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof PublicListingOutput
     */
    'quantity_listed': number;
    /**
     * 
     * @type {number}
     * @memberof PublicListingOutput
     */
    'quantity_remaining': number;
    /**
     * 
     * @type {string}
     * @memberof PublicListingOutput
     */
    'start_time'?: string | null;
}

export const PublicListingOutputCurrencyEnum = {
    Usd: 'USD',
    Eth: 'ETH',
    Erc20: 'ERC20',
    Eur: 'EUR'
} as const;

export type PublicListingOutputCurrencyEnum = typeof PublicListingOutputCurrencyEnum[keyof typeof PublicListingOutputCurrencyEnum];
export const PublicListingOutputPaymentProvidersEnum = {
    Stripe: 'STRIPE',
    MintVoucher: 'MINT_VOUCHER',
    EmailClaim: 'EMAIL_CLAIM',
    Organization: 'ORGANIZATION',
    BetaFreeMint: 'BETA_FREE_MINT'
} as const;

export type PublicListingOutputPaymentProvidersEnum = typeof PublicListingOutputPaymentProvidersEnum[keyof typeof PublicListingOutputPaymentProvidersEnum];
export const PublicListingOutputPolicyTypeEnum = {
    AllowList: 'ALLOW_LIST',
    Fcfs: 'FCFS',
    LargeAllowList: 'LARGE_ALLOW_LIST'
} as const;

export type PublicListingOutputPolicyTypeEnum = typeof PublicListingOutputPolicyTypeEnum[keyof typeof PublicListingOutputPolicyTypeEnum];

/**
 * 
 * @export
 * @interface PublicListingsPaginatedInput
 */
export interface PublicListingsPaginatedInput {
    /**
     * 
     * @type {string}
     * @memberof PublicListingsPaginatedInput
     */
    'campaign_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicListingsPaginatedInput
     */
    'collection_id'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof PublicListingsPaginatedInput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicListingsPaginatedInput
     */
    'item_id'?: string | null;
    /**
     * MediumPaginationLimit
     * @type {number}
     * @memberof PublicListingsPaginatedInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof PublicListingsPaginatedInput
     */
    'order_direction'?: PublicListingsPaginatedInputOrderDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicListingsPaginatedInput
     */
    'organization_id': string;
}

export const PublicListingsPaginatedInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type PublicListingsPaginatedInputOrderDirectionEnum = typeof PublicListingsPaginatedInputOrderDirectionEnum[keyof typeof PublicListingsPaginatedInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface PublicListingsPaginatedOutput
 */
export interface PublicListingsPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof PublicListingsPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PublicListingsPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<PublicListingsPaginatedOutputResultsInner>}
     * @memberof PublicListingsPaginatedOutput
     */
    'results': Array<PublicListingsPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof PublicListingsPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface PublicListingsPaginatedOutputResultsInner
 */
export interface PublicListingsPaginatedOutputResultsInner {
    /**
     * 
     * @type {string}
     * @memberof PublicListingsPaginatedOutputResultsInner
     */
    'campaign_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicListingsPaginatedOutputResultsInner
     */
    'collection_id'?: string | null;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof PublicListingsPaginatedOutputResultsInner
     */
    'contract': string;
    /**
     * 
     * @type {string}
     * @memberof PublicListingsPaginatedOutputResultsInner
     */
    'currency': PublicListingsPaginatedOutputResultsInnerCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicListingsPaginatedOutputResultsInner
     */
    'end_time'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicListingsPaginatedOutputResultsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PublicListingsPaginatedOutputResultsInner
     */
    'item_id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PublicListingsPaginatedOutputResultsInner
     */
    'max_per_user'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PublicListingsPaginatedOutputResultsInner
     */
    'max_quantity_per_tx': number;
    /**
     * 
     * @type {number}
     * @memberof PublicListingsPaginatedOutputResultsInner
     */
    'network_id': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicListingsPaginatedOutputResultsInner
     */
    'payment_providers': Array<PublicListingsPaginatedOutputResultsInnerPaymentProvidersEnum>;
    /**
     * 
     * @type {string}
     * @memberof PublicListingsPaginatedOutputResultsInner
     */
    'policy_type'?: PublicListingsPaginatedOutputResultsInnerPolicyTypeEnum;
    /**
     * Uint256
     * @type {string}
     * @memberof PublicListingsPaginatedOutputResultsInner
     */
    'price': string;
    /**
     * 
     * @type {number}
     * @memberof PublicListingsPaginatedOutputResultsInner
     */
    'quantity_listed': number;
    /**
     * 
     * @type {number}
     * @memberof PublicListingsPaginatedOutputResultsInner
     */
    'quantity_remaining': number;
    /**
     * 
     * @type {string}
     * @memberof PublicListingsPaginatedOutputResultsInner
     */
    'start_time'?: string | null;
}

export const PublicListingsPaginatedOutputResultsInnerCurrencyEnum = {
    Usd: 'USD',
    Eth: 'ETH',
    Erc20: 'ERC20',
    Eur: 'EUR'
} as const;

export type PublicListingsPaginatedOutputResultsInnerCurrencyEnum = typeof PublicListingsPaginatedOutputResultsInnerCurrencyEnum[keyof typeof PublicListingsPaginatedOutputResultsInnerCurrencyEnum];
export const PublicListingsPaginatedOutputResultsInnerPaymentProvidersEnum = {
    Stripe: 'STRIPE',
    MintVoucher: 'MINT_VOUCHER',
    EmailClaim: 'EMAIL_CLAIM',
    Organization: 'ORGANIZATION',
    BetaFreeMint: 'BETA_FREE_MINT'
} as const;

export type PublicListingsPaginatedOutputResultsInnerPaymentProvidersEnum = typeof PublicListingsPaginatedOutputResultsInnerPaymentProvidersEnum[keyof typeof PublicListingsPaginatedOutputResultsInnerPaymentProvidersEnum];
export const PublicListingsPaginatedOutputResultsInnerPolicyTypeEnum = {
    AllowList: 'ALLOW_LIST',
    Fcfs: 'FCFS',
    LargeAllowList: 'LARGE_ALLOW_LIST'
} as const;

export type PublicListingsPaginatedOutputResultsInnerPolicyTypeEnum = typeof PublicListingsPaginatedOutputResultsInnerPolicyTypeEnum[keyof typeof PublicListingsPaginatedOutputResultsInnerPolicyTypeEnum];

/**
 * 
 * @export
 * @interface PublicListingsRedemptionEligibilityInput
 */
export interface PublicListingsRedemptionEligibilityInput {
    /**
     * SafeString
     * @type {string}
     * @memberof PublicListingsRedemptionEligibilityInput
     */
    'email_address'?: string | null;
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof PublicListingsRedemptionEligibilityInput
     */
    'eth_address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PublicListingsRedemptionEligibilityInput
     */
    'listing_id': string;
}
/**
 * 
 * @export
 * @interface PublicListingsRedemptionEligibilityOutput
 */
export interface PublicListingsRedemptionEligibilityOutput {
    /**
     * 
     * @type {boolean}
     * @memberof PublicListingsRedemptionEligibilityOutput
     */
    'is_eligible'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PublicListingsRedemptionEligibilityOutput
     */
    'quantity_allowed': string;
    /**
     * 
     * @type {string}
     * @memberof PublicListingsRedemptionEligibilityOutput
     */
    'quantity_claimed': string;
}
/**
 * 
 * @export
 * @interface PublicNetworkPaymentTokensPaginatedInput
 */
export interface PublicNetworkPaymentTokensPaginatedInput {
    /**
     * SafeString
     * @type {string}
     * @memberof PublicNetworkPaymentTokensPaginatedInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof PublicNetworkPaymentTokensPaginatedInput
     */
    'limit'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PublicNetworkPaymentTokensPaginatedInput
     */
    'network_id': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PublicNetworkPaymentTokensPaginatedInput
     */
    'order_direction'?: PublicNetworkPaymentTokensPaginatedInputOrderDirectionEnum;
}

export const PublicNetworkPaymentTokensPaginatedInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type PublicNetworkPaymentTokensPaginatedInputOrderDirectionEnum = typeof PublicNetworkPaymentTokensPaginatedInputOrderDirectionEnum[keyof typeof PublicNetworkPaymentTokensPaginatedInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface PublicNetworkPaymentTokensPaginatedOutput
 */
export interface PublicNetworkPaymentTokensPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof PublicNetworkPaymentTokensPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PublicNetworkPaymentTokensPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<PublicNetworkPaymentTokensPaginatedOutputResultsInner>}
     * @memberof PublicNetworkPaymentTokensPaginatedOutput
     */
    'results': Array<PublicNetworkPaymentTokensPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof PublicNetworkPaymentTokensPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface PublicNetworkPaymentTokensPaginatedOutputResultsInner
 */
export interface PublicNetworkPaymentTokensPaginatedOutputResultsInner {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
     */
    'decimals': number;
    /**
     * 
     * @type {boolean}
     * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
     */
    'eth'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
     */
    'eur_stablecoin'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
     */
    'network_id': number;
    /**
     * 
     * @type {string}
     * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
     */
    'symbol': string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicNetworkPaymentTokensPaginatedOutputResultsInner
     */
    'usd_stablecoin'?: boolean;
}
/**
 * 
 * @export
 * @interface PublicNetworksPaginatedInput
 */
export interface PublicNetworksPaginatedInput {
    /**
     * SafeString
     * @type {string}
     * @memberof PublicNetworksPaginatedInput
     */
    'cursor'?: string | null;
    /**
     * PaginationLimit
     * @type {number}
     * @memberof PublicNetworksPaginatedInput
     */
    'limit'?: number;
    /**
     * 
     * @type {string}
     * @memberof PublicNetworksPaginatedInput
     */
    'order_direction'?: PublicNetworksPaginatedInputOrderDirectionEnum;
}

export const PublicNetworksPaginatedInputOrderDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type PublicNetworksPaginatedInputOrderDirectionEnum = typeof PublicNetworksPaginatedInputOrderDirectionEnum[keyof typeof PublicNetworksPaginatedInputOrderDirectionEnum];

/**
 * 
 * @export
 * @interface PublicNetworksPaginatedOutput
 */
export interface PublicNetworksPaginatedOutput {
    /**
     * 
     * @type {string}
     * @memberof PublicNetworksPaginatedOutput
     */
    'cursor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PublicNetworksPaginatedOutput
     */
    'has_more': boolean;
    /**
     * 
     * @type {Array<PublicNetworksPaginatedOutputResultsInner>}
     * @memberof PublicNetworksPaginatedOutput
     */
    'results': Array<PublicNetworksPaginatedOutputResultsInner>;
    /**
     * 
     * @type {number}
     * @memberof PublicNetworksPaginatedOutput
     */
    'total_results': number;
}
/**
 * 
 * @export
 * @interface PublicNetworksPaginatedOutputResultsInner
 */
export interface PublicNetworksPaginatedOutputResultsInner {
    /**
     * 
     * @type {number}
     * @memberof PublicNetworksPaginatedOutputResultsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PublicNetworksPaginatedOutputResultsInner
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicNetworksPaginatedOutputResultsInner
     */
    'testnet': boolean;
}
/**
 * 
 * @export
 * @interface RedeemEmailClaimApiInput
 */
export interface RedeemEmailClaimApiInput {
    /**
     * ChecksumAddress
     * @type {string}
     * @memberof RedeemEmailClaimApiInput
     */
    'address'?: string | null;
    /**
     * SafeString
     * @type {string}
     * @memberof RedeemEmailClaimApiInput
     */
    'pin'?: string | null;
}
/**
 * 
 * @export
 * @interface RedeemEmailClaimOutput
 */
export interface RedeemEmailClaimOutput {
    /**
     * 
     * @type {string}
     * @memberof RedeemEmailClaimOutput
     */
    'status': string;
    /**
     * 
     * @type {GetClaimOutputVoucher}
     * @memberof RedeemEmailClaimOutput
     */
    'voucher'?: GetClaimOutputVoucher | null;
}
/**
 * 
 * @export
 * @interface StatusOutput
 */
export interface StatusOutput {
    /**
     * 
     * @type {string}
     * @memberof StatusOutput
     */
    'app_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatusOutput
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface VerificationEmailAuth0Input
 */
export interface VerificationEmailAuth0Input {
    /**
     * SafeString
     * @type {string}
     * @memberof VerificationEmailAuth0Input
     */
    'auth0_id': string;
    /**
     * SafeString
     * @type {string}
     * @memberof VerificationEmailAuth0Input
     */
    'client_id'?: string | null;
}
/**
 * 
 * @export
 * @interface VerifyAuth0UserInput
 */
export interface VerifyAuth0UserInput {
    /**
     * 
     * @type {string}
     * @memberof VerifyAuth0UserInput
     */
    'application_type'?: VerifyAuth0UserInputApplicationTypeEnum;
    /**
     * 
     * @type {VerifyAuth0UserInputIdentity}
     * @memberof VerifyAuth0UserInput
     */
    'identity': VerifyAuth0UserInputIdentity;
    /**
     * SafeString
     * @type {string}
     * @memberof VerifyAuth0UserInput
     */
    'invitation_token'?: string | null;
}

export const VerifyAuth0UserInputApplicationTypeEnum = {
    DevUi: 'DEV_UI',
    Portal: 'PORTAL',
    Crumb: 'CRUMB'
} as const;

export type VerifyAuth0UserInputApplicationTypeEnum = typeof VerifyAuth0UserInputApplicationTypeEnum[keyof typeof VerifyAuth0UserInputApplicationTypeEnum];

/**
 * 
 * @export
 * @interface VerifyAuth0UserInputIdentity
 */
export interface VerifyAuth0UserInputIdentity {
    /**
     * SafeString
     * @type {string}
     * @memberof VerifyAuth0UserInputIdentity
     */
    'auth0_id': string;
    /**
     * SafeString
     * @type {string}
     * @memberof VerifyAuth0UserInputIdentity
     */
    'email': string;
    /**
     * SafeString
     * @type {string}
     * @memberof VerifyAuth0UserInputIdentity
     */
    'eth_address': string;
}
/**
 * 
 * @export
 * @interface VerifyAuth0UserOutput
 */
export interface VerifyAuth0UserOutput {
    /**
     * 
     * @type {string}
     * @memberof VerifyAuth0UserOutput
     */
    'user_id': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {VerificationEmailAuth0Input} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth0VerificationEmailPost: async (body?: VerificationEmailAuth0Input, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth0/verification-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyAuth0UserInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth0VerifyUserPost: async (body?: VerifyAuth0UserInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth0/verify-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAuthTypeForEmailGet: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('authAuthTypeForEmailGet', 'email', email)
            const localVarPath = `/auth/auth-type-for-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Application Binary Interface (ABI) for a specific capability
         * @param {string} capability 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        capabilitiesCapabilityGet: async (capability: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'capability' is not null or undefined
            assertParamExists('capabilitiesCapabilityGet', 'capability', capability)
            const localVarPath = `/capabilities/{capability}`
                .replace(`{${"capability"}}`, encodeURIComponent(String(capability)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get known contract capabilities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        capabilitiesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/capabilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a public collection capabilities by collection id
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdCapabilitiesGet: async (collectionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('collectionsCollectionIdCapabilitiesGet', 'collectionId', collectionId)
            const localVarPath = `/collections/{collection_id}/capabilities`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a public collection by collection id
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdGet: async (collectionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('collectionsCollectionIdGet', 'collectionId', collectionId)
            const localVarPath = `/collections/{collection_id}`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get collection stats
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdStatsGet: async (collectionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('collectionsCollectionIdStatsGet', 'collectionId', collectionId)
            const localVarPath = `/collections/{collection_id}/stats`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all collections by an organization
         * @param {string} organizationId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {Array<string>} [collectionId] 
         * @param {Array<number>} [networkId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsGet: async (organizationId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, collectionId?: Array<string>, networkId?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('collectionsGet', 'organizationId', organizationId)
            const localVarPath = `/collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (collectionId) {
                localVarQueryParameter['collection_id'] = collectionId;
            }

            if (networkId) {
                localVarQueryParameter['network_id'] = networkId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the contract level metadata
         * @param {string} networkId 
         * @param {string} contractAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractMetadataNetworkIdContractAddressGet: async (networkId: string, contractAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            assertParamExists('contractMetadataNetworkIdContractAddressGet', 'networkId', networkId)
            // verify required parameter 'contractAddress' is not null or undefined
            assertParamExists('contractMetadataNetworkIdContractAddressGet', 'contractAddress', contractAddress)
            const localVarPath = `/contract-metadata/{network_id}/{contract_address}`
                .replace(`{${"network_id"}}`, encodeURIComponent(String(networkId)))
                .replace(`{${"contract_address"}}`, encodeURIComponent(String(contractAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get global contract addresses.
         * @param {Array<number>} networkIds 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractsGet: async (networkIds: Array<number>, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkIds' is not null or undefined
            assertParamExists('contractsGet', 'networkIds', networkIds)
            const localVarPath = `/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (networkIds) {
                localVarQueryParameter['network_ids'] = networkIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the item that is redeemable for the given claim token
         * @param {string} claimToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailClaimsClaimTokenGet: async (claimToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimToken' is not null or undefined
            assertParamExists('emailClaimsClaimTokenGet', 'claimToken', claimToken)
            const localVarPath = `/email-claims/{claim_token}`
                .replace(`{${"claim_token"}}`, encodeURIComponent(String(claimToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * If the tx_payer is SELLER, the token is immediately transferred to the buyer. If the tx_payer is BUYER, a mint voucher is returned.
         * @summary Redeems an email claim based on the claim token and pincode
         * @param {string} claimToken 
         * @param {RedeemEmailClaimApiInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailClaimsClaimTokenRedeemPost: async (claimToken: string, body?: RedeemEmailClaimApiInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimToken' is not null or undefined
            assertParamExists('emailClaimsClaimTokenRedeemPost', 'claimToken', claimToken)
            const localVarPath = `/email-claims/{claim_token}/redeem`
                .replace(`{${"claim_token"}}`, encodeURIComponent(String(claimToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends a pincode to the email address to verify the claim
         * @param {string} claimToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailClaimsClaimTokenVerifyPost: async (claimToken: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claimToken' is not null or undefined
            assertParamExists('emailClaimsClaimTokenVerifyPost', 'claimToken', claimToken)
            const localVarPath = `/email-claims/{claim_token}/verify`
                .replace(`{${"claim_token"}}`, encodeURIComponent(String(claimToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all public items from an organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [organizationId] 
         * @param {Array<number>} [networkIds] 
         * @param {Array<string>} [attributes] 
         * @param {Array<string>} [collectionIds] 
         * @param {Array<number>} [tokenIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, organizationId?: string, networkIds?: Array<number>, attributes?: Array<string>, collectionIds?: Array<string>, tokenIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (networkIds) {
                localVarQueryParameter['network_ids'] = networkIds;
            }

            if (attributes) {
                localVarQueryParameter['attributes'] = attributes;
            }

            if (collectionIds) {
                localVarQueryParameter['collection_ids'] = collectionIds;
            }

            if (tokenIds) {
                localVarQueryParameter['token_ids'] = tokenIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a public item by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsItemIdGet: async (itemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('itemsItemIdGet', 'itemId', itemId)
            const localVarPath = `/items/{item_id}`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get item history
         * @param {string} itemId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {'MINT' | 'TRANSFER' | 'BURN' | 'null'} [eventType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsItemIdHistoryGet: async (itemId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, eventType?: 'MINT' | 'TRANSFER' | 'BURN' | 'null', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('itemsItemIdHistoryGet', 'itemId', itemId)
            const localVarPath = `/items/{item_id}/history`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (eventType !== undefined) {
                localVarQueryParameter['event_type'] = eventType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get item owners.
         * @param {string} itemId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsItemIdOwnersGet: async (itemId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('itemsItemIdOwnersGet', 'itemId', itemId)
            const localVarPath = `/items/{item_id}/owners`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get listings for the given organization
         * @param {string} organizationId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingsGet: async (organizationId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, campaignId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('listingsGet', 'organizationId', organizationId)
            const localVarPath = `/listings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organization_id'] = organizationId;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (collectionId !== undefined) {
                localVarQueryParameter['collection_id'] = collectionId;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaign_id'] = campaignId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Listing for the given listing id
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingsListingIdGet: async (listingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('listingsListingIdGet', 'listingId', listingId)
            const localVarPath = `/listings/{listing_id}`
                .replace(`{${"listing_id"}}`, encodeURIComponent(String(listingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Listing for the given listing id
         * @param {string} listingId 
         * @param {string} [ethAddress] ChecksumAddress
         * @param {string} [emailAddress] SafeString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingsRedemptionEligibilityGet: async (listingId: string, ethAddress?: string, emailAddress?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listingId' is not null or undefined
            assertParamExists('listingsRedemptionEligibilityGet', 'listingId', listingId)
            const localVarPath = `/listings/redemption-eligibility`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (listingId !== undefined) {
                localVarQueryParameter['listing_id'] = listingId;
            }

            if (ethAddress !== undefined) {
                localVarQueryParameter['eth_address'] = ethAddress;
            }

            if (emailAddress !== undefined) {
                localVarQueryParameter['email_address'] = emailAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get metadata for a specific token in a collection
         * @param {string} collectionId 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metadataCollectionIdTokenIdGet: async (collectionId: string, tokenId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('metadataCollectionIdTokenIdGet', 'collectionId', collectionId)
            // verify required parameter 'tokenId' is not null or undefined
            assertParamExists('metadataCollectionIdTokenIdGet', 'tokenId', tokenId)
            const localVarPath = `/metadata/{collection_id}/{token_id}`
                .replace(`{${"collection_id"}}`, encodeURIComponent(String(collectionId)))
                .replace(`{${"token_id"}}`, encodeURIComponent(String(tokenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the pending mint vouchers for the ethereum address
         * @param {string} ethAddress 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [contractAddress] ChecksumAddress
         * @param {number} [networkId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mintVouchersEthAddressGet: async (ethAddress: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, contractAddress?: string, networkId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ethAddress' is not null or undefined
            assertParamExists('mintVouchersEthAddressGet', 'ethAddress', ethAddress)
            const localVarPath = `/mint-vouchers/{eth_address}`
                .replace(`{${"eth_address"}}`, encodeURIComponent(String(ethAddress)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (contractAddress !== undefined) {
                localVarQueryParameter['contract_address'] = contractAddress;
            }

            if (networkId !== undefined) {
                localVarQueryParameter['network_id'] = networkId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the available networks
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        networksGet: async (orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/networks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public organization attributes by user email
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsEmailGet: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('organizationsEmailGet', 'email', email)
            const localVarPath = `/organizations/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentStripeWebhookPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment/stripe/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The purchase intent can be made with any provider configured for the listing. The provider must be one of the following: STRIPE. The response data will have provider specific data in the data field.
         * @summary Create a purchase intent
         * @param {CreatePurchaseIntentInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseIntentsPost: async (body?: CreatePurchaseIntentInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/purchase-intents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request a cancel of a purchase intent for a given purchase intent id.
         * @param {string} purchaseIntentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseIntentsPurchaseIntentIdDelete: async (purchaseIntentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchaseIntentId' is not null or undefined
            assertParamExists('purchaseIntentsPurchaseIntentIdDelete', 'purchaseIntentId', purchaseIntentId)
            const localVarPath = `/purchase-intents/{purchase_intent_id}`
                .replace(`{${"purchase_intent_id"}}`, encodeURIComponent(String(purchaseIntentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get API status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get payment tokens available for the given networks
         * @param {Array<number>} networkId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokensPaymentTokensGet: async (networkId: Array<number>, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            assertParamExists('tokensPaymentTokensGet', 'networkId', networkId)
            const localVarPath = `/tokens/payment-tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderDirection !== undefined) {
                localVarQueryParameter['order_direction'] = orderDirection;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (networkId) {
                localVarQueryParameter['network_id'] = networkId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {VerificationEmailAuth0Input} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auth0VerificationEmailPost(body?: VerificationEmailAuth0Input, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auth0VerificationEmailPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VerifyAuth0UserInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auth0VerifyUserPost(body?: VerifyAuth0UserInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifyAuth0UserOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auth0VerifyUserPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authAuthTypeForEmailGet(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTypeForEmailOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authAuthTypeForEmailGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the Application Binary Interface (ABI) for a specific capability
         * @param {string} capability 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async capabilitiesCapabilityGet(capability: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicGetCapabilitiesOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.capabilitiesCapabilityGet(capability, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get known contract capabilities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async capabilitiesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicGetCapabilitiesPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.capabilitiesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a public collection capabilities by collection id
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCollectionIdCapabilitiesGet(collectionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CapabilitiesOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCollectionIdCapabilitiesGet(collectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a public collection by collection id
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCollectionIdGet(collectionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicCollectionOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCollectionIdGet(collectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get collection stats
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsCollectionIdStatsGet(collectionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionStats>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsCollectionIdStatsGet(collectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all collections by an organization
         * @param {string} organizationId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {Array<string>} [collectionId] 
         * @param {Array<number>} [networkId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectionsGet(organizationId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, collectionId?: Array<string>, networkId?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicCollectionsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectionsGet(organizationId, orderDirection, cursor, limit, collectionId, networkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the contract level metadata
         * @param {string} networkId 
         * @param {string} contractAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractMetadataNetworkIdContractAddressGet(networkId: string, contractAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetContractMetadataOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractMetadataNetworkIdContractAddressGet(networkId, contractAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get global contract addresses.
         * @param {Array<number>} networkIds 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractsGet(networkIds: Array<number>, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicGetContractsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractsGet(networkIds, orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the item that is redeemable for the given claim token
         * @param {string} claimToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailClaimsClaimTokenGet(claimToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClaimOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailClaimsClaimTokenGet(claimToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * If the tx_payer is SELLER, the token is immediately transferred to the buyer. If the tx_payer is BUYER, a mint voucher is returned.
         * @summary Redeems an email claim based on the claim token and pincode
         * @param {string} claimToken 
         * @param {RedeemEmailClaimApiInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailClaimsClaimTokenRedeemPost(claimToken: string, body?: RedeemEmailClaimApiInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedeemEmailClaimOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailClaimsClaimTokenRedeemPost(claimToken, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sends a pincode to the email address to verify the claim
         * @param {string} claimToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailClaimsClaimTokenVerifyPost(claimToken: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailClaimsClaimTokenVerifyPost(claimToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all public items from an organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [organizationId] 
         * @param {Array<number>} [networkIds] 
         * @param {Array<string>} [attributes] 
         * @param {Array<string>} [collectionIds] 
         * @param {Array<number>} [tokenIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, organizationId?: string, networkIds?: Array<number>, attributes?: Array<string>, collectionIds?: Array<string>, tokenIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPublicOrgItemsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsGet(orderDirection, cursor, limit, organizationId, networkIds, attributes, collectionIds, tokenIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a public item by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsItemIdGet(itemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicItemOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsItemIdGet(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get item history
         * @param {string} itemId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {'MINT' | 'TRANSFER' | 'BURN' | 'null'} [eventType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsItemIdHistoryGet(itemId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, eventType?: 'MINT' | 'TRANSFER' | 'BURN' | 'null', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicGetItemHistoryPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsItemIdHistoryGet(itemId, orderDirection, cursor, limit, eventType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get item owners.
         * @param {string} itemId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsItemIdOwnersGet(itemId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicItemOwnersPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsItemIdOwnersGet(itemId, orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get listings for the given organization
         * @param {string} organizationId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listingsGet(organizationId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, campaignId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicListingsPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listingsGet(organizationId, orderDirection, cursor, limit, itemId, collectionId, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Listing for the given listing id
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listingsListingIdGet(listingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicListingOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listingsListingIdGet(listingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Listing for the given listing id
         * @param {string} listingId 
         * @param {string} [ethAddress] ChecksumAddress
         * @param {string} [emailAddress] SafeString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listingsRedemptionEligibilityGet(listingId: string, ethAddress?: string, emailAddress?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicListingsRedemptionEligibilityOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listingsRedemptionEligibilityGet(listingId, ethAddress, emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get metadata for a specific token in a collection
         * @param {string} collectionId 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metadataCollectionIdTokenIdGet(collectionId: string, tokenId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metadataCollectionIdTokenIdGet(collectionId, tokenId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the pending mint vouchers for the ethereum address
         * @param {string} ethAddress 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [contractAddress] ChecksumAddress
         * @param {number} [networkId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mintVouchersEthAddressGet(ethAddress: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, contractAddress?: string, networkId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVouchersPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mintVouchersEthAddressGet(ethAddress, orderDirection, cursor, limit, contractAddress, networkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the available networks
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async networksGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicNetworksPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.networksGet(orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get public organization attributes by user email
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationsEmailGet(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrganizationByEmailOutputList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsEmailGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentStripeWebhookPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentStripeWebhookPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The purchase intent can be made with any provider configured for the listing. The provider must be one of the following: STRIPE. The response data will have provider specific data in the data field.
         * @summary Create a purchase intent
         * @param {CreatePurchaseIntentInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseIntentsPost(body?: CreatePurchaseIntentInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePurchaseIntentOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseIntentsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request a cancel of a purchase intent for a given purchase intent id.
         * @param {string} purchaseIntentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseIntentsPurchaseIntentIdDelete(purchaseIntentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseIntentsPurchaseIntentIdDelete(purchaseIntentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get API status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statusGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statusGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get payment tokens available for the given networks
         * @param {Array<number>} networkId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokensPaymentTokensGet(networkId: Array<number>, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicNetworkPaymentTokensPaginatedOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokensPaymentTokensGet(networkId, orderDirection, cursor, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {VerificationEmailAuth0Input} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth0VerificationEmailPost(body?: VerificationEmailAuth0Input, options?: any): AxiosPromise<void> {
            return localVarFp.auth0VerificationEmailPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyAuth0UserInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth0VerifyUserPost(body?: VerifyAuth0UserInput, options?: any): AxiosPromise<VerifyAuth0UserOutput> {
            return localVarFp.auth0VerifyUserPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email Email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authAuthTypeForEmailGet(email: string, options?: any): AxiosPromise<AuthTypeForEmailOutput> {
            return localVarFp.authAuthTypeForEmailGet(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Application Binary Interface (ABI) for a specific capability
         * @param {string} capability 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        capabilitiesCapabilityGet(capability: string, options?: any): AxiosPromise<PublicGetCapabilitiesOutput> {
            return localVarFp.capabilitiesCapabilityGet(capability, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get known contract capabilities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        capabilitiesGet(options?: any): AxiosPromise<PublicGetCapabilitiesPaginatedOutput> {
            return localVarFp.capabilitiesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a public collection capabilities by collection id
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdCapabilitiesGet(collectionId: string, options?: any): AxiosPromise<CapabilitiesOutput> {
            return localVarFp.collectionsCollectionIdCapabilitiesGet(collectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a public collection by collection id
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdGet(collectionId: string, options?: any): AxiosPromise<PublicCollectionOutput> {
            return localVarFp.collectionsCollectionIdGet(collectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get collection stats
         * @param {string} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsCollectionIdStatsGet(collectionId: string, options?: any): AxiosPromise<CollectionStats> {
            return localVarFp.collectionsCollectionIdStatsGet(collectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all collections by an organization
         * @param {string} organizationId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {Array<string>} [collectionId] 
         * @param {Array<number>} [networkId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectionsGet(organizationId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, collectionId?: Array<string>, networkId?: Array<number>, options?: any): AxiosPromise<PublicCollectionsPaginatedOutput> {
            return localVarFp.collectionsGet(organizationId, orderDirection, cursor, limit, collectionId, networkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the contract level metadata
         * @param {string} networkId 
         * @param {string} contractAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractMetadataNetworkIdContractAddressGet(networkId: string, contractAddress: string, options?: any): AxiosPromise<GetContractMetadataOutput> {
            return localVarFp.contractMetadataNetworkIdContractAddressGet(networkId, contractAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get global contract addresses.
         * @param {Array<number>} networkIds 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractsGet(networkIds: Array<number>, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<PublicGetContractsPaginatedOutput> {
            return localVarFp.contractsGet(networkIds, orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the item that is redeemable for the given claim token
         * @param {string} claimToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailClaimsClaimTokenGet(claimToken: string, options?: any): AxiosPromise<GetClaimOutput> {
            return localVarFp.emailClaimsClaimTokenGet(claimToken, options).then((request) => request(axios, basePath));
        },
        /**
         * If the tx_payer is SELLER, the token is immediately transferred to the buyer. If the tx_payer is BUYER, a mint voucher is returned.
         * @summary Redeems an email claim based on the claim token and pincode
         * @param {string} claimToken 
         * @param {RedeemEmailClaimApiInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailClaimsClaimTokenRedeemPost(claimToken: string, body?: RedeemEmailClaimApiInput, options?: any): AxiosPromise<RedeemEmailClaimOutput> {
            return localVarFp.emailClaimsClaimTokenRedeemPost(claimToken, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends a pincode to the email address to verify the claim
         * @param {string} claimToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailClaimsClaimTokenVerifyPost(claimToken: string, options?: any): AxiosPromise<PublicItem> {
            return localVarFp.emailClaimsClaimTokenVerifyPost(claimToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all public items from an organization
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [organizationId] 
         * @param {Array<number>} [networkIds] 
         * @param {Array<string>} [attributes] 
         * @param {Array<string>} [collectionIds] 
         * @param {Array<number>} [tokenIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, organizationId?: string, networkIds?: Array<number>, attributes?: Array<string>, collectionIds?: Array<string>, tokenIds?: Array<number>, options?: any): AxiosPromise<GetPublicOrgItemsPaginatedOutput> {
            return localVarFp.itemsGet(orderDirection, cursor, limit, organizationId, networkIds, attributes, collectionIds, tokenIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a public item by id
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsItemIdGet(itemId: string, options?: any): AxiosPromise<PublicItemOutput> {
            return localVarFp.itemsItemIdGet(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get item history
         * @param {string} itemId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {'MINT' | 'TRANSFER' | 'BURN' | 'null'} [eventType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsItemIdHistoryGet(itemId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, eventType?: 'MINT' | 'TRANSFER' | 'BURN' | 'null', options?: any): AxiosPromise<PublicGetItemHistoryPaginatedOutput> {
            return localVarFp.itemsItemIdHistoryGet(itemId, orderDirection, cursor, limit, eventType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get item owners.
         * @param {string} itemId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsItemIdOwnersGet(itemId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<PublicItemOwnersPaginatedOutput> {
            return localVarFp.itemsItemIdOwnersGet(itemId, orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get listings for the given organization
         * @param {string} organizationId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [itemId] 
         * @param {string} [collectionId] 
         * @param {string} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingsGet(organizationId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, campaignId?: string, options?: any): AxiosPromise<PublicListingsPaginatedOutput> {
            return localVarFp.listingsGet(organizationId, orderDirection, cursor, limit, itemId, collectionId, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Listing for the given listing id
         * @param {string} listingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingsListingIdGet(listingId: string, options?: any): AxiosPromise<PublicListingOutput> {
            return localVarFp.listingsListingIdGet(listingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Listing for the given listing id
         * @param {string} listingId 
         * @param {string} [ethAddress] ChecksumAddress
         * @param {string} [emailAddress] SafeString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listingsRedemptionEligibilityGet(listingId: string, ethAddress?: string, emailAddress?: string, options?: any): AxiosPromise<PublicListingsRedemptionEligibilityOutput> {
            return localVarFp.listingsRedemptionEligibilityGet(listingId, ethAddress, emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get metadata for a specific token in a collection
         * @param {string} collectionId 
         * @param {string} tokenId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metadataCollectionIdTokenIdGet(collectionId: string, tokenId: string, options?: any): AxiosPromise<void> {
            return localVarFp.metadataCollectionIdTokenIdGet(collectionId, tokenId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the pending mint vouchers for the ethereum address
         * @param {string} ethAddress 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] MediumPaginationLimit
         * @param {string} [contractAddress] ChecksumAddress
         * @param {number} [networkId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mintVouchersEthAddressGet(ethAddress: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, contractAddress?: string, networkId?: number, options?: any): AxiosPromise<GetVouchersPaginatedOutput> {
            return localVarFp.mintVouchersEthAddressGet(ethAddress, orderDirection, cursor, limit, contractAddress, networkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the available networks
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        networksGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<PublicNetworksPaginatedOutput> {
            return localVarFp.networksGet(orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public organization attributes by user email
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsEmailGet(email: string, options?: any): AxiosPromise<GetOrganizationByEmailOutputList> {
            return localVarFp.organizationsEmailGet(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentStripeWebhookPost(options?: any): AxiosPromise<void> {
            return localVarFp.paymentStripeWebhookPost(options).then((request) => request(axios, basePath));
        },
        /**
         * The purchase intent can be made with any provider configured for the listing. The provider must be one of the following: STRIPE. The response data will have provider specific data in the data field.
         * @summary Create a purchase intent
         * @param {CreatePurchaseIntentInput} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseIntentsPost(body?: CreatePurchaseIntentInput, options?: any): AxiosPromise<CreatePurchaseIntentOutput> {
            return localVarFp.purchaseIntentsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request a cancel of a purchase intent for a given purchase intent id.
         * @param {string} purchaseIntentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseIntentsPurchaseIntentIdDelete(purchaseIntentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.purchaseIntentsPurchaseIntentIdDelete(purchaseIntentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get API status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusGet(options?: any): AxiosPromise<StatusOutput> {
            return localVarFp.statusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get payment tokens available for the given networks
         * @param {Array<number>} networkId 
         * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
         * @param {string} [cursor] SafeString
         * @param {number} [limit] PaginationLimit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokensPaymentTokensGet(networkId: Array<number>, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: any): AxiosPromise<PublicNetworkPaymentTokensPaginatedOutput> {
            return localVarFp.tokensPaymentTokensGet(networkId, orderDirection, cursor, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {VerificationEmailAuth0Input} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public auth0VerificationEmailPost(body?: VerificationEmailAuth0Input, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).auth0VerificationEmailPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyAuth0UserInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public auth0VerifyUserPost(body?: VerifyAuth0UserInput, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).auth0VerifyUserPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email Email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authAuthTypeForEmailGet(email: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authAuthTypeForEmailGet(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the Application Binary Interface (ABI) for a specific capability
     * @param {string} capability 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public capabilitiesCapabilityGet(capability: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).capabilitiesCapabilityGet(capability, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get known contract capabilities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public capabilitiesGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).capabilitiesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a public collection capabilities by collection id
     * @param {string} collectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public collectionsCollectionIdCapabilitiesGet(collectionId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).collectionsCollectionIdCapabilitiesGet(collectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a public collection by collection id
     * @param {string} collectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public collectionsCollectionIdGet(collectionId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).collectionsCollectionIdGet(collectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get collection stats
     * @param {string} collectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public collectionsCollectionIdStatsGet(collectionId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).collectionsCollectionIdStatsGet(collectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all collections by an organization
     * @param {string} organizationId 
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {Array<string>} [collectionId] 
     * @param {Array<number>} [networkId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public collectionsGet(organizationId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, collectionId?: Array<string>, networkId?: Array<number>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).collectionsGet(organizationId, orderDirection, cursor, limit, collectionId, networkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the contract level metadata
     * @param {string} networkId 
     * @param {string} contractAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contractMetadataNetworkIdContractAddressGet(networkId: string, contractAddress: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contractMetadataNetworkIdContractAddressGet(networkId, contractAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get global contract addresses.
     * @param {Array<number>} networkIds 
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public contractsGet(networkIds: Array<number>, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).contractsGet(networkIds, orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the item that is redeemable for the given claim token
     * @param {string} claimToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public emailClaimsClaimTokenGet(claimToken: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).emailClaimsClaimTokenGet(claimToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * If the tx_payer is SELLER, the token is immediately transferred to the buyer. If the tx_payer is BUYER, a mint voucher is returned.
     * @summary Redeems an email claim based on the claim token and pincode
     * @param {string} claimToken 
     * @param {RedeemEmailClaimApiInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public emailClaimsClaimTokenRedeemPost(claimToken: string, body?: RedeemEmailClaimApiInput, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).emailClaimsClaimTokenRedeemPost(claimToken, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sends a pincode to the email address to verify the claim
     * @param {string} claimToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public emailClaimsClaimTokenVerifyPost(claimToken: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).emailClaimsClaimTokenVerifyPost(claimToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all public items from an organization
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {string} [organizationId] 
     * @param {Array<number>} [networkIds] 
     * @param {Array<string>} [attributes] 
     * @param {Array<string>} [collectionIds] 
     * @param {Array<number>} [tokenIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public itemsGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, organizationId?: string, networkIds?: Array<number>, attributes?: Array<string>, collectionIds?: Array<string>, tokenIds?: Array<number>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).itemsGet(orderDirection, cursor, limit, organizationId, networkIds, attributes, collectionIds, tokenIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a public item by id
     * @param {string} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public itemsItemIdGet(itemId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).itemsItemIdGet(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get item history
     * @param {string} itemId 
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {'MINT' | 'TRANSFER' | 'BURN' | 'null'} [eventType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public itemsItemIdHistoryGet(itemId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, eventType?: 'MINT' | 'TRANSFER' | 'BURN' | 'null', options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).itemsItemIdHistoryGet(itemId, orderDirection, cursor, limit, eventType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get item owners.
     * @param {string} itemId 
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public itemsItemIdOwnersGet(itemId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).itemsItemIdOwnersGet(itemId, orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get listings for the given organization
     * @param {string} organizationId 
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {string} [itemId] 
     * @param {string} [collectionId] 
     * @param {string} [campaignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listingsGet(organizationId: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, itemId?: string, collectionId?: string, campaignId?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listingsGet(organizationId, orderDirection, cursor, limit, itemId, collectionId, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Listing for the given listing id
     * @param {string} listingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listingsListingIdGet(listingId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listingsListingIdGet(listingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Listing for the given listing id
     * @param {string} listingId 
     * @param {string} [ethAddress] ChecksumAddress
     * @param {string} [emailAddress] SafeString
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listingsRedemptionEligibilityGet(listingId: string, ethAddress?: string, emailAddress?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listingsRedemptionEligibilityGet(listingId, ethAddress, emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get metadata for a specific token in a collection
     * @param {string} collectionId 
     * @param {string} tokenId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public metadataCollectionIdTokenIdGet(collectionId: string, tokenId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).metadataCollectionIdTokenIdGet(collectionId, tokenId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the pending mint vouchers for the ethereum address
     * @param {string} ethAddress 
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] MediumPaginationLimit
     * @param {string} [contractAddress] ChecksumAddress
     * @param {number} [networkId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mintVouchersEthAddressGet(ethAddress: string, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, contractAddress?: string, networkId?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mintVouchersEthAddressGet(ethAddress, orderDirection, cursor, limit, contractAddress, networkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the available networks
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public networksGet(orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).networksGet(orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public organization attributes by user email
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public organizationsEmailGet(email: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).organizationsEmailGet(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public paymentStripeWebhookPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).paymentStripeWebhookPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The purchase intent can be made with any provider configured for the listing. The provider must be one of the following: STRIPE. The response data will have provider specific data in the data field.
     * @summary Create a purchase intent
     * @param {CreatePurchaseIntentInput} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public purchaseIntentsPost(body?: CreatePurchaseIntentInput, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).purchaseIntentsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request a cancel of a purchase intent for a given purchase intent id.
     * @param {string} purchaseIntentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public purchaseIntentsPurchaseIntentIdDelete(purchaseIntentId: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).purchaseIntentsPurchaseIntentIdDelete(purchaseIntentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get API status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public statusGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).statusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get payment tokens available for the given networks
     * @param {Array<number>} networkId 
     * @param {'ASC' | 'DESC' | 'null'} [orderDirection] 
     * @param {string} [cursor] SafeString
     * @param {number} [limit] PaginationLimit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public tokensPaymentTokensGet(networkId: Array<number>, orderDirection?: 'ASC' | 'DESC' | 'null', cursor?: string, limit?: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).tokensPaymentTokensGet(networkId, orderDirection, cursor, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


