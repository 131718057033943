import Box from '@mui/material/Box';
import { Breakpoint, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import KebabMenuButton from '@/common/button/KebabMenu';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

export interface ResponsiveButtonGroupButton {
  element: ReactJSXElement;
  key: string;
}

export interface ResponsiveButtonGroupProps {
  orientation?: ResponsiveButtonGroupOrientation;
  responsiveBreakpoint?: Breakpoint;
  kebabButtonLabel?: string;
}

/**
 * The orientation options of an action button group.
 */
export enum ResponsiveButtonGroupOrientation {
  INLINE = 'INLINE',
  KEBAB = 'KEBAB',
  RESPONSIVE = 'RESPONSIVE',
}

/**
 * Lays out a group of actions in a specific orientation.
 * @param orientation Determines the orientation of the action buttons. Defaults to RESPONSIVE.
 * @param responsiveBreakpoint If the orientation is RESPONSIVE, determines the breakpoint at which the buttons will switch from inline to kebab. Defaults to 'xl'.
 * @param actions The actions in the group.
 * @param kebabButtonLabel The label to use for the kebab menu button when it is rendered.
 */
const ResponsiveButtonGroup = ({
  buttons,
  kebabButtonLabel,
  orientation = ResponsiveButtonGroupOrientation.RESPONSIVE,
  responsiveBreakpoint = 'xl',
}: ResponsiveButtonGroupProps & { buttons: ResponsiveButtonGroupButton[] }) => {
  const theme = useTheme();
  const inlinePreferred = useMediaQuery(theme.breakpoints.up(responsiveBreakpoint));
  const renderInline =
    (orientation === ResponsiveButtonGroupOrientation.RESPONSIVE && inlinePreferred) ||
    orientation === ResponsiveButtonGroupOrientation.INLINE;

  return renderInline ? (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        columnGap: '8px',
        gridTemplateColumns: `repeat(${buttons.length}, 1fr)`,
      }}
    >
      {buttons.map(button => (
        <Box sx={{ width: '100%', '.MuiButton-root': { width: '100%' } }} key={button.key}>
          {button.element}
        </Box>
      ))}
    </Box>
  ) : (
    <KebabMenuButton
      label={kebabButtonLabel}
      menuItems={buttons.map(button => ({
        key: button.key,
        element: button.element,
      }))}
    />
  );
};

export default ResponsiveButtonGroup;
