import BlockExplorerFilter, { BlockExplorerFilterType } from '@/common/data/BlockExplorerFilter';
import { paymentsNetworkId } from '@/config';
import { platformDepositABI, platformDepositAddress } from '@/contracts/generated';
import { ORG_BALANCE_QUERY_KEY } from '@/utils/constants';
import { usdcToDecimalString, uuidToBytes32 } from '@/utils/helpers';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { parseUnits } from 'ethers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAccount, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi';
import { readContract } from '@wagmi/core';
import { AllowedNetworks } from './types';
import ModalActionButtons from './ModalActionButtons';
import { Alert, AlertTitle, Box } from '@mui/material';
import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import LoadingBox from '@/common/container/LoadingBox';
import { useWithdrawCrypto } from '@/utils/hooks/useWithdrawCrypto';
import TxInProgress from './TxInProgress';
import DisconnectWallet from './DisconnectWallet';

const NEXT_ACTION = {
  CONNECT_WALLET: 0,
  SWITCH_CHAIN: 1,
  WITHDRAW: 2,
  CLOSE: 3,
};

const usePlatformBalance = (chainId: number, orgId: `0x${string}`, address?: `0x${string}`) => {
  return useQuery({
    queryKey: [ORG_BALANCE_QUERY_KEY, orgId, address],
    queryFn: async () => {
      const balance = await readContract({
        abi: platformDepositABI,
        address: platformDepositAddress[chainId as keyof typeof platformDepositAddress],
        functionName: 'withdrawable',
        chainId: chainId,
        args: [orgId, address!],
      });
      return balance;
    },
    enabled: !!address,
  });
};

const WithdrawCryptoForm = ({ orgId, onSuccess, onCancel }: any) => {
  const [amount, setAmount] = useState<number | string>('');
  const { address, status: addressStatus } = useAccount();
  const [withdrawTxHash, setWithdrawTxHash] = useState<string | undefined>(undefined);
  const [nextAction, setNextAction] = useState(NEXT_ACTION.CONNECT_WALLET);
  const [withdrawSuccessful, setWithdrawSuccessful] = useState(false);
  const [nextButtonLabel, setNextButtonLabel] = useState('Connect Wallet');
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [waitingForMetamask, setWaitingForMetamask] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const queryClient = useQueryClient();
  const { openConnectModal } = useConnectModal();
  const { chains, switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();
  const { disconnect } = useDisconnect();

  // get network from config
  const network = paymentsNetworkId as AllowedNetworks;

  const {
    data: balanceData,
    isLoading: balanceLoading,
    isError: balanceError,
  } = usePlatformBalance(network, uuidToBytes32(orgId) as `0x${string}`, address);

  const amountAsWei = useMemo(() => {
    try {
      return BigInt((amount as number) * 10 ** 6);
    } catch (e) {
      return BigInt(0);
    }
  }, [amount]);

  const { handleSubmit: handleWithdraw } = useWithdrawCrypto(amountAsWei, orgId, network, {
    onStart: () => {
      setWaitingForMetamask(true);
    },
    onSubmit: txHash => {
      setTxInProgress(true);
      setWithdrawTxHash(txHash);
    },
    // onError: err => handleApprovalError(err),
    onComplete: () => {
      queryClient.invalidateQueries({ queryKey: [ORG_BALANCE_QUERY_KEY] });
      setTxInProgress(false);
      setWithdrawSuccessful(true);
    },
    onFinally: () => {
      setWaitingForMetamask(false);
    },
    handlePrepareError: (err: any) => {
      setTxInProgress(false);
      console.log('Prepare error:', err);
    },
  });

  const onNextClicked = useCallback(() => {
    if (nextAction === NEXT_ACTION.CONNECT_WALLET && openConnectModal) {
      openConnectModal();
    } else if (nextAction === NEXT_ACTION.WITHDRAW) {
      handleWithdraw();
    } else if (nextAction === NEXT_ACTION.CLOSE) {
      onSuccess();
    } else if (nextAction === NEXT_ACTION.SWITCH_CHAIN) {
      if (switchNetwork) {
        switchNetwork(network as number);
      }
    }
  }, [nextAction, openConnectModal, onSuccess, handleWithdraw, switchNetwork, network]);

  const targetChain = useMemo(() => {
    if (!chains) {
      return undefined;
    }
    try {
      return chains.filter(c => c.id === network)[0];
    } catch (e) {
      return undefined;
    }
  }, [chains, network]);

  // based on state, set the action button status, labels and how to handle the next action
  useEffect(() => {
    if (withdrawSuccessful) {
      setNextButtonDisabled(false);
      setNextButtonLabel('Close');
      setNextAction(NEXT_ACTION.CLOSE);
    } else if (address && chain?.id !== network) {
      setNextButtonLabel('Switch Chain');
      setNextButtonDisabled(false);
      setNextAction(NEXT_ACTION.SWITCH_CHAIN);
    } else if (!address || addressStatus !== 'connected' || !chain) {
      setNextButtonLabel('Connect Wallet');
      setNextButtonDisabled(false);
      setNextAction(NEXT_ACTION.CONNECT_WALLET);
    } else if (
      address &&
      (balanceLoading ||
        balanceError ||
        (balanceData && amountAsWei <= 0) ||
        (balanceData && amountAsWei > balanceData) ||
        balanceData === BigInt(0))
    ) {
      setNextButtonLabel('Withdraw');
      setNextButtonDisabled(true);
      setNextAction(NEXT_ACTION.WITHDRAW);
    } else if (address && balanceData && balanceData >= BigInt(amountAsWei)) {
      setNextButtonLabel('Withdraw');
      setNextButtonDisabled(false);
      setNextAction(NEXT_ACTION.WITHDRAW);
    } else {
      setNextButtonLabel('Unknown');
      setNextButtonDisabled(true);
    }
  }, [
    address,
    setNextButtonLabel,
    setNextButtonDisabled,
    amountAsWei,
    balanceData,
    balanceError,
    balanceLoading,
    chain,
    network,
    addressStatus,
    withdrawSuccessful,
  ]);

  return (
    <form onSubmit={() => {}}>
      {!withdrawSuccessful && (
        <Typography variant="subtitle2" id="parent-modal-description" sx={{ marginBottom: 3 }}>
          Connect a wallet that has previously deposited funds to this account. Learn more about{' '}
          <a href="https://xxx" target="_blank" rel="noreferrer">
            withdrawing funds
          </a>
          .
        </Typography>
      )}

      {withdrawSuccessful ? (
        <>
          <Alert severity="success">
            <AlertTitle>Success!</AlertTitle>Your withdraw has been confirmed.
          </Alert>
          <Box>
            <BasicInfoDisplay title="Amount">{`${amount}`} USDC</BasicInfoDisplay>
          </Box>
          <Box>
            <BasicInfoDisplay title="Tx Hash">
              <BlockExplorerFilter
                filterValue={withdrawTxHash}
                filterType={BlockExplorerFilterType.TX_HASH}
                networkId={network}
                charLengthOnEitherSide={6}
                iconPosition="end"
              />
            </BasicInfoDisplay>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              id="address"
              label="Wallet Address"
              name="address"
              placeholder="Connect Wallet"
              required
              type="text"
              value={addressStatus === 'connected' ? address : ''}
              InputProps={{
                readOnly: true,
              }}
            />
            {address && <DisconnectWallet onDisconnect={disconnect} />}
          </Box>

          <Box>
            <LoadingBox isLoading={balanceLoading}>
              <TextField
                type="number"
                name="amount"
                label="Amount"
                inputProps={{ step: 0.01 }}
                sx={{ width: '100%' }}
                onChange={e => {
                  if (e.target.value.length === 0) {
                    setAmount('');
                  }
                  try {
                    parseUnits(e.target.value, 6);
                    setAmount(+e.target.value);
                  } catch (e: any) {
                    // swallow
                  }
                }}
                value={amount}
                placeholder="The amount to withdraw"
                disabled={!address}
                required
              />

              {nextAction === NEXT_ACTION.SWITCH_CHAIN ? (
                <Typography variant="caption" sx={{ color: 'red' }}>
                  Please change to the {targetChain!.name} network in your wallet.
                </Typography>
              ) : balanceData === BigInt(0) ? (
                <Typography variant="caption" sx={{ color: 'red' }}>
                  You have no funds to withdraw with the connected wallet.
                </Typography>
              ) : balanceData !== undefined && amountAsWei > balanceData ? (
                <Typography variant="caption" sx={{ color: 'red' }}>
                  You can only withdraw up to: {usdcToDecimalString(balanceData)}.
                </Typography>
              ) : (balanceData !== undefined && amount == '') ||
                (balanceData && balanceData >= BigInt(amountAsWei)) ? (
                <Typography variant="caption" sx={{ color: 'shade.grey500' }}>
                  You can withdraw up to: {usdcToDecimalString(balanceData)}.
                </Typography>
              ) : null}
            </LoadingBox>
          </Box>
        </>
      )}

      <ModalActionButtons
        cancelDisabled={withdrawSuccessful}
        nextLabel={nextButtonLabel}
        nextDisabled={nextButtonDisabled}
        onNext={onNextClicked}
        onCancel={onCancel}
        isLoading={waitingForMetamask || txInProgress}
      />

      {txInProgress && withdrawTxHash && <TxInProgress txHash={withdrawTxHash} network={network} />}
    </form>
  );
};

export default WithdrawCryptoForm;
