import { string, arrayOf, object } from 'prop-types';
import ReactHelmet from 'react-helmet';

interface HelmetProps {
  title: string;
  meta?: any[];
  lang?: string;
}

/**
 * The helmet for a page.
 */
export const PageHelmet = ({ lang, meta = [], title }: HelmetProps) => {
  return (
    <ReactHelmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate="Phosphor Developer | %s"
      meta={[
        {
          property: `og:title`,
          content: title,
        },
      ].concat(meta)}
    />
  );
};

PageHelmet.defaultProps = {
  lang: `en`,
  meta: [],
};

PageHelmet.propTypes = {
  lang: string,
  meta: arrayOf(object),
  title: string.isRequired,
};

export default PageHelmet;
