import { CreateMarketingCampaignInput } from '@/api-client';
import { MAIN_ROUTES } from '@/routes';
import { ROUTE_NAME } from '@/utils/constants';
import { useCreateMarketingCampaign } from '@/utils/hooks/marketingCampaigns';
import { useOrganizationProfile } from '@/utils/hooks/organization';
import { authState } from '@/utils/states/authState';
import { LoadingButton } from '@mui/lab';
import { Box, Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { find } from 'lodash';
import { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import * as yup from 'yup';

const initialValues = {
  name: '',
  email_from: '',
};

const validationSchema = yup.object().shape({
  name: yup.string().max(80).required('Please enter a valid name'),
  email_from: yup.string(),
});

export const CreateCampaignForm = ({ onCancel }: any) => {
  const navigate = useNavigate();  
  const { platformOrg } = useSnapshot(authState);
  const { data: orgProfile } = useOrganizationProfile();

  const defaultEmailFrom = useMemo(() => {
    return orgProfile?.public_name ?? `${platformOrg?.id} at Phosphor`;
  }, [platformOrg, orgProfile]);

  const { mutate: CreateCampaign, isPending: creatingCampaign } = useCreateMarketingCampaign();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      const body: CreateMarketingCampaignInput = {
        name: values.name,
        email_from: values.email_from === '' ? defaultEmailFrom : values.email_from,
      };
      CreateCampaign(body, {
        onSuccess: axiosResponse => {
          if (!axiosResponse?.data) return;
          const { data: campaignOutput } = axiosResponse;
          resetForm({ values });
          const campaignDetailsPath = find(
            MAIN_ROUTES,
            route => route.name === ROUTE_NAME.EMAIL_CAMPAIGN_DETAILS
          );
          if (campaignOutput?.id && campaignDetailsPath?.path) {
            navigate(generatePath(campaignDetailsPath.path, { campaignId: campaignOutput.id }));
          }
        },
        onError: () => {
          setSubmitting(false);
        },
      });
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        autoFocus
        type="text"
        name="name"
        value={formik.values.name}
        label="Campaign Name"
        disabled={formik.isSubmitting}
        sx={{ width: '60%', mb: 2 }}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && (formik.errors.name as string)}
        placeholder="Fill in the name of the email campaign"
        autoComplete="off"
        required
      />
      <TextField
        type="text"
        name="email_from"
        value={formik.values.email_from}
        label="Email From"
        disabled={formik.isSubmitting}
        sx={{ width: '60%', mb: 2 }}
        onChange={formik.handleChange}
        error={formik.touched.email_from && Boolean(formik.errors.email_from)}
        helperText={
          formik.touched.email_from
            ? (formik.errors.email_from as string)
            : 'Enter a custom email from name or leave it blank for default'
        }
        placeholder={defaultEmailFrom}
        autoComplete="off"
      />
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'left',
          alignItems: 'center',
          gap: 2,
          mt: 2,
        }}
      >
        <Button
          variant="outlined"
          type="button"
          onClick={() => onCancel()}
          sx={{ minWidth: '50px' }}
        >
          Cancel
        </Button>

        <LoadingButton
          type="submit"
          loading={formik.isSubmitting || creatingCampaign}
          disabled={formik.isSubmitting || !formik.values.name || !!formik.errors.name}
          variant="contained"
          color="primary"
          sx={{ minWidth: '50px' }}
        >
          Create
        </LoadingButton>
      </Box>
    </form>
  );
};

export default CreateCampaignForm;
