import { GridColDef } from '@mui/x-data-grid';
import { useCallback, useEffect } from 'react';
import ServerPaginatedDataGrid, {
  DEFAULT_PAGINATED_GRID_PAGE,
  PaginatedGridPage,
} from '@/common/data/grid/ServerPaginated';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { proxy, useSnapshot } from 'valtio';
import { TransactionsPaginatedOutputResultsInner } from '@/api-client';
import { API_SORT_DIRECTION_ASC, API_SORT_DIRECTION_DESC, ROUTE_NAME } from '@/utils/constants';
import { useSortAndFilterGrid } from '@/utils/hooks/useSortAndFilterGrid';
import { BaseRow } from '@/common/data/grid/constants';
import { useTransactions } from '@/utils/hooks/transaction';
import { formatUnits } from '@/utils/helpers';
import { useAllPublicNetworks } from '@/utils/hooks/publicNetwork';
import PrettyDate from '@/common/data/PrettyDate';
import { find } from 'lodash';
import { generatePath } from 'react-router-dom';
import { AppRoute, MAIN_ROUTES } from '@/routes';

// Row specific to this grid.
interface TxGridRow extends BaseRow {
  id: string;
  timestamp: string;
  type: string;
  network: string;
  sources: string[];
  amount: string;
}

// Columns specific to this grid.
const columns: Array<GridColDef<TxGridRow>> = [
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    filterable: false,
    sortable: true,
    width: 175,
    renderCell: (params: { row: TxGridRow }) => <PrettyDate date={params.row.timestamp} />,
  },
  {
    field: 'type',
    headerName: 'Type',
    filterable: true,
    sortable: false,
    minWidth: 175,
    maxWidth: 350,
    flex: 1,
  },
  {
    field: 'network',
    headerName: 'Network',
    filterable: false,
    sortable: false,
    minWidth: 175,
    maxWidth: 350,
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    filterable: false,
    sortable: false,
    width: 100,
  },
  {
    field: 'sources',
    headerName: 'Fund Source(s)',
    filterable: false,
    sortable: false,
    flex: 1,
  },
];

// Initial state specific to this grid.
const initialState: GridInitialStateCommunity = {
  sorting: {
    sortModel: [{ field: 'created_at', sort: 'desc' }],
  },
  columns: {
    columnVisibilityModel: {
      id: false,
    },
  },
};

// Will map API data (in this case a mix of multiple sources) to rows.
const mapToRow = (
  tx: TransactionsPaginatedOutputResultsInner,
  networksById: Record<number, string>
): TxGridRow => {
  return {
    id: tx.id,
    timestamp: tx.created_at,
    type: tx.tx_type as string,
    network: networksById[tx.network_id],
    sources: tx.billing_details?.charges?.map(c => c.payment_method) ?? [],
    amount: tx.billing_details ? formatUnits(tx.billing_details.total, 2) : '0.00',
  };
};

const pageState: PaginatedGridPage<TxGridRow> = proxy({
  ...DEFAULT_PAGINATED_GRID_PAGE,
  sortBy: initialState.sorting?.sortModel
    ? {
        field: initialState.sorting?.sortModel[0].field,
        direction:
          initialState.sorting?.sortModel[0].sort === 'asc'
            ? API_SORT_DIRECTION_ASC
            : API_SORT_DIRECTION_DESC,
      }
    : undefined,
});

const ChargesGrid = () => {
  // Get readonly snaps from the state.
  const pageSnap = useSnapshot(pageState);

  // Construct sort and filter args based on the snap of the active page.
  const { sortBy, filterBy } = useSortAndFilterGrid(pageState);

  // Get all API data for current render.
  const { data: txsResponse, isPending: usersLoading } = useTransactions(
    pageSnap.pageSize,
    pageSnap.cursor,
    sortBy,
    { ...filterBy, billingStatus: 'COMPLETED' }
  );

  const { data: networksResponse } = useAllPublicNetworks();

  // Populate rows when any API data changes.
  useEffect(() => {
    if (!networksResponse) return;

    const networksById: Record<number, string> = {};
    for (const network of networksResponse) {
      networksById[network.id] = network.name;
    }

    pageState.rows =
      txsResponse?.results.map(tx => {
        return mapToRow(tx, networksById);
      }) ?? [];
    pageState.nextCursor = txsResponse?.cursor ?? DEFAULT_PAGINATED_GRID_PAGE.cursor;
  }, [txsResponse?.results, txsResponse?.cursor, networksResponse]);

  const getRowDoubleClickUrl = useCallback((row: TxGridRow) => {
    const detailsRoute = find(
      MAIN_ROUTES,
      route => route.name === ROUTE_NAME.BILLING_TRANSACTION_DETAILS
    ) as AppRoute;
    return generatePath(detailsRoute.path as string, { transactionId: row.id });
  }, []);
  return (
    <ServerPaginatedDataGrid
      columns={columns}
      initialState={initialState}
      // eslint-disable-next-line valtio/state-snapshot-rule
      activePageProxy={pageState}
      pageLoading={usersLoading}
      totalRowCount={txsResponse?.total_results ?? 0}
      getRowDoubleClickUrl={getRowDoubleClickUrl}
    />
  );
};

export default ChargesGrid;
