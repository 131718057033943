import useMinimumLoadingTime from '@/utils/hooks/useMinimumLoadingTime';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { PropsWithChildren } from 'react';

interface LoadingBoxProps {
  isLoading: boolean;
  minimumTime?: number;
}

/**
 * Can be used to mimic the loading state of a section or page.
 * Will display a loading indicator for a minimum amount of time, regardless of the isLoading prop.
 * @param isLoading Whether the loading indicator should be displayed
 * @param minimumTime The minimum amount of time to display the loading indicator
 * @param children The children to display when the loading indicator is not displayed.
 */
export const LoadingBox = ({
  isLoading,
  minimumTime,
  children,
}: PropsWithChildren & LoadingBoxProps) => {
  const loading = useMinimumLoadingTime(isLoading, minimumTime ?? 100);

  return loading ? (
    <Box
      width="100%"
      height="100%"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress disableShrink size={55} sx={{ mb: 2 }} />
    </Box>
  ) : (
    <>{children}</>
  );
};

export default LoadingBox;
