import { CollectionOutputDeploymentTokenIdAssignmentStrategyEnum } from '@/api-client';
import { Alert, AlertProps, AlertTitle } from '@mui/material';
import { useMemo } from 'react';

interface TokenAssignmentStrategyAlertProps extends AlertProps {
  strategy: CollectionOutputDeploymentTokenIdAssignmentStrategyEnum;
}

export const TokenAssignmentStrategyAlert = ({
  strategy,
  ...rest
}: TokenAssignmentStrategyAlertProps) => {
  const alertText = useMemo(() => {
    switch (strategy) {
      case CollectionOutputDeploymentTokenIdAssignmentStrategyEnum.Automatic:
        return 'The platform will automatically assign a token ID when locking items.';
      case CollectionOutputDeploymentTokenIdAssignmentStrategyEnum.Manual:
        return 'Token IDs will have to be manually specified when locking items.';
      case CollectionOutputDeploymentTokenIdAssignmentStrategyEnum.External:
        return 'The smart contract will assign token IDs when minting.';
      default:
        return '';
    }
  }, [strategy]);

  if (!alertText) return null;

  return (
    <Alert severity="info" color="info" {...rest}>
      <AlertTitle>{strategy}</AlertTitle>
      {alertText}
    </Alert>
  );
};

export default TokenAssignmentStrategyAlert;
