import {
  TransactionOutput,
  TransactionsPaginatedOutputResultsInner,
  TransactionsPaginatedOutput,
} from '@/api-client';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { adminApi, HOT_REFETCH_INTERVAL_MILLISECONDS } from '../api';
import {
  TRANSACTIONS_QUERY_KEY,
  API_SORT_DIRECTION_ASC,
  API_SORT_DIRECTION_DESC,
} from '../constants';
import { ValidBillingStatus, ValidTxType } from '@/types/pagevisit';
import { testModeState } from '@/utils/states/testModeState';

export const useTransaction = (transactionId?: string) => {
  return useQuery({
    queryKey: [TRANSACTIONS_QUERY_KEY, transactionId],
    queryFn: () =>
      adminApi.transaction
        .transactionsTransactionIdGet(transactionId as string)
        .then(res => res.data),
    enabled: Boolean(transactionId),
  });
};

export const useTransactions = (
  pageSize?: number,
  cursor?: string,
  sort?: {
    fieldName?: string;
    direction?: typeof API_SORT_DIRECTION_ASC | typeof API_SORT_DIRECTION_DESC;
  },
  filter?: {
    id?: string;
    networkId?: number;
    txType?: ValidTxType;
    state?: 'PENDING' | 'SUBMITTED' | 'COMPLETED' | 'CANCELLED' | 'QUEUED';
    signer?: string;
    itemId?: string;
    collectionId?: string;
    billingStatus?: ValidBillingStatus;
  }
) => {
  const [output, setOutput] = useState<any>({} as any);
  const { active: testnet } = useSnapshot(testModeState);

  const getTransactions = useQuery({
    queryKey: [
      TRANSACTIONS_QUERY_KEY,
      sort,
      filter?.networkId,
      filter?.txType,
      filter?.state,
      filter?.signer,
      filter?.itemId,
      filter?.collectionId,
      filter?.billingStatus,
      pageSize,
      cursor,
      testnet,
    ],
    queryFn: () =>
      adminApi.transaction
        .transactionsGet(
          sort?.direction,
          cursor,
          pageSize,
          filter?.networkId ? [filter?.networkId] : undefined,
          filter?.txType ? [filter?.txType] : undefined,
          filter?.state ? [filter?.state] : undefined,
          filter?.signer ? [filter?.signer] : undefined,
          filter?.itemId,
          filter?.collectionId,
          sort?.fieldName ? 'CREATED_AT' : undefined,
          undefined,
          undefined,
          filter?.billingStatus,
          testnet,
        )
        .then(res => res.data),
    refetchInterval: HOT_REFETCH_INTERVAL_MILLISECONDS,
  });

  const getTransaction: UseQueryResult<TransactionOutput, unknown> = useQuery({
    queryKey: [TRANSACTIONS_QUERY_KEY, filter?.id],
    queryFn: () =>
      adminApi.transaction.transactionsTransactionIdGet(filter?.id as string).then(res => res.data),
    enabled: Boolean(filter?.id),
    refetchInterval: HOT_REFETCH_INTERVAL_MILLISECONDS,
  });

  useEffect(() => {
    if (filter?.id) {
      setOutput({
        ...getTransaction,
        data: {
          cursor: null,
          results: getTransaction.data
            ? [getTransaction.data as TransactionsPaginatedOutputResultsInner]
            : [],
          total_results: getTransaction.data ? (getTransaction.data.id ? 1 : 0) : 0,
          has_more: false,
        },
      });
    } else {
      setOutput(getTransactions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getTransaction.data,
    getTransaction.isPending,
    getTransaction.isError,
    getTransactions.data,
    getTransactions.error,
    getTransactions.isPending,
    filter?.id,
  ]);

  return output as UseQueryResult<TransactionsPaginatedOutput, unknown>;
};
