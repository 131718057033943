import { MAIN_ROUTES } from '@/routes';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { generatePath } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { Link as MuiLink } from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined';
import { ROUTE_NAME } from '@/utils/constants';
import { useMemo } from 'react';
import { addAlphaToHexColor } from '@/utils/helpers';
import { customPalette } from '@/theme/custom';

export interface ClickToNavProps {
  url?: string;
  routeName?: ROUTE_NAME;
  routeParams?: { [key: string]: any };
  routeQueryFilter?: string;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  mimicButton?: boolean;
  mimicButtonColorHex?: string;
  hideIcon?: boolean;
}

/**
 * The base component for rendering a link to a route or url.
 * Could be an absolute or relative url (url vs routeName).
 * @param url The url to navigate to.
 * @param routeName The name of the route to navigate to.
 * @param routeParams The route params to pass to the route.
 * @param routeQueryFilter The query filter to pass to the route.
 * @param children The children to render.
 * @param sx The sx prop to pass to the MuiLink component.
 * @param mimicButton Whether or not to mimic a button. (default is @false)
 * @param mimicButtonColorHex The color to use for the button. (default is @primary.main)
 * @param hideIcon When absolute url default to showing external link icon.
 */
export const ClickToNav = ({
  url,
  routeName,
  routeParams,
  children,
  sx,
  mimicButton = false,
  mimicButtonColorHex = '',
  routeQueryFilter = '',
  hideIcon = false,
}: ClickToNavProps) => {
  const { palette } = useTheme();
  const route = MAIN_ROUTES.find(r => r.name === routeName);

  const coercedmimicButtonColorHex = useMemo(() => {
    return mimicButtonColorHex ? mimicButtonColorHex : palette.primary.main;
  }, [mimicButtonColorHex, palette]);

  const coercedRouteParams = useMemo(() => {
    if (!routeParams) return {};
    return Object.keys(routeParams).reduce((acc, routeParamKey) => {
      const routeParamValue = routeParams[routeParamKey];
      const coercedRouteParamValue = routeParamValue ?? '';
      return { ...acc, [routeParamKey]: coercedRouteParamValue };
    }, {});
  }, [routeParams]);

  if (!route && !url) {
    return <>{children}</>;
  }

  let path = !url ? generatePath(route?.path as string, coercedRouteParams) : null;
  if (routeQueryFilter && path) {
    path = `${path}?${routeQueryFilter}`;
  }

  const to = path ?? url ?? '';
  const isUrlAbsolute = url ? !url.startsWith('/') : false;

  return (
    <Tooltip title={`Click to navigate to ${to}`} followCursor>
      <MuiLink
        to={to}
        target={url && isUrlAbsolute ? '_blank' : '_self'}
        onClick={() => {
          if (url && isUrlAbsolute) window.open(url, '_blank');
        }}
        component={ReactRouterLink}
        sx={{
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          fontSize: '1.2em',
          '&:hover': {
            '.MuiTypography-root': {
              fontWeight: 'bold',
              textDecoration: 'underline',
            },
            textDecoration: 'underline',
            '.MuiSvgIcon-root': {
              //scale: 1.05,
            },
            background: mimicButton
              ? `${addAlphaToHexColor(coercedmimicButtonColorHex, 4)}`
              : 'inherit',
            border: mimicButton ? `1px solid ${coercedmimicButtonColorHex}` : 'inherit',
          },
          border: mimicButton
            ? `1px solid ${addAlphaToHexColor(coercedmimicButtonColorHex, 75)}`
            : 'none',
          color: coercedmimicButtonColorHex,
          borderRadius: mimicButton ? '4px' : 'none',
          padding: mimicButton ? '2px 8px' : 'none',
          ...(sx || {}),
        }}
      >
        {isUrlAbsolute && !hideIcon && (
          <OpenInNewIcon
            sx={{
              mr: '4px',
              color: mimicButton ? coercedmimicButtonColorHex : customPalette.colors.cnftDarkGrey,
            }}
          />
        )}
        <>{children}</>
      </MuiLink>
    </Tooltip>
  );
};

export default ClickToNav;
