import {
  PublicNetworksPaginatedOutputResultsInner,
  PublicNetworksPaginatedOutput,
} from '@/api-client-public';
import { publicApi, DEFAULT_API_PAGINATION_LIMIT } from '../api';
import { PUBLIC_NETWORKS_QUERY_KEY } from '../constants';
import useAllPages from './useAllPages';

export const useAllPublicNetworks = () => {
  const result = useAllPages<
    PublicNetworksPaginatedOutputResultsInner,
    PublicNetworksPaginatedOutput
  >(
    [PUBLIC_NETWORKS_QUERY_KEY],
    (cursor: string | undefined) =>
      publicApi.network.networksGet(undefined, cursor, DEFAULT_API_PAGINATION_LIMIT),
    {
      staleTime: 1000 * 60 * 60 * 4, // 4 hours
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    }
  );
  return result;
};

export const usePublicNetwork = (networkId?: number) => {
  const { data: networks } = useAllPublicNetworks();
  const network = networks.find(n => n.id === networkId) ?? null;
  return network;
};
