import { Alert, AlertTitle } from '@mui/material';

export const EmailClaimDurationAlert = () => {
  return (
    <Alert severity="info">
      <AlertTitle>Email Claim Duration</AlertTitle>
      This indicates how much time a buyer has to to complete an email claim flow.
    </Alert>
  );
};

export default EmailClaimDurationAlert;
