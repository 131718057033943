import { jwtDecode, JwtPayload } from 'jwt-decode';
import { Box, Button, CircularProgress, Skeleton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useResendEmail } from '@/utils/hooks/user';
import { setAuthAccessToken } from '@/utils/states/authState';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';

const AUTH0_VERIFY_CLAIM = 'https://consensys-nft.com/claims/email_verified';

interface CustomJwtToken extends JwtPayload {
  [AUTH0_VERIFY_CLAIM]: string;
}

interface EmailVerificationPageProps {
  accessToken?: string;
}
const EmailVerificationPage = ({ accessToken }: EmailVerificationPageProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [checkingVerificationStatus, setCheckingVerificationStatus] = useState(false);
  const { mutate: resendVerificationEmail, isPending: sendingVerificationEmail } = useResendEmail();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const auth0_user = jwtDecode<JwtPayload>(accessToken as string);
  const auth0_id = auth0_user?.sub as string;

  const authFailed = useCallback(() => {
    enqueueSnackbar('Failed to authenticate. Redirecting to log in page...', { variant: 'info' });
    setTimeout(() => {
      navigate('/auth0/logout');
    }, 2500);
  }, [enqueueSnackbar, navigate]);

  const checkVerificationStatus = useCallback(() => {
    setCheckingVerificationStatus(true);
    getAccessTokenSilently({ cacheMode: 'off' })
      .then(token => {
        const decoded = jwtDecode<CustomJwtToken>(token);
        const email_verified = Boolean(decoded[AUTH0_VERIFY_CLAIM]);
        if (email_verified) {
          enqueueSnackbar('Your email has been successfully verified', { variant: 'success' });
          setAuthAccessToken(token);
          navigate('/auth0/login');
        } else {
          enqueueSnackbar('Your email has not been verified yet', { variant: 'warning' });
        }
      })
      .catch(() => {
        authFailed();
      })
      .finally(() => {
        setCheckingVerificationStatus(false);
      });
  }, [authFailed, enqueueSnackbar, getAccessTokenSilently, navigate]);

  if (!accessToken || !isAuthenticated) {
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'grid',
          rowGap: '16px',
          gridTemplateRows: '1fr',
        }}
      >
        <Skeleton key="pending-user" variant="rectangular" />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        rowGap: 3,
      }}
    >
      <Typography variant="h1" textAlign="center">
        Almost There!
      </Typography>

      <Typography variant="h5" gutterBottom textAlign="center">
        Please verify your email to login.
      </Typography>

      <Typography variant="body2" gutterBottom textAlign="center">
        You should have received an email which will guide you through the process of verifying your
        email.
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
          columnGap: 2,
          justifyContent: 'center',
          alignItems: 'center',
          '.MuiButton-root': {
            width: '275px', // Size evenly
          },
        }}
      >
        <Button
          title="Sends a verification email to your email address so that you can complete the sign up process"
          variant="outlined"
          disabled={sendingVerificationEmail || checkingVerificationStatus}
          startIcon={sendingVerificationEmail ? <CircularProgress size={25} /> : undefined}
          onClick={() => resendVerificationEmail(auth0_id)}
        >
          {sendingVerificationEmail ? 'Sending verification email...' : 'Resend verification email'}
        </Button>

        <Button
          disabled={sendingVerificationEmail || checkingVerificationStatus}
          sx={{ width: 'max-content' }}
          variant="text"
          onClick={checkVerificationStatus}
        >
          {checkingVerificationStatus ? 'Verifying...' : 'Check verification status'}
        </Button>
      </Box>
    </Box>
  );
};

export default EmailVerificationPage;
