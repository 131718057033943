import { Box, Button, Typography } from '@mui/material';
import { useOrganizationBalance } from '@/services/billing';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { RainbowKitProvider, getDefaultWallets } from '@rainbow-me/rainbowkit';
import { NETWORK_ID_TO_CHAIN } from './types';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';
import { paymentsNetworkId, infuraPublicApiKey, wcProjectId } from '@/config';
import LoadingBox from '@/common/container/LoadingBox';
import { useSnapshot } from 'valtio';
import { authState } from '@/utils/states/authState';
import { useMemo, useState } from 'react';
import SimpleModal from '@/common/SimpleModal';
import AddCryptoForm from './AddCryptoForm';
import AddFiatForm from './AddFiatForm';
import WithdrawCryptoForm from './WithdrawCryptoForm';
import { usdcToDecimalString } from '@/utils/helpers';

export const BalanceOverview = () => {
  const { isLoading, isError, data } = useOrganizationBalance();
  const { platformOrg } = useSnapshot(authState);
  const [addCryptoModalOpen, setAddCryptoModalOpen] = useState(false);
  const [withdrawCryptoModalOpen, setWithdrawCryptoModalOpen] = useState(false);
  const [addFiatModalOpen, setAddFiatModalOpen] = useState(false);

  const chain = NETWORK_ID_TO_CHAIN[paymentsNetworkId];

  const { chains, publicClient } = configureChains(
    [chain],
    [infuraProvider({ apiKey: infuraPublicApiKey }), publicProvider()]
  );

  const { connectors } = getDefaultWallets({
    appName: `CNFT Developer UI`,
    projectId: wcProjectId,
    chains,
  });
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });

  function closeModal() {
    setAddCryptoModalOpen(false);
    setAddFiatModalOpen(false);
    setWithdrawCryptoModalOpen(false);
  }

  const anyModalOpen = addCryptoModalOpen || addFiatModalOpen || withdrawCryptoModalOpen;
  const creditsWei = useMemo(() => {
    if (!data) {
      return 0;
    }
    // gross, until we have this in the payload, safe conversion: string -> js float -> int, then convert back to a string in display logic
    const totalF = Math.round(parseFloat(data.total) * 10 ** 6);
    const cryptoF = Math.round(parseFloat(data.crypto) * 10 ** 6);
    const fiatF = Math.round(parseFloat(data.fiat) * 10 ** 6);
    return totalF - cryptoF - fiatF;
  }, [data]);

  // todo: move this to overview container or a common location
  if (isError) {
    return <p>Error: Could not load balance data</p>;
  }

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <LoadingBox isLoading={isLoading} minimumTime={250}>
          <Box justifyContent="space-between" sx={{ mt: 2 }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h5">Total Balance</Typography>
              <Typography>{data?.total ?? 0}</Typography>
            </Box>

            <hr />

            <Box display="flex" justifyContent="space-between" sx={{ alignItems: 'center' }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="caption">Crypto Balance</Typography>
                <Typography>{data?.crypto ?? 0} USDC</Typography>
              </Box>

              <Box display="flex">
                <Button
                  disabled={anyModalOpen}
                  variant="contained"
                  onClick={() => setAddCryptoModalOpen(true)}
                  sx={{ height: '36.5px' }}
                >
                  Add Funds
                </Button>
                <Button
                  disabled={anyModalOpen}
                  variant="outlined"
                  onClick={() => setWithdrawCryptoModalOpen(true)}
                  sx={{ ml: '1em', height: '36.5px' }}
                >
                  Withdraw
                </Button>
              </Box>
            </Box>

            <hr />

            <Box display="flex" justifyContent="space-between" sx={{ alignItems: 'center' }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="caption">Fiat Balance</Typography>
                <Typography>{'$' + (data?.fiat ?? 0)}</Typography>
              </Box>

              <Box>
                <Button
                  disabled={anyModalOpen}
                  variant="contained"
                  onClick={() => setAddFiatModalOpen(true)}
                >
                  Add Funds
                </Button>
              </Box>
            </Box>

            {creditsWei > 0 && (
              <>
                <hr />
                <Box>
                  <Typography variant="caption">Credits</Typography>
                  <Typography>{usdcToDecimalString(creditsWei)}</Typography>
                </Box>
              </>
            )}
          </Box>
        </LoadingBox>

        {platformOrg && (
          <>
            <SimpleModal open={addCryptoModalOpen} onClose={closeModal} title="Add funds">
              <AddCryptoForm orgId={platformOrg.id} onCancel={closeModal} onSuccess={closeModal} />
            </SimpleModal>

            <SimpleModal open={withdrawCryptoModalOpen} onClose={closeModal} title="Withdraw">
              <WithdrawCryptoForm
                orgId={platformOrg.id}
                onCancel={closeModal}
                onSuccess={closeModal}
              />
            </SimpleModal>

            <SimpleModal open={addFiatModalOpen} onClose={closeModal} title="Add funds">
              <AddFiatForm orgId={platformOrg.id} onCancel={closeModal} onSuccess={closeModal} />
            </SimpleModal>
          </>
        )}

        {platformOrg && (
          <>
            <SimpleModal open={addCryptoModalOpen} onClose={closeModal} title="Add funds">
              <AddCryptoForm orgId={platformOrg.id} onCancel={closeModal} onSuccess={closeModal} />
            </SimpleModal>

            <SimpleModal open={addFiatModalOpen} onClose={closeModal} title="Add funds">
              <AddFiatForm orgId={platformOrg.id} onCancel={closeModal} onSuccess={closeModal} />
            </SimpleModal>
          </>
        )}
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default BalanceOverview;
