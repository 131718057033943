import { Box, LinearProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import HideImageIcon from '@mui/icons-material/HideImageOutlined';
import { getMimeType } from '@/utils/helpers';
import { AUDIO_MIME_TYPES, VIDEO_MIME_TYPES } from '@/utils/constants';
import ClickToNav from '@/common/data/ClickToNav';
import ClipboardContent from '@/common/data/ClipboardContent';
import ImageIcon from '@mui/icons-material/ImageOutlined';
import ReactPlayer from 'react-player';
import AnimationIcon from '@mui/icons-material/AnimationOutlined';

interface ItemContentProps {
  contentUrl?: string;
  placeholderIcon?: any;
}

const enum ItemContentType {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
}

const ICON_PLACEHOLDER_SX_PROPS = { height: '75%', width: '75%', borderRadius: '15px' };

export const ITEM_CONTENT_IMAGE_PLACEHOLDER = <ImageIcon sx={{ ...ICON_PLACEHOLDER_SX_PROPS }} />;

export const ITEM_CONTENT_VIDEO_AUDIO_PLACEHOLDER = (
  <AnimationIcon sx={{ ...ICON_PLACEHOLDER_SX_PROPS }} />
);

export const ItemContent = ({
  contentUrl,
  placeholderIcon, // Assume content is image as a fallback.
}: ItemContentProps) => {
  const [contentType, setContentType] = useState<ItemContentType | null>(null);
  const [contentError, setContentError] = useState(false);
  const [contentLoading, setContentLoading] = useState(!!contentUrl);

  useEffect(() => {
    if (!contentUrl) {
      setContentType(null);
      return;
    }

    async function setContentTypeByResponse(url: string) {
      const responseMimeType = await getMimeType(url);

      if (!responseMimeType) setContentType(null);
      else if (VIDEO_MIME_TYPES.includes(responseMimeType)) setContentType(ItemContentType.VIDEO);
      else if (AUDIO_MIME_TYPES.includes(responseMimeType)) setContentType(ItemContentType.AUDIO);
      else setContentType(ItemContentType.IMAGE);
    }
    setContentTypeByResponse(contentUrl);
  }, [contentUrl]);

  useEffect(() => {
    setContentType(null);
    setContentError(false);
    setContentLoading(false);
  }, [contentUrl]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        img: { height: '100%', width: '100%', objectFit: 'contain' },
      }}
    >
      {/* Error section */}
      {contentError && contentUrl && contentType === ItemContentType.IMAGE && (
        <HideImageIcon
          sx={{ position: 'absolute', height: '75%', width: '75%', borderRadius: '15px' }}
        />
      )}

      {contentError &&
        contentUrl &&
        (contentType === ItemContentType.VIDEO || contentType === ItemContentType.AUDIO) && (
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1,
              width: '100%',
              height: '100%',
            }}
          >
            <Typography textAlign="center" variant="body1" paragraph>
              Unable to play in browser
            </Typography>

            <ClipboardContent tooltipContext="video image url" clipboardValue={contentUrl}>
              <Typography variant="caption" paragraph>
                {contentUrl?.substring(0, 50) + '...'}
              </Typography>
            </ClipboardContent>

            <ClickToNav url={contentUrl}>Open File in New Tab</ClickToNav>
          </Box>
        )}

      {/* Placeholder section */}
      {!contentError && !contentUrl && placeholderIcon && (
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%' }}>
          {placeholderIcon}
        </Box>
      )}

      {/* Actual content section */}
      {contentUrl && contentType && (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {contentLoading && (
            <Box
              sx={{
                zIndex: 1,
                background: 'paper.main',
                top: '-10px',
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                position: 'absolute',
              }}
            >
              <LinearProgress color="secondary" sx={{ width: '100%' }} />
            </Box>
          )}

          {(contentType === ItemContentType.VIDEO || contentType === ItemContentType.AUDIO) && (
            <ReactPlayer
              onError={() => {
                setContentLoading(false);
                setContentError(true);
              }}
              url={contentUrl}
              controls={true}
              width="100%"
              height={contentType === ItemContentType.AUDIO ? '50px' : '100%'}
            />
          )}

          {contentType === ItemContentType.IMAGE && (
            <img
              src={contentUrl}
              onError={() => {
                setContentLoading(false);
                setContentError(true);
              }}
              onLoad={() => {
                setContentLoading(false);
                setContentError(false);
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ItemContent;
