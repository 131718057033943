import { setAxiosAuthHeader as setAxiosAuthHeader } from '@/utils/api';
import { proxy } from 'valtio';
import { subscribeKey } from 'valtio/utils';
import { CurrentUserOutputOrganization, CurrentUserOutputUser } from '@/api-client';
import { AuthState } from '@/utils/types';

const initialState: AuthState = {
  accessToken: '',
};

export const authState = proxy<AuthState>(initialState);

subscribeKey(authState, 'accessToken', (token?: string) => {
  setAxiosAuthHeader(token);
});

// ACTIONS
export const setAuthAccessToken = (accessToken?: string) => {
  authState.accessToken = accessToken;
};

export const setAuthPlatformData = ({
  user,
  org,
  permissions,
}: {
  user: CurrentUserOutputUser;
  org: CurrentUserOutputOrganization;
  permissions: Array<string>;
}) => {
  authState.platformUser = user;
  authState.platformOrg = org;
  authState.platformPermissions = permissions;
};
