import { addAlphaToHexColor } from '@/utils/helpers';

const breakpointValues = {
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1536,
};

export const customBreakpoints = {
  breakpoints: {
    keys: ['sm', 'md', 'lg', 'xl'],
    up: (key: 'sm' | 'md' | 'lg' | 'xl') =>
      `@media (min-width:${breakpointValues[key] as number}px)`,
  },
};

export const customPalette = {
  gradients: {
    backdrop:
      'linear-gradient(310deg, rgb(108, 69, 182) 0%, rgb(81, 52, 137) 0%, rgb(54, 34, 91) 53%, rgb(81, 52, 137) 75%, rgb(17, 10, 29) 100%)',
  },
  colors: {
    cnftDarkGrey: '#212121',
    cnftDarkGreyDark: '#000000',
    cnftDarkGreyLight: '#484848',
    cnftDarkGreyLighter: '#f8f8f8',
    cnftPageBackground: '#fffdfd',

    cnftHover: '#F0FF14', // over black font color
    cnftDocHint: '#BDB4CF',

    cnftBlue: '#0C6CF7',
    cnftBlueDark: '#0039B7',
    cnftBlueLight: '#658fff',


    primaryText: addAlphaToHexColor('#000000', 85),
    secondaryText: addAlphaToHexColor('#000000', 60),
    disabledText: addAlphaToHexColor('#000000', 40),

    success: '#66BB6A',
    info: '#50328F',
    warning: '#FFA726',
    error: '#F44336',

    black: '#000',
    white: '#fff',
    lightGrey: '#d3d3d3',

    offWhite: {
      grey: 'whitesmoke',
      background: '#FDFCFE',
    },

    table: {
      even: '#ffffff',
      odd: '#fdfcfe',
    },  
    testMode: {
      barColor: '#FFFFFF',
      barBackground: '#FFA726',
      drawerColor: '#F09510',
      drawerBackground: addAlphaToHexColor('#FFA726', 10),
    },
  },
};
