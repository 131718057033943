import { ApiKeyPaginatedOutputResultsInner } from '@/api-client';
import PermissionModalButton from '@/common/button/PermissionModal';
import PlatformEntityIdentifier from '@/common/data/PlatformEntityIdentifier';
import PrettyDate from '@/common/data/PrettyDate';
import ServerPaginatedDataGrid, {
  DEFAULT_PAGINATED_GRID_PAGE,
  PaginatedGridPage,
} from '@/common/data/grid/ServerPaginated';
import { BaseRow } from '@/common/data/grid/constants';
import { useApiKey, useApiKeys } from '@/utils/hooks/apiKey';
import Box from '@mui/material/Box';
import { GridColDef } from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import orderBy from 'lodash/orderBy';
import { useCallback, useEffect, useMemo } from 'react';
import { proxy, useSnapshot } from 'valtio';
import DeleteApiKeyForm from './DeleteForm';
import OverviewContainer from '@/common/container/OverviewContainer';
import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import { useLocation, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Button } from '@mui/material';

// Row specific to this grid.
interface ApiKeyGridRow extends BaseRow {
  name: string;
  expiration?: string | null;
  lastUsed?: string | null;
}

// Columns specific to this grid.
const columns: Array<GridColDef<ApiKeyGridRow>> = [
  {
    field: 'id',
    headerName: 'ID',
    width: 150,
    renderCell: (params: { row: ApiKeyGridRow }) => (
      <PlatformEntityIdentifier
        tooltipContext="this API key ID"
        entityId={params.row.id as string}
      />
    ),
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
  },
  {
    field: 'lastUsed',
    headerName: 'Last Used',
    flex: 1,
    minWidth: 175,
    renderCell: (params: { row: ApiKeyGridRow }) => {
      return params.row.lastUsed ? <PrettyDate date={params.row.lastUsed} /> : <>Never</>;
    },
  },
];

// Initial state specific to this grid.
const initialState: GridInitialStateCommunity = {
  columns: {
    columnVisibilityModel: {
      id: false,
    },
  },
};

// Will map API data (in this case a mix of multiple sources) to rows.
const mapToRow = (apiKey: ApiKeyPaginatedOutputResultsInner): ApiKeyGridRow => {
  return {
    id: apiKey.id,
    expiration: apiKey.expiration,
    name: apiKey.name,
    lastUsed: apiKey.last_used,
    original: apiKey,
  };
};

const pageState: PaginatedGridPage<ApiKeyGridRow> = proxy({
  ...DEFAULT_PAGINATED_GRID_PAGE,
});

const ApiKeysGrid = () => {
  // Get readonly snaps from the state.
  const pageSnap = useSnapshot(pageState);
  const navigate = useNavigate();
  const location = useLocation();
  const { state: urlState } = location;

  const apiKeyId = useMemo(() => {
    return urlState ? (urlState['apiKeyId'] as string) : null;
  }, [urlState]);

  // Get all API data for current render.
  const { data: apiKeysResponse, isLoading: isKeysLoading } = useApiKeys(
    pageSnap.pageSize,
    pageSnap.cursor
  );
  const { data: apiKeyResponse, isLoading: isKeyLoading } = useApiKey(apiKeyId as string);

  // Populate rows when any API data changes.
  useEffect(() => {
    pageState.rows =
      orderBy(apiKeysResponse?.results, 'name').map(apiKey => {
        return mapToRow(apiKey);
      }) ?? [];
    pageState.nextCursor = apiKeysResponse?.cursor ?? DEFAULT_PAGINATED_GRID_PAGE.cursor;
  }, [location.state, apiKeysResponse?.results, apiKeysResponse?.cursor]);

  const doubleClickNavigationFunction = useCallback(
    (row: ApiKeyGridRow) => {
      return navigate('/settings?tab=api-keys', { state: { cleared: null, apiKeyId: row.id } });
    },
    [navigate]
  );

  if (apiKeyId) {
    return (
      <Box>
        <OverviewContainer
          isLoading={isKeyLoading}
          gridColumnDivisions={2}
          columnGap={1}
          gridRowDivisions={1}
          notFound={!apiKeysResponse?.total_results}
          fullHeight={false}
        >
          <BasicInfoDisplay title="Name">{apiKeyResponse?.name}</BasicInfoDisplay>
          <BasicInfoDisplay title="Last Used">
            {apiKeyResponse?.last_used
              ? DateTime.fromISO(apiKeyResponse?.last_used as string).toLocaleString(
                  DateTime.DATETIME_SHORT_WITH_SECONDS
                )
              : 'Never'}
          </BasicInfoDisplay>
        </OverviewContainer>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'left',
            alignItems: 'center',
            gap: 2,
            mt: 2,
          }}
        >
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              navigate('/settings?tab=api-keys', {
                replace: true,
                state: {
                  webHookId: null,
                  webHookName: null,
                  webHookStatus: null,
                  webHookLastUsed: null,
                },
              });
            }}
            sx={{ minWidth: '50px' }}
          >
            Cancel
          </Button>

          <PermissionModalButton
            modalTitle="Delete API key"
            modalDescription={'Delete API Key'}
            variant="delete"
            buttonLabel="Delete"
            requiredPermissions={['api_keys:delete']}
            buttonProps={{ variant: 'outlined' }}
          >
            {({ closeModal }) => {
              const close = () => {
                closeModal();
                navigate('/settings?tab=api-keys', {
                  replace: true,
                  state: { cleared: apiKeyId, apiKeyId: null },
                });
              };
              return <DeleteApiKeyForm apiKeyId={apiKeyId} onSuccess={close} onCancel={close} />;
            }}
          </PermissionModalButton>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ height: 'calc(100% - 65px)', width: '100%' }}>
      <ServerPaginatedDataGrid
        columns={columns}
        initialState={initialState}
        // eslint-disable-next-line valtio/state-snapshot-rule
        activePageProxy={pageState}
        pageLoading={isKeysLoading}
        totalRowCount={apiKeysResponse?.total_results ?? 0}
        getRowDoubleClickNavigationFunction={doubleClickNavigationFunction}
      />
    </Box>
  );
};

export default ApiKeysGrid;
