import { ContractInteractionHookOptions, ContractInteractionHookReturn } from '@/common/types';
import { usePlatformDepositDeposit } from '@/contracts/generated';
import { useCallback, useEffect, useState } from 'react';
import { useWaitForTransaction } from 'wagmi';

export function useContractInteraction(
  config: any,
  options: ContractInteractionHookOptions = {}
): ContractInteractionHookReturn {
  const [txHash, setTxHash] = useState<string | null>(null);
  const { onStart, onSubmit, onError, onFinally } = options;
  const { write, writeAsync } = usePlatformDepositDeposit(config);

  const handleSubmit = useCallback(async () => {
    if (writeAsync) {
      try {
        if (onStart) {
          await onStart();
        }

        const tx = await writeAsync();
        setTxHash(tx.hash);

        if (onSubmit) {
          await onSubmit(tx.hash);
        }
      } catch (error) {
        console.error(error);
        if (onError) {
          onError(error);
        }
      } finally {
        if (onFinally) {
          onFinally();
        }
      }
    }
  }, [writeAsync, onStart, onSubmit, onError, onFinally]);

  const { data: txReceipt } = useWaitForTransaction({
    hash: txHash as `0x${string}`,
  });

  useEffect(() => {
    if (txReceipt && options?.onReceipt) {
      options?.onReceipt(txReceipt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txReceipt]);

  return {
    write,
    writeAsync,
    handleSubmit,
  };
}
