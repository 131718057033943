import { Box, Paper, Typography } from '@mui/material';
import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import { useItem } from '@/utils/hooks/item';
import { useCollection } from '@/utils/hooks/collection';
import OverviewContainer from '@/common/container/OverviewContainer';
import { usePurchaseIntent } from '@/utils/hooks/purchaseIntent';
import { useListing } from '@/utils/hooks/listing';
import PrettyDate from '@/common/data/PrettyDate';
import ReactJsonThemed from '@/common/data/PaletteModeReactJson';
import Status from '@/common/data/Status';
import BlockExplorerFilter, { BlockExplorerFilterType } from '@/common/data/BlockExplorerFilter';

interface PurchaseIntentDetailsOverviewProps {
  purchaseIntentId?: string;
}

const PURCHASE_INTENT_OVERVIEW_ROW_DIVISIONS = 8;
const PURCHASE_INTENT_OVERVIEW_COLUMN_DIVISIONS = 4;

export const PurchaseIntentDetailsOverview = ({
  purchaseIntentId,
}: PurchaseIntentDetailsOverviewProps) => {
  const {
    data: purchaseIntent,
    isPending: purchaseIntentLoading,
    isFetched: purchaseIntentFetched,
  } = usePurchaseIntent(purchaseIntentId ?? '');
  const { data: listing } = useListing(purchaseIntent?.listing_id ?? '');
  const { data: item } = useItem(listing?.item_id ?? '');
  const { data: collection } = useCollection(listing?.collection_id ?? item?.collection_id ?? '');

  return (
    <OverviewContainer
      isLoading={purchaseIntentLoading}
      gridColumnDivisions={PURCHASE_INTENT_OVERVIEW_COLUMN_DIVISIONS}
      gridRowDivisions={PURCHASE_INTENT_OVERVIEW_ROW_DIVISIONS}
      notFound={purchaseIntentFetched && !purchaseIntent}
    >
      <BasicInfoDisplay title="Created On">
        {purchaseIntent?.created_at ? <PrettyDate date={purchaseIntent?.created_at} /> : 'N/A'}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Expires On">
        {purchaseIntent?.expiration ? <PrettyDate date={purchaseIntent?.expiration} /> : 'N/A'}
      </BasicInfoDisplay>

      <BasicInfoDisplay
        title="Status"
        sx={{ gridColumnStart: `span ${PURCHASE_INTENT_OVERVIEW_COLUMN_DIVISIONS - 2}` }}
      >
        <Status status={purchaseIntent?.status ?? 'N/A'} />
      </BasicInfoDisplay>

      <Box sx={{ gridColumnStart: `span ${PURCHASE_INTENT_OVERVIEW_COLUMN_DIVISIONS}` }}>
        <Typography variant="h6" sx={{ textDecoration: 'underline' }}>
          Fulfillment
        </Typography>
      </Box>

      <BasicInfoDisplay title="Address">
        {collection?.deployment?.network_id && purchaseIntent?.eth_address ? (
          <BlockExplorerFilter
            filterType={BlockExplorerFilterType.ADDRESS}
            filterValue={purchaseIntent.eth_address}
            networkId={collection.deployment.network_id}
          />
        ) : (
          <>{purchaseIntent?.eth_address ?? 'N/A'}</>
        )}
      </BasicInfoDisplay>

      <BasicInfoDisplay
        sx={{ gridColumnStart: `span ${PURCHASE_INTENT_OVERVIEW_COLUMN_DIVISIONS - 1}` }}
        title="Email"
      >
        {purchaseIntent?.email ?? 'N/A'}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Fulfillment Status">
        {purchaseIntent?.fulfillment_status ? (
          <Status status={purchaseIntent.fulfillment_status} />
        ) : (
          'N/A'
        )}
      </BasicInfoDisplay>

      <BasicInfoDisplay
        sx={{ gridColumnStart: `span ${PURCHASE_INTENT_OVERVIEW_COLUMN_DIVISIONS - 1}` }}
        title="Fulfillment method"
      >
        {purchaseIntent?.fulfillment_method ? (
          <Status status={purchaseIntent.fulfillment_method} />
        ) : (
          'N/A'
        )}
      </BasicInfoDisplay>

      <Box sx={{ gridColumnStart: `span ${PURCHASE_INTENT_OVERVIEW_COLUMN_DIVISIONS}` }}>
        <Typography variant="h6" sx={{ textDecoration: 'underline' }}>
          Payment
        </Typography>
      </Box>

      <BasicInfoDisplay title="Provider">
        {purchaseIntent?.payment_provider ?? 'N/A'}
      </BasicInfoDisplay>

      <BasicInfoDisplay
        title="Session ID"
        sx={{ gridColumnStart: `span ${PURCHASE_INTENT_OVERVIEW_COLUMN_DIVISIONS - 1}` }}
      >
        {purchaseIntent?.stripe_payment_intent_id ?? purchaseIntent?.stripe_payment_intent_id}
      </BasicInfoDisplay>

      <Paper
        sx={{
          width: '100%',
          height: '100%',
          p: 2,
          gridColumnStart: `span ${PURCHASE_INTENT_OVERVIEW_COLUMN_DIVISIONS}`,
        }}
      >
        <BasicInfoDisplay title="Price Data" sx={{ height: '100%', width: '100%' }}>
          {purchaseIntent?.provider_price ? (
            <Box sx={{ width: '100%', height: 'calc(100% - 50px)' }}>
              <ReactJsonThemed src={purchaseIntent?.provider_price ?? {}} />
            </Box>
          ) : (
            'N/A'
          )}
        </BasicInfoDisplay>
      </Paper>
    </OverviewContainer>
  );
};

export default PurchaseIntentDetailsOverview;
