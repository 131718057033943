import PageContentTabs, {
  ContentTab,
  OVERVIEW_TAB_LABEL,
  OVERVIEW_TAB_VALUE,
} from '@/common/page/ContentTabs';
import Page, { entityPageVisitState } from '@/common/page/Index';
import EmailClaimsGrid, {
  EmailClaimGridColumnField,
  generateDefaultColumnVisibilityModel as generateDefaultEmailClaimColumnVisiblityModel,
} from '@/email-claim/Grid';
import ListingsGrid, {
  ListingGridColumnField,
  generateDefaultColumnVisibilityModel as generateDefaultListingColumnVisibilityModel,
} from '@/listings/Grid';
import { MAIN_ROUTES } from '@/routes';
import TransactionsGrid, {
  TransactionGridColumnField,
  generateDefaultColumnVisibilityModel as generateDefaultTransactionColumnVisibilityModel,
} from '@/transactions/Grid';
import { ENTITY_PAGE_VISIT_TYPE, ROUTE_NAME } from '@/utils/constants';
import { useCollection } from '@/utils/hooks/collection';
import { useItem, useItemTitle } from '@/utils/hooks/item';

import Box from '@mui/material/Box';
import { find } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { generatePath } from 'react-router-dom';
import ItemDetailsOverview from './DetailsOverview';

const customTransactionVisibility = {
  ...generateDefaultTransactionColumnVisibilityModel(),
  [TransactionGridColumnField.ITEM_ID]: false,
  [TransactionGridColumnField.COLLECTION_ID]: false,
};

const customEmailClaimVisibility = {
  ...generateDefaultEmailClaimColumnVisiblityModel(),
  [EmailClaimGridColumnField.ITEM_ID]: false,
  [EmailClaimGridColumnField.TOKEN_ID]: false,
  [EmailClaimGridColumnField.COLLECTION_ID]: false,
};

const customListingVisibility = {
  ...generateDefaultListingColumnVisibilityModel(),
  [ListingGridColumnField.ITEM_ID]: false,
  [EmailClaimGridColumnField.COLLECTION_ID]: false,
};

const ItemDetailsPage = () => {
  const { itemId } = useParams();
  const {
    data: item,
    isPending: itemLoading,
    isFetched: itemFetched,
    isError: itemError,
  } = useItem(itemId ?? '');
  const itemTitle = useItemTitle(item?.attributes, item?.token_id);
  const { data: collection } = useCollection(item?.collection_id ?? '');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const tabs = useMemo(() => {
    const result: Array<ContentTab> = [
      {
        label: OVERVIEW_TAB_LABEL,
        value: OVERVIEW_TAB_VALUE,
        gridContent: false,
        content: <ItemDetailsOverview />,
        action: (
          <Box
            sx={{
              display: 'grid',
              columnGap: '8px',
              height: '100%',
              gridTemplateColumns: 'repeat(6, 115px)',
            }}
          ></Box>
        ),
      },
      {
        label: 'Listings',
        value: 'listings',
        content: (
          <ListingsGrid
            collectionId={collection?.id}
            itemId={itemId}
            customVisibility={customListingVisibility}
          />
        ),
      },
      {
        label: 'Email Claims',
        value: 'email-claims',
        gridContent: true,
        content: (
          <EmailClaimsGrid
            collectionId={collection?.id}
            itemId={itemId}
            customVisibility={customEmailClaimVisibility}
          />
        ),
      },
      {
        label: 'Transactions',
        value: 'transactions',
        gridContent: true,
        content: (
          <TransactionsGrid
            collectionId={item?.collection_id}
            itemId={itemId}
            customVisibility={customTransactionVisibility}
          />
        ),
      },
    ];
    return result;
  }, [item?.collection_id, itemId, collection?.id]);

  useEffect(() => {
    if (!item || !itemTitle || entityPageVisitState?.data?.platformId === item.id) return;
    entityPageVisitState.data = {
      type: ENTITY_PAGE_VISIT_TYPE.ITEM,
      context: itemTitle,
      platformId: item.id,
    };
  }, [item, itemTitle]);

  useEffect(() => {
    if (!itemId || itemError || (!item && !itemLoading && itemFetched)) {
      const collectionOverviewRoute = find(
        MAIN_ROUTES,
        route => route.name === ROUTE_NAME.COLLECTIONS_OVERVIEW
      );
      enqueueSnackbar('Item not found, returning to collection overview.', { variant: 'info' });

      if (collectionOverviewRoute?.path) {
        navigate(generatePath(collectionOverviewRoute.path));
      }
    }
  }, [itemId, itemError, item, itemLoading, itemFetched, enqueueSnackbar, navigate]);

  const collectionDetailsPath = useMemo(() => {
    const detailsRoute = MAIN_ROUTES.find(route => route.name === ROUTE_NAME.COLLECTION_DETAILS);
    if (!detailsRoute || !collection?.id) return undefined;
    return `${generatePath(detailsRoute.path, { collectionId: collection?.id })}?tab=items`;
  }, [collection?.id]);

  return (
    <Page
      testIdPrefix="item-details"
      helmet={(item?.attributes as any)?.['title'] || 'Item Details'}
      title="Item Details"
      subTitle={(item?.attributes as any)?.['title']}
      backTo={{
        routePath: collectionDetailsPath ?? '',
        buttonLabel: 'Back To Collection Items',
      }}
    >
      <PageContentTabs tabs={tabs} />
    </Page>
  );
};

export default ItemDetailsPage;
