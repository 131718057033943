import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { InputListingContextCurrencyEnum, ListingsPaginatedOutputResultsInner } from '@/api-client';
import PlatformEntityIdentifier from '@/common/data/PlatformEntityIdentifier';
import Box from '@mui/material/Box';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { useListings } from '@/utils/hooks/listing';
import { API_SORT_DIRECTION_ASC, ROUTE_NAME } from '@/utils/constants';
import CollectionDetailsOverview, {
  COLLECTION_DETAILS_OVERVIEW_POPOVER_SIZE,
} from '@/collections/DetailsOverview';
import ItemDetailsOverview from '@/items/DetailsOverview';
import PrettyDate from '@/common/data/PrettyDate';
import PopoverButton from '@/common/button/Popover';
import ListingPolicyDetailsOverview, {
  LISTING_POLICY_OVERVIEW_POPOVER_SIZE,
} from './policy/DetailsOverview';
import ReactJsonThemed from '@/common/data/PaletteModeReactJson';
import ClipboardContent from '@/common/data/ClipboardContent';
import Status from '@/common/data/Status';
import { BaseRow } from '@/common/data/grid/constants';
import { getCoercedListingPrice } from '@/utils/helpers';
import MarketingCampaignDetailsOverview, {
  CAMPAIGN_OVERVIEW_POPOVER_SIZE,
} from '@/marketing-campaigns/DetailsOverview';
import ServerPaginatedDataGrid, {
  DEFAULT_PAGINATED_GRID_PAGE,
  PaginatedGridPage,
} from '@/common/data/grid/ServerPaginated';
import { proxy, useSnapshot } from 'valtio';
import { useSortAndFilterGrid } from '@/utils/hooks/useSortAndFilterGrid';
import { getColOrItemHierarchyRoutePath } from '@/utils/route';
import { DateTime } from 'luxon';

export enum ListingGridColumnField {
  ID = 'id',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  COLLECTION_ID = 'collectionId',
  ITEM_ID = 'itemId',
  PAYMENT_PROVIDERS = 'paymentProviders',
  POLICY = 'policy',
  PRICE = 'price',
  CURRENCY = 'currency',
  QUANTITY_LISTED = 'quantityListed',
  QUANTITY_REMAINING = 'quantityRemaining',
  STATUS = 'status',
  SALE_TYPE = 'saleType',
  CAMPAIGN_ID = 'campaignId',
  // Max quantity per tx is a field in listing, but from a user POV it makes more sense to be part of the policy.
}

type ListingGridRow = { [key in ListingGridColumnField]: any } & BaseRow;

// Columns specific to this grid.
const columns: Array<GridColDef<ListingGridRow>> = [
  {
    field: ListingGridColumnField.ID,
    headerName: 'ID',
    width: 150,
    sortable: false,
    filterable: true,
    renderCell: (params: { row: ListingGridRow }) => (
      <PlatformEntityIdentifier tooltipContext="this listing ID" entityId={params.row.id} />
    ),
  },
  {
    field: ListingGridColumnField.STATUS,
    headerName: 'Status',
    sortable: false,
    filterable: true,
    width: 100,
    renderCell: (params: { row: ListingGridRow }) => <Status status={params.row.status} />,
  },
  {
    field: ListingGridColumnField.START_TIME,
    headerName: 'Start Time',
    sortable: false,
    filterable: false,
    width: 165,
    type: 'dateTime',
    valueGetter: params => DateTime.fromISO(params.row.startTime).toJSDate(),
    renderCell: (params: { row: ListingGridRow }) =>
      params.row.startTime ? <PrettyDate date={params.row.startTime} /> : <></>,
  },
  {
    field: ListingGridColumnField.END_TIME,
    headerName: 'End Time',
    sortable: false,
    filterable: false,
    width: 165,
    type: 'dateTime',
    valueGetter: params => DateTime.fromISO(params.row.startTime).toJSDate(),
    renderCell: (params: { row: ListingGridRow }) =>
      params.row.endTime ? <PrettyDate date={params.row.endTime} /> : <></>,
  },
  {
    field: ListingGridColumnField.QUANTITY_LISTED,
    headerName: 'Listed',
    sortable: false,
    filterable: false,
    width: 75,
    type: 'number',
  },
  {
    field: ListingGridColumnField.QUANTITY_REMAINING,
    headerName: 'Remaining',
    sortable: false,
    filterable: false,
    type: 'number',
    width: 85,
  },
  {
    field: ListingGridColumnField.PRICE,
    headerName: 'Price',
    sortable: false,
    filterable: false,
    width: 150,
  },
  {
    field: ListingGridColumnField.CURRENCY,
    headerName: 'Currency',
    sortable: false,
    width: 75,
    filterable: false,
    type: 'singleSelect',
    valueOptions: Object.values(InputListingContextCurrencyEnum),
  },
  {
    field: ListingGridColumnField.COLLECTION_ID,
    headerName: 'Collection ID',
    width: 150,
    filterable: true,
    renderCell: (params: { row: ListingGridRow }) =>
      params.row.collectionId ? (
        <PlatformEntityIdentifier
          tooltipContext="this listing's collection ID"
          entityId={params.row.collectionId}
          popoverPreviewContent={
            <CollectionDetailsOverview explicitCollectionId={params.row.collectionId} />
          }
          popoverPreviewSx={{ ...COLLECTION_DETAILS_OVERVIEW_POPOVER_SIZE }}
        />
      ) : (
        <></>
      ),
  },
  {
    field: ListingGridColumnField.ITEM_ID,
    headerName: 'Item ID',
    width: 150,
    filterable: true,
    renderCell: (params: { row: ListingGridRow }) =>
      params.row.itemId ? (
        <PlatformEntityIdentifier
          tooltipContext="this listing's item ID"
          entityId={params.row.itemId}
          popoverPreviewContent={<ItemDetailsOverview explicitItemId={params.row.itemId} />}
          popoverPreviewSx={{ ...CAMPAIGN_OVERVIEW_POPOVER_SIZE }}
        />
      ) : (
        <></>
      ),
  },
  {
    field: ListingGridColumnField.CAMPAIGN_ID,
    headerName: 'Campaign ID',
    width: 150,
    filterable: true,
    renderCell: (params: { row: ListingGridRow }) =>
      params.row.campaignId ? (
        <PlatformEntityIdentifier
          tooltipContext="this listing's campaign ID"
          entityId={params.row.campaignId}
          popoverPreviewContent={
            <MarketingCampaignDetailsOverview explicitCampaignId={params.row.campaignId} />
          }
          popoverPreviewSx={{ ...CAMPAIGN_OVERVIEW_POPOVER_SIZE }}
        />
      ) : (
        <></>
      ),
  },
  {
    field: ListingGridColumnField.POLICY,
    headerName: 'Policy',
    sortable: false,
    filterable: false,
    width: 135,
    renderCell: (params: { row: ListingGridRow }) =>
      params.row.policy ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PopoverButton
            popoverContent={<ListingPolicyDetailsOverview listingId={params.row.id} />}
            popoverContainerSx={{ ...LISTING_POLICY_OVERVIEW_POPOVER_SIZE }}
          />
          {params.row.policy?.type}
        </Box>
      ) : (
        <></>
      ),
  },
  {
    field: ListingGridColumnField.PAYMENT_PROVIDERS,
    headerName: 'Payment',
    sortable: false,
    filterable: false,
    width: 135,
    renderCell: (params: { row: ListingGridRow }) =>
      params.row.paymentProviders && params.row.paymentProviders.length > 0 ? (
        params.row.paymentProviders.length === 1 ? (
          <>{params.row.paymentProviders[0]}</>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PopoverButton
              popoverContent={<ReactJsonThemed src={params.row.paymentProviders} />}
              popoverContainerSx={{ width: '250px', height: '250px' }}
            />

            <Box
              sx={{
                textOverflow: 'ellipsis',
                width: '80px',
                overflow: 'hidden',
                whiteSpace: 'noWrap',
              }}
            >
              <ClipboardContent
                tooltipContext={`payment providers (${params.row.paymentProviders.join(', ')})`}
                clipboardValue={params.row.paymentProviders.join(', ')}
              >
                {params.row.paymentProviders.join(', ')}
              </ClipboardContent>
            </Box>
          </Box>
        )
      ) : (
        <></>
      ),
  },
  {
    field: ListingGridColumnField.SALE_TYPE,
    headerName: 'Sale Type',
    sortable: false,
    filterable: false,
    width: 96,
  },
];

export const generateDefaultColumnVisibilityModel = () => {
  return {
    [ListingGridColumnField.ID]: false,
    [ListingGridColumnField.SALE_TYPE]: false,
  };
};

// Will map API data (in this case a mix of multiple sources) to rows.
const mapToRow = (listing: ListingsPaginatedOutputResultsInner): ListingGridRow => {
  const coercedListingPrice = getCoercedListingPrice(listing.price, listing.currency);
  return {
    [ListingGridColumnField.ID]: listing.id,
    [ListingGridColumnField.COLLECTION_ID]: listing.collection_id,
    [ListingGridColumnField.ITEM_ID]: listing.item_id,
    [ListingGridColumnField.START_TIME]: listing.start_time,
    [ListingGridColumnField.END_TIME]: listing.end_time,
    [ListingGridColumnField.QUANTITY_LISTED]: listing.quantity_listed,
    [ListingGridColumnField.QUANTITY_REMAINING]: listing.quantity_remaining,
    [ListingGridColumnField.PRICE]: coercedListingPrice ?? listing?.price,
    [ListingGridColumnField.CURRENCY]: listing.currency,
    [ListingGridColumnField.STATUS]: listing.status,
    [ListingGridColumnField.PAYMENT_PROVIDERS]: listing.payment_providers,
    [ListingGridColumnField.POLICY]: listing.policy,
    [ListingGridColumnField.SALE_TYPE]: listing.sale_type,
    [ListingGridColumnField.CAMPAIGN_ID]: listing.campaign_id,
    original: listing,
  };
};

// Initial state specific to this grid.
const getInitialGridState = (
  customTransactionColumnVisibility?: GridColumnVisibilityModel
): GridInitialStateCommunity => {
  return {
    sorting: {
      sortModel: [{ field: ListingGridColumnField.START_TIME, sort: 'desc' }],
    },
    columns: {
      columnVisibilityModel:
        customTransactionColumnVisibility ?? generateDefaultColumnVisibilityModel(),
    },
  };
};

export interface ListingsGridProps {
  collectionId?: string;
  itemId?: string;
  customVisibility?: GridColumnVisibilityModel;
}

const pageState: PaginatedGridPage<ListingGridRow> = proxy({
  ...DEFAULT_PAGINATED_GRID_PAGE,
});

// TODO: Switch to server side pagination for perf reasons.
const ListingsGrid = ({ collectionId, itemId, customVisibility }: ListingsGridProps) => {
  // Get readonly snaps from the state.
  const pageSnap = useSnapshot(pageState);

  // Construct sort and filter args based on the snap of the active page.
  const { sortBy, filterBy } = useSortAndFilterGrid(pageState);

  const [initialState] = useState<GridInitialStateCommunity>(getInitialGridState(customVisibility));

  useEffect(() => {
    pageState.sortBy = initialState.sorting?.sortModel
      ? {
          field: initialState.sorting?.sortModel[0].field,
          direction:
            initialState.sorting?.sortModel[0].sort === 'asc'
              ? API_SORT_DIRECTION_ASC
              : API_SORT_DIRECTION_ASC,
        }
      : undefined;
  }, [initialState]);

  const { data: listingsResponse, isPending: listingsLoading } = useListings(
    pageSnap.pageSize,
    pageSnap.cursor,
    sortBy,
    itemId
      ? {
          ...filterBy,
          itemId,
        }
      : collectionId
        ? { ...filterBy, collectionId }
        : filterBy
  );

  useEffect(() => {
    pageState.rows =
      listingsResponse?.results.map(listing => {
        return mapToRow(listing);
      }) ?? [];
    pageState.nextCursor = listingsResponse?.cursor ?? DEFAULT_PAGINATED_GRID_PAGE.cursor;
  }, [listingsResponse?.results, listingsResponse?.cursor]);

  const getRowDoubleClickUrl = useCallback(
    (row: ListingGridRow) =>
      getColOrItemHierarchyRoutePath({
        id: row.id,
        idPropName: 'listingId',
        detailsRouteName: ROUTE_NAME.LISTING_DETAILS,
        collectionId,
        collectionDetailsRouteName: ROUTE_NAME.COLLECTION_LISTING_DETAILS,
        itemId,
        itemDetailsRouteName: ROUTE_NAME.ITEM_LISTING_DETAILS,
      }) || '',
    [collectionId, itemId]
  );

  return (
    <ServerPaginatedDataGrid
      columns={columns.filter(column => column.field !== ListingGridColumnField.CAMPAIGN_ID)}
      initialState={initialState}
      // eslint-disable-next-line valtio/state-snapshot-rule
      activePageProxy={pageState}
      pageLoading={listingsLoading}
      totalRowCount={listingsResponse?.total_results ?? 0}
      getRowDoubleClickUrl={getRowDoubleClickUrl}
    />
  );
};

export default ListingsGrid;
