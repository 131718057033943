import PageContentPaper from '@/common/page/ContentPaper';
import Page from '@/common/page/Index';
import ListingsGrid from './Grid';

const ListingsOverviewPage = () => {
  return (
    <Page testIdPrefix="listings" helmet="Listings" title="Listings">
      <PageContentPaper>
        <ListingsGrid />
      </PageContentPaper>
    </Page>
  );
};

export default ListingsOverviewPage;
