import { Contracts } from './types';
import PlatformDepositDeployments from './PlatformDeposit';

export function getContractDeployments(contract: Contracts) {
  switch (contract) {
    case 'PlatformDeposit':
      return PlatformDepositDeployments;
    default:
      return null;
  }
}

export function getContractAddress(
  contract: Contracts,
  network: number
): `0x${string}` | undefined {
  const contractDeployments = getContractDeployments(contract);
  return contractDeployments
    ? (contractDeployments[network as keyof typeof contractDeployments] as `0x${string}`)
    : undefined;
}

export function getContractNetworks(contract: Contracts) {
  const contractDeployments = getContractDeployments(contract);
  return contractDeployments ? Object.keys(contractDeployments) : [];
}

export default {
  PlatformDepositDeployments,
};
