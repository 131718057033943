import { useQuery } from '@tanstack/react-query';
import { adminApi, HOT_REFETCH_INTERVAL_MILLISECONDS } from '../api';
import {
  API_SORT_DIRECTION_ASC,
  API_SORT_DIRECTION_DESC,
  PURCHASE_INTENTS_QUERY_KEY,
} from '../constants';

export const usePurchaseIntent = (purchaseIntentId?: string) => {
  return useQuery({
    queryKey: [PURCHASE_INTENTS_QUERY_KEY, purchaseIntentId],
    queryFn: () =>
      adminApi.listing
        .purchaseIntentsPurchaseIntentIdGet(purchaseIntentId as string)
        .then(res => res.data),
    enabled: Boolean(purchaseIntentId),
  });
};

export interface PurchaseIntentPaginatedFilter {
  itemId?: string;
  collectionId?: string;
  listingId?: string;
  status?: 'CANCELLED' | 'PENDING' | 'UNRESOLVED' | 'CONFIRMED' | 'EXPIRED';
  email?: string;
  ethAddress?: string;
  fulfillmentStatus?: 'PENDING' | 'ASSIGNED' | 'COMPLETED' | 'EXCEPTION';
  fulfillmentMethod?: 'EMAIL' | 'TRANSFER' | 'VOUCHER';
  paymentProvider?: 'STRIPE' | 'MINT_VOUCHER' | 'EMAIL_CLAIM';
}

export const usePurchaseIntents = (
  pageSize?: number,
  cursor?: string,
  sort?: {
    fieldName?: string;
    direction?: typeof API_SORT_DIRECTION_ASC | typeof API_SORT_DIRECTION_DESC;
  },
  filter?: PurchaseIntentPaginatedFilter
) => {
  const result = useQuery({
    queryKey: [
      PURCHASE_INTENTS_QUERY_KEY,
      pageSize,
      cursor,
      sort?.fieldName,
      sort?.direction,
      filter?.itemId,
      filter?.collectionId,
      filter?.listingId,
      filter?.status,
      filter?.email,
      filter?.ethAddress,
      filter?.fulfillmentStatus,
      filter?.paymentProvider,
      filter?.fulfillmentMethod,
    ],
    queryFn: () =>
      adminApi.listing
        .purchaseIntentsGet(
          sort?.direction ?? API_SORT_DIRECTION_ASC,
          cursor,
          pageSize,
          filter?.itemId,
          filter?.collectionId,
          filter?.listingId,
          filter?.status ? [filter?.status] : [],
          filter?.email,
          filter?.ethAddress,
          filter?.fulfillmentStatus ? [filter?.fulfillmentStatus] : [],
          filter?.fulfillmentMethod ? [filter?.fulfillmentMethod] : [],
          filter?.paymentProvider ? [filter?.paymentProvider] : [],
          undefined
        )
        .then(res => res.data),
    refetchInterval: HOT_REFETCH_INTERVAL_MILLISECONDS,
  });

  return result;
};
