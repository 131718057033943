import { GridColDef } from '@mui/x-data-grid';
import { useCallback, useEffect } from 'react';
import { PurchaseIntentPaginatedOutputResultsInner } from '@/api-client';
import PlatformEntityIdentifier from '@/common/data/PlatformEntityIdentifier';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { ROUTE_NAME } from '@/utils/constants';
import PrettyDate from '@/common/data/PrettyDate';
import { usePurchaseIntents } from '@/utils/hooks/purchaseIntent';
import { useListing } from '@/utils/hooks/listing';
import { useCollection } from '@/utils/hooks/collection';
import { useItem } from '@/utils/hooks/item';
import Status from '@/common/data/Status';
import BlockExplorerFilter, { BlockExplorerFilterType } from '@/common/data/BlockExplorerFilter';
import ServerPaginatedDataGrid, {
  DEFAULT_PAGINATED_GRID_PAGE,
  PaginatedGridPage,
} from '@/common/data/grid/ServerPaginated';
import { proxy, useSnapshot } from 'valtio';
import { useSortAndFilterGrid } from '@/utils/hooks/useSortAndFilterGrid';
import { getColOrItemHierarchyRoutePath } from '@/utils/route';
import { useLocation } from 'react-router-dom';

export enum PurchaseIntentColumnField {
  ID = 'id',
  CREATED_ON = 'createdOn',
  EXPIRATION = 'expiration',
  STATUS = 'status',
  FULFILLMENT_METHOD = 'fulfillmentMethod',
  FULFILLMENT_STATUS = 'fulfillmentStatus',
  PAYMENT_PROVIDER = 'paymentProvider',
  NETWORK_ID = 'networkId',
  EMAIL = 'email',
  ETH_ADDRESS = 'ethAddress',
}

type PurchaseIntentGridRow = { [key in PurchaseIntentColumnField]: any };

// Columns specific to this grid.
const columns: Array<GridColDef<PurchaseIntentGridRow>> = [
  {
    field: PurchaseIntentColumnField.ID,
    headerName: 'ID',
    width: 150,
    sortable: false,
    filterable: true,
    renderCell: (params: { row: PurchaseIntentGridRow }) => (
      <PlatformEntityIdentifier tooltipContext="this purchase intent ID" entityId={params.row.id} />
    ),
  },
  {
    field: PurchaseIntentColumnField.STATUS,
    headerName: 'Status',
    sortable: true,
    filterable: true,
    width: 100,
    renderCell: (params: { row: PurchaseIntentGridRow }) => <Status status={params.row.status} />,
  },
  {
    field: PurchaseIntentColumnField.CREATED_ON,
    headerName: 'Created On',
    width: 165,
    sortable: true,
    filterable: true,
    renderCell: (params: { row: PurchaseIntentGridRow }) => {
      return params.row.createdOn ? <PrettyDate date={params.row.createdOn} /> : <></>;
    },
  },
  {
    field: PurchaseIntentColumnField.FULFILLMENT_STATUS,
    headerName: 'Fulfillment Status',
    sortable: true,
    filterable: true,
    width: 135,
    renderCell: (params: { row: PurchaseIntentGridRow }) => (
      <Status status={params.row.fulfillmentStatus} />
    ),
  },
  {
    field: PurchaseIntentColumnField.FULFILLMENT_METHOD,
    headerName: 'Fulfillment Method',
    sortable: true,
    filterable: true,
    width: 135,
  },
  {
    field: PurchaseIntentColumnField.ETH_ADDRESS,
    headerName: 'Address',
    sortable: true,
    filterable: true,
    width: 165,
    renderCell: (params: { row: PurchaseIntentGridRow }) =>
      params.row.ethAddress && params.row.networkId ? (
        <BlockExplorerFilter
          filterType={BlockExplorerFilterType.ADDRESS}
          filterValue={params.row.ethAddress}
          networkId={params.row.networkId}
        />
      ) : (
        <>{params.row.ethAddress}</>
      ),
  },
  {
    field: PurchaseIntentColumnField.EMAIL,
    headerName: 'Email',
    sortable: true,
    filterable: true,
    flex: 1,
  },
];

const initialState: GridInitialStateCommunity = {
  sorting: {
    sortModel: [{ field: PurchaseIntentColumnField.CREATED_ON, sort: 'desc' }],
  },
  columns: {
    columnVisibilityModel: {
      [PurchaseIntentColumnField.ID]: false,
    },
  },
};

// Will map API data (in this case a mix of multiple sources) to rows.
const mapToRow = (
  intent: PurchaseIntentPaginatedOutputResultsInner,
  networkId: number
): PurchaseIntentGridRow => {
  return {
    id: intent.id,
    status: intent.status,
    fulfillmentMethod: intent.fulfillment_method,
    fulfillmentStatus: intent.fulfillment_status,
    paymentProvider: intent.payment_provider,
    createdOn: intent.created_at,
    expiration: intent.expiration,
    ethAddress: intent.eth_address,
    email: intent.email,
    networkId,
  };
};

export interface PurchaseIntentGridProps {
  listingId?: string;
}

const pageState: PaginatedGridPage<PurchaseIntentGridRow> = proxy({
  ...DEFAULT_PAGINATED_GRID_PAGE,
});

const PurchaseIntentGrid = ({ listingId }: PurchaseIntentGridProps) => {
  const { pathname } = useLocation();
  const { data: listing } = useListing(listingId ?? '');
  const { data: item } = useItem(listing?.item_id ?? '');
  const { data: collection } = useCollection(listing?.collection_id ?? item?.collection_id ?? '');

  // Get readonly snaps from the state.
  const pageSnap = useSnapshot(pageState);

  // Construct sort and filter args based on the snap of the active page.
  const { sortBy, filterBy } = useSortAndFilterGrid(pageState);

  // Get all API data for current render.
  const { data: itemsResponse, isPending } = usePurchaseIntents(
    pageSnap.pageSize,
    pageSnap.cursor,
    sortBy,
    {
      ...filterBy,
      listingId,
    }
  );

  useEffect(() => {
    const networkId = collection?.deployment?.network_id;
    if (networkId) {
      pageState.rows =
        itemsResponse?.results.map(item => {
          return mapToRow(item, networkId);
        }) ?? [];
    } else {
      pageState.rows = [];
    }

    pageState.nextCursor = itemsResponse?.cursor ?? DEFAULT_PAGINATED_GRID_PAGE.cursor;
  }, [collection, itemsResponse?.results, itemsResponse?.cursor]);

  const getRowDoubleClickUrl = useCallback(
    (row: PurchaseIntentGridRow) => {
      return (
        // Based on the current path, determine the route to use for the double click.
        // If there's a reference to the collection, use that for the route path.
        // Otherwise the route path will only reference the listing.
        getColOrItemHierarchyRoutePath({
          id: row.id,
          idPropName: 'purchaseIntentId',
          detailsRouteName: ROUTE_NAME.PURCHASE_INTENT_DETAILS,
          collectionId: pathname?.toLowerCase()?.includes('collections')
            ? collection?.id
            : undefined,
          collectionDetailsRouteName: ROUTE_NAME.COLLECTION_PURCHASE_INTENT_DETAILS,
          itemId: pathname?.toLowerCase()?.includes('collections') ? item?.id : undefined,
          itemDetailsRouteName: ROUTE_NAME.ITEM_PURCHASE_INTENT_DETAILS,
        }) || ''
      );
    },
    [item?.id, collection?.id, pathname]
  );

  return (
    <ServerPaginatedDataGrid
      columns={columns}
      initialState={initialState}
      // eslint-disable-next-line valtio/state-snapshot-rule
      activePageProxy={pageState}
      pageLoading={isPending}
      totalRowCount={itemsResponse?.total_results ?? 0}
      getRowDoubleClickUrl={getRowDoubleClickUrl}
    />
  );
};

export default PurchaseIntentGrid;
