import axios from 'axios';
import { baseApiUrl, publicApiUrl } from '@/config';
import {
  AuthenticationApiFactory,
  CollectionApiFactory,
  EmailClaimApiFactory,
  EmailIntegrationApiFactory,
  EmailTemplateApiFactory,
  ItemApiFactory,
  ItemTypeApiFactory,
  ListingApiFactory,
  MarketingCampaignApiFactory,
  NetworkApiFactory,
  OrganizationApiFactory,
  PaymentIntegrationApiFactory,
  RoleApiFactory,
  StorageApiFactory,
  TransactionApiFactory,
  UserApiFactory,
  WalletApiFactory,
  WebhookApiFactory,
} from '@/api-client/api';
import { Configuration as AdminApiConfiguration } from '@/api-client';
import { DefaultApiFactory, Configuration as PublicConfiguration,  } from '@/api-client-public';

export const DEFAULT_API_PAGINATION_LIMIT = 100;
export const MEDIUM_PAGINATION_LIMIT = 1000; // For entities that have a higher threshold for pagination.
export const HOT_REFETCH_INTERVAL_MILLISECONDS = 30000; // 30 seconds

const createApiInstance = (baseUrl: string) => {
  return axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const axiosAuthedApiInstance = createApiInstance(baseApiUrl);
export const axiosPublicApiInstance = createApiInstance(publicApiUrl);

export function setAxiosAuthHeader(accessToken?: string) {
  axiosAuthedApiInstance.defaults.headers.Authorization = accessToken
    ? `Bearer ${accessToken}`
    : null;
}

// used in a request interceptor to abort a request
export function abortRequest(message?: string) {
  const controller = new AbortController();
  const cfg = {
    signal: controller.signal,
  };
  controller.abort(message);
  return cfg;
}

// Add a request interceptor. This is used for auth0 expiry tracking.
export function setAuthedRequestInterceptors(interceptor: any) {
  // Only add if there is not already one
  if ((axiosAuthedApiInstance as any).interceptors.request.handlers.length === 0) {
    axiosAuthedApiInstance.interceptors.request.use(interceptor, function (error) {
      return Promise.reject(error);
    });
  }
}

export interface AdminApi {
  auth: ReturnType<typeof AuthenticationApiFactory>;
  org: ReturnType<typeof OrganizationApiFactory>;
  collection: ReturnType<typeof CollectionApiFactory>;
  emailClaim: ReturnType<typeof EmailClaimApiFactory>;
  network: ReturnType<typeof NetworkApiFactory>;
  user: ReturnType<typeof UserApiFactory>;
  role: ReturnType<typeof RoleApiFactory>;
  webhook: ReturnType<typeof WebhookApiFactory>;
  emailIntegration: ReturnType<typeof EmailIntegrationApiFactory>;
  emailTemplate: ReturnType<typeof EmailTemplateApiFactory>;
  marketingCampaign: ReturnType<typeof MarketingCampaignApiFactory>;
  transaction: ReturnType<typeof TransactionApiFactory>;
  item: ReturnType<typeof ItemApiFactory>;
  itemType: ReturnType<typeof ItemTypeApiFactory>;
  wallet: ReturnType<typeof WalletApiFactory>;
  paymentIntegration: ReturnType<typeof PaymentIntegrationApiFactory>;
  listing: ReturnType<typeof ListingApiFactory>;
  storage: ReturnType<typeof StorageApiFactory>;
}

export const adminApi: AdminApi = {
  auth: AuthenticationApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  org: OrganizationApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  collection: CollectionApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  emailClaim: EmailClaimApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  network: NetworkApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  user: UserApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  role: RoleApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  webhook: WebhookApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  emailIntegration: EmailIntegrationApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  emailTemplate: EmailTemplateApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  marketingCampaign: MarketingCampaignApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  transaction: TransactionApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  item: ItemApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  itemType: ItemTypeApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  wallet: WalletApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  paymentIntegration: PaymentIntegrationApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  listing: ListingApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
  storage: StorageApiFactory(
    new AdminApiConfiguration({ basePath: baseApiUrl }),
    undefined,
    axiosAuthedApiInstance
  ),
};

export interface PublicApi {
  network: ReturnType<typeof DefaultApiFactory>;
  collection: ReturnType<typeof DefaultApiFactory>;
  capability: ReturnType<typeof DefaultApiFactory>;
  auth0: ReturnType<typeof DefaultApiFactory>;
}

export const publicApi: PublicApi = {
  network: DefaultApiFactory(
    new PublicConfiguration({ basePath: publicApiUrl }),
    undefined,
    axiosPublicApiInstance
  ),
  collection: DefaultApiFactory(
    new PublicConfiguration({ basePath: publicApiUrl }),
    undefined,
    axiosPublicApiInstance
  ),
  capability: DefaultApiFactory(
    new PublicConfiguration({ basePath: publicApiUrl }),
    undefined,
    axiosPublicApiInstance
  ),
  auth0: DefaultApiFactory(
    new PublicConfiguration({ basePath: publicApiUrl }),
    undefined,
    axiosPublicApiInstance
  ),
};
