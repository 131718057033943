import BlockExplorerFilter, { BlockExplorerFilterType } from '@/common/data/BlockExplorerFilter';
import ClipboardContent from '@/common/data/ClipboardContent';
import { shortenUuid4 } from '@/utils/helpers';
import { useCollection } from '@/utils/hooks/collection';
import {
  useItem,
  useItemTitle,
  ITEM_ATTRIBUTE_SCHEMA_INPUT_MODE,
  useItemAttributeSchema,
} from '@/utils/hooks/item';
import { useMemo } from 'react';
import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import ItemSupply from './Supply';
import { COMMON_ITEM_ATTRIBUTE } from '@/utils/constants';
import OverviewContainer from '@/common/container/OverviewContainer';
import Status from '@/common/data/Status';

interface ItemDetailsOverviewSummaryProps {
  itemId?: string;
}

/**
 * Contains a summary of the item.
 */
export const ItemDetailsOverviewSummary = ({ itemId }: ItemDetailsOverviewSummaryProps) => {
  const { data: item, isPending: itemLoading } = useItem(itemId ?? '');
  const itemTitle = useItemTitle(item?.attributes);
  const { data: collection, isPending: collectionLoading } = useCollection(
    item?.collection_id ?? ''
  );
  const itemDescription = useMemo(() => {
    return item?.attributes ? (item.attributes as any)[COMMON_ITEM_ATTRIBUTE.DESCRIPTION] : null;
  }, [item?.attributes]);

  const { getItemType } = useItemAttributeSchema({
    itemTypeId: item?.item_type_id,
    mode: ITEM_ATTRIBUTE_SCHEMA_INPUT_MODE.EDIT,
  });
  const { isPending: itemTypeLoading } = getItemType;

  const itemRoyaltyPercentage = useMemo(() => {
    if (item?.royalty_info?.amount_bps) {
      return (item?.royalty_info?.amount_bps / 100).toString();
    }
    return 'N/A';
  }, [item?.royalty_info?.amount_bps]);

  const itemRoyaltyReceiver = useMemo(() => {
    return item?.royalty_info?.receiver ?? null;
  }, [item?.royalty_info?.receiver]);

  return (
    <OverviewContainer
      isLoading={itemLoading || (item?.item_type_id && itemTypeLoading) || collectionLoading}
      gridColumnDivisions={3}
      gridRowDivisions={5}
    >
      <BasicInfoDisplay title="Title">{itemTitle}</BasicInfoDisplay>

      <BasicInfoDisplay title="ID">
        <ClipboardContent tooltipContext="item ID" clipboardValue={item?.id}>
          {shortenUuid4(item?.id)}
        </ClipboardContent>
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Collection ID">
        <ClipboardContent tooltipContext="collection ID" clipboardValue={item?.collection_id}>
          {shortenUuid4(item?.collection_id)}
        </ClipboardContent>
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Locked" variant="boolean">
        {item?.locked ?? false}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Token ID">{item?.token_id ?? 'NOT YET ASSIGNED'}</BasicInfoDisplay>

      <ItemSupply itemId={itemId ?? ''} />

      <BasicInfoDisplay title="Mint Status">
        <Status status={item?.token_status?.toString() ?? 'N/A'} />
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Royalty %">{itemRoyaltyPercentage}</BasicInfoDisplay>

      <BasicInfoDisplay title="Royalty Receiver Address">
        {itemRoyaltyReceiver ? (
          <BlockExplorerFilter
            filterValue={itemRoyaltyReceiver}
            filterType={BlockExplorerFilterType.ADDRESS}
            networkId={Number(collection?.deployment?.network_id)}
          />
        ) : (
          'N/A'
        )}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Item Description" sx={{ gridColumnStart: 'span 4' }}>
        {itemDescription ?? 'N/A'}
      </BasicInfoDisplay>

      <OverviewContainer
        isLoading={itemLoading || (item?.item_type_id && itemTypeLoading) || collectionLoading}
        gridColumnDivisions={3}
        gridRowDivisions={5}
      >
        <BasicInfoDisplay title="Item Description" sx={{ gridColumnStart: 'span 4' }}>
          {itemDescription ?? 'N/A'}
        </BasicInfoDisplay>
        <BasicInfoDisplay title="Title">{itemTitle}</BasicInfoDisplay>
      </OverviewContainer>
    </OverviewContainer>
  );
};

export default ItemDetailsOverviewSummary;
