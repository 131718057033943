import {
  CreateOrganizationEmailTemplateInput,
  CreateOrganizationEmailTemplateTestMailInput,
  EditOrganizationEmailTemplateInput,
  GetCustomizedEmailTemplateInput,
} from '@/api-client';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { adminApi, HOT_REFETCH_INTERVAL_MILLISECONDS } from '../api';
import {
  API_SORT_DIRECTION_ASC,
  API_SORT_DIRECTION_DESC,
  EMAIL_TEMPLATES_QUERY_KEY,
  EMAIL_TEMPLATE_TYPES_QUERY_KEY,
  EMAIL_TEMPLATE_VARIABLES_QUERY_KEY,
} from '../constants';
import { EmailTemplateType } from '../types';

export const useEmailTemplate = (templateType?: EmailTemplateType | null, campaignId?: string) => {
  return useQuery({
    queryKey: [EMAIL_TEMPLATES_QUERY_KEY, templateType, campaignId],
    queryFn: () =>
      adminApi.emailTemplate
        .emailTemplatesTemplateTypeGet(templateType as EmailTemplateType, campaignId)
        .then(res => res.data),
    enabled: Boolean(templateType),
  });
};

export const useEmailTemplates = (
  campaign_id?: string,
  pageSize?: number,
  cursor?: string,
  sort?: {
    fieldName?: string;
    direction?: typeof API_SORT_DIRECTION_ASC | typeof API_SORT_DIRECTION_DESC;
  }
) => {
  return useQuery({
    queryKey: [EMAIL_TEMPLATES_QUERY_KEY, campaign_id, pageSize, cursor, sort?.direction],
    queryFn: () =>
      adminApi.emailTemplate
        .emailTemplatesGet(sort?.direction ?? API_SORT_DIRECTION_ASC, cursor, pageSize, campaign_id)
        .then(res => res.data),
    enabled: !!campaign_id,
    refetchInterval: HOT_REFETCH_INTERVAL_MILLISECONDS,
  });
};

export const useAllEmailTemplateTypes = () => {
  const result = useQuery({
    queryKey: [EMAIL_TEMPLATE_TYPES_QUERY_KEY],
    queryFn: () => adminApi.emailTemplate.emailTemplatesTypesGet().then(res => res.data),
  });

  return {
    data: result.data?.results as EmailTemplateType[],
    isPending: result.isPending,
  };
};

export const useAllEmailTemplateVariables = (emailTemplateType: null | EmailTemplateType) => {
  const result = useQuery({
    queryKey: [EMAIL_TEMPLATE_VARIABLES_QUERY_KEY, emailTemplateType],
    queryFn: () =>
      adminApi.emailTemplate
        .emailTemplatesTemplateTypeVariablesGet(emailTemplateType as EmailTemplateType)
        .then(res => res.data),
    enabled: Boolean(emailTemplateType),
  });

  return {
    data: result.data?.results,
    isPending: result.isPending,
  };
};

export const useCreateEmailTemplate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  // Handler to create (POST) an email template.
  const createEmailTemplate = useMutation({
    mutationFn: ({
      templateType,
      template,
    }: {
      templateType: EmailTemplateType;
      template: CreateOrganizationEmailTemplateInput;
    }) => adminApi.emailTemplate.emailTemplatesTemplateTypePost(templateType, template),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMAIL_TEMPLATES_QUERY_KEY] });
      enqueueSnackbar('Email template saved', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Error saving email template', {
        variant: 'error',
      });
    },
  });

  return createEmailTemplate;
};

export const useDeleteEmailTemplate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const deleteEmailTemplate = useMutation({
    mutationFn: ({
      templateType,
      body,
    }: {
      templateType: EmailTemplateType;
      body: GetCustomizedEmailTemplateInput;
    }) => adminApi.emailTemplate.emailTemplatesTemplateTypeDelete(templateType, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMAIL_TEMPLATES_QUERY_KEY] });
      enqueueSnackbar('Successfully reset the email template.', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Error resetting the email template', {
        variant: 'error',
      });
    },
  });

  return deleteEmailTemplate;
};

export const useSendTestEmailTemplate = () => {
  const { enqueueSnackbar } = useSnackbar();

  // Handler to create (POST) an email template.
  const sendTestEmail = useMutation({
    mutationFn: ({
      templateType,
      testMailBody,
    }: {
      templateType: EmailTemplateType;
      testMailBody: CreateOrganizationEmailTemplateTestMailInput;
    }) => adminApi.emailTemplate.emailTemplatesTemplateTypeSendTestPost(templateType, testMailBody),
    onSuccess: () => {
      enqueueSnackbar('Email template test mail sent.', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Error sending test email', {
        variant: 'error',
      });
    },
  });

  return sendTestEmail;
};

export const useUpdateEmailTemplate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const updateEmailTemplate = useMutation({
    mutationFn: ({
      templateType,
      template,
    }: {
      templateType: EmailTemplateType;
      template: EditOrganizationEmailTemplateInput;
    }) => adminApi.emailTemplate.emailTemplatesTemplateTypePatch(templateType, template),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMAIL_TEMPLATES_QUERY_KEY] });
      enqueueSnackbar('Successfully updated the email template.', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Error updating the email template', {
        variant: 'error',
      });
    },
  });

  return updateEmailTemplate;
};
