import { EditOrganizationPayoutConfigurationInput } from '@/api-client';
import { isValidAddress } from '@/utils/helpers';
import { useEditOrganizationPayoutConfig } from '@/utils/hooks/organization';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  payout_address: yup
    .string()
    .required()
    .test('validEthAddress', 'Please enter a valid ethereum address', value => {
      if (value && value.length > 0) return isValidAddress(value);
      return true;
    }),
});

const CreatePayoutWalletForm = ({
  organizationId,
  onSuccess,
  onCancel,
}: {
  organizationId: string;
  onSuccess?: () => void;
  onCancel: () => void;
}) => {
  const { mutate, isPending: updatingPayoutConfig } =
    useEditOrganizationPayoutConfig(organizationId);

  const formik = useFormik({
    initialValues: {
      payout_address: '',
    },
    validationSchema,
    onSubmit: (values: EditOrganizationPayoutConfigurationInput, { resetForm }) =>
      mutate(values, {
        ...(onSuccess ? { onSuccess } : {}),
        onSettled: () => resetForm({ values }),
      }),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        name="payout_address"
        label="Payout Address"
        disabled={formik.isSubmitting}
        sx={{ width: '100%', mb: 2 }}
        onChange={formik.handleChange}
        error={formik.touched.payout_address && Boolean(formik.errors.payout_address)}
        helperText={formik.touched.payout_address && formik.errors.payout_address}
        placeholder="Enter a valid ethereum address"
      />

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Button variant="outlined" type="button" onClick={onCancel} sx={{ flexGrow: 1 }}>
          Cancel
        </Button>

        <LoadingButton
          type="submit"
          disabled={formik.isSubmitting}
          loading={formik.isSubmitting || updatingPayoutConfig}
          variant="contained"
          color="primary"
          sx={{ flexGrow: 1 }}
        >
          Set Wallet
        </LoadingButton>
      </Box>
    </form>
  );
};

export default CreatePayoutWalletForm;
