import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import TextField from '@mui/material/TextField';
import { useCreateStripePaymentIntent } from '@/services/billing';
import { parseUnits } from 'ethers';
import { Alert, Box, Typography } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { stripeKey } from '@/config';
import ModalActionButtons from './ModalActionButtons';
import CheckoutForm from './CheckoutForm';
import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';

const MIN_FIAT_DEPOSIT = 10;

interface AddFiatFormProps {
  orgId: string;
  onSuccess: () => void;
  onCancel: () => void;
}

const stripePromise = stripeKey ? loadStripe(stripeKey) : null;

// stripe has some constraints on how it is used in react, so we cannot easily embed the
// form and retain control on submission in a parent component. instead, we decompose this
// into two parts:
// 1. Fetching the amount the user wants to deposit, AmountForm
// 2. Delegating all remaining flow to the CheckoutForm
// this component is just acting as a state machine

export function AddFiatForm({ orgId, onSuccess, onCancel }: AddFiatFormProps) {
  const [clientSecret, setClientSecret] = useState('');
  const [amount, setAmount] = useState<number | string>('');
  const { mutateAsync, isPending } = useCreateStripePaymentIntent();
  const [readyForStripe, setReadyForStripe] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    async function createPaymentIntent() {
      try {
        const resp = await mutateAsync({
          amount: `${(amount as number) * 100}`, // amount in cents
          currency: 'USD',
          organization_id: orgId!,
        });
        setClientSecret(resp.client_secret);
      } catch (e) {
        console.error(e);
      }
    }

    if (readyForStripe && amount && (amount as number) >= MIN_FIAT_DEPOSIT) {
      createPaymentIntent();
    }
  }, [mutateAsync, amount, orgId, readyForStripe]);

  const showStripeForm = readyForStripe && clientSecret && stripePromise && !paymentComplete;

  return (
    <form onSubmit={() => {}}>
      {paymentComplete && (
        <>
          <Typography variant="subtitle2" id="parent-modal-description" sx={{ marginBottom: 3 }}>
            <Alert severity="success">
              Your payment has been successfully submitted. Your balance will be updated shortly.
            </Alert>
          </Typography>

          <Box>
            <BasicInfoDisplay title="Amount">{`$${amount}`}</BasicInfoDisplay>
          </Box>
        </>
      )}

      {!showStripeForm && !paymentComplete && (
        <>
          <Typography variant="subtitle2" id="parent-modal-description" sx={{ marginBottom: 3 }}>
            Select the amount you want to deposit to your balance. The minimum deposit is $10.
          </Typography>
          <TextField
            type="number"
            name="amount"
            label="Amount"
            inputProps={{ step: 0.01 }}
            sx={{ width: '100%', mb: 2 }}
            onChange={e => {
              if (e.target.value.length === 0) {
                setAmount('');
              }
              try {
                parseUnits(e.target.value, 2);
                setAmount(+e.target.value);
              } catch (e: any) {
                // swallow
              }
            }}
            value={amount}
            placeholder="The amount to add to your balance"
            required
          />
        </>
      )}

      {showStripeForm ? (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
            loader: 'always',
          }}
        >
          <CheckoutForm
            amount={amount as number}
            onCancel={onCancel}
            onPaymentComplete={() => {
              setPaymentComplete(true);
            }}
          />
        </Elements>
      ) : paymentComplete ? (
        <ModalActionButtons
          isLoading={isPending}
          nextDisabled={false}
          cancelDisabled={true}
          nextLabel={'Close'}
          onNext={onSuccess}
          onCancel={onCancel}
        />
      ) : (
        <ModalActionButtons
          isLoading={isPending}
          nextDisabled={!amount || (amount as number) < MIN_FIAT_DEPOSIT}
          nextLabel={'Next'}
          onNext={() => setReadyForStripe(true)}
          onCancel={onCancel}
        />
      )}
    </form>
  );
}

export default AddFiatForm;
