import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import MoreVert from '@mui/icons-material/MoreVertOutlined';
import Button, { ButtonProps } from '@mui/material/Button';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useCallback, useState } from 'react';

export interface KebabMenuButtonProps {
  label?: string;
  menuItems: Array<{
    key?: string;
    onClick?: () => void;
    element: ReactJSXElement;
  }>;
  menuProps?: Partial<MenuProps>;
  buttonProps?: IconButtonProps | ButtonProps;
}

/**
 * A button that opens a menu when clicked.
 *
 * @param label The label to display on the button.
 * @param menuItems The items to display in the menu.
 * @param buttonProps Props to pass to the MUI button that, when clicked, opens the menu.
 * @param menuProps Props to pass to the MUI menu component.
 */
export const KebabMenuButton = ({
  label,
  menuItems,
  menuProps = {},
  buttonProps = {},
}: KebabMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      {label ? (
        <Button
          color="primary"
          variant="contained"
          aria-haspopup="true"
          onClick={handleClick}
          {...(buttonProps as ButtonProps)}
        >
          {label}
        </Button>
      ) : (
        <IconButton color="primary" aria-haspopup="true" onClick={handleClick} {...buttonProps}>
          <MoreVert />
        </IconButton>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        sx={{ padding: 0, '.MuiList-root': { p: 0 } }}
        {...menuProps}
      >
        {menuItems.map(item => (
          <MenuItem
            key={item.key}
            onClick={() => {
              if (item.onClick) {
                item.onClick();
                handleClose();
              }
            }}
            sx={{
              fontSize: '1rem',
              display: 'flex',
              justifyContent: 'flex-start',
              p: 0,
            }}
          >
            {item.element}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default KebabMenuButton;
