import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_SORT_DIRECTION_ASC, API_SORT_DIRECTION_DESC, INVITES_QUERY_KEY, INVITE_QUERY_KEY } from '../constants';
import { adminApi } from '../api';
import { useSnackbar } from 'notistack';
import { getApiErrorMessage } from '../helpers';

export const useInvites = (
  pageSize?: number,
  cursor?: string,
  sort?: {
    fieldName?: string;
    direction?: typeof API_SORT_DIRECTION_ASC | typeof API_SORT_DIRECTION_DESC;
  },
  organizationId?: string
) => {
  return useQuery({
    queryKey: [INVITES_QUERY_KEY, sort, pageSize, cursor, organizationId],
    queryFn: () =>
      adminApi.org.invitationsGet(sort?.direction, cursor, pageSize, organizationId)
      .then(res => res.data),
  });
};

export const useInvite = (token: string) => {
  return useQuery({
    queryKey: [INVITE_QUERY_KEY, token],
    queryFn: () =>
      adminApi.org.invitationsInvitationTokenGet(token).then(res => res.data),
  });
};

export const useDeleteInvite = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (inviteId: string) => adminApi.org.invitationsInvitationIdDelete(inviteId),
    onSuccess: (_, variables) => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey: [INVITE_QUERY_KEY, variables] });
      queryClient.invalidateQueries({ queryKey: [INVITES_QUERY_KEY] });
      enqueueSnackbar(`Invite has been deleted`, { variant: 'success' });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error deleting invite', { variant: 'error' });
    },
  });

  return mutation;
};

export const useResendInvite = () => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (inviteId: string) => adminApi.org.invitationsInvitationIdResendPost(inviteId),
    onSuccess: () => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey: [INVITE_QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [INVITES_QUERY_KEY] });
      enqueueSnackbar(`Invite has been resent`, { variant: 'success' });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error resending invite', { variant: 'error' });
    },
  });

  return mutation;
};
