import PageContentTabs, {
  ContentTab,
  OVERVIEW_TAB_LABEL,
  OVERVIEW_TAB_VALUE,
} from '@/common/page/ContentTabs';
import Page from '@/common/page/Index';
import { MAIN_ROUTES } from '@/routes';
import { ROUTE_NAME } from '@/utils/constants';
import { useCampaign } from '@/utils/hooks/marketingCampaigns';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import MarketingCampaignDetailsOverview from './DetailsOverview';
import TemplateGrid from './Templates/TemplateGrid';
import { useAllEmailTemplateTypes } from '@/utils/hooks/emailTemplate';

const MarketingCampaignDetailsPage = () => {
  const { campaignId } = useParams();
  const { isError: campaignError } = useCampaign(campaignId ?? '');
  const { data: emailTemplateTypes } = useAllEmailTemplateTypes();

  const routePath = useMemo(() => {
    const route = MAIN_ROUTES.find(route => route.name === ROUTE_NAME.EMAIL_CAMPAIGNS);
    if (!route) return '';
    return generatePath(route.path);
  }, []);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const tabs: Array<ContentTab> = useMemo(
    () => [
      {
        label: OVERVIEW_TAB_LABEL,
        value: OVERVIEW_TAB_VALUE,
        content: <MarketingCampaignDetailsOverview />,
      },
      {
        label: `${emailTemplateTypes?.length} Templates`,
        value: 'email-templates',
        content: <TemplateGrid />,
      },
    ],
    [emailTemplateTypes]
  );

  useEffect(() => {
    if (campaignError) {
      const overviewRoute = MAIN_ROUTES.find(route => route.name === ROUTE_NAME.EMAIL_CAMPAIGNS);
      if (!overviewRoute) return;
      enqueueSnackbar('Campaign not found, returning to Email Campaigns.', {
        variant: 'info',
      });
      navigate(generatePath(overviewRoute.path));
    }
  }, [navigate, enqueueSnackbar, campaignError]);

  return (
    <Page
      testIdPrefix="campaign-details"
      helmet="Campaign Details"
      title="Campaign Details"
      backTo={{
        routePath: routePath,
        buttonLabel: 'Back To Email Campaigns',
      }}
    >
      <PageContentTabs tabs={tabs} />
    </Page>
  );
};

export default MarketingCampaignDetailsPage;
