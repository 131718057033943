import { DateTime } from 'luxon';
import Page from '@/common/page/Index';
import { useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { find } from 'lodash';
import { ROUTE_NAME } from '@/utils/constants';
import { AppRoute, MAIN_ROUTES } from '@/routes';
import PageContentPaper from '@/common/page/ContentPaper';
import { useAllRoles } from '@/utils/hooks/role';
import { useEditUser, useUser } from '@/utils/hooks/user';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { EditUserInput, UserOutput } from '@/api-client';
import PermissionModalButton from '@/common/button/PermissionModal';
import DeleteUserForm from './DeleteForm';
import PermissionGuard from '@/common/PermissionGuard';
import LoadingBox from '@/common/container/LoadingBox';

interface UpdateUserFormProps {
  user: UserOutput;
  onCancel: () => void;
}

const validationSchema = yup.object().shape({
  role: yup.string().required('Please select a role'),
});

const UpdateUserForm = ({ user, onCancel }: UpdateUserFormProps) => {
  const navigate = useNavigate();
  const { data: roles, isPending: rolesLoading } = useAllRoles();
  const { mutate: editUser } = useEditUser();

  const formik = useFormik({
    initialValues: {
      email: '',
      role: user.role_id,
      lastSeen: 'Never',
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      editUser(
        { userId: user.id, body: { role_id: values.role } as EditUserInput },
        {
          onSettled: () => {
            resetForm({ values });
          },
        }
      );
    },
  });

  return (
    <LoadingBox isLoading={rolesLoading}>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          type="email"
          name="email"
          label="Email"
          disabled={true}
          sx={{ width: '60%', mb: 2 }}
          value={user.email as string}
          required
        />
        <TextField
          type="lastSeen"
          name="lastSeen"
          label="Last Seen"
          disabled={true}
          sx={{ width: '60%', mb: 2 }}
          value={DateTime.fromISO(user.last_seen as string).toLocaleString(
            DateTime.DATETIME_SHORT_WITH_SECONDS
          )}
          required
        />
        <FormControl fullWidth>
          <InputLabel id="role-select-label">Role*</InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            name="role"
            value={formik.values.role}
            label="Role"
            onChange={formik.handleChange}
            disabled={formik.isSubmitting}
            sx={{ width: '60%', mb: 2 }}
            error={formik.touched.role && Boolean(formik.errors.role)}
            required
          >
            {roles &&
              (roles ?? []).map(role => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'left',
            alignItems: 'center',
            gap: 2,
            mt: 2,
          }}
        >
          <Button variant="outlined" type="button" onClick={onCancel} sx={{ minWidth: '50px' }}>
            Cancel
          </Button>

          <PermissionModalButton
            modalTitle="Delete User"
            buttonLabel="Delete"
            variant="delete"
            buttonProps={{ size: 'small', variant: 'outlined' }}
            requiredPermissions={['users:delete']}
            modalDescription={user.email as string}
          >
            {({ closeModal }) => {
              const close = () => {
                closeModal();
                navigate('/users');
              };

              return (
                <DeleteUserForm userId={user.id as string} onSuccess={close} onCancel={close} />
              );
            }}
          </PermissionModalButton>

          <PermissionGuard requiredPermissions={['users:update']}>
            <LoadingButton
              type="submit"
              disabled={
                rolesLoading ||
                formik.values.role == user.role_id ||
                formik.isSubmitting ||
                !formik.dirty
              }
              loading={formik.isSubmitting}
              variant="contained"
              color="primary"
              sx={{ minWidth: '50px' }}
            >
              Save
            </LoadingButton>
          </PermissionGuard>
        </Box>
      </form>
    </LoadingBox>
  );
};

const UserDetailsPage = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { data: user, isPending: userPending } = useUser(userId as string);

  const mainPath = useMemo(() => {
    const route = find(MAIN_ROUTES, route => route.name === ROUTE_NAME.USERS) as AppRoute;
    return generatePath(route.path as string);
  }, []);

  const cancelToRoute = () => {
    navigate(mainPath);
  };

  if (userPending || !user) {
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'grid',
          rowGap: '16px',
          gridTemplateRows: '1fr',
        }}
      >
        <Skeleton key="pending-user" variant="rectangular" />
      </Box>
    );
  }

  return (
    <Page
      testIdPrefix="user-details"
      title={user?.email as string}
      backTo={{
        routePath: mainPath ?? '',
        buttonLabel: 'Back To Active Users',
      }}
    >
      <PageContentPaper>
        <UpdateUserForm user={user} onCancel={cancelToRoute} />
      </PageContentPaper>
    </Page>
  );
};

export default UserDetailsPage;
