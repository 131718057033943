import { userVerifyUrl } from '@/config';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AuthErrorPage from './AuthErrorPage';
import LoadingPage from '@/common/page/Loading';

type InviteParams = {
  token: string;
};

const InviteUserPage = () => {
  const { error, loginWithRedirect } = useAuth0();
  const { token } = useParams<InviteParams>();

  useEffect(() => {
    async function triggerAuth0Redirect() {
      let authorizationParams: any = {
        screen_hint: 'signup',
        prompt: 'login',
        invitationToken: token,
        beta: 'true',
      };

      if (
        window.location.hostname.includes('localhost') ||
        window.location.host.includes('127.0.0.1')
      ) {
        authorizationParams = { ...authorizationParams, verifyUri: userVerifyUrl };
      }

      loginWithRedirect({
        authorizationParams,
      });
    }

    if (token) {
      triggerAuth0Redirect();
    }
  }, [loginWithRedirect, token]);

  let localError = '';

  if (error) {
    return <div>Error: {`${error}`}</div>;
  }

  if (!token) {
    localError = 'Missing invite token, cannot continue.';
  }

  if (error || localError) return <AuthErrorPage reason={error || localError} />;
  else return <LoadingPage />;
};

export default InviteUserPage;
