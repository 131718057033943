import { failureStatus, inProgressStatus, successStatus } from '@/utils/helpers';
import { Chip, Typography, useTheme } from '@mui/material';

export interface StatusProps {
  status: string;
  isChip?: boolean;
}

/**
 * A component to display a read-only status(-ish) value in a consistent way.
 * Check out @/utils/helpers for the status values.
 * @param status The status value to display
 * @param isChip Whether to display the status as a chip or not. By default, this is @false.
 */
export const Status = ({ status, isChip = false }: StatusProps) => {
  const theme = useTheme();
  const color = inProgressStatus.includes(status)
    ? 'colors.secondary'
    : successStatus.includes(status)
      ? 'colors.success'
      : failureStatus.includes(status)
        ? 'colors.error'
        : 'colors.secondary';

  if (isChip) {
    let borderColor = '';
    try {
      borderColor = theme.palette.colors[color.replace('colors.', '')];
    } catch {
      console.warn(`Failed to determine status color for ${status}`);
    }

    return (
      <Chip
        label={status}
        sx={{ border: borderColor ? `1px solid ${borderColor}` : 'inherit', color: color }}
      />
    );
  }

  return (
    <Typography variant="caption" sx={{ color }}>
      {status}
    </Typography>
  );
};

export default Status;
