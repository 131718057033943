import { StripeIntegrationOutput } from '@/api-client';
import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import ResponsiveButtonGroup from '@/common/button/ResponsiveGroup';
import { useStripeIntegration } from '@/utils/hooks/stripePaymentIntegration';
import Box from '@mui/material/Box';
import ConnectStripeAccountButton from './ConnectAccountButton';
import DisconnectStripeAccountButton from './DisconnectAccountButton';
import RetryConnectStripeAccountButton from './RetryConnectAccountButton';
import PageContentPaper from '@/common/page/ContentPaper';
import OverviewContainer from '@/common/container/OverviewContainer';


const ConnectedStripeDetailsOverview = ({
  stripeIntegration,
}: {
  stripeIntegration: StripeIntegrationOutput;
}) => {
  return (
    <OverviewContainer isLoading={false} gridColumnDivisions={3} gridRowDivisions={1} fullHeight>
      <BasicInfoDisplay title="Name">
        {stripeIntegration.platform_stripe_account?.name || ''}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Mode">
        {stripeIntegration.platform_stripe_account?.mode || ''}
      </BasicInfoDisplay>

      <BasicInfoDisplay title="Status">Connected</BasicInfoDisplay>
    </OverviewContainer>
  );
};

const ConnectedStripeButtons = ({
  stripeIntegration,
  isConnected,
  isError,
}: {
  stripeIntegration: StripeIntegrationOutput;
  isConnected: boolean;
  isError: boolean;
}) => {
  if (isConnected && isError || !isConnected) return (<ConnectStripeAccountButton />)

  return (
    <Box sx={{ width: '250px' }}>
      <ResponsiveButtonGroup
        responsiveBreakpoint="lg"
        buttons={[
          {
            key: 'retry',
            element: (
              <RetryConnectStripeAccountButton disabled={stripeIntegration.setup_complete} />
            ),
          },
          {
            key: 'disconnect',
            element: <DisconnectStripeAccountButton />,
          },
        ]}
      />
    </Box>
  );
};

const StripePaymentIntegration = () => {
  const { data: stripeIntegration, isError } = useStripeIntegration();
  const isConnected = Boolean(stripeIntegration);

  return (
    <PageContentPaper
      headerText='Stripe'
      headerAction={<ConnectedStripeButtons isConnected={isConnected} isError={isError} stripeIntegration={stripeIntegration as StripeIntegrationOutput} />}
      paperWell={false}
    >
      <OverviewContainer isLoading={false} gridColumnDivisions={3} gridRowDivisions={1} fullHeight>
        {(isError || !isConnected) && (
          <>
          <BasicInfoDisplay title="Name"></BasicInfoDisplay>
          <BasicInfoDisplay title="Mode"></BasicInfoDisplay>
          <BasicInfoDisplay title="Status">Not Connected</BasicInfoDisplay>
          </>
        )}
      {isConnected && !isError && (
          <ConnectedStripeDetailsOverview
            stripeIntegration={stripeIntegration as StripeIntegrationOutput}
          />
        )} 
         
      </OverviewContainer>
    </PageContentPaper>
  );
};

export default StripePaymentIntegration;
