import { PropsWithChildren } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Box, Divider, SxProps } from '@mui/material';

export interface SimpleModalProps extends PropsWithChildren<any> {
  open: boolean;
  title: string | JSX.Element;
  titleIcon?: JSX.Element;
  description?: string;
  paperSxProps?: SxProps;
  onClose?: () => void;
}

/**
 * A simple modal with a title, description, and children.
 * @param open Whether the modal is open.
 * @param title The title of the modal.
 * @param titleIcon An optional icon to display next to the title.
 * @param description An optional description of the modal.
 * @param paperSxProps Props to pass to the Paper component of the modal.
 */
export const SimpleModal = ({
  open,
  children,
  title,
  titleIcon,
  description,
  paperSxProps,
  onClose,
}: SimpleModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
          ...(paperSxProps ?? {}),
          p: 4,
        }}
        elevation={5}
      >
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: description ? 1 : 0 }}>
            {titleIcon && titleIcon}

            <Typography variant="h5" sx={{ ml: titleIcon ? 1 : 0 }} id="parent-modal-title">
              {title}
            </Typography>
          </Box>

          {description && (
            <Typography variant="subtitle2" id="parent-modal-description">
              {description}
            </Typography>
          )}

          <Divider sx={{ mt: 1, mb: 2 }} />

          {children}
        </>
      </Paper>
    </Modal>
  );
};

export default SimpleModal;
