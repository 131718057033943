import { useOutletContext } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthContext } from './AuthPage';
import { useEffect } from 'react';
import { authState, setAuthAccessToken } from '../utils/states/authState';
import { auth0ClientId, userVerifyUrl } from '@/config';
import AuthErrorPage from './AuthErrorPage';
import { useSnapshot } from 'valtio';
import LoadingPage from '@/common/page/Loading';

const AuthLandingPage = ({ forceLogout }: { forceLogout?: boolean }) => {
  const { onLogin } = useOutletContext<AuthContext>();
  const { accessToken: authStateAccessToken } = useSnapshot(authState);
  const {
    loginWithRedirect,
    isAuthenticated,
    getAccessTokenSilently,
    error,
    user,
    isLoading,
    logout: auth0Logout,
  } = useAuth0();

  useEffect(() => {
    // If we hit this page but we need to logout, then logout and redirect back to this page.
    // An example would be when we redirect to the creator portal
    //  and make sure the user reauthenticates manually instead of continuing with their session.
    // e.g. Alpha/Private beta invite flow:
    // 1. [Frankenstein UI] - invite
    // 2. [Developer UI (Auth0 universal login/sign up)] - Sign up & redirect to developer UI
    // 3. [Developer UI] - redirect to creator portal after sign up
    // 4. [Creator Portal UI] - redirect to auth universal login/sign up page
    // 5. [Creator Portal UI (Auth0 universal login/sign up)] - Log in & redirect to Creator Portal UI
    if (forceLogout)
      if (isAuthenticated) {
        auth0Logout({
          clientId: auth0ClientId,
          logoutParams: {
            returnTo: `${window.location.origin}/auth0/logout`,
          },
        });
      } else {
        loginWithRedirect({
          authorizationParams: {
            prompt: 'login',
          },
        });
      }
  }, [forceLogout, loginWithRedirect, isAuthenticated, auth0Logout]);

  useEffect(() => {
    // Auth0 error or we're detecting if we're logged in -> get out of this effect
    if (error || isLoading || forceLogout) {
      return;
    }

    async function syncTokenToAuthState() {
      try {
        const auth0AccessToken = await getAccessTokenSilently();
        setAuthAccessToken(auth0AccessToken);
      } catch (e: any) {
        // If the refresh token is missing or invalid, then redirect to the login page.
        // This should not happen as we have refresh tokens enabled, store them in local storage, and
        // our audience API is configured to allow refresh tokens.
        // But, as a fallback, it's best to keep this here.
        if (e.error === 'missing_refresh_token' || e.error === 'invalid_grant') {
          loginWithRedirect({
            authorizationParams: {
              prompt: 'login',
            },
          });
        }
      }
    }

    if (authStateAccessToken) {
      // We're good, nothing to do, get out of here!
      onLogin();
    } else if (isAuthenticated && user) {
      // Auth0 says we're authenticated -> trigger a request for access token
      syncTokenToAuthState();
    } else {
      // No access token and not authenticated with auth0 -> trigger a login.
      let authorizationParams: any = {
        beta: 'true',
        prompt: 'login',
      };

      if (
        window.location.hostname.includes('localhost') ||
        window.location.host.includes('127.0.0.1')
      ) {
        authorizationParams = { ...authorizationParams, verifyUri: userVerifyUrl };
      }

      loginWithRedirect({
        authorizationParams,
      });
    }
  }, [
    isAuthenticated,
    user,
    isLoading,
    loginWithRedirect,
    authStateAccessToken,
    error,
    getAccessTokenSilently,
    onLogin,
    forceLogout,
  ]);

  if (error) return <AuthErrorPage reason={error.message} />;
  else return <LoadingPage />;
};

export default AuthLandingPage;
