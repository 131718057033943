import PageContentTabs, {
  ContentTab,
  OVERVIEW_TAB_LABEL,
  OVERVIEW_TAB_VALUE,
} from '@/common/page/ContentTabs';
import Page, { entityPageVisitState } from '@/common/page/Index';
import { MAIN_ROUTES } from '@/routes';
import { ENTITY_PAGE_VISIT_TYPE, ROUTE_NAME } from '@/utils/constants';
import { useCollection } from '@/utils/hooks/collection';
import { useItem, useItemTitle } from '@/utils/hooks/item';
import { useListing } from '@/utils/hooks/listing';
import { usePurchaseIntent } from '@/utils/hooks/purchaseIntent';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { generatePath, useNavigate } from 'react-router-dom';
import PurchaseIntentDetailsOverview from './DetailsOverview';
import { getColOrItemHierarchyRoutePath } from '@/utils/route';

const PurchaseIntentDetailsPage = () => {
  const {
    purchaseIntentId: purchaseIntentIdFromUrl,
    collectionId: collectionIdFromUrl,
    itemId: itemIdFromUrl,
  } = useParams();
  const { data: purchaseIntent, isError: purchaseIntentError } = usePurchaseIntent(
    purchaseIntentIdFromUrl ?? ''
  );
  const { data: listing } = useListing(purchaseIntent?.listing_id ?? '');
  const { data: item } = useItem(listing?.item_id ?? '');
  const itemTitle = useItemTitle(item?.attributes, item?.token_id);
  const { data: collection } = useCollection(listing?.collection_id ?? item?.collection_id ?? '');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const tabs = useMemo(() => {
    const result: Array<ContentTab> = [
      {
        label: OVERVIEW_TAB_LABEL,
        value: OVERVIEW_TAB_VALUE,
        gridContent: false,
        content: purchaseIntent?.id ? (
          <PurchaseIntentDetailsOverview purchaseIntentId={purchaseIntent?.id} />
        ) : (
          <></>
        ),
      },
    ];
    return result;
  }, [purchaseIntent?.id]);

  useEffect(() => {
    if (!purchaseIntent?.id || entityPageVisitState?.data?.platformId === purchaseIntent?.id)
      return;

    entityPageVisitState.data = {
      type: ENTITY_PAGE_VISIT_TYPE.PURCHASE_INTENT,
      context: !item?.id
        ? `Purchase Intent for collection ${collection?.name ?? ''}`
        : `Purchase Intent for item ${itemTitle}`,
      platformId: purchaseIntent?.id,
    };
  }, [purchaseIntent?.id, collection?.name, item?.id, itemTitle]);

  useEffect(() => {
    if (purchaseIntentError) {
      const overviewRoute = MAIN_ROUTES.find(route => route.name === ROUTE_NAME.LISTINGS_OVERVIEW);
      if (!overviewRoute) return;
      enqueueSnackbar('Purchase intent not found, returning to listing overview.', {
        variant: 'info',
      });
      navigate(generatePath(overviewRoute.path));
    }
  }, [purchaseIntentError, navigate, enqueueSnackbar]);

  const backToRoutePath = useMemo(() => {
    const detailsRoute = MAIN_ROUTES.find(route => route.name === ROUTE_NAME.LISTING_DETAILS);
    if (!detailsRoute || !purchaseIntent?.listing_id) return undefined; // fallback route should always exist.

    let routePath = generatePath(detailsRoute.path, { listingId: purchaseIntent?.listing_id });

    if (itemIdFromUrl || collectionIdFromUrl) {
      routePath =
        getColOrItemHierarchyRoutePath({
          id: purchaseIntent?.listing_id,
          idPropName: 'listingId',
          detailsRouteName: ROUTE_NAME.LISTING_DETAILS,
          collectionId: collectionIdFromUrl,
          collectionDetailsRouteName: ROUTE_NAME.COLLECTION_LISTING_DETAILS,
          itemId: itemIdFromUrl,
          itemDetailsRouteName: itemIdFromUrl ? ROUTE_NAME.ITEM_LISTING_DETAILS : undefined,
        }) || '';
    }

    return routePath;
  }, [purchaseIntent?.listing_id, collectionIdFromUrl, itemIdFromUrl]);

  return (
    <Page
      testIdPrefix="purchase-intent-details"
      helmet="Purchase Intent Details"
      title="Purchase Intent Details"
      backTo={{
        routePath: backToRoutePath ?? '',
        buttonLabel: `Back To All${
          itemIdFromUrl && collectionIdFromUrl ? ' Item' : collectionIdFromUrl ? ' Collection' : ''
        } Purchase Intents`,
      }}
    >
      <PageContentTabs tabs={tabs} />
    </Page>
  );
};

export default PurchaseIntentDetailsPage;
