import { Alert, AlertTitle } from '@mui/material';
import { useMemo } from 'react';
import { getUriProtocol, isIpfsUri } from '@/utils/helpers';

interface BaseUriProps {
  baseUri?: string;
}

export const BaseUriAlert = ({ baseUri }: BaseUriProps) => {
  const [alertState, alertText] = useMemo(() => {
    const protocol = getUriProtocol(baseUri);

    if (!baseUri)
      return [
        protocol,
        'Currently Base URI is unknown. Not all contracts require baseURI, so this might be OK.',
      ];

    return isIpfsUri(baseUri)
      ? [
          'IPFS',
          'Collection items are pinned to IPFS. Collection items metadata are stored in decentralized file networks, so that they can’t be modified.',
        ]
      : [
          protocol.slice(0, -1).toUpperCase(),
          'Collection items are store in centralized networks, so may be modifiable.',
        ];
  }, [baseUri]);

  return (
    <Alert severity="info">
      <AlertTitle>Base URI ({alertState})</AlertTitle>
      {alertText}
    </Alert>
  );
};

export default BaseUriAlert;
