import { customPalette } from '@/theme/custom';
import { Box, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface AuthErrorPageProps {
  reason?: string;
}

export const AuthErrorPage = ({ reason }: AuthErrorPageProps) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        background: customPalette.gradients.backdrop,
      }}
    >
      <Typography variant="h1" gutterBottom textAlign="center" color="white">
        Oops!
      </Typography>

      <Typography variant="h4" gutterBottom textAlign="center" sx={{ mb: 2 }} color="white">
        {reason || 'An unexpected authentication error occurred.'}
      </Typography>

      <Link
        onClick={() => navigate('/auth0/logout')}
        variant="h5"
        textAlign="center"
        sx={{ color: 'white', textDecoration: 'underline', cursor: 'pointer' }}
      >
        To log in page
      </Link>
    </Box>
  );
};

export default AuthErrorPage;
