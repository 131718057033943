import {
  CreateMarketingCampaignInput,
  EditMarketingCampaignInput,
  MarketingCampaignOutput,
  MarketingCampaignsPaginatedOutput,
} from '@/api-client';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { adminApi, HOT_REFETCH_INTERVAL_MILLISECONDS } from '../api';
import {
  API_SORT_DIRECTION_ASC,
  API_SORT_DIRECTION_DESC,
  MARKETING_CAMPAIGNS_QUERY_KEY,
} from '../constants';

export const useCampaigns = (
  pageSize?: number,
  cursor?: string,
  sort?: {
    fieldName?: string;
    direction?: typeof API_SORT_DIRECTION_ASC | typeof API_SORT_DIRECTION_DESC;
  },
  filter?: {
    id?: string;
    name?: string;
  }
) => {
  const [output, setOutput] = useState<any>({} as any);
  const getCampaigns = useQuery({
    queryKey: [
      MARKETING_CAMPAIGNS_QUERY_KEY,
      pageSize,
      cursor,
      sort?.fieldName,
      sort?.direction,
      filter?.name,
      filter?.id,
    ],
    queryFn: () =>
      adminApi.marketingCampaign
        .marketingCampaignsGet(
          sort?.direction ?? API_SORT_DIRECTION_ASC,
          cursor,
          pageSize,
          filter?.name
        )
        .then(res => res.data),
    // Don't fire if we're filtering by id.
    enabled: !filter?.id,
    refetchInterval: HOT_REFETCH_INTERVAL_MILLISECONDS,
  });

  const getCampaign: UseQueryResult<MarketingCampaignOutput, unknown> = useQuery({
    queryKey: [MARKETING_CAMPAIGNS_QUERY_KEY, filter?.id],
    queryFn: () =>
      adminApi.marketingCampaign
        .marketingCampaignsCampaignIdGet(filter?.id as string)
        .then(res => res.data),
    enabled: Boolean(filter?.id),
  });

  useEffect(() => {
    if (getCampaign.data) {
      setOutput({
        ...getCampaign,
        data: {
          cursor: null,
          results: [getCampaign.data as MarketingCampaignOutput],
          total_results: getCampaign.data.id ? 1 : 0,
          has_more: false,
        },
      });
    } else if (getCampaigns.data) {
      setOutput(getCampaigns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCampaign?.data, getCampaigns?.data]);

  return output as UseQueryResult<MarketingCampaignsPaginatedOutput, unknown>;
};

export const useCampaign = (campaignId?: string) => {
  return useQuery({
    queryKey: [MARKETING_CAMPAIGNS_QUERY_KEY, campaignId],
    queryFn: () =>
      adminApi.marketingCampaign
        .marketingCampaignsCampaignIdGet(campaignId as string)
        .then(res => res.data),
    enabled: Boolean(campaignId),
    refetchInterval: HOT_REFETCH_INTERVAL_MILLISECONDS,
  });
};

export const useDeleteMarketingCampaign = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const deleteMarketingCampaign = useMutation({
    mutationFn: ({ campaignId }: { campaignId: string }) =>
      adminApi.marketingCampaign.marketingCampaignsCampaignIdDelete(campaignId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [MARKETING_CAMPAIGNS_QUERY_KEY] });
      enqueueSnackbar('Successfully deleted the marketing campaign.', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Error deleting the marketing campaign', {
        variant: 'error',
      });
    },
  });

  return deleteMarketingCampaign;
};

export const useEditMarketingCampaign = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const editMarketingCampaign = useMutation({
    mutationFn: ({ campaignId, body }: { campaignId: string, body: EditMarketingCampaignInput }) =>
      adminApi.marketingCampaign.marketingCampaignsCampaignIdPatch(campaignId, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [MARKETING_CAMPAIGNS_QUERY_KEY] });
      enqueueSnackbar('Successfully updated the marketing campaign.', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Error updating the marketing campaign', {
        variant: 'error',
      });
    },
  });

  return editMarketingCampaign;
};


export const useCreateMarketingCampaign = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const createMarketingCampaign = useMutation({
    mutationFn: (body: CreateMarketingCampaignInput) =>
      adminApi.marketingCampaign.marketingCampaignsPost(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [MARKETING_CAMPAIGNS_QUERY_KEY] });
      enqueueSnackbar('Marketing campaign saved', {
        variant: 'success',
      });
    },
    onError: (e: any) => {
      enqueueSnackbar(`Error saving marketing campaign: ${e.response.data.error.detail}`, {
        variant: 'error',
      });
    },
  });

  return createMarketingCampaign;
};
