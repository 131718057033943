import BlockExplorerFilter, { BlockExplorerFilterType } from '@/common/data/BlockExplorerFilter';
import { Typography } from '@mui/material';
import React from 'react';

interface TxInProgressProps {
  txHash: string;
  network: number;
}

const TxInProgress: React.FC<TxInProgressProps> = ({ txHash, network }) => {
  return (
    <Typography variant="caption" sx={{ color: 'shade.grey500', mr: '0.5em' }}>
      If you navigate away from this screen, the transaction
      <BlockExplorerFilter
        filterValue={txHash}
        filterType={BlockExplorerFilterType.TX_HASH}
        networkId={network}
        charLengthOnEitherSide={6}
        iconPosition="end"
        sx={{ mx: '0.5em' }}
      />
      will continue to process in the background.
    </Typography>
  );
};

export default TxInProgress;
