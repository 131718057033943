import { CreateApiKeyInput } from '@/api-client';
import { adminApi } from '@/utils/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_KEYS_QUERY_KEY } from '../constants';
import { useSnackbar } from 'notistack';
import { getApiErrorMessage } from '../helpers';

export const useApiKeys = (pageSize?: number, cursor?: string) => {
  return useQuery({
    queryKey: [API_KEYS_QUERY_KEY, pageSize, cursor],
    queryFn: () => adminApi.auth.apiKeysGet(undefined, cursor, pageSize).then(res => res.data),
  });
};

export const useApiKey = (apiKeyId?: string) => {
  return useQuery({
    queryKey: [API_KEYS_QUERY_KEY],
    queryFn: () => adminApi.auth.apiKeysApiKeyIdGet(apiKeyId as string).then(res => res.data),
    enabled: Boolean(apiKeyId),
  });
};

export const useCreateApiKey = () => {
  const queryClient = useQueryClient();

  // if we ever want to break these hooks out into a library, we don't want this to be a dependency
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (body: CreateApiKeyInput) => adminApi.auth.apiKeysPost(body),
    onSuccess: () => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey: [API_KEYS_QUERY_KEY] });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error creating api key', { variant: 'error' });
    },
  });

  return mutation;
};

export const useDeleteApiKey = () => {
  const queryClient = useQueryClient();

  // if we ever want to break these hooks out into a library, we don't want this to be a dependency
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: (apiKeyId: string) => adminApi.auth.apiKeysApiKeyIdDelete(apiKeyId),
    onSuccess: () => {
      // invalidate queries
      queryClient.invalidateQueries({ queryKey: [API_KEYS_QUERY_KEY] });
      enqueueSnackbar('Api key deleted', { variant: 'success' });
    },
    onError: error => {
      const errorMessage = getApiErrorMessage(error);
      enqueueSnackbar(errorMessage || 'Error creating api key', { variant: 'error' });
    },
  });

  return mutation;
};
