import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';

interface ModalActionButtonsProps {
  onNext: (() => void) | ((e: any) => void);
  onCancel: () => void;
  nextDisabled?: boolean;
  cancelDisabled?: boolean;
  nextLabel?: string;
  cancelLabel?: string;
  isLoading?: boolean;
}

const ModalActionButtons = ({
  onCancel,
  onNext,
  nextDisabled,
  cancelDisabled,
  nextLabel,
  cancelLabel,
  isLoading,
}: ModalActionButtonsProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
        mt: 2,
      }}
    >
      <Button
        variant="outlined"
        type="button"
        onClick={onCancel}
        sx={{ flexGrow: 1 }}
        disabled={Boolean(cancelDisabled)}
      >
        {cancelLabel ? cancelLabel : 'Cancel'}
      </Button>

      <LoadingButton
        loading={isLoading}
        disabled={Boolean(nextDisabled)}
        onClick={onNext}
        variant="contained"
        color="primary"
        sx={{ flexGrow: 1 }}
      >
        {nextLabel ? nextLabel : 'Next'}
      </LoadingButton>
    </Box>
  );
};

export default ModalActionButtons;
