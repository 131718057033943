import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import { TOKEN_TYPE_721_MAX_SUPPLY } from '@/utils/constants';
import { getMaxSupplyCoerced } from '@/utils/helpers';
import { useItemStatus } from '@/utils/hooks/item';
import { Skeleton } from '@mui/material';
import { useMemo } from 'react';

interface ItemSupplyProps {
  itemId: string;
}

export const ItemSupply = ({ itemId }: ItemSupplyProps) => {
  const { data: itemStatus, isPending } = useItemStatus(itemId);

  const coercedMaxSupply = useMemo(() => {
    return getMaxSupplyCoerced(itemStatus?.supply.total ?? '');
  }, [itemStatus?.supply.total]);

  const supplyLabel = useMemo(() => {
    if (!itemStatus?.supply || !itemStatus?.supply.total || itemStatus?.supply.total === '0')
      return 'N/A';
    else if (itemStatus?.supply.total === TOKEN_TYPE_721_MAX_SUPPLY) return 'UNIQUE';
    else {
      return `${itemStatus.supply.current} minted of ${coercedMaxSupply}`;
    }
  }, [itemStatus?.supply, coercedMaxSupply]);

  if (isPending) {
    return <Skeleton variant="text" width="150px" height="50px" />;
  }

  return <BasicInfoDisplay title="Supply">{supplyLabel}</BasicInfoDisplay>;
};

export default ItemSupply;
