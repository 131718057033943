import { Alert, AlertTitle, Typography } from '@mui/material';

export const TxPayerAlert = () => {
  return (
    <Alert severity="info">
      <AlertTitle>On-chain Tx Payer</AlertTitle>

      <Typography paragraph variant="inherit">
        When buying item(s) from this listing via <strong>an email address</strong>, the on-chain
        transfer transaction can be paid for by the buyer or by the seller.
      </Typography>

      <ul>
        <li>
          When the transaction is paid for by the <strong>buyer</strong>, the platform will return a{' '}
          <strong>mint voucher</strong> when payment is complete and the item is claimed.
        </li>

        <li>
          When the transaction is paid for <strong>seller</strong>, the platform will start an{' '}
          <strong>email claim flow</strong> when payment is complete and the item is claimed.
        </li>
      </ul>
    </Alert>
  );
};

export default TxPayerAlert;
