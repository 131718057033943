import Box from '@mui/material/Box';
import { Outlet, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import Sidebar from '@/layout/Sidebar';
import TopBar from '../layout/TopBar';
import { testModeState } from '@/utils/states/testModeState';
import { useMemo } from 'react';
import { useSnapshot } from 'valtio';

const appBarHeight = 56;
const testModeBarHeight = 48;

/**
 * The main layout for an authed user in the app.
 */
export const AuthedRouteLayout = () => {
  const { pathname } = useLocation();
  const { active: testMode } = useSnapshot(testModeState);

  const useHeight = useMemo(() => {
    return testMode ? appBarHeight + testModeBarHeight : appBarHeight;
  }, [testMode]);

  return (
    <Box width="100vw" height="100vh">
      <TopBar height={useHeight} testMode={testMode} testModeBarHeight={testModeBarHeight} />
      <Box
        sx={{
          display: 'flex',
          height: `calc(100vh - ${useHeight}px)`,
        }}
      >
        <Sidebar />

        <motion.main
          style={{ height: '100%', width: '100%' }}
          initial="initial"
          animate="in"
          exit="out"
          key={pathname}
          variants={{
            initial: {
              opacity: 0,
            },
            in: {
              opacity: 1,
            },
            out: {
              opacity: 0,
            },
          }}
          transition={{
            type: 'tween',
            ease: 'linear',
            duration: 0.6,
          }}
        >
          <Outlet />
        </motion.main>
      </Box>
    </Box>
  );
};

export default AuthedRouteLayout;
