import { useState } from 'react';
import { useContractInteraction } from './useContractInteraction';
import {
  ContractInteractionHookOptions,
  ContractInteractionHookReturn,
  ContractInteractionPrepareReturn,
} from '@/common/types';
import { usePreparePlatformDepositWithdraw } from '@/contracts/generated';
import { uuidToBytes32 } from '../helpers';
import { AllowedNetworks } from '@/billing/types';

export function useWithdrawCrypto(
  amount: bigint,
  orgId: string,
  network: AllowedNetworks,
  options: ContractInteractionHookOptions & { onComplete?: () => void } = {}
): ContractInteractionHookReturn & ContractInteractionPrepareReturn {
  const { handlePrepareError, disabled } = options;
  const [prepareError, setPrepareError] = useState<boolean>(false);

  const { config, isLoading: prepareLoading } = usePreparePlatformDepositWithdraw({
    chainId: network,
    onError: err => {
      if (disabled) {
        return;
      }

      if (handlePrepareError) {
        handlePrepareError(err);
      } else {
        setPrepareError(true);
      }
    },
    onSuccess: () => {
      setPrepareError(false);
    },
    args: [uuidToBytes32(orgId) as `0x${string}`, amount],
    enabled: !disabled,
  });

  const { handleSubmit, write, writeAsync } = useContractInteraction(config, {
    ...options,
    onReceipt: receipt => {
      if (options.onReceipt) {
        options.onReceipt(receipt);
      }
      if (options.onComplete) {
        options.onComplete();
      }
    },
  });

  return {
    prepareLoading,
    write,
    writeAsync,
    prepareError,
    handleSubmit,
  };
}
