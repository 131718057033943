import PermissionGuard from '@/common/PermissionGuard';
import { Button, ButtonProps } from '@mui/material';

interface PermissionButtonProps {
  action: () => void;
  buttonLabel: string;
  buttonProps?: ButtonProps;
  variant?: 'create' | 'delete' | 'configure';
  requiredPermissions?: string[];
}

/**
 * Renders a single button that trigger an action, restricted by permissions.
 * @param action function to execute on button push. 
 * @param variant The variant of the button. Defaults to 'create'. Affects button color.
 * @param buttonLabel The label of the button.
 * @param requiredPermissions The permissions required to render the button.
 * @param buttonProps Props to pass to the button.
 */
export const PermissionButton = ({
  action,
  variant,
  buttonLabel,
  requiredPermissions,
  buttonProps = {},
}: PermissionButtonProps) => {
  return (
    <PermissionGuard requiredPermissions={requiredPermissions || []}>
      <Button
        color={variant === 'delete' ? 'error' : 'primary'}
        variant="contained"
        onClick={action}
        {...(buttonProps ?? {})}
      >
        {buttonLabel}
      </Button>
    </PermissionGuard>
  );
};

export default PermissionButton;
