import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { adminApi } from '../api';
import { EMAIL_INTEGRATION_QUERY_KEY } from '../constants';
import { useSnackbar } from 'notistack';
import { EmailIntegrationInput, SendTestEmailInput } from '@/api-client';

export const useEmailIntegration = () => {
  const getIntegration = useQuery({
    queryKey: [EMAIL_INTEGRATION_QUERY_KEY],
    queryFn: () =>
      adminApi.emailIntegration.emailIntegrationsGet().then(res => res.data),
  });

  return getIntegration;
};


export const useCreateEmailProvider = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const createEmailProvider = useMutation({
    mutationFn: (body: EmailIntegrationInput) => adminApi.emailIntegration.emailIntegrationsPost(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMAIL_INTEGRATION_QUERY_KEY] });
      enqueueSnackbar('Email provider saved', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Error saving email provider', {
        variant: 'error',
      });
    },
  });

  return createEmailProvider;
};

export const useDeleteEmailProvider = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const deleteEmailProvider = useMutation({
    mutationFn: (id: string) => adminApi.emailIntegration.emailIntegrationsIntegrationIdDelete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMAIL_INTEGRATION_QUERY_KEY] });
      enqueueSnackbar('Email provider deleted', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Error deleting email provider', {
        variant: 'error',
      });
    },
  });

  return deleteEmailProvider;
};


export const useUpdateEmailProvider = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const updateEmailProvider = useMutation({
    mutationFn: ({id, body}: {id: string, body: EmailIntegrationInput}) => adminApi.emailIntegration.emailIntegrationsIntegrationIdPatch(id, body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMAIL_INTEGRATION_QUERY_KEY] });
      enqueueSnackbar('Email provider updated', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Error updating email provider', {
        variant: 'error',
      });
    },
  });

  return updateEmailProvider;
};

export const useSendTestEmailProvider = () => {
  const { enqueueSnackbar } = useSnackbar();

  const sendTestProvider = useMutation({
    mutationFn: (body: SendTestEmailInput) => adminApi.emailIntegration.emailIntegrationsSendTestPost(body),
    onSuccess: () => {
      enqueueSnackbar('Email provider test mail sent', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Error sending test email', {
        variant: 'error',
      });
    },
  });

  return sendTestProvider;
};
