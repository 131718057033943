import PermissionModalButton from '@/common/button/PermissionModal';
import RetryStripeSetupForm from './setup/RetrySetupForm';

interface RetryConnectStripeAccountButtonProps {
  disabled?: boolean;
}

export const RetryConnectStripeAccountButton = ({
  disabled,
}: RetryConnectStripeAccountButtonProps) => {
  return (
    <PermissionModalButton
      modalTitle="Retry stripe connection"
      buttonLabel="Retry"
      requiredPermissions={['payment_integrations:create']}
      buttonProps={{ disabled, variant: 'outlined' }}
    >
      {({ closeModal }) => {
        const close = () => {
          closeModal();
        };
        return <RetryStripeSetupForm onCancel={close} onSuccess={close} />;
      }}
    </PermissionModalButton>
  );
};

export default RetryConnectStripeAccountButton;
