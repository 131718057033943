import PageContentTabs, {
  ContentTab,
  OVERVIEW_TAB_LABEL,
  OVERVIEW_TAB_VALUE,
} from '@/common/page/ContentTabs';
import Page, { entityPageVisitState } from '@/common/page/Index';
import CollectionDetailsOverview from './DetailsOverview';
import { CollectionOutputRevealStrategyEnum } from '@/api-client';
import EmailClaimsGrid, {
  EmailClaimGridColumnField,
  generateDefaultColumnVisibilityModel as generateDefaultEmailClaimColumnVisiblityModel,
} from '@/email-claim/Grid';
import ItemsGrid from '@/items/Grid';
import ListingsGrid, {
  generateDefaultColumnVisibilityModel as generateDefaultListingColumnVisibilityModel,
} from '@/listings/Grid';
import { MAIN_ROUTES } from '@/routes';
import TransactionsGrid, {
  TransactionGridColumnField,
  generateDefaultColumnVisibilityModel as generateDefaultTransactionColumnVisibilityModel,
} from '@/transactions/Grid';
import { ENTITY_PAGE_VISIT_TYPE, ROUTE_NAME } from '@/utils/constants';
import { useCollection } from '@/utils/hooks/collection';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router';
import {
  DELAYED_REVEAL_TAB_LABEL,
  DELAYED_REVEAL_TAB_VALUE,
  EMAIL_CLAIMS_TAB_LABEL,
  EMAIL_CLAIMS_TAB_VALUE,
  ITEMS_TAB_LABEL,
  ITEMS_TAB_VALUE,
  LISTINGS_TAB_LABEL,
  LISTINGS_TAB_VALUE,
  TRANSACTIONS_TAB_LABEL,
  TRANSACTIONS_TAB_VALUE,
} from './CollectionDetailsContentTabs';
import RevealStrategyOverviewPage from './reveal-strategy/OverviewPage';

const customTransactionVisibility = {
  ...generateDefaultTransactionColumnVisibilityModel(),
  [TransactionGridColumnField.COLLECTION_ID]: false,
};

const customEmailClaimVisibility = {
  ...generateDefaultEmailClaimColumnVisiblityModel(),
  [EmailClaimGridColumnField.COLLECTION_ID]: false,
  [EmailClaimGridColumnField.LAST_PIN_CODE_EMAIL_SENT_AT]: false,
};

const customListingVisibility = {
  ...generateDefaultListingColumnVisibilityModel(),
  [EmailClaimGridColumnField.COLLECTION_ID]: false,
};

// TODO: Use Valtio to manage selected item state for non-grid manipulations. (e.g. delete item -> remove from selected items)
// TODO: Be smarter about allowing to locking, listing and minting selected/remaining items.

const CollectionDetailsPage = () => {
  const { collectionId } = useParams();
  const { data: collection, isError: collectionError } = useCollection(collectionId ?? '');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const tabs = useMemo(() => {
    let result: Array<ContentTab> = [
      {
        label: OVERVIEW_TAB_LABEL,
        value: OVERVIEW_TAB_VALUE,
        content: <CollectionDetailsOverview />,
      },
      {
        label: DELAYED_REVEAL_TAB_LABEL,
        value: DELAYED_REVEAL_TAB_VALUE,
        content: <RevealStrategyOverviewPage collectionId={collectionId} />,
      },
      {
        label: ITEMS_TAB_LABEL,
        value: ITEMS_TAB_VALUE,
        content: <ItemsGrid collectionId={collectionId ?? ''} />,
      },
      {
        label: LISTINGS_TAB_LABEL,
        value: LISTINGS_TAB_VALUE,
        content: (
          <ListingsGrid collectionId={collectionId} customVisibility={customListingVisibility} />
        ),
      },
      {
        label: EMAIL_CLAIMS_TAB_LABEL,
        value: EMAIL_CLAIMS_TAB_VALUE,
        content: (
          <EmailClaimsGrid
            collectionId={collectionId}
            customVisibility={customEmailClaimVisibility}
          />
        ),
      },
      {
        label: TRANSACTIONS_TAB_LABEL,
        value: TRANSACTIONS_TAB_VALUE,
        content: (
          <TransactionsGrid
            collectionId={collectionId}
            customVisibility={customTransactionVisibility}
          />
        ),
      },
    ];

    // optionally remove reveal tab
    if (collection?.reveal_strategy === CollectionOutputRevealStrategyEnum.Instant)
      result = result.filter(item => item.value !== DELAYED_REVEAL_TAB_VALUE);

    return result;
  }, [collectionId, collection?.reveal_strategy]);

  useEffect(() => {
    if (!collection || entityPageVisitState?.data?.platformId === collection.id) return;
    entityPageVisitState.data = {
      type: ENTITY_PAGE_VISIT_TYPE.COLLECTION,
      context: collection.name,
      platformId: collection.id,
    };
  }, [collection]);

  const overviewRoutePath = useMemo(() => {
    const overviewRoute = MAIN_ROUTES.find(route => route.name === ROUTE_NAME.COLLECTIONS_OVERVIEW);
    if (!overviewRoute) return undefined;
    return generatePath(overviewRoute.path);
  }, []);

  useEffect(() => {
    if (collectionError && overviewRoutePath) {
      enqueueSnackbar('Collection not found, returning to collection overview.', {
        variant: 'info',
      });
      navigate(overviewRoutePath);
    }
  }, [collectionError, navigate, enqueueSnackbar, overviewRoutePath]);

  return (
    <Page
      testIdPrefix="collection-details"
      helmet={collection?.name ?? 'Collection Details'}
      title={collection?.name ?? ''}
      backTo={{
        routePath: overviewRoutePath ?? '',
        buttonLabel: 'Back To All Collections',
      }}
    >
      <PageContentTabs tabs={tabs} />
    </Page>
  );
};

export default CollectionDetailsPage;
