import BlockExplorerFilter, { BlockExplorerFilterType } from '@/common/data/BlockExplorerFilter';
import { paymentsNetworkId } from '@/config';
import { getContractAddress } from '@/contracts/deployments';
import { erc20ABI, usePlatformDepositPaymentTokenAddress } from '@/contracts/generated';
import { ORG_BALANCE_QUERY_KEY } from '@/utils/constants';
import { usdcToDecimalString } from '@/utils/helpers';
import { useDepositCrypto } from '@/utils/hooks/useDepositCrypto';
import { useTokenAllowance } from '@/utils/hooks/useTokenAllowance';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { parseUnits } from 'ethers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAccount, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi';
import { AllowedNetworks } from './types';
import ModalActionButtons from './ModalActionButtons';
import { Alert, AlertTitle, Box } from '@mui/material';
import BasicInfoDisplay from '@/common/data/BasicInfoDisplay';
import TxInProgress from './TxInProgress';
import DisconnectWallet from './DisconnectWallet';
import { readContract } from '@wagmi/core';

const NEXT_ACTION = {
  CONNECT_WALLET: 0,
  APPROVE_AMOUNT: 1,
  SWITCH_CHAIN: 2,
  DEPOSIT: 3,
  CLOSE: 4,
};

const MINIMUM_DEPOSIT = 1;

const useSafeERC20Balance = (chainId: AllowedNetworks, address?: `0x${string}`) => {
  const { data: paymentTokenAddress } = usePlatformDepositPaymentTokenAddress({ chainId });

  return useQuery({
    queryKey: ['paymentTokenBalance', chainId, address],
    queryFn: async () => {
      const balance = await readContract({
        abi: erc20ABI,
        address: paymentTokenAddress!,
        functionName: 'balanceOf',
        chainId: chainId,
        args: [address!],
      });
      return balance;
    },
    enabled: !!address && !!paymentTokenAddress,
  });
};

const AddCryptoForm = ({ orgId, onSuccess, onCancel }: any) => {
  const [amount, setAmount] = useState<number | string>('');
  const [approveTxHash, setApproveTxHash] = useState('');
  const [depositTxHash, setDepositTxHash] = useState('');
  const [depositSuccessful, setDepositSuccessful] = useState(false);
  const [nextButtonLabel, setNextButtonLabel] = useState('Connect Wallet');
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [waitingForMetamask, setWaitingForMetamask] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const { address, status: addressStatus } = useAccount();
  const [nextAction, setNextAction] = useState(NEXT_ACTION.CONNECT_WALLET);
  const queryClient = useQueryClient();
  const { openConnectModal } = useConnectModal();
  const { chains, switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();

  // get network from config
  const network = paymentsNetworkId as AllowedNetworks;

  const { disconnect } = useDisconnect();

  // Retrieve payment token address
  // This also provides an easy way to ensure that the contract is deployed and configured correctly
  const { data: paymentTokenAddress } = usePlatformDepositPaymentTokenAddress({
    chainId: network,
  });

  const { data: balanceData } = useSafeERC20Balance(network, address);

  const { isApproved, handleSubmit: handleApprove } = useTokenAllowance(
    amount === '' ? 9999999999999 : (amount as number),
    getContractAddress('PlatformDeposit', network)!,
    network,
    paymentTokenAddress as `0x${string}`,
    {
      onStart: () => {
        setWaitingForMetamask(true);
      },
      onFinally: () => {
        setWaitingForMetamask(false);
      },
      onSubmit: txHash => {
        setTxInProgress(true);
        setApproveTxHash(txHash);
      },
      onError: err => handleApprovalError(err),
      onAllowanceComplete: () => {
        setTxInProgress(false);
      },
      handlePrepareError: (err: any) => {
        setTxInProgress(false);
        // if connected to the wrong chain
        console.log('Prepare error:', err);
      },
    }
  );

  // based on state, set the action button status, labels and how to handle the next action
  useEffect(() => {
    if (depositSuccessful) {
      setNextButtonDisabled(false);
      setNextButtonLabel('Close');
      setNextAction(NEXT_ACTION.CLOSE);
    } else if (address && isApproved) {
      setNextButtonLabel('Confirm Deposit');
      setNextButtonDisabled(false);
      setNextAction(NEXT_ACTION.DEPOSIT);
    } else if (address && chain?.id !== network) {
      setNextButtonLabel('Switch Chain');
      setNextButtonDisabled(false);
      setNextAction(NEXT_ACTION.SWITCH_CHAIN);
    } else if (address && !isApproved) {
      if (amount === '') {
        setNextButtonLabel('Select Amount');
        setNextButtonDisabled(true);
        setNextAction(NEXT_ACTION.APPROVE_AMOUNT);
      } else {
        setNextButtonLabel(`Approve Amount`);
        setNextButtonDisabled((amount as number) < MINIMUM_DEPOSIT);
        setNextAction(NEXT_ACTION.APPROVE_AMOUNT);
      }
    } else if (!address || addressStatus !== 'connected' || !chain) {
      setNextButtonLabel('Connect Wallet');
      setNextButtonDisabled(false);
      setNextAction(NEXT_ACTION.CONNECT_WALLET);
    } else {
      setNextButtonLabel('Unknown');
      setNextButtonDisabled(true);
    }
  }, [
    address,
    isApproved,
    setNextButtonLabel,
    setNextButtonDisabled,
    amount,
    chain,
    network,
    addressStatus,
    depositSuccessful,
  ]);

  function handleApprovalError(err: any) {
    setTxInProgress(false);
    setNextButtonDisabled(false);
    console.log(err);
  }

  const { handleSubmit: handleDeposit } = useDepositCrypto(
    amount === '' ? 0 : (amount as number),
    orgId,
    network,
    {
      disabled: !isApproved,
      onStart: () => {
        setWaitingForMetamask(true);
      },
      onSubmit: txHash => {
        setTxInProgress(true);
        setDepositTxHash(txHash);
      },
      onError: err => handleApprovalError(err),
      onComplete: () => {
        queryClient.invalidateQueries({ queryKey: [ORG_BALANCE_QUERY_KEY] });
        setTxInProgress(false);
        setDepositSuccessful(true);
      },
      onFinally: () => {
        setWaitingForMetamask(false);
      },
      handlePrepareError: (err: any) => {
        setTxInProgress(false);
        console.log('Prepare error:', err);
      },
    }
  );

  const onNextClicked = useCallback(() => {
    if (nextAction === NEXT_ACTION.CONNECT_WALLET && openConnectModal) {
      openConnectModal();
    } else if (nextAction === NEXT_ACTION.APPROVE_AMOUNT) {
      handleApprove();
    } else if (nextAction === NEXT_ACTION.DEPOSIT) {
      handleDeposit();
    } else if (nextAction === NEXT_ACTION.SWITCH_CHAIN && switchNetwork) {
      switchNetwork(network as number);
    } else if (nextAction === NEXT_ACTION.CLOSE) {
      onSuccess();
    }
  }, [
    nextAction,
    handleApprove,
    handleDeposit,
    openConnectModal,
    switchNetwork,
    network,
    onSuccess,
  ]);

  const targetChain = useMemo(() => {
    if (!chains) {
      return undefined;
    }
    try {
      return chains.filter(c => c.id === network)[0];
    } catch (e) {
      return undefined;
    }
  }, [chains, network]);

  const amountAsWei = useMemo(() => {
    try {
      return (amount as number) * 10 ** 6;
    } catch (e) {
      return '';
    }
  }, [amount]);

  return (
    <form onSubmit={() => {}}>
      {!depositSuccessful && (
        <Typography variant="subtitle2" id="parent-modal-description" sx={{ marginBottom: 3 }}>
          Select the amount you want to deposit to your balance. The minimum deposit is 1 USDC.
        </Typography>
      )}

      {depositSuccessful ? (
        <>
          <Alert severity="success">
            <AlertTitle>Success!</AlertTitle>Your deposit has been submitted. Once it has been
            included in the blockchain, the platform will automatically detect the deposit and
            update your balance.
          </Alert>
          <Box>
            <BasicInfoDisplay title="Amount">{`${amount}`} USDC</BasicInfoDisplay>
          </Box>
          <Box>
            <BasicInfoDisplay title="Tx Hash">
              <BlockExplorerFilter
                filterValue={depositTxHash}
                filterType={BlockExplorerFilterType.TX_HASH}
                networkId={network}
                charLengthOnEitherSide={6}
                iconPosition="end"
              />
            </BasicInfoDisplay>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              id="address"
              label="Wallet Address"
              name="address"
              placeholder="Connect Wallet"
              required
              type="text"
              value={addressStatus === 'connected' ? address : ''}
              InputProps={{
                readOnly: true,
              }}
            />
            {address && <DisconnectWallet onDisconnect={disconnect} />}
          </Box>

          <Box>
            <TextField
              type="number"
              name="amount"
              label="Amount"
              inputProps={{ step: 0.01 }}
              sx={{ width: '100%' }}
              onChange={e => {
                if (e.target.value.length === 0) {
                  setAmount('');
                }
                try {
                  parseUnits(e.target.value, 6);
                  setAmount(+e.target.value);
                } catch (e: any) {
                  // swallow
                }
              }}
              value={amount}
              placeholder="The amount to add to your balance"
              disabled={!address}
              required
            />

            {nextAction === NEXT_ACTION.SWITCH_CHAIN ? (
              <Typography variant="caption" sx={{ color: 'red' }}>
                Please change to the {targetChain!.name} network in your wallet.
              </Typography>
            ) : balanceData === BigInt(0) ? (
              <Typography variant="caption" sx={{ color: 'red' }}>
                You do not currently have any USDC on {targetChain!.name}. Please get some before
                continuing.
              </Typography>
            ) : balanceData !== undefined && balanceData < BigInt(amountAsWei) ? (
              <Typography variant="caption" sx={{ color: 'red' }}>
                You do not have enough USDC to deposit this amount. Your current balance is:{' '}
                {usdcToDecimalString(balanceData)}.
              </Typography>
            ) : amount !== '' && (amount as number) < MINIMUM_DEPOSIT ? (
              <Typography variant="caption" sx={{ color: 'red' }}>
                The minimum deposit is {MINIMUM_DEPOSIT} USDC.
              </Typography>
            ) : (balanceData !== undefined && amount == '') ||
              (balanceData && balanceData >= BigInt(amountAsWei)) ? (
              <Typography variant="caption" sx={{ color: 'shade.grey500' }}>
                Your current balance is: {usdcToDecimalString(balanceData)}.
              </Typography>
            ) : null}
          </Box>
        </>
      )}

      <ModalActionButtons
        cancelDisabled={depositSuccessful}
        nextLabel={nextButtonLabel}
        nextDisabled={nextButtonDisabled}
        onNext={onNextClicked}
        onCancel={onCancel}
        isLoading={waitingForMetamask || txInProgress}
      />

      {txInProgress && depositTxHash ? (
        <TxInProgress txHash={depositTxHash} network={network} />
      ) : txInProgress && approveTxHash ? (
        <TxInProgress txHash={approveTxHash} network={network} />
      ) : null}
    </form>
  );
};

export default AddCryptoForm;
