import { EntityPageVisit } from '@/types/pagevisit';
import useAllEntityPageVisits from '@/utils/hooks/useAllEntityPageVisits';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ArrowBackIcon from '@mui/icons-material/ArrowBackOutlined';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { proxy, subscribe } from 'valtio';
import PageHelmet from '@/common/page/Helmet';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DocsContextHint from '../data/DocsContextHint';

interface PageProps extends React.PropsWithChildren<any> {
  backTo?: { routePath: string; buttonLabel: string };
  helmet?: string;
  title?: string;
  subTitle?: string;
  actions?: React.ReactNode;
  testIdPrefix?: string;
  sx?: SxProps;
}

export type SpecifyableEntityPageVisit = Omit<Omit<EntityPageVisit, 'visitedOn'>, 'path'>;

export const entityPageVisitState = proxy<{ data: SpecifyableEntityPageVisit | null }>();

/**
 * The base component for all content pages.
 * @param helmet The title to display in the browser tab
 * @param title The page title
 * @param subTitle The page subtitle
 * @param actions The actions to display in the top right corner
 * @param allowVerticalScroll Whether to allow vertical scrolling (small resolutions)
 * @param backTo If we need to render a back button
 * @param children The page content
 */
export const Page = ({
  backTo,
  children,
  helmet,
  testIdPrefix,
  title,
  subTitle,
  actions,
  sx = {},
  allowVerticalScroll = false,
}: PageProps) => {
  const navigate = useNavigate();
  const { palette } = useTheme<Theme>();
  const { registerPageVisit } = useAllEntityPageVisits();

  useEffect(() => {
    if (entityPageVisitState.data) {
      entityPageVisitState.data = null;
    }

    subscribe(entityPageVisitState, () => {
      if (!entityPageVisitState.data) {
        return;
      }
      const { context, type, platformId } = entityPageVisitState.data;
      registerPageVisit(context, window.location.pathname, type, platformId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasTopSection =
    Boolean(backTo) || Boolean(title) || Boolean(subTitle);

  return (
    <Paper
      elevation={0}
      square
      sx={{
        overflowY: allowVerticalScroll ? 'auto' : 'hidden',
        padding: '1.5rem 2.5rem',
        display: 'flex',
        flexDirection: title ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        background: palette.background.page,
        ...sx,
      }}
    >
      {helmet && <PageHelmet title={helmet} />}

      {/* Top section: navigation back to, title, subtitle, in our docs */}
      <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: '100%',
          }}
        >
        {hasTopSection && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: 'auto',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {title && (
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ ml: 0 }}
                  >
                    {title}
                  </Typography>
                </Box>
              )}  

              {backTo ? (
                <Box sx={{ width: '100%', display: 'flex' }}>
                  <Button
                    variant="text"
                    color="secondary"
                    startIcon={<ArrowBackIcon />}
                    sx={{ py: 0, pl: 0 }}
                    onClick={() => {
                      if (backTo.routePath) navigate(backTo.routePath);
                    }}
                  >
                    {backTo.buttonLabel}
                  </Button>
                </Box>
              ) : (
                <></>
              )}

              {subTitle && (
                <Box sx={{ width: '100%', display: 'flex' }}>
                  <Typography variant="h6">{subTitle}</Typography>
                </Box>
              )}
            </Box>

            {actions && <Box>{actions}</Box>}
          </Box>
        )}

        {!testIdPrefix?.startsWith('nodocs-') && (<DocsContextHint />)}
      </Box>


      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: title ? 'calc(100% - 50px)' : '100%',
          width: '100%',
          mt: hasTopSection ? 2 : 0,
        }}
        data-testid={`page-${testIdPrefix}`}
      >
        {children}
      </Box>
    </Paper>
  );
};

export default Page;
