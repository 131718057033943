import { BaseRow } from '@/common/data/grid/constants';
import { PaginatedGridPage } from '@/common/data/grid/ServerPaginated';
import { useMemo } from 'react';
import { useSnapshot } from 'valtio';

export const useSortAndFilterGrid = <TRow extends BaseRow>(pageState: PaginatedGridPage<TRow>) => {
  const pageSnap = useSnapshot(pageState);

  // Construct sort and filter args based on the snap of the active page.
  const sortBy = useMemo(() => {
    return {
      // eslint-disable-next-line valtio/state-snapshot-rule
      fieldName: pageSnap.sortBy?.field,
      // eslint-disable-next-line valtio/state-snapshot-rule
      direction: pageSnap.sortBy?.direction,
    };
  }, [pageSnap.sortBy]);
  const filterBy = useMemo(() => {
    return {
      // eslint-disable-next-line valtio/state-snapshot-rule
      ...pageSnap.filterBy?.reduce(
        (reduced, current) => ({
          ...reduced,
          [current.field]: current.value === '' ? undefined : current.value,
        }),
        {}
      ),
    };
  }, [pageSnap.filterBy]);

  return {
    sortBy,
    filterBy,
  };
};
