import { RolesPaginatedOutputResultsInner, RolesPaginatedOutput } from '@/api-client';
import { adminApi, DEFAULT_API_PAGINATION_LIMIT } from '@/utils/api';
import { ROLES_QUERY_KEY } from '../constants';
import useAllPages from './useAllPages';

export const useAllRoles = () => {
  const result = useAllPages<RolesPaginatedOutputResultsInner, RolesPaginatedOutput>(
    [ROLES_QUERY_KEY],
    (cursor: string | undefined) =>
      adminApi.role.rolesGet(undefined, cursor, DEFAULT_API_PAGINATION_LIMIT, undefined),
    {
      staleTime: 1000 * 60 * 60 * 4, // 4 hours
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
    }
  );
  return result;
};
