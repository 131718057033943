import { default as MuiAppBar } from '@mui/material/AppBar';
import Switch from '@mui/material/Switch';
import Toolbar from '@mui/material/Toolbar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import AppLogo from '@/common/data/AppLogo';
import { useSnapshot } from 'valtio';
import PopoverButton from '@/common/button/Popover';
import UserProfileCard from '@/users/ProfileCard';
import { authState } from '@/utils/states/authState';
import { setTestMode } from '@/utils/states/testModeState';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { customPalette } from '@/theme/custom';

export interface TopBarProps {
  height: number;
  testModeBarHeight: number;
  testMode?: boolean;
}

const TopBar = ({ height, testMode, testModeBarHeight }: TopBarProps) => {
  const { platformUser } = useSnapshot(authState);

  return (
    <MuiAppBar
      position="static"
      sx={{
        height: `${height}px`,
        border: 0,
        position: 'relative',
        zIndex: 1,
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-around', height: `${height}px` }}>
        <Box sx={{ flexGrow: 1 }}>
          <AppLogo fillColor="white" textWidth="294px" />
        </Box>
        <Box sx={{ mr: 2 }}>
          <FormControlLabel
            control={
              <Switch
                sx={{ color: 'white' }}
                color="warning"
                checked={testMode}
                onChange={(_, state) => setTestMode(state)}
              />
            }
            label="Test Mode"
          />
        </Box>

        {platformUser && (
          <Box sx={{ display: 'flex' }}>
            <PopoverButton
              buttonIcon={<AccountCircleIcon fontSize="large" sx={{ color: 'white' }} />}
              popoverContent={<UserProfileCard />}
              popoverContainerSx={{ width: '300px', padding: 0, top: `${height + 2}px !important` }}
            />
          </Box>
        )}
      </Toolbar>
      {testMode && (
        <Toolbar
          sx={{
            backgroundColor: customPalette.colors.testMode.barBackground,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'unset !important',
            height: `${testModeBarHeight}px !important`,
          }}
        >
          <WarningAmberIcon />
          <Typography marginLeft={1} color={customPalette.colors.testMode.barColor} variant="body2">
            You are in Test Net mode and currently viewing collections, transaction and balances on
            test networks.
          </Typography>
        </Toolbar>
      )}
    </MuiAppBar>
  );
};

export default TopBar;
