import {
  CollectionStats,
  CollectionStatsAttributesInner,
  CollectionStatsAttributesInnerValuesInner,
} from '@/api-client-public';
import {
  Accordion,
  Typography,
  AccordionDetails,
  Checkbox,
  Chip,
  Box,
  AccordionSummary,
  TextField,
  InputAdornment,
  useTheme,
  Paper,
} from '@mui/material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { useCallback, useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import orderBy from 'lodash/orderBy';
import NoDataOverlay from '@/common/data/NoDataOverlay';

export interface ItemAttributeGridFilterItem {
  category: string;
  attribute: any;
  source: CollectionStatsAttributesInnerValuesInner;
}

interface ItemAttributeFilterProps {
  collectionStats?: CollectionStats;
  handleFilter: (checked: ItemAttributeGridFilterItem[]) => void;
}

export const ItemAttributeGridFilter = ({
  collectionStats,
  handleFilter,
}: ItemAttributeFilterProps) => {
  const theme = useTheme();
  const [attributeSearch, setAttributeSearch] = useState('');
  const [attributes, setAttributes] = useState<CollectionStatsAttributesInner[]>([]);
  const [checked, setChecked] = useState<ItemAttributeGridFilterItem[]>([]);
  const [totalAttributeCount, setTotalAttributeCount] = useState<number>(0);

  useEffect(() => {
    const filteredAttributes = attributeSearch
      ? collectionStats?.attributes
          .filter(
            attribute =>
              attribute?.name?.toLowerCase()?.includes(attributeSearch.toLowerCase()) ||
              attribute?.values?.some(
                attributeValue =>
                  attributeValue?.name
                    ?.toString()
                    .toLowerCase()
                    ?.includes(attributeSearch.toLowerCase())
              )
          )
          .map(attribute => {
            return {
              ...attribute,
              values: attribute?.values?.filter(
                attributeValue =>
                  attributeValue?.name
                    ?.toString()
                    .toLowerCase()
                    .includes(attributeSearch.toLowerCase())
              ),
            };
          })
      : collectionStats?.attributes;
    setAttributes(orderBy(filteredAttributes, attribute => attribute.name, 'asc'));
    let totalAttributes = 0;
    for (const attribute of collectionStats?.attributes ?? []) {
      totalAttributes += attribute?.values?.length;
    }
    setTotalAttributeCount(totalAttributes);
  }, [attributeSearch, collectionStats?.attributes]);

  const handleCheckedChanged = useCallback(
    (
      isChecked: boolean,
      attribute: CollectionStatsAttributesInnerValuesInner,
      category: CollectionStatsAttributesInner
    ) => {
      const newChecked = isChecked
        ? [...checked, { category: category.name, attribute: attribute.name, source: attribute }]
        : checked.filter(a => a.source !== attribute);
      setChecked(newChecked);
      handleFilter(newChecked);
    },
    [checked, handleFilter]
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <TextField
        size="small"
        value={attributeSearch}
        onChange={e => setAttributeSearch(e.target.value)}
        placeholder="Search attributes"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ mb: 1 }}
      />

      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Chip color="info" label="Attributes are indexed after minting" sx={{ flex: 1, mb: 1 }} />
      </Box>

      <Box sx={{ display: 'flex', mb: 1 }}>
        <Chip
          variant={attributes?.length ? 'filled' : 'outlined'}
          label={
            attributeSearch
              ? `Showing: ${attributes?.length} / ${totalAttributeCount}`
              : `Total Attributes: ${totalAttributeCount}`
          }
          sx={{ mr: 1, flex: 1 }}
        />

        <Chip
          variant={checked.length ? 'filled' : 'outlined'}
          label={checked.length ? `Active Filters: ${checked?.length}` : `No active filters`}
          sx={{ flex: 1 }}
        />
      </Box>

      <Box
        sx={{
          display: 'grid',
          overflowY: 'auto',
          height: 'calc(100% - 100px)',
        }}
      >
        {attributes?.length === 0 && (
          <Paper
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
          >
            <NoDataOverlay
              text={
                totalAttributeCount > 0 ? 'No matching indexed attributes' : 'No indexed attributes'
              }
            />
          </Paper>
        )}

        {attributes.map((attributeCategory: CollectionStatsAttributesInner) => {
          return (
            <Accordion
              defaultExpanded
              sx={{
                '&.MuiAccordion-root': {
                  margin: '0 !important',
                  '&:not(:last-child)': {
                    borderBottom: '0 !important',
                  },
                },
              }}
              key={attributeCategory?.name}
            >
              <AccordionSummary
                expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
                sx={{
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? 'rgba(255, 255, 255, .05)'
                      : 'rgba(0, 0, 0, .03)',
                  flexDirection: 'row-reverse',
                  minHeight: '25px !important',
                  '.Mui-expanded': {
                    minHeight: '25px !important',
                  },
                  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                    transform: 'rotate(90deg)',
                    mr: 2,
                  },
                  '& .MuiAccordionSummary-content.Mui-expanded': {
                    margin: '10px !important',
                  },
                }}
              >
                <Typography variant="body2" display="flex" sx={{ alignItems: 'center' }}>
                  {attributeCategory.name}
                </Typography>
              </AccordionSummary>

              <AccordionDetails
                sx={{
                  padding: 0,
                  paddingLeft: '10px',
                  borderTop: '1px solid rgba(0, 0, 0, .125)',
                }}
              >
                {orderBy(
                  attributeCategory.values,
                  (attribute: CollectionStatsAttributesInnerValuesInner) => attribute?.name
                ).map((attribute: CollectionStatsAttributesInnerValuesInner) => {
                  return (
                    <Box key={attribute?.name} sx={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        size="small"
                        sx={{ mr: 1 }}
                        checked={checked?.some(a => a.source === attribute)}
                        onChange={e =>
                          handleCheckedChanged(e.target.checked, attribute, attributeCategory)
                        }
                      />
                      <Typography sx={{ mr: 1 }} variant="body2">
                        {attribute?.name}
                      </Typography>
                      <Chip label={attribute?.count} />
                    </Box>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
};

export default ItemAttributeGridFilter;
