import PermissionModalButton from '@/common/button/PermissionModal';
import SetupStripeForm from './setup/SetupForm';

export const ConnectStripeAccountButton = () => {
  return (
    <PermissionModalButton
      modalTitle="Connect stripe account"
      buttonLabel="Connect"
      modalDescription="Select your platform stripe account and connect type"
      requiredPermissions={['payment_integrations:create']}
    >
      {({ closeModal }) => {
        const close = () => {
          closeModal();
        };

        return <SetupStripeForm onCancel={close} />;
      }}
    </PermissionModalButton>
  );
};

export default ConnectStripeAccountButton;
